.m80 {
    margin: 25px 0;
    // .hbspt-form {
    //     label {
    //         display: none
    //     }
    //     input, select {
    //         @extend .form-control;
    //         margin: 0 12px 12px 0 !important;
    //         &.invalid {
    //             @extend .form-control.is-invalid;
    //         }
    //         &.hs-button {
    //             @extend .green-btn;
    //         }
    //     }
    //     .hs-error-msgs {
    //         padding: 0 !important;
    //         li {
    //             margin-top: -10px;
    //             margin-bottom: 15px;
    //             list-style: none !important;
    //             label {
    //                 display: block;
    //                 color: #dc3545;
    //                 margin-bottom: 0;
    //             }
    //         }
    //     }
    //     .inputs-list {
    //         padding: 0 !important;
    //         margin-bottom: 15px;
    //         li {
    //             list-style: none !important;
    //             label {
    //                 display: block;
    //                 color: #000;
                    
    //                 a {
    //                     color: $blue;
    //                 }
                    
    //                 input {
    //                     @extend .form-check-input;
    //                     width: 20px;
    //                     height: 20px;
    //                     border-radius: 2px;
    //                     border: solid 1px #ced4da;
    //                     display: inline;
    //                     padding: 0 10px;
    //                 }
    //             }
    //         }

    //     }
    // }
    .hs-col2 {
        img {
            width: 100%;
            height: auto;
        }
    }
    .col2_form_title {
        font-size: 22px;
        font-weight: 900;
        margin-bottom: 43px;
        line-height: 1.5;
    }
    // REPEATING HS FORM STYLING TO STYLE THE RELOADED FORM
    .hs-form-container-reload {
        position: relative;
        // background-color: red;
        .hs-reload-loading {
            @extend .form-loading;
        }
    }
    .hs-form-container {
        label {
            display: none
        }
        input, select {
            @extend .form-control;
            margin: 0 12px 12px 0 !important;
            &.invalid {
                @extend .form-control.is-invalid;
            }
            &.hs-button {
                @extend .green-btn;
            }
        }
        .inputs-list {
            padding: 0 !important;
            margin-bottom: 15px;
            li {
                list-style: none !important;
                label {
                    display: block;
                    color: #000;
                    
                    a {
                        color: $blue;
                    }
                    
                    input {
                        @extend .form-check-input;
                        width: 20px;
                        height: 20px;
                        border-radius: 2px;
                        border: solid 1px #ced4da;
                        display: inline;
                        padding: 0 10px;
                    }
                }
            }

        }
        .hs-error-msgs {
            padding: 0 !important;
            li {
                margin-top: -10px;
                margin-bottom: 15px;
                list-style: none !important;
                label {
                    display: block;
                    color: #dc3545;
                    margin-bottom: 0;
                }
            }
        }
    }
    .add-more-contacts button {
        font-weight: 900;
        font-size: 18px;
        margin-top: 15px;
    }
}
