.registration-new{
    background-color: #fff;
    background-image: linear-gradient(90deg, #0064c8 0%, #003264 100%);
    background-repeat: no-repeat;
    background-size: 50% 100%;
    max-width: inherit;
    min-height: 900px;
    &:lang(ar) {
        background-image: linear-gradient(90deg, #003264 0%, #0064c8 100%);
        background-position: right;
        @include media-breakpoint-down(xl) {
            background-image: none;
        }
    }
    @include media-breakpoint-down(xl) {
        background-image: none;
        max-width: 500px;
        margin: 0 auto;
    }
    &::before {
        content: inherit;
    }
    h1 {
        color: #FFF;
        text-align: left !important;
        font-weight: 900 !important;
        margin-bottom: 90px !important;
        font-size: 44px !important;
        @include media-breakpoint-down(xl) {
            color: $blue;
            text-align: center !important;
            margin-bottom: 10px !important;
            font-size: 35px !important;
        }
        &:lang(ar) {
            text-align: right !important;
        }
    }
    h2{
        font-size: 16px;
        color: #000;
        text-align: center;
        font-weight: $regular;
        margin-bottom: 25px;
    }
    .registrationForm {
        margin: 0 !important;
    }
    // FORM
    .registration-form {
        color: #FFF;
        padding: 75px 100px 0 0;
        &:lang(ar) {
            padding: 75px 0 0 100px;
            @include media-breakpoint-down(xl) {
                padding: 0 15px;
            }   
        }
        @include media-breakpoint-down(xl) {
            padding: 0 15px;
            // min-height: inherit;
        }

        // LOADING
        .form-loading {
        top: 300px;
        border-color: rgba(255, 255, 255, .25);
        border-right-color: rgba(255, 255, 255, 1);
            @include media-breakpoint-down(md) {
                border-color: rgba(29, 98, 173, .25);
                border-right-color: rgba(29, 98, 173, 1);
            }
        }
        input {
        // border: solid 1px #969696 !important;
        &::placeholder {
            color: #bcbcbc !important;
        }
        &.is-invalid {
            border-radius: 0.3rem 0.3rem 0 0 !important;
            border-color: #F00 !important;
            border-bottom: 0 !important;
        }
        }
        .signup-error {
        background-color: #FFF !important;
        >span >div {
            color: #F00 !important;
            margin: -2px 0 0 !important;
            font-weight: 400 !important;
            border: 1px solid #F00 !important;
            border-radius: 0 0 0.3rem 0.3rem !important;
            a {
                color: $blue !important;
                text-decoration: none !important;
            }
        }
        &#tos_error {
            background-color: #F00 !important;
            >span >div {
            margin-top: 5px !important;
            border-radius: 0.3rem !important;
            color: #FFF !important;
            }
        }
        }

        .pr_checkbox {
        padding-top: 6px;
        padding-left: 0;
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        color-adjust: exact;
        @include media-breakpoint-up(xl) {
            padding: 0;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        .checkmark {
            position: absolute;
            top: calc(50% - 16px);
            right: 0;
            height: 32px;
            width: 52px;
            background-color: #d8d8d8;
            border: 1px solid #FFFFFF;
            border-radius: 36.5px;
            left: inherit;
            &:lang(ar) {
                @include media-breakpoint-down(xl) {
                    left: 0 !important;
                    right: initial !important;
                }   
            }
            @include media-breakpoint-up(xl) {
                top: 4px;
                left: 0px;
                right: inherit;
                height: 20px;
                width: 20px;
                border-radius: 3px;
                background-color: unset;
            }
            &::after {
                content: "";
                position: absolute;
                display: block;
                @include media-breakpoint-up(xl) {
                    display: none;
                }
            }
        }
        .chekmark-label {
            font-size: 18px;
            max-width: 207px;
            color: #000;
            padding: 0;
            display: block;
            a {
                color: #000;
            }
            @include media-breakpoint-up(xl) {
                padding-left: 30px;
                max-width: inherit;
                padding-top: 3px;
                color: #FFF;
                &:lang(ar) {
                    padding-left: inherit;
                    padding-right: 30px;
                }
            a {
                color: #FFF;
            }
            }
        }
        a {
            text-decoration: underline !important;
            cursor: pointer;
        }
        }
        .pr_checkbox input:checked ~ .checkmark {
        background-color: #28965a;
            @include media-breakpoint-up(xl) {
                background-color: inherit;
            }
        }
        
        
        /* Show the checkmark when checked */
        .pr_checkbox input:checked ~ .checkmark:after {
        left: 21px;
        transition: all 0.25s ease;
        @include media-breakpoint-up(xl) {
            display: block;
        }
        }
        .pr_checkbox .checkmark:lang(ar){
            left: inherit !important;
            right: 0;
        }
        /* Style the checkmark/indicator */
        .pr_checkbox .checkmark:after {
        left: 1px;
        top: 1px;
        width: 28px;
        height: 28px;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.04);
        border-radius: 50%;
        box-shadow: 0 3px 1px 0 rgba(0,0,0, 0.1);
        transition: all 0.25s ease;
            @include media-breakpoint-up(xl) {
                left: 5px !important;
                top: 0;
                width: 8px;
                height: 14px;
                border: solid #FFFFFF;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                background-color: unset;
                border-radius: unset;
            }
        }
        .mbstep-1 {
        margin-bottom: 35px;
        }
        .consultation-check {
            margin: 40px 0 10px;
        }
        .consultation-time {
            margin-bottom: 40px;
        }
        .promo-check {
            margin: 18px 0 25px;
        }
        .promo-code {
            margin-bottom: 25px;
            display: none;
        }
        .mbstep-2 {
        margin-bottom: 10px;
        input {
            padding: 9px 15px;
        }
        }
        .signup-phone {
        input {
            padding-left: 100px;
        }
        .select-country {
            width: 100px;
            .btn.dropdown-toggle {
            font-size: 18px;
            color: #bcbcbc;
            }
        }
        }
        .signup-password i {
        position: absolute;
        top: calc(50% - 15px) !important;
        right: 10px;
        &:lang(ar) {
            right: inherit;
            left: 10px;
        }
        &::before {
            content: '';
            background-size: cover;
            width: 30px;
            height: 30px;
            display: block;
            opacity: 0.5;
        }
        &.fa-eye {
            &::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUgMTJjMCAxLjY1NC0xLjM0NiAzLTMgM3MtMy0xLjM0Ni0zLTMgMS4zNDYtMyAzLTMgMyAxLjM0NiAzIDN6bTktLjQ0OXMtNC4yNTIgNy40NDktMTEuOTg1IDcuNDQ5Yy03LjE4IDAtMTIuMDE1LTcuNDQ5LTEyLjAxNS03LjQ0OXM0LjQ0Ni02LjU1MSAxMi4wMTUtNi41NTFjNy42OTQgMCAxMS45ODUgNi41NTEgMTEuOTg1IDYuNTUxem0tNyAuNDQ5YzAtMi43NjEtMi4yMzgtNS01LTUtMi43NjEgMC01IDIuMjM5LTUgNSAwIDIuNzYyIDIuMjM5IDUgNSA1IDIuNzYyIDAgNS0yLjIzOCA1LTV6Ii8+PC9zdmc+");
            }
        }
        &.fa-eye-slash {
            &::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTEuODg1IDE0Ljk4OGwzLjEwNC0zLjA5OC4wMTEuMTFjMCAxLjY1NC0xLjM0NiAzLTMgM2wtLjExNS0uMDEyem04LjA0OC04LjAzMmwtMy4yNzQgMy4yNjhjLjIxMi41NTQuMzQxIDEuMTQ5LjM0MSAxLjc3NiAwIDIuNzU3LTIuMjQzIDUtNSA1LS42MzEgMC0xLjIyOS0uMTMtMS43ODUtLjM0NGwtMi4zNzcgMi4zNzJjMS4yNzYuNTg4IDIuNjcxLjk3MiA0LjE3Ny45NzIgNy43MzMgMCAxMS45ODUtOC40NDkgMTEuOTg1LTguNDQ5cy0xLjQxNS0yLjQ3OC00LjA2Ny00LjU5NXptMS40MzEtMy41MzZsLTE4LjYxOSAxOC41OC0xLjM4Mi0xLjQyMiAzLjQ1NS0zLjQ0N2MtMy4wMjItMi40NS00LjgxOC01LjU4LTQuODE4LTUuNThzNC40NDYtNy41NTEgMTIuMDE1LTcuNTUxYzEuODI1IDAgMy40NTYuNDI2IDQuODg2IDEuMDc1bDMuMDgxLTMuMDc1IDEuMzgyIDEuNDJ6bS0xMy43NTEgMTAuOTIybDEuNTE5LTEuNTE1Yy0uMDc3LS4yNjQtLjEzMi0uNTM4LS4xMzItLjgyNyAwLTEuNjU0IDEuMzQ2LTMgMy0zIC4yOTEgMCAuNTY3LjA1NS44MzMuMTM0bDEuNTE4LTEuNTE1Yy0uNzA0LS4zODItMS40OTYtLjYxOS0yLjM1MS0uNjE5LTIuNzU3IDAtNSAyLjI0My01IDUgMCAuODUyLjIzNSAxLjY0MS42MTMgMi4zNDJ6Ii8+PC9zdmc+");
            }
        }
        }
        .personal-info {
            margin: 25px 0 10px;
            font-size: 14px;
            @include media-breakpoint-down(xl) {
                color: #000;
            }
        }
        select:invalid {
        color: rgba(0,0,0,.3);
        }
        .btn.signup-blue-btn {
        background-color: #28965a;
        border-color: #28965a;
        font-size: 22px;
        font-weight: 900;
        }
        .form-check {
        padding-left: 0;
        }
    }



    // SIDEBAR
    .registration-sidebar {
        padding: 75px 0 0 100px;
        color: #000;
        &:lang(ar) {
            padding: 75px 100px 0 0;
            @include media-breakpoint-down(xl) {
                padding: 20px 15px 0;
            }
        }
        @include media-breakpoint-down(xl) {
            padding: 20px 15px 0;
        }
        .registration-sidebar2-top {
            p {
                font-size: 16px;
                &.sb2-title {
                font-weight: 900;
                color: $blue;
                font-size: 25px;
                letter-spacing: .43px;
                margin-bottom: 20px;
                line-height: 30px;
                }
            }
            img {
                margin: 75px 0 100px;
                width: 100%;
            }
            .logos{
                display: flex;
                justify-content: flex-start;
                .logo{
                    margin: 20px 0;
                    img{
                        height: 40px;
                        width: auto !important;
                        margin: 0 auto !important;
                        max-width: 100%;
                    }
                }
            }
        }
        .sb2-cont-block {
        margin-bottom: 100px;
        p {
            font-size: 18px;
            margin-bottom: 25px;
            &.sb2-cont-title {
            color: $blue;
            font-weight: 900;
            font-size: 22px;
            letter-spacing: .38px;
            margin-bottom: 30px;
            }
        }
        img {
            width: 100%;
            max-width: 400px;
            margin: inherit;
        }
        .sb2-content-list {
            padding: 3px 0 2px 40px;
            background-image: var(--icon);
            background-repeat: no-repeat;
            background-size: 30px 30px;
            &:lang(ar) {
                padding: 3px 40px 2px 0;
                background-position: right;
            }
        }
        }
    }
    &.from-app {
        background-color: #FFF;
        background-image: inherit;
        max-width: 700px;
        margin: 0 auto;
        .registration-form {
            h1 {
                color: $blue;
            }
            .pr_checkbox {
                .chekmark-label {
                    color: #000;
                    a {
                        color: #000;
                    }
                }
                .checkmark {
                    border-color: $blue;
                    &::after {
                        border-color: $blue;
                    }
                }
            }
        }
    }
}
.stickForm {
    position: sticky;
    transition: all .5s ease;
    top: 141px;
    &.step2scroll {
        top: -75px;
        transition: all 0.5s ease;
        @include media-breakpoint-down(xl) {
            top: inherit;
        }
    }
    @include media-breakpoint-down(xl) {
        position: inherit;
        top: inherit;
    }
}
#sb-showInfo {
    padding: 0;
    margin-bottom: 100px;
    @include media-breakpoint-down(xl) {
        text-decoration: underline;
        color: #000;
    }
    &:focus {
        box-shadow: inherit;
    }
}
.reg_legal-notice {
    margin-top: 50px;
    align-items: center;
    padding-bottom: 55px;
    @include media-breakpoint-down(xl) {
        padding-bottom: 55px;
        border-bottom: 1px solid #979797;

        .col-6.col-xl{
            text-align: left;
            padding-left: 15px;
            &:first-of-type {
                text-align: right;
                padding-right: 15px;
            }
        }

    }
    
    img {
        filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(331deg) brightness(108%) contrast(101%);
        width: 70px;
        height: auto;
        opacity: .5;
        @include media-breakpoint-down(xl) {
            filter: inherit;
        }
    }
    p {
        font-size: 13px;
        // text-align: left;
        margin: 0;
        @include media-breakpoint-down(xl) {
            color: #000;
            margin-top: 20px;
            font-size: 11px;
            text-align: center;
        }
    }
}



