.m39 {
    padding: 75px 0;
    p.m39_numbers {
        font-size: 50px;
        color: #000000;
        text-align: center;
        font-weight: 900;
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }
    .mtt_number {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        padding-top: 45px;
        @include media-breakpoint-down(lg) {
             max-width: 235px;
            margin: 0 auto 60px;
            background-size: 175px;
        }
        p {
            font-size: 18px;
            font-weight: 300;
            margin: 0;
            &.m39_number {
                font-weight: 900;
                font-size: 100px;
                line-height: 100px;
                color: #0064C8;
            }
        }
      }
}