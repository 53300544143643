.m60{
    padding: 45px 0;
    color: $black;
    .title{
        font-size: 70px;
        font-weight: $black-bold;
        line-height: 1.2;
        @include media-breakpoint-down(md) {
            font-size: 32px;
            line-height: 36px;
        }
    }
    .col-lg-4{
        @include media-breakpoint-down(lg) {
            margin-bottom: 25px;
        }
    }
    .block{
        background-color: #f7f7f7;
        height: 100%;
        border-radius: 15px;
        padding: 25px 25px 250px;
        position: relative;
        @include media-breakpoint-down(lg) {
            padding: 25px 25px 0;
        }
        @include media-breakpoint-down(md) {
            padding: 25px 0 0;
        }
        .block-image{
            width: 100%;
            height: auto;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            border-radius: 0 0 15px 15px;
            @include media-breakpoint-down(lg) {
                position: inherit;
            }
        }
        .content{
            padding: 15px 15px 60px;
            .block-title{
                font-size: 22px;
                font-weight: 900;
                margin-bottom: 18px;
                padding-left: 0;
                margin-top: 0;
            }
            .block-desc{
                font-size: 18px;
                font-weight: $light;
                line-height: 26px;
            }
            .cta{
                display: block;
                // font-weight: $black-bold;
                // color: $white;
                // font-size: 22px;
            }
        }
    }
}