.m34{
    background: var(--bg) center no-repeat,linear-gradient(-44deg, #0064C8 0%, #003164 100%);
    background-size: cover !important;
    padding: 30px 0 50px;
    height: 300px;
    @include media-breakpoint-down(md) {
        background: var(--mobileBg) center no-repeat,linear-gradient(-44deg, #0064C8 0%, #003164 100%);
        height: 650px;
    }
    .banner{
        color: $white;
        align-items: center;
        @include media-breakpoint-down(md) {
            justify-content: space-between;
        }
        h2{
            font-weight: $black-bold;
            font-size: 100px;
            letter-spacing: 1.72px;
            margin-bottom: 30px;
            margin-top: 0;
            @include media-breakpoint-down(xl) {
                font-size: 80px;
            }
            @include media-breakpoint-down(lg) {
                font-size: 50px;
            }
        }
        p{
            font-size: 22px;
            margin: 18px auto;
            line-height: 1.5;
            font-weight: $bold;
        }
        .pr-btn-group{
            display: block;
            a{
                &:nth-child(2){
                    margin-top: 24px;
                }
            }
        }
    }
}