.m67b{
    padding: 70px 0;
    .title{
        color: $blue;
        margin-bottom: 30px;
    }
    .our-values{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 0;
        @include media-breakpoint-down(sm){
            gap: 12px;
            margin: 0;
        }
        .block{
            width: calc(100% / 4 - 15px);
            background-color: #F2F2F2;
            padding: 20px 20px 32px;//30px;
            border-radius: 10px;
            align-self: stretch;
            &.hashtag{
                padding: 40px 30px 30px;
            }
            @include media-breakpoint-down(lg){
                width: calc(100% / 3 - 14px);
            }
            @include media-breakpoint-down(md){
                width: calc(100% / 2 - 10px);
            }
            @include media-breakpoint-down(sm){
                width: 100%;
            }
            img{
                height: 57px;
                width: auto;
                margin: 24px;//30px;
            }
            h3{
                font-weight: $black-bold;
                font-size: 27px;
                line-height: 32px;
                margin: 0 0 24px; //10px;
                & + img{
                    width: 100%;
                    height: auto;
                    margin: 48px 0 20px;
                }
            }
            p{
                font-weight: 300;
                font-size: 18px;
                line-height: 22px;
                margin: 0;
                strong,b{
                    font-weight: $black-bold;
                }
            }
        }
    }
}