.m01f{
    padding: 70px 0;
    background-color:  $white;
    //background-image: var(--bg);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    @include media-breakpoint-down(lg) {
        background-image: var(--mobilebg);
        background-position: bottom center;
        background-size: contain;
        position: relative;
        padding-bottom: 90vw;
    }
    &::before{
        content: '';
        position: absolute;
        background-image: var(--bg);
        background-position: right center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 50%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 8;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    &::after{
        content: '';
        position: absolute;
        background-color: $white;
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 98;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    &.curved{
        padding: 0;
        @include media-breakpoint-down(lg) {
            padding-bottom: 90vw;
        }
        .col-lg-7{
            padding: 70px 12px;
            position: relative;
            height: 100%;
            @include media-breakpoint-down(lg) {
                position: unset;
                padding: 23px 12px;
            }
            &::before{
                content:' ';
                position: absolute;
                background-image: url('images/curved-bg.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;
                height: 100%;
                width: 100%;
                top: 0;
                right: -190px;
                z-index: 9;
                @include media-breakpoint-down(xl) {
                    right: -200px;
                }
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
            .hero-content{
                position: relative;
                z-index: 99;
            }
        }
    }
    &.bg-left{
        background-position: left center;
        @include media-breakpoint-down(lg) {
            background-position: bottom center;
        }
        &::before{
            background-position: left center;
            right: auto;
            left: 0;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        &::after{
            right: 0;
            left: auto;
        }
        .col-lg-7{
            padding: 70px 12px;
            position: relative;
            height: 100%;
            &::before{
                transform: rotateY(180deg);
                right: auto;
                left: -190px;
                @include media-breakpoint-down(xl) {
                    left: -200px;
                }
            }
            
            .hero-content{
                position: relative;
                z-index: 99;
                padding-left: 80px;
                @include media-breakpoint-down(lg){
                    padding-left: 0;
                }
            }
        }
    }
    h1 {
        display: inline-block;
        font-size: 16px;
        padding: 8px 12px;
        background-color: rgba(248, 248, 248, 1);
        border-radius: 8px;
        font-weight: $black-bold;
        margin: 32px 0 0;
        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
    }
    h2 {
        font-size: 50px;
        color: $blue;
        font-weight: $black-bold;
        margin: 32px 0;
        @include media-breakpoint-down(lg) {
            font-size: 40px;
            margin: 32px 0 28px;
        }
    }
    h3 {
        font-size: 27px;
        font-weight: $medium;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
        }
    }
    img.logo {
        width: 89px;
        height: auto;
        margin-top: 50px;
        @include media-breakpoint-down(md) {
            margin-top: 32px;
        }
    }

    .app-stores {
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-down(md) {
            display: none;
        }
        .app-store {
            display: inline-block;
            img {
                filter: invert(100%);
                opacity: 0.4;
            }
            
        }
    }
    .mockup{
        position: relative;
        z-index: 99;
        width: auto;
        height: auto;
        max-width: 100%;
    }
    &.m01fb {
        padding: inherit;
        height: 600px;
        @include media-breakpoint-down(lg) {
            height: inherit;
            padding-bottom: 90vw;
        }
        .container, .row {
            height: 100%;
        }
        .col-lg-7 {
            padding: 0 12px;
            h3 {
                font-weight: 900;
            }
            &::before {
                right: -170px;
            }
        }
        &.bg-left {
            .col-lg-7 {
                &::before {
                    left: -170px;
                }
                .hero-content {
                    padding-left: 40px;
                    @include media-breakpoint-down(lg) {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    &:lang(ar) {
        direction: ltr;
        .col-lg-7 .hero-content {
            direction: rtl;
        }
    }
    &.curved-mini {
        background: $white;
        background: linear-gradient(90deg, $white 0%, $white 50%, $blue 50%, $blue 100%);
        position: relative;
        min-height: 400px;
        z-index: 2;
        overflow: hidden;
        @include media-breakpoint-down(lg) {
            min-height: inherit;
            padding: inherit;

        }
        @include media-breakpoint-down(sm) {
            background: $blue;
        }
        .container, .row {
            min-height: 400px;
            @include media-breakpoint-down(lg) {
                min-height: inherit;
            }
        }
        // background-color: $blue;
        // .row {
        //     align-items: inherit !important;
        // }
        &::before {
            content: '';
            position: absolute;
            left: calc(50% - 475px);
            top: -775px;
            height: 1200px;
            width: 1200px;
            background-color: #FFF;
            border-radius: 50%;
            z-index: -1;
            background-image: none;
            right: inherit;
            background-position: inherit;
            background-size: inherit;
            background-repeat: inherit;
            @include media-breakpoint-down(xl) {
                right: inherit;
            }
            @include media-breakpoint-down(lg) {
                display: block !important;
                left: inherit;
                right: -50px;
                top: -250px;
                width: 800px;
                height: 800px;
            }
            @include media-breakpoint-down(md) {
                top: -275px;
                right: -20px;
            }
            @include media-breakpoint-down(sm) {
                top: -225px;
                right: -40px;
            }
        }
        &::after {
            content: none;
        }
        .col-lg-6.offset-md-1 {
            @include media-breakpoint-down(lg) {
                display: flex !important;
                img {
                    max-width: 100%;
                    width: 375px;
                    margin: 0 auto;
                }
            }
        }
        .col-lg-5 {
            padding-right: 0;
            @include media-breakpoint-down(lg) {
                padding-right: calc(var(--bs-gutter-x) * .5);
            }
        }
    }
}
.m01f.curved.m01fb {
    .app-stores {
        display: flex !important;
        @include media-breakpoint-down(lg) {
            width: 100%;
            justify-content: center;
            margin: 24px 0;
        }
    }
}