.m59{
    .numbers{
        display: flex;
        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding: 44px 0 60px;
        }
        &:has(> :nth-child(4)) {
            .facts{
                .number{
                    font-size: 25px;
                }
            }       
        }
        &:has(> :nth-child(5)) {
            .facts{
                flex-wrap: wrap;
            }       
        }
        .facts{
            width: calc(100% / 3);
            padding-right: 36px;
            max-width: 240px;
            @include media-breakpoint-down(lg) {
                padding-right: 25px;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                justify-content: center;
                text-align: center;
                max-width: 100%;
                padding-right: 0;
            }
            .number{
                font-size: 75px;
                font-weight: $black-bold;
                background-color: #003264;
                background-image: linear-gradient(0deg, #003264, #0064C8);
                background-size: 100%;
                background-repeat: repeat;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                margin: 20px 0 0;
                line-height: 124px;
                @include media-breakpoint-down(lg) {
                    font-size: 46px;
                    line-height: 60px;
                }
                &:has(> :nth-child(6)) {
                    font-size: 35px;
                }
                
            }
            .txt{
                font-weight: $black-bold;
                color: #003264;
                font-size: 18px;
                line-height: 22px;
                margin: 10px 0;
            }
        }
    }
    img{
        display: block;
        width: 100%;
        height: auto;
    }
}