.m102 {
    padding: 32px 0;
    p {
        font-size: 18px;
        margin: 0;
        margin-top: 12px;
        &.title {
            font-size: 40px;
            font-weight: 900;
            color: $blue;
        }
    }
    .download-box {
        background-color: #F8F8F8;
        margin: 32px 0;
        padding: 24px;
        display: flex;
        border-radius: 8px;
        gap: 24px;
        align-items: center;
        @include media-breakpoint-down(md) {
            margin: 12px 0;
        }
        .icon {
            max-width: 109px;
            @include media-breakpoint-down(md) {
                display: none;
            }
            img {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
            }
        }
        .text {
            width: calc(100% - 380px);
            @include media-breakpoint-down(md) {
                width: 100%;
            }
            .title {
                font-size: 27px;
                font-weight: 900;
                color: #000;
                margin: 0;
                @include media-breakpoint-down(md) {
                    font-size: 22px;
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: calc(50% - 6px);
                        background-image: url('images/arrow-fill.svg');
                        width: 12px;
                        height: 12px;
                        background-size: cover;
                        transform: rotate(90deg);
                        transition: all 0.25s ease;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                .description {
                    display: none;
                }
            }
        }
        .right-button {
            width: 200px;
            margin-left: auto;
            @include media-breakpoint-down(md) {
                display: none;
            }
            img {
                display: none;
            }
            .pr-btn {
                width: 100%;
                // border-radius: 8px;
                // padding: 12px;
                // font-size: 22px;
                // // font-weight: 900;
                // border: 1px solid #B1B5B9;
                margin-left: auto;
                max-width: 200px;
                position: relative;
                // &:hover {
                //     background-color: rgba(0, 0, 0, 0.05);
                // }
                .msg {
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.75);
                    font-size: 16px;
                    color: #fff;
                    font-weight: 400;
                    padding: 8px;
                    border-radius: 8px;
                    display: block;
                    bottom: -20px;
                    left: calc(50% - 31px);
                    display: none;
                }
            }
        }
        &:last-of-type {
            margin-bottom: 64px;
        }
        &.opened {
            @include media-breakpoint-down(md) {
                display: block;
                .text  {
                    .title::after {
                        transform: rotate(180deg);
                        transition: all 0.25s ease;
                    }
                    .description {
                        display: block;
                        margin: 20px 0 0;
                    }
                }
                .right-button {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    margin: 20px 0 0;
                    width: 100%;
                    img {
                        display: block;
                    }
                    a {
                        display: block;
                        width: inherit;
                        
                    }
                }

            }
            
        }
    }
    .download-box-full {
        background-color: #F8F8F8;
        border-radius: 8px;
        padding: 64px;
        margin-bottom: 32px;
        @include media-breakpoint-down(md) {
            padding: 16px;
        }
        img {
            border-radius: 8px;
            @include media-breakpoint-down(md) {
                margin-bottom: 32px;
            }
        }
        p {
            font-size: 18px;
            margin-bottom: 32px;
            &.title {
                font-size: 27px; 
                font-weight: 900;
                color: #000;
            }
        }
        ul {
            margin-bottom: 32px;
            list-style: disc;
            padding-left: 25px;
            li {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
        a.btn {
            min-width: 166px;
            text-align: center;
            border: 1px solid #B1B5B9;
            font-size: 22px;
            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }

    hr {
        background-color: #B1B5B9;
        margin: 0;
    }
}