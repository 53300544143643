.m107 {
    .calculator {
        padding: 64px 0;
        @include media-breakpoint-down(md) {
            padding: 32px 0;
        }
        p {
            font-size: 27px;
            line-height: 1.25;
            font-weight: 900;
            @include media-breakpoint-down(md) {
                font-size: 22px;
            }
            &.black, &.blue {
                text-align: center;
                @include media-breakpoint-down(md) {
                    text-align: inherit;
                }
            }
            &.black {
                margin-bottom: 48px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 32px;
                }
            }
            @include media-breakpoint-down(md) {
                br {
                    display: none;
                }
            }
        }
        img {
            display: block;
            width: 50px;
            height: auto;
            margin: 0 auto 20px;
            @include media-breakpoint-down(md) {
                margin: 0 0 20px;
            }
        }
        .area, .blue, .black {
            max-width: 735px;
            margin: 0 auto;
        }
        .area {
            margin: 48px auto 64px;
            display: flex;
            align-items: center;
            border: 1px solid #B1B5B9;
            border-radius: 8px;
            padding: 6px;
            gap: 5px;
            position: relative;
            
            @include media-breakpoint-down(md) {
                margin: 32px 0;
            }
            &.error {
                border-color: red;
                .input, p {
                    color: red;
                }
            }
            .input {
                text-align: right;
                width: 50%;
                font-weight: 900;
                font-size: 27px;
                border: none;
                &::placeholder {
                    color: #000;
                }
                &:lang(ar) {
                    text-align: left;
                }
                @include media-breakpoint-down(md) {
                    text-align: left;
                    width: 30px;

                }
                &:focus {
                    border: none;
                    outline: none;
                }
                &#input-size {
                    position: absolute;
                    visibility: hidden;
                    @include media-breakpoint-down(md) {
                        width: inherit;
                    }
                }
            }
            p {
                margin: 0;
                width: 50%;
                font-weight: 900;
                font-size: 27px;
            }
        }
        .period {
            display: flex;
            margin-bottom: 48px;
            margin-top: 32px;
            justify-content: center;
            @include media-breakpoint-down(lg) {
                display: block;
                margin-top: 16px;
                margin-bottom: 32px;
            }
            .pr-form-check {
                width: fit-content;
                padding-right: 32px;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
                label {
                    font-size: 18px;
                    word-break: break-word;
                    padding-right: 5px;
                    &:lang(ar) {
                        padding-right: 0;
                        padding-left: 5px;
                    }
                }
            }
        }
        button {
            display: block;
            margin: 0 auto;
        }
    }
    .result {
        background-color: #F2F7FC;
        padding: 64px 0;
        text-align: center;
        position: relative;
        @include media-breakpoint-down(md) {
            text-align: inherit;
            padding: 32px 0 48px;
        }
        img {
            margin-bottom: 20px;
        }
        .blue {
            font-size: 27px;
            font-weight: 900;
            margin: 0;
        }
        .estimation {
            margin: 48px 0;
            font-size: 40px;
            font-weight: 900;
            @include media-breakpoint-down(md) {
                margin: 24px 0 32px;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .offset-lg-2:lang(ar) {
            margin-right: 16.66667%;
            margin-left: 0;
        }
    }
}
.m14 {
    .col-12 a.pr-btn.cta {
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }
    .content a.pr-btn {
        @extend .pr-primary-btn;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}