.m37 {
    padding: 60px 0;
    h2 {
        color: $blue;
        margin: 0 0 25px;
    }
    .m37-slider {
        .image{
            display: none;
            &:first-child{
                display: block;
            }
        }
        &.loaded{
            .image{
                display: block;
            }
        }
        button.slick-next,
        button.slick-prev {
            // background-color: yellowgreen;
            height: 100%;
            position: absolute;
            z-index: 9;
            width: 111px;
            visibility: hidden;
            &::before {
                content: "";
                font-family: inherit;
                font-size: inherit;
                line-height: inherit;
                opacity: inherit;
                width: 30px;
                height: 30px;
                background-image: url('images/arrow-fill.svg');
                background-size: cover;
                display: block;
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(339deg) brightness(103%) contrast(103%);
                position: absolute;
                top: calc(50% - 15px);
            }
        } 
        button.slick-next {
            right: 0;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 75%);
            &::before {
                transform: rotate(90deg);
                right: 35px;
            }
        }
        button.slick-prev {
            left: 0;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 75%);
            &::before {
                transform: rotate(-90deg);
                left: 35px;
            }
        }
        .showSliderArrow {
            visibility: visible !important;
        }
        ul.slick-dots > li {
            button:before {
                font-size: 14px;
                color: #D8D8D8;
                opacity: 1;
            }
            &.slick-active > button::before {
                color: #0064C8;
            }
        }
    }
}