.m29v2{
    padding: 70px 0;
    background-color: #FFF !important;
    h2 {
        color: $blue;
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
            margin-right: 16px;
            margin-left: 16px;
        }
    }
    a {
        color: #0064C8;
    }
    @include media-breakpoint-down(md) {
        .container {
            padding-left: 0;
            padding-right: 0;
            .row {
                margin: 0;
            }
            p {
                margin-left: 16px;
                margin-right: 16px;
            }

        }
    }
    .jobs-filter {
        border-bottom: 1px solid #d8d8d8;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 5px;
        @include media-breakpoint-down(md) {
            display: block;
        }
        .btn-group {
            button {
                padding: inherit;
                font-size: 22px;
                padding: 5px 30px 5px 10px;
                font-weight: 900;
                &:focus {
                    background-color: #f8f8f8;
                }
                &::after {
                    border: none;
                    background-image: url('images/arrow.svg');
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    top: calc(50% - 5px);
                    right: 10px;
                }
            }
            ul {
                li {
                    font-size: 18px;
                    cursor: pointer;
                    padding: 5px 10px;
                    &:hover {
                        background-color: #f8f8f8;
                    }
                }
            }
        }
        .filter-departments {
            width: calc(100% - 200px);
            ul {
                width: 300px;
            }
        }
        .filter-cities {
            width: 200px;
        }
    }
    .job {
        > button {
            display: flex;
            width: 100%;
            padding: 0;
            background: none;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            position: relative;
            &::after {
                content: '';
                background-image: url('images/arrow-fill.svg');
                width: 8px;
                height: 8px;
                background-size: contain;
                position: absolute;
                top: calc(50% - 3px);
                right: 10px;
                transform: rotate(180deg);
                transition: all 0.5s ease;
            }
            &[aria-expanded="true"] {
                &::after {
                    transform: rotate(0);
                    transition: all 0.5s ease;
                }
            }
            p {
                margin: 0;
                text-align: left;
                padding: 10px;
                &.job-title {
                    width: calc(100% - 200px);
                    font-weight: 300;
                }
                &.job-city {
                    width: 200px;
                }
            }
        }
        .collapse {
            padding: 0;
            border-bottom: 1px solid #d8d8d8;
            .job-hero {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                flex-wrap: wrap;
                @include media-breakpoint-down(md) {
                    background-color: $blue;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                
                p {
                    color: #FFF;
                    font-size: 60px;
                    font-weight: 900;
                    max-width: 600px;
                    text-align: center;
                    position: absolute;
                    @include media-breakpoint-down(lg) {
                        font-size: 40px;
                        max-width: 400px;
                    }
                    @include media-breakpoint-down(md) {
                        position: inherit;
                        max-width: inherit;
                        text-align: left;
                        font-size: 33px;
                        margin-top: 25px;
                        margin-bottom: 5px;
                        color: #ffffff;
                    }
                }
            }
            h2 {
                color: $blue;
                font-size: 40px;
                @include media-breakpoint-down(lg) {
                    font-size: 35px;
                }
            }
            .txt {
                padding: 0 25px;
                ul {
                    list-style: disc;
                    padding-left: 20px;
                    li {
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                }
                @include media-breakpoint-down(lg) {
                    padding: 0;
                }
            }
            .img {
                padding: 0 25px;
                @include media-breakpoint-down(lg) {
                    padding: 0;
                }
                img {
                    border-radius: 16px;
                    width: 100%;
                    height: auto;
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 25px;
                    }
                }
            }
            .job-data {
                padding: 40px 60px;
                @include media-breakpoint-down(md) {
                    padding: 20px 0;
                }
                .job-data-item {
                    display: flex;
                    align-items: center;
                    gap: 22px;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 10px;
                    }
                    .icon {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        img {
                            margin: auto;
                        }
                    }
                    .text {
                        p {
                            margin: 0;
                            &:first-child {
                                font-weight: 900;
                            }
                        }
                    }
                }
            }
            .what-we-do {
                background-color: #F8F8F8;
                padding:50px;
                border-radius: 12px;
                @include media-breakpoint-down(lg) {
                    padding: 25px;
                }
            }
            .your-place {
                margin-top: 60px;
                background-color: #F8F8F8;
                padding: 60px;
                border-radius: 12px;
                @include media-breakpoint-down(lg) {
                    margin-top: 30px;
                    padding: 30px;
                }
            }
            .do-look {
                margin: 60px 0;
                @include media-breakpoint-down(lg) {
                    margin: 30px 0 0;
                    .row {
                        margin: 0;
                        .col-lg-6 {
                            padding: 0;
                            margin-bottom: 24px;
                        }
                    }
                }
                .what-you-do {
                    margin-right: 15px;
                    @include media-breakpoint-down(lg) {
                        margin-right: inherit;
                    }
                }
                .what-we-look {
                    margin-left: 15px;
                    @include media-breakpoint-down(lg) {
                        margin-left: inherit;
                    }
                }
                .what-you-do {
                    background-color: #E5EFF9;
                    margin-bottom: 100px;
                }
                .what-we-look {
                    background-color: #F8F8F8;
                }
                .what-we-look,
                .what-you-do {
                    height: 100%;
                    padding: 60px;
                    border-radius: 12px;
                    @include media-breakpoint-down(lg) {
                        padding: 32px 20px;
                        border-radius: 0;
                    }
                    ul {
                        list-style: disc;
                        padding-left: 20px;
                        li {
                            font-size: 18px;
                            margin-bottom: 5px;
                            line-height: 1.5;
                        }
                    }
                }
            }
            .what-we-offer {
                border-radius: 12px;
                background-color: #F8F8F8;
                margin: 60px 0 0;
                padding: 60px;
                align-items: center;
                @include media-breakpoint-down(lg) {
                    padding: 20px;
                    margin: 30px 0 0;
                }
                h2 {
                    margin-bottom: 32px;
                }
                p {
                    margin-top: 25px;
                }

            }
        
            .job-details {
                .salary {
                    padding: 40px 60px 60px;
                    @include media-breakpoint-down(lg) {
                        padding: 20px 30px 30px;
                    }
                    h2 {
                        margin-bottom: 50px;
                        @include media-breakpoint-down(lg) {
                            margin-bottom: 20px;
                        }
                    }
                }
                .recruiter-apply {
                    padding: 40px 60px 60px;
                    @include media-breakpoint-down(lg) {
                        padding: 20px 30px 30px;
                    }
                    h2 {
                        margin-bottom: 25px;
                    }
                    .pr-btn {
                        margin-top: 20px;
                        min-width: 200px;
                        // font-weight: 900;
                        @include media-breakpoint-down(lg) {
                            width: 100%;
                        }
                    }
                    .recruiter-img {
                        img {
                            border-radius: 50%;
                        }
                    }
                    .recruiter-name {
                        @include media-breakpoint-down(md) {
                            margin-top: 20px;
                        }
                        p {
                            margin: 0;
                            font-weight: 300;
                            &:first-child {
                                font-weight: 900;
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .kununu {
                        display: flex;
                        justify-content: end;
                        gap: 50px;
                        @include media-breakpoint-down(md) {
                            gap: 20px;
                            margin-top: 20px;
                        }
                        img {
                            display: block;
                            width: 165px;
                            @include media-breakpoint-down(lg) {
                                width: calc(50% - 25px);
                                height: auto;
                            }
                            @include media-breakpoint-down(md) {
                                width: calc(50% - 10px);
                            }
                        }
                    }
                    span.de {
                        display: none;
                    }
                }
                .job-apply-container {
                    background-color: #F8F8F8;
                    margin: 60px 0 0;
                    padding: 30px;
                    border-radius: 12px;
                    margin-bottom: 50px;
                    position: relative;
                    form {
                        .input-group-text {
                            background-color: #FFF;
                        }
                        .pr-btn.pr-primary-btn {
                            // font-weight: 900;
                            // min-width: 275px;
                            // font-size: 22px;
                            margin-top: 15px;
                        }
                    }
                    @include media-breakpoint-down(md) {
                        padding: 15px;
                    }

                    .job-success {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        margin: auto;
                        left: 0;
                        top: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        p {
                            max-width: 600px;
                            text-align: center;
                            span {
                                display: block;
                                &.title {
                                    color: $blue;
                                    font-weight: 900;
                                    margin-bottom: 15px;
                                }
                            }
                            &.de {
                                display: none;
                            }
                        }
                    }
                    .job-loading {
                        height: 0;
                        width: 0;
                        padding: 15px;
                        border: 6px solid rgba(29, 98, 173, .25);
                        border-right-color: rgba(29, 98, 173, 1);
                        border-radius: 22px;
                        -webkit-animation: rotate 1s infinite linear;
                        /* left, top and position just for the demo! */
                        position: absolute;
                        left: calc(50% - 25px);
                        top: calc(50% - 25px);
                      }
                      @-webkit-keyframes rotate {
                        /* 100% keyframe for  clockwise.
                           use 0% instead for anticlockwise */
                        100% {
                          -webkit-transform: rotate(360deg);
                        }
                      }
                }
            }
        }
        .collapsing {
            // -webkit-transition-delay: 0.5s;
            // transition-delay: 0.5s;
            div {
                display: none;
            }
        }
        .job-data-de {
            .wp-job-block-row:not(.benefits) {
                p:not(.de) {
                    display: none;
                }
                p.de {
                    display: block;
                }
            }
            .benefits {
                .benefit {
                    p {
                        span:not(.de) {
                            display: none;
                        }
                        span.de {
                            display: block !important;
                        }
                    }
                }
            }
            .recruiter-apply {
                span:not(.de) {
                    display: none;
                }
                span.de {
                    display: block !important;
                }
            }
            .job-success {
                p:not(.de) {
                    display: none;
                }
                p.de {
                    display: block !important;
                }
            }
        }
    }
    .no-Jobs {
        text-align: center;
        font-size: 24px;
        color: #333;
        font-weight: 300;
        padding: 50px 0 0;
    }
    .wp-job-block-row {
        background-color: #F8F8F8;
        border-radius: 20px;
        margin-bottom: 60px;
        padding: 30px;
        @include media-breakpoint-down(md) {
            padding: 0 0 32px;
            border-radius: 0;
            margin-bottom: 24px;
        }
        .wp-job-media {
            position: relative;
            padding: 30px;
            @include media-breakpoint-down(md) {
                padding: 15px;
            }
            .wp-job-icon {
                background-color: var(--bg);
                position: absolute;
                bottom: 30px;
                left: 30px;
                width: 100px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0px 20px;
                @include media-breakpoint-down(md) {
                    left: 15px;
                    bottom: 15px;
                }
            }

            




        .plyr--video,.plyr--vimeo{
            position: relative;
            &:before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                background-image: var(--plyr-video-background) !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                opacity: 1 !important;
                z-index: 1;
            }
            &.plyr--playing, &.plyr--paused:not(.plyr--stopped){
                &:before{
                    opacity: 0 !important;
                }
                .plyr__control--overlaid {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &.plyr--stopped{
                .plyr__controls {
                    opacity: 0;
                    pointer-events: none;
                    transform: translateY(100%);
                }
            }
        }














            .plyr {
                border-radius: 20px;
                width: 100%;
                position: relative;
                .plyr__controls {
                    display: none;
                }
                &.plyr--playing {
                    .plyr__controls {
                        display: flex;
                    }
                }
            }
        }
        .wp-job-text {
            padding: 30px;
            @include media-breakpoint-down(md) {
                padding: 15px;
            }
            p {
                margin: 0;
                font-size: 18px;
                line-height: 1.5;
                &.title{
                    color: $blue;
                    font-weight: 900;
                    font-size: 40px;
                    margin-bottom: 32px;
                }
                &.de {
                    display: none;
                }
            }
        }
        .col-md-6.order-md-1 .wp-job-icon {
            right: 30px;
            left: inherit;
            border-radius: 20px 0px;
            @include media-breakpoint-down(md) {
                right: 15px;
            }
        }




        &.benefits {
            background-color: #E5EFF9;
            @include media-breakpoint-down(lg) {
                padding: 32px 15px;
            }
            h2 {
                margin-bottom: 30px;
            }
            .benefits-list {
                display: flex;
                flex-wrap: wrap;
                .benefit {
                    width: 20%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 20px;
                    padding-right: 30px;
                    margin-bottom: 50px;
                    &:nth-child(5n+5) {
                        padding-right: 0;
                    }
                    @include media-breakpoint-down(lg) {
                        width: 50%;
                        &:nth-child(2n+2) {
                            padding-right: 0;
                        }
                    }
                    // @include media-breakpoint-down(md) {
                    //     width: 100%;
                    // }
                    img {
                        display: block;
                        width: 50px;
                        height: 50px;
                    }
                    p {
                        margin: 0;
                        width: calc(100% - 70px);
                        font-size: 18px;
                        line-height: 1.2;
                        span {
                            display: block;
                        }
                        span.de {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
// NOTHING 