.quick-guide{
    .sidebar{
        position: absolute;
        top: 45vh;
        max-width: 280px;
        &.scrolled{
            position: sticky;
            top: 125px;
        }
        h4{
            font-weight: $bold;
            font-size: 16px;
            color: #00396B;
            letter-spacing: -.38px;
        }
    }
    .quick_guides{
        h1{
            font-size: 58px;
            font-weight: $light;
            color: #1D62AD;
            letter-spacing: -1.39px;
            margin: 70px 0 15px;
            @include media-breakpoint-down(lg){
                font-size: 52px;
            }
            @include media-breakpoint-down(md){
                font-size: 34px;
            }
        }
        h5{
            font-weight: $bold;
            font-size: 28px;
            color: #3D4548;
            letter-spacing: -.67px;
            line-height: 40px;
            margin: 0 0 15px;
            @include media-breakpoint-down(md){
                font-size: 18px;
            }
        }
        p{
            font-size: 16px;
            font-weight: $medium;
            line-height: 1.5;
            color: rgb(61, 69, 72);
            &.note{
                color: #1D62AD;
                border: 1px solid #1D62AD;
                border-radius: 6px;
                margin: 40px 0 80px;
                font-weight: 700;
                padding: 5px 25px;
                display: inline-block;
            }
        }
        img{
            width: 100%;
            height: auto;
        }
        .steps{
            .step{
                .step-title{
                    font-size: 36px;
                    color: #00396B;
                    letter-spacing: -.86px;
                    font-weight: $light;
                    margin-bottom: 20px;
                    span{
                        font-size: 46px;
                        font-weight: $bold;
                        padding-right: 20px;
                    }
                }
                p{

                }
                img{
                    width: 100%;
                    height: auto;
                }
                .columns{
                    img{
                        margin: 60px auto;
                        height: 180px;
                        width: auto;
                        display: block;
                    }
                    h2{
                        font-size: 36px;
                        color: #00396B;
                        letter-spacing: -.86px;
                        font-weight: $light;
                        margin: 0 0 20px;
                    }
                    .content{
                        a{
                            font-weight: $bold;
                            color: #1D62AD;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .gq_footer_col{
        @include media-breakpoint-down(lg){
            background: #E9F0FA;
            margin-top: 50px;
        }
        .quick-guide-footer{
            background: #E9F0FA;
            border-radius: 10px;
            padding: 50px 100px;
            margin-top: 50px;
            @include media-breakpoint-down(lg){
                padding: 40px 15px;
                border-radius: 0;
                margin-top: 0;
            }
            h2{
                font-weight: 300;
                font-size: 36px;
                color: #00396B;
                letter-spacing: -.86px;
                margin: 0 0 8px;
            }
            p{
                font-size: 16px;
                font-weight: $medium;
                color: #3d4548;
                a{
                    font-weight: 700;
                    color: #1D62AD;
                    text-decoration: underline;
                }
                strong{
                    color: #00396B;
                    font-weight: bolder;
                }
            }
        }
    }
    .QSG_menu{
        .menu-item{
            margin: 15px 0;
            a{
                font-weight: $bold;
                color: #00396B;
                @include media-breakpoint-down(lg){
                    font-size: 16px;
                }
            }
            &.active{
                .sub-menu{
                    display: block;
                }
            }
            .sub-menu{
                display: none;
                list-style: none;
                padding: 0 0 0 15px;
                li{
                    margin: 5px 0;
                    a{
                        color: #00396B!important;
                        font-weight: 400;
                        line-height: 25px;
                        &::before{
                            content: "\f105";
                            font-family: "fontAwesome";
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
    .qg_mobile_menu{
        background: #E9F0FA;
        .qg_mobile_menu_header {
            display: block;
            padding-left: 15px;
            width: 100%;
            font-weight: 700;
            color: #00396B!important;
            padding-top: 30px;
            padding-bottom: 30px;
            @include media-breakpoint-down(lg){
                font-size: 16px;
            }
            &[aria-expanded="true"]{
                &:after{
                    transform: rotate(180deg);
                    transition: all .2s;
                    padding-right: 10px;
                }
            }
            &:after{
                content: "\f078";
                font-family: "fontAwesome";
                display: inline-block;
                transition: all .2s;
                padding-left: 10px;
            }
        }
    }
    .qsg-mobile{
        background-color: $white;
        .QSG_menu{
            >.menu-item{
                margin: 0;
                &:first-child{
                    a{
                        border-top: 0;
                    }
                }
                >a{
                    display: block;
                    width: 100%;
                    border-top: 1px solid #E9F0FA;
                    padding: 20px 10px;
                }
            }
        }
    }
}