.m27 {
    margin: 98px 0 20px;
    h2 {
        font-size: 50px;
        color: #1d62ad;
        letter-spacing: .86px;
        font-weight: 900;
        line-height: 60px;
        margin-bottom: 28px;
        margin-top: 0;
        @include media-breakpoint-down(lg) {
            font-size: 35px;
        }
    }
    h3 {
        font-size: 22px;
        color: #000;
        letter-spacing: 0;
        font-weight: 900;
        line-height: 27px;  
        margin-bottom: 114px;
        @include media-breakpoint-down(md) {
            margin-bottom: 54px;
        }
    }
    .relocate-benefit {
        margin-bottom: 75px;;
        .relocate-icon {
            width: 40px;
            margin-right: 5px;
        }
        h3 {
            margin: 0;
            padding: 0 5px;
            font-size: 22px;
            color: #000;
            letter-spacing: 0;
            font-weight: 900;
            line-height: 27px;
        }
        p {
            font-size: 18px;
            color: #000;
            letter-spacing: 0;
            font-weight: 300;
            line-height: 22px;
            margin-top: 8px;
            margin-bottom: 0;
            width: 500px;
            max-width: 100%; 
            a {
                color: #0064c8;
                font-weight: 900;
            }
        }
    }
}