.m64{
    padding: 100px 0;
    @include media-breakpoint-down(md) {
        padding: 50px 0;
    }
    h2 {
        font-size: 40px;
        color: #0064C8;
        font-weight: 900;
        margin-bottom: 30px;
        @include media-breakpoint-down(lg) {
        text-align: center;
        }
    }
    p{
        font-size: 22px;
        font-weight: 900;
        line-height: 1.14;
        color: #000;
        margin-bottom: 0;
        @include media-breakpoint-down(lg) {
        text-align: center;
        }
    }
    .search-box{
        display: flex;
        justify-content: flex-end;
        align-items: end;
        @include media-breakpoint-down(lg) {
        justify-content: center;
        align-items: center;
        margin-top: 70px;
        }
        .search-apps{
        width: 373px;
        height: 50px;
        padding: 14px 14px 14px 40px;
        border-radius: 8px;
        box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
        background-color: #f7f7f7;
        margin: 0 0 0 12px;
        font-size: 18px;
        font-weight: 300;
        color: #000;
        border: none;
        background-image: url('images/icons/search.svg');
        background-repeat: no-repeat;
        background-position: 10px center;
        @include media-breakpoint-down(lg) {
            width: 340px;
            height: 60px;
        }
        }
    }
    .apps{
        display: flex;
        flex-wrap: wrap;
        margin: 70px auto;
        padding: 0;
        @include media-breakpoint-down(md) {
        margin: 50px auto;
        }
        .app{
        text-align: center;
        margin: 35px 0;
        width: calc(100% / 7);
        @include media-breakpoint-down(md) {
            width: calc(100% / 3);
            margin: 25px 0;
        }
        &:hover{
            text-decoration: none;
        }
        &:nth-child(n+22){
            display: none;
        }
        .icon{
            width: 84px;
            height: 84px;
            background-color: #d8d8d8;
            border-radius: 8px;
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
            margin: 0 auto;
            img{
            width: 100%;
            border-radius: 8px;
            }
        }
        h3{
            margin: 18px 0 0;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.33;
            color: #000;
        }
        }
    }
    .contact-specialist{
        display: none;
        text-align: center;
        img{
        margin-bottom: 40px;
        }
        h4{
        font-size: 18px;
        font-weight: 900;
        line-height: 1.33;
        text-align: center;
        color: #000;
        margin-bottom: 20px;
        }
        p{
        font-size: 18px;
        font-weight: 300;
        line-height: 1.33;
        text-align: center;
        color: #000;
        margin-bottom: 100px;
        }
        .contact-btn{
        display: block;
        width: 372px;
        height: 50px;
        line-height: 50px;
        margin: 0 auto;
        border-radius: 8px;
        background-color: #0064c8;
        font-size: 22px;
        font-weight: 900;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        cursor: pointer;
        border: none;
        &:hover{
            background-color: #0155a9;
            text-decoration: none;
        }
        }
    }
    .show-more{
        width: 276px;
        // height: 50px;
        // line-height: 50px;
        margin: 0 auto;
        // border-radius: 8px;
        // background-color: #0064c8;
        // font-size: 22px;
        // font-weight: 900;
        // letter-spacing: normal;
        // text-align: center;
        // color: #fff;
        cursor: pointer;
        // border: none;
        @include media-breakpoint-down(md) {
        width: 360px;
        max-width: 100%;
        }
        &:hover{
        background-color: #0155a9;
        }
    }
}