.m92{
    padding: 77px 0;
    h2{
        font-weight: $black-bold;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        @include media-breakpoint-down(lg) {
            text-align: start;
        }
    }
    .timeline{
        margin-top: 108px;
        @include media-breakpoint-down(lg) {
            margin-top: 0px;
            margin-inline-start: 28px;
        }
        .steps{
            display: flex;
            justify-content: space-between;
            position: relative;
            padding-top: 68px;
            @include media-breakpoint-down(lg) {
                flex-direction: column;
                padding-top: 40px;
            }
            &:before{
                content: '';
                position: absolute;
                height: 3px;
                width: calc(100% - 130px);
                background-color: $black;
                top: 0;
                right: 0;
                left: 0;
                margin: 0 auto;
                @include media-breakpoint-down(lg) {
                    width: 3px;
                    height: calc(100% - 320px);
                    left: 0;
                    right: auto;
                    top: 40px;
                }
            }
            .step{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: calc(100% / 5);
                @include media-breakpoint-down(lg) {
                    padding: 20px 0;
                    width: 100%;
                }
                .step-no{
                    position: relative;
                    position: absolute;
                    top: -26px;
                    width: 52px;
                    height: 52px;
                    color: $white;
                    background-color: $blue;
                    font-weight: $black-bold;
                    font-size: 28px;
                    line-height: 53px;
                    border-radius: 12px 0 12px 0;
                    text-align: center;
                    outline: white solid 25px;
                    @include media-breakpoint-down(lg) {
                        top: auto;
                        left: -26px;
                    }
                }
                img{
                    width: auto;
                    max-width: 204px;
                    max-height: 204px;
                    display: block;
                    margin-bottom: 48px;
                }
                p{
                    font-weight: $light;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    max-width: 90%;
                }
                &:last-child{
                    .step-no{
                        background-color: $green;
                        &:after{
                            position: absolute;
                            content: '';
                            width: 24px;
                            height: 24px;
                            background-image: url(images/icons/right.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            top: 0;
                            bottom: 0;
                            right: -34px;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}