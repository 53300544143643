.w01 {
    display: none;
    width: 100%;
    max-width: 376px;
    margin: 0 auto 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 10px;
    padding-bottom: 90px;
    @include media-breakpoint-down(md) {
        margin: 30px auto;
    }
    &.w01-opened {
        padding-bottom: 0;
        background-image: none !important;
    }
    .w01-step1 {
        // position: absolute;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        color: #fff;
        @include media-breakpoint-down(md) {
            padding: 0 40px;
        }
        img {
            height: 90px;
            width: auto;
            margin: 0 0 0 -25px !important;
        }
        button {
            position: absolute;
            width: calc(100% - 40px);
            bottom: 20px;
            left: 20px;
            font-size: 18px;
            font-weight: 900;
            padding: 10px 0 11px;
        }
        p {
            color: #fff;
            &.title {
                font-weight: 900 !important;
                font-size: 20px !important;
                margin-bottom: 16px !important;
                @include media-breakpoint-down(md) {
                    font-size: 26px !important;
                }
            }
            &.subtitle {
                font-size: 14px !important;
                margin-bottom: 50px !important;
                @include media-breakpoint-down(md) {
                    font-size: 16px !important;
                    margin-bottom: 150px !important;
                }
            }
        }
    }
    .w01-step2 {
        background-color: $blue;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 10px;
        color: #fff;
        p {
            margin-bottom: 10px !important;
            &.title {
                font-size: 18px;
                font-weight: 900;
            }
            &.subtitle {
                font-size: 22px;
                font-weight: 900;
            }
            &.date {
                font-weight: 400;
                font-size: 18px;
            }
        }
        .hbspt-form {
            padding: 25px;
            background: #f7f7f7;
            border-radius: 5px;
            label {
                display: none;
            }
            input {
                margin-top: 10px !important;
            }
            ul {
                margin-bottom: 0;
                &.hs-error-msgs{
                    margin-top: 0;
                    li{
                        label{
                            font-size: 10px;
                            color: #cf2319;
                            font-weight: 400;
                        }
                    }
                }
                &.inputs-list{
                    padding: 0;
                    li{
                        margin-top: 0;
                        label{
                            display: block;
                            margin-bottom: 0;
                            input{
                                margin-top: 5px !important;
                                margin-right: 5px;
                            }
                            &.hs-error-msg{
                                font-size: 10px;
                                color: #cf2319;
                                font-weight: 400;
                            }
                            span{
                                color: #000;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 15px;
                                a{
                                    color: $blue;
                                }
                            }
                        }
                    }
                }
            }
            .hs-fieldtype-booleancheckbox {
                ul {
                    padding-left: 0;
                    li {
                        list-style: none;
                        margin-top: 10px;
                        color: #000;
                        a  {
                            color: $blue;
                        }
                    }
                } 
                label {
                    display: block;
                }
            }
            .hs_submit.hs-submit {
                margin-top: 30px;
                input {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 900;
                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}