.m15{
    //background-image: linear-gradient(225deg, #0064C8 0%, #003264 100%);
    background-color: $blue;
    padding: 50px 0;
    &.bg-white {
        background-color: #FFF;
        a.link,
        .banner {
            color: #000 !important;
        }
    }
    .banner{
        text-align: center;
        color: $white;
        h2{
            font-weight: $black-bold;
            font-size: 54px;//40px
            line-height: 1.1;//48px
            margin: 0 auto 24px;
            @include media-breakpoint-down(md) {
                font-size: 40px; //30px
            }
        }
        p{
            font-size: 27px;//18px
            margin: 18px auto;
            line-height: 1.1;//22px
            @include media-breakpoint-down(md) {
                font-size: 18px; 
            }
        }
        .pr-btn-group{
            margin-top: 50px;
            justify-content: center;
            &.link{
                margin-top: 0;
                a{
                    text-decoration: underline;
                    margin-bottom: 50px;
                    display: block;
                    line-height: 28px;
                    font-size: 22px;
                    font-weight: 900;
                    color: white;
                }
            }
        }
    }
}