.m10 {
   background-color: #e9f0fa;
   padding: 100px 0 50px 0;
   h2 {
    letter-spacing: 1px;
    font-size: 36px;
    color: #3d4548;
    font-weight: 300;
    text-align: center;
    margin: 0;
   }
   .m10-testimonial.row {
    .col {
        padding-left: 15px;
        padding-right: 15px;
        min-height: 100%;
        .testimonial {
            background: #FFFFFF;
            text-align: center;
            padding: 25px;
            box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.05);
            margin: 0 0 2px;
            min-height: 325px;
            height: 100%;
            p {
                color: #3d4548;
            }
            p.title {
                color: #1d62ad;
                letter-spacing: 1px;
                font-weight: 700;
                font-size: 20px;
            }
            .testimonial_five_stars {
                .t-star {
                    background-image: url('images/icons/star.svg');
                    background-size: 15px 15px;
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    filter: invert(75%) sepia(45%) saturate(569%) hue-rotate(39deg) brightness(89%) contrast(82%);
                }
            }

        }






      }
    }
    .slick-track
    {
        display: flex !important;
    }
    
    .slick-slide
    {
        height: inherit !important;
    }
}