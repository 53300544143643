.m48{
    padding: 40px 0;
    @include media-breakpoint-down(md) {
        padding: 40px 0 0;
    }
    .flip{
        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }
    }
    .right{
        padding-left: 90px;
        @include media-breakpoint-down(xl) {
            padding-left: 15px;
        }
    }
    .left{
        @include media-breakpoint-down(md) {
            overflow: hidden;
        }
    }
    h2{
        font-size: 70px;
        color: $blue;
        font-weight: $black-bold;
        margin: 0 0 8px;
        @include media-breakpoint-down(lg) {
            font-size: 54px;
        }
        @include media-breakpoint-down(md) {
            font-size: 36px;
        }
    }
    h3{
        font-size: 22px;
        font-weight: $black-bold;
        line-height: 26px;
        margin: 16px 0;
        @include media-breakpoint-down(lg) {
            font-size: 18px;
        }
    }
    .content{
        margin-top: 65px;
        @include media-breakpoint-down(lg) {
            margin-top: 50px;
        }
        ol,ul{
            padding-left: 25px;
            li{
                font-size: 22px;
                margin-top: 15px;
                line-height: 26px;
                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                }
            }
        }
    }
    a{
        text-decoration: underline;
        color: $blue;
    }
    img{
        width: 100%;
        height: auto;
        @include media-breakpoint-down(md) {
            max-width: 260px;
            margin: 40px auto -115px;
            display: block;
        }
    }
}