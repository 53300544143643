.m66{
    .contact-hero{
        background: var(--bg) right bottom no-repeat, linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
        height: 1010px;
        @include media-breakpoint-down(xl){
            background: var(--bg) 480px no-repeat,linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
        }
        @include media-breakpoint-down(lg){
            height: auto;
            margin-bottom: 550px;
            padding: 70px 38px 0;
            background: var(--mobilebg) top right no-repeat, linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
        }
        @include media-breakpoint-down(md){
            margin-bottom: 580px;
            padding: 48px 20px 0;
            overflow: visible !important;
        }
        h1{
            font-size: 65px;
            font-weight: $black-bold;
            line-height: 1.2;
            text-transform: none;
            margin-top: 0;
            margin-bottom: 30px;
            width: 80%;
            color: $white;
            @include media-breakpoint-down(lg) {
                font-size: 48px;
            }
            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
        }
        p{
            font-size: 22px;
            letter-spacing: 0.3px;
            font-weight: $black-bold;
            margin-bottom: 40px;
            width: 90%;
            color: $white;
            line-height: 1.5;
        }
        .form-container{
            position: relative;
            @include media-breakpoint-down(md){
                position: relative;
            }
        }
        .hsform-container{
            padding: 49px;
            border-radius: 20px;
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
            background-color: #fff;
            @include media-breakpoint-down(lg){
                position: absolute;
                top: 0px;
                box-shadow: none;
                padding: 49px 0;
                right: 0;
            }
        }
    }
}