.m86{
    padding: 90px 0;
    @include media-breakpoint-down(lg){
        padding: 50px 0;
    }
    h2{
        padding-bottom: 20px;
        font-weight: $black-bold;
        font-size: 40px;
        line-height: 33px;
    }
    p{
        font-weight: $light;
        font-size: 22px;
        line-height: 22px;
    }
    &.bg-gray{
        .card-container{
            background-color: $white;
        }
    }
    &.bg-white{
        .card-container{
            background-color: #F7F7F7;
        }
    }
    .card-container{
        padding: 30px 0px 0px 74px;
        border-radius: 20px;
        margin-top: 30px;
        text-align: left;
        background-image: var(--bg);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 28%;
        @include media-breakpoint-down(lg){
            padding: 50px 30px;
        }
        @include media-breakpoint-down(md){
            background-image: none;
            
        }
        .row{
            @include media-breakpoint-down(md){
                flex-direction: column-reverse;
            }
        }
        .logo{
            height: 65px;
            width: auto;
            object-fit: cover;
            margin-bottom: 20px;
            @include media-breakpoint-down(md){
                height: 55px;
            }
        }
        h3{
            font-weight: 900;
            font-size: 32px;
            line-height: 40px;
            max-width: 80%;
            margin-bottom: 0;
            @include media-breakpoint-down(md){
                font-size: 24px;
                line-height: 30px;
                max-width: 100%;
            }
        }
        .statistics{
            display: flex;   
            align-items: center;
            margin: 40px 0 25px;
            @include media-breakpoint-down(lg){
                margin: 25px 0 25px;
            }
            p{
                font-weight: $black-bold;
                font-size: 32px;
                line-height: 40px;
                color: $blue;
                @include media-breakpoint-down(lg){
                    font-size: 24px;
                    line-height: 30px;
                }
                @include media-breakpoint-down(md){
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 0;
                }
            }
            span{
                font-weight: $black-bold;
                font-size: 130px;
                line-height: 160px;
                color: $blue;
                @include media-breakpoint-down(lg){
                    font-size: 110px;
                    line-height: 130px;
                }
                @include media-breakpoint-down(md){
                    font-size: 70px;
                    line-height: 90px;
                }
            }
        }
        img{
            height: 250px;
            width: auto;
            max-width: 100%;
            object-fit: cover;
        }
        a{
            display: inline-block;
            font-weight: $black-bold;
            font-size: 22px;
            line-height: 24px;
            border-radius: 8px;
            padding: 12px 43px;
            margin: 0 0 30px;
            @include media-breakpoint-down(md){
                font-size: 18px;
                line-height: 22px;
                padding: 8px 38px;
                margin: 0 0 0px;
            }
        }
    }
    .pr-btn{
        // display: inline-block;
        margin: 30px auto 0;
        width: auto;
        // padding: 12px 80px;
        // font-weight: $black-bold;
        // font-size: 22px;
        // line-height: 26px;
        @include media-breakpoint-down(lg){
            margin: 50px auto 0;
            max-width: 90%;
            // padding: 10px 40px;
            // font-size: 20px;
        }
    }
    iframe{
        width: 100%;
        height: auto;
        min-height: 400px;
    }
    .video-container{
        padding: 20px 60px 85px 40px;
        position: relative;
        @include media-breakpoint-down(lg){
            padding: 20px 0 20px 20px;
        }
        @include media-breakpoint-down(md){
            padding: 20px 0;
        }
        &::before{
            content:'';
            position: absolute;
            width: 80%;
            height: 69%;
            top: 47px;
            left: 7px;
            background: rgba(60, 70, 80, 0.2);
            border-radius: 6px;
            @include media-breakpoint-down(lg){
                width: 90%;
                height: 75%;
            }
            @include media-breakpoint-down(md){
                display: none;
            }
        }
        .plyr--full-ui.plyr--video .plyr__control--overlaid{
            border: 2px solid #fff !important;
            background-color: transparent !important;
        }
        .plyr--vimeo{
            position: relative;
            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background-color: #00000047;
                z-index: 1;
            }
            &.plyr--playing{
                &::before{
                    display: none;
                }
            }
        }
    }
    #player {
        padding: 0 !important;
        background: #fff !important;
        border-radius: 6px;
        
    }
}