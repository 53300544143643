.m09 {
    background-color: #00396b;
    h2 {
        margin: 0;
        text-align: center;
        padding: 50px 0;
        background-position: center;
        background-size: cover;
        color: #FFFFFF;
        font-size: 36px;
        font-weight: 300;
        letter-spacing: 1px;
    }
    .homepage-cs {
        position: relative;
        border: 1px solid #FFFFFF;
        border-left: none;
        p {
            position: absolute;
            width: 100%;
            text-align: center;
            bottom: 0;
            color: #FFFFFF;
            padding: 10px;
            z-index: 999;
            font-weight: 700;
            font-size: 20px;
        }
        img {
            display: block;
        }
        &::after {
            background-image: linear-gradient(transparent , rgba(0, 0, 0, 0.75) 150%);
            bottom: 0;
            content: "";
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 1s ease;
        }
        &:hover::after {
            background-color:  rgba(0, 0, 0, 0.5);
            transition: all 1s ease;
        }
        &:last-child {
            border-right: none;
        }
    }
    .cs-reviews {
        padding: 15px 0;
        img {
            display: block;
            @include media-breakpoint-down(md) {
                margin-bottom: 15px;
            }
        }
        .cs_review {
            color: #FFFFFF;
            p {
                font-weight: 300;
                margin: 0;
                span {
                    font-weight: 700;
                }
            }
            .cs_quote {
                font-style: italic;
            }
            &:last-child {
                text-align: right;
                margin-top: 50px;
                @include media-breakpoint-down(md) {
                    text-align: left;
                }
            }
        }
    }

}