header {
    background-color: $white;
    .header-menu{
        .menu{
            margin: 0;
            li{
                display: inline-block;
                margin: 0 10px;
                padding: 0px 10px;
                vertical-align: middle;
                position: relative;
                &.menu-item-has-children{
                    border-left: 1px solid $white;
                    border-right: 1px solid $white;
                    padding-right: 22px;
                    &:lang(ar) {
                        padding-right: 25px;
                        padding-left: 37px;
                    }
                    > a{
                        &:lang(ar) {
                            &::after {
                                margin-right: 5px;
                                margin-left: 0;
                                transform: rotate(180deg);
                            }
                            &:hover {
                                >a {
                                    &::after {
                                        transform: rotate(90deg);
                                    }
                                }
                            }
                        }
                        &:after{
                            display:inline-block;
                            margin-left:5px;
                            content:"";
                            background:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=") no-repeat;
                            background-repeat:no-repeat;
                            background-size:auto;
                            width:4px;
                            height:4px;
                            background-size:cover;
                            background-repeat:no-repeat;
                            border:none;
                            position:absolute;
                            top:calc(50% - 1px);
                            // top:0;
                            // bottom: 0;
                            // margin: auto;
                        }
                    }
                    &:hover{
                        border-left: 1px solid $border-color;
                        border-right: 1px solid $border-color;
                        >a{
                            &:after{
                                transform: rotate(90deg);
                                filter: invert(20%) sepia(87%) saturate(4500%) hue-rotate(199deg) brightness(94%) contrast(101%);
                            }
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -2px;
                            z-index: 99999;
                            background-color: $white;
                            width: 100%;
                            height: 2px;
                            left: 0;
                        }
                    }
                }
                a{
                    display: inline-block;
                    font-size: 11px;
                    color: $black;
                    text-align: center;
                    line-height: 40px;
                }
                .sub-menu{
                    display: none;
                    position: absolute;
                    width: 200px;
                    top: 40px;
                    left: -1px;
                    padding: 0;
                    background-color: $white;
                    border: 1px solid $border-color;
                    z-index: 9999;
                    li{
                        display: block;
                    }
                }
                // &.login-btn{
                //     box-sizing: content-box;
                //     a{
                //         line-height: 30px;
                //         width: 100px;
                //         border: 1px solid $border-color;
                //         border-radius: 4px;
                //         text-align: center;
                //         &:hover{
                //             border: 1px solid $blue;
                //         }
                //     }
                // }
                &:not(li.login-btn):hover{
                    .sub-menu{
                        display: block;
                    }
                    >a{
                        color: $blue;
                    }
                }
            }
        }
        .supporting-menu{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border-bottom: 1px solid $border-color-light;
            padding: 0;
            .lang-switcher{
                margin: 0;
                height: 30px;
                width: 30px;
                display: flex;
                align-items: center;
            }
            ul{
                li{
                    &:nth-child(2){
                        .sub-menu {
                            left: inherit;
                            right: -1px;
                        }
                    }
                    .sub-menu {
                        li{
                            padding: 10px;
                            a{
                                text-align: left;
                                line-height: 2;
                            }
                        }
                    }
                }
                
            }
        }
        &.admin-bar-spacing {
            @include media-breakpoint-down(md) {
                .main-menu{
                    top: 46px;
                }
            }
        }
        .main-menu{
            display: flex;
            align-items: center;
            position: relative;
            z-index: 999;
            background-color: $white;
            -webkit-transform: translate3d(0,0,0);
            &.hovered{
                &:after{
                    content: "";
                    position: absolute;
                    background: $border-color;
                    bottom: 0;
                    height: 1px;
                    width: 100%;
                    z-index: 9;
                }
            }
            .container{
                display: flex;
                align-items: center;
                background-color: $white;
            }
            .site-branding{
                background-color: $white;
                .logo{
                    width: 180px;
                    height: 32px;
                    display: block;
                    /*transition: width .2s;*/
                    background-image: url(images/planradar_logo.svg);
                    background-size: 150px 32px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    @include media-breakpoint-down(md) {
                        width: 155px;
                        margin-right: 30px;
                    }
                    &:lang(ar) {
                        background-position: right center;
                    }
                }
            }
            .main-nav{
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: static;
                background-color: $white;
                width: calc(100% - 180px); 
                &.simple_header {
                    padding: 12px 0;
                }
                .main-navigation{
                    position: static;
                    ul{
                        position: static;
                        li{
                            padding: 21px 25px;
                            position: static;
                            margin: 0;
                            &:lang(ar) {
                                padding: 21px 20px;
                            }
                            &.menu-item-has-children{
                                padding-right: 37px;
                                &:lang(ar) {
                                    padding-left: 30px;
                                    padding-right: 20px;
                                }
                                a{
                                    position: relative;
                                    &:after {
                                        width: 7px;
                                        height: 7px;
                                        top: calc(50% - 2px);
                                    }
                                }
                                &:hover{
                                    &:after{
                                        display: none;
                                    }
                                    >a{
                                        &:before {
                                            content: "";
                                            position: absolute;
                                            width: calc(100% + 62px);
                                            left: -25px;
                                            bottom: -23px;
                                            z-index: 99999;
                                            background-color: $white;
                                            height: 2px;
                                        }
                                    }
                                    .sub-menu{
                                        display: flex;
                                        flex-wrap: wrap;
                                    }
                                }
                            }
                            &.product-menu{
                                &:hover{
                                    .sub-menu{
                                        display: grid;
                                        grid-template-columns: repeat(3, 1fr);
                                        li{
                                            width: 100%;
                                            &:nth-child(7){
                                                grid-column: 3;
                                                grid-row: 2;
                                            }
                                            &:nth-child(8){
                                                grid-column: 3;
                                                grid-row: 2;
                                                margin-top: 40px;
                                            }
                                            &:nth-child(9){
                                                grid-column: 3;
                                                grid-row: 2;
                                                margin-top: 80px;
                                            }
                                        }
                                    }
                                }
                            }
                            &.product-menu-new{
                                &:hover{
                                    & > .sub-menu{
                                        display: flex;
                                        flex-direction: column;
                                        // display: grid;
                                        // grid-template-columns: repeat(3, 1fr);
                                        li{
                                            width: 100%;
                                            // &:nth-child(7){
                                            //     grid-column: 3;
                                            //     grid-row: 2;
                                            // }
                                            // &:nth-child(8){
                                            //     grid-column: 3;
                                            //     grid-row: 2;
                                            //     margin-top: 40px;
                                            // }
                                            // &:nth-child(9){
                                            //     grid-column: 3;
                                            //     grid-row: 2;
                                            //     margin-top: 80px;
                                            // }
                                        }
                                    }
                                }
                            }
                            &.product-submenu{
                                border: none;
                                padding: 40px 15px 0 !important;
                                & + .product-submenu{
                                    padding: 20px 15px 0 !important;
                                }
                                > a{
                                    color: #00000080;
                                    font-style: normal;
                                    font-weight: $black-bold;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-transform: uppercase;
                                    &::after{
                                        display: none;
                                    }
                                }
                                &:hover{
                                    border: none;
                                    a{
                                        color: #00000080;
                                        &:after, &:before{
                                            display: none;
                                        }
                                    }
                                }
                                .sub-menu{
                                    display: grid !important;
                                    grid-template-columns: repeat(3, 1fr);
                                    position: unset;
                                    top: auto;
                                    box-shadow: none;
                                    padding: 0;
                                    li{
                                        padding: 20px 0 0 !important;
                                        a{
                                            color: $black;
                                        }
                                        &:hover{
                                            a{
                                                color: $blue;
                                            }
                                        }
                                    }
                                }
                            }
                            &.industry-menu {
                                &:hover{
                                    & > .sub-menu{
                                        display: flex;
                                        flex-wrap: wrap;
                                        > li{
                                            width: 33.33%;
                                            &:last-child {
                                                width: 66.66%;
                                                .sub-menu {
                                                    display: flex !important;
                                                    li {
                                                        width: 50%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.industry-submenu{
                                border: none;
                                padding: 40px 15px 0 !important;
                                // & + .industry-submenu{
                                //     padding: 20px 15px 0 !important;
                                // }
                                > a{
                                    color: #00000080;
                                    font-style: normal;
                                    font-weight: $black-bold;
                                    font-size: 12px;
                                    line-height: 14px;
                                    text-transform: uppercase;
                                    &::after{
                                        display: none;
                                    }
                                }
                                &:hover{
                                    border: none;
                                    a{
                                        color: #00000080;
                                        &:after, &:before{
                                            display: none;
                                        }
                                    }
                                }
                                .sub-menu{
                                    display: block !important;
                                    // grid-template-columns: repeat(3, 1fr);
                                    position: unset;
                                    top: auto;
                                    box-shadow: none;
                                    padding: 0;
                                    width: 100%;
                                    li{
                                        padding: 10px 0 0 !important;
                                        display: block;
                                        width: 100%;
                                        a{
                                            color: $black;
                                            .menu-item-description {
                                                display: none;
                                            }
                                        }
                                        &:hover{
                                            a{
                                                color: $blue;
                                            }
                                        }
                                    }
                                }
                            }
                            &.has_thumb{
                                &:hover{
                                    .sub-menu{
                                        display: grid;
                                        grid-template-columns: repeat(4, 1fr);
                                        grid-gap: 15px;
                                        padding: 0 40px;
                                        li{
                                            width: 100% !important;
                                            padding: 20px 15px;
                                            &:nth-child(2),&:nth-child(3),&:nth-child(4){
                                                padding: 40px 15px 0px;
                                            }
                                            &.menu_thumb {
                                                grid-row: 1 / span gridline 3;
                                                grid-column: 4;
                                                padding: 15px 0 15px 30px;
                                                border-left: 1px solid #DCDCDC;
                                                min-width: 300px;
                                                a{
                                                    font-size: 0;
                                                    padding: 15px 0 0 20px;
                                                    background: #F5F9FD;
                                                    border-radius: 5px;
                                                    position: relative;
                                                    .thumb{
                                                        p{
                                                            font-weight: $black-bold;
                                                            font-size: 15px;
                                                            line-height: 18px;
                                                            color: #1C1C1C;
                                                        }
                                                        img{
                                                            width: 110px;
                                                            height: auto;
                                                            object-fit: cover;
                                                            display: block;
                                                            float: right;
                                                        }
                                                        span{
                                                            font-weight: $black-bold;
                                                            font-size: 12px;
                                                            line-height: 17px;
                                                            color: $blue;
                                                            position: relative;
                                                            position: absolute;
                                                            bottom: 20px;
                                                            padding-right: 10px;
                                                            &::before{
                                                                display:inline-block;
                                                                content:"";
                                                                background:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=") no-repeat;
                                                                background-repeat:no-repeat;
                                                                background-size:auto;
                                                                width: 4px;
                                                                height: 4px;
                                                                background-size:cover;
                                                                background-repeat:no-repeat;
                                                                border:none;
                                                                position:absolute;
                                                                top:calc(50% - 1px);
                                                                right: 0;
                                                                filter: invert(20%) sepia(87%) saturate(4500%) hue-rotate(199deg) brightness(94%) contrast(101%);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            a{
                                font-size: 18px;
                                font-weight: $black-bold;
                                line-height: normal;
                            }
                            .menu-item-description{
                                font-size: 14px;
                                font-weight: 400;
                                padding-right: 20px;
                                &:lang(ar) {
                                    line-height: 1.5;
                                    padding-right: 0;
                                    padding-left: 20px;
                                }
                            }
                            p {
                                padding-top: 5px;
                                font-size: 14px;
                                font-weight: 400;
                                &:lang(ar) {
                                    line-height: 1.5;
                                }
                            }
                            .sub-menu{
                                position: absolute;
                                top: 64px;
                                max-width: 1140px;
                                border-radius: 0;
                                padding: 0 40px 40px;
                                margin-top: 0;
                                box-shadow: 0 7px 1em #00000080;
                                background-color: $white;
                                left: calc(50% - 570px);
                                border: none;
                                z-index: -9;
                                width: 100%;
                                li{
                                    display: inline-block;
                                    width: calc(100% / 3);
                                    padding: 40px 15px 0;
                                    line-height: 40px;
                                    a{
                                        text-align: left;
                                        &:lang(ar) {
                                            text-align: right;
                                        }
                                    }
                                }
                            }

                            // new stuff october 24
                            &.product-menu-2 {
                                >ul {
                                    > li {
                                        padding: 0;
                                        border: none;
                                        >a {
                                            font-size: 12px;
                                            font-weight: 900;
                                            text-transform: uppercase;
                                            opacity: 0.5;
                                        }
                                        &:hover {
                                            > a {
                                                color: #000;
                                                opacity: 0.5;
                                            }
                                        }
                                        a:after,
                                        &:hover a::before {
                                            content: inherit;
                                            // background-color: red;
                                        }
                                        &.product-submenu-3 {
                                            width: 100%;
                                            > ul {
                                                gap: 100px;
                                                > li {
                                                    width: calc(33.33% - (200px/3));
                                                    padding: 0;
                                                    
                                                }
                                            }
                                        }
                                        &.product-submenu-2 {
                                            width: 66.66%;
                                            gap: 100px;
                                            ul {
                                                gap: 0 100px;
                                            }
                                            ul li {
                                                width: calc(50% - 65px);
                                                padding: 0;
                                            }
                                        }
                                        &.product-submenu-1 {
                                            width: calc(33.33% - 65px);
                                            margin-left: 65px;
                                            &:hover {
                                                ul li a {
                                                    color: $black;
                                                }
                                                
                                            }
                                            &:lang(ar) {
                                                padding-left: 0;
                                                padding-right: 0;
                                                margin-left: inherit;
                                                margin-right: 40px;
                                            }
                                            ul li {
                                                width: 100%;
                                                padding: 0;
                                                a {
                                                    display: block;
                                                    padding: 24px;
                                                    background: #F5F9FD;
                                                    border-radius: 6px;
                                                    position: relative;
                                                    width: 100%;
                                                    height: 207px;
                                                    .menu-item-description {
                                                        margin-top: 14px;
                                                    }
                                                    span {
                                                        position: absolute;
                                                        left: 24px;
                                                        bottom: 24px;
                                                        color: $blue;
                                                        font-weight: 900;
                                                        &::after {
                                                            content: "";
                                                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNyA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIuODQ4MjkgMC42NTk5ODdMNi40NDIzNSAzLjEwMjU5TDIuODQ4MjkgNS41NDUyTDIuODQ4MjkgMC42NTk5ODdaIiBmaWxsPSIjMDA2NEM4Ii8+Cjwvc3ZnPgo=");
                                                            background-repeat: no-repeat;
                                                            position: absolute;
                                                            top: 7px;
                                                            right: -10px;
                                                            height: 7px;
                                                            width: 7px;
                                                            display: block;
                                                            background-size: contain;
                                                        }
                                                        &:lang(ar) {
                                                            left: inherit;
                                                            right: 24px;
                                                            &::after {
                                                                // right: inherit;
                                                                // left: -10px;
                                                                right: unset;
                                                                left: -10px;
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                        
                                                    } 
                                                    img {
                                                        position: absolute;
                                                        right: 0;
                                                        bottom: 0;
                                                        border-radius: 0 0 6px;
                                                        &:lang(ar) {
                                                            right: inherit;
                                                            left: 0;
                                                            transform: scaleX(-1);
                                                        }
                                                    }
                                                }

                                            }
                                        }
                                        > ul {
                                            display: flex;
                                            flex-wrap: wrap;
                                            position: inherit;
                                            padding: inherit;
                                            background-color: inherit;
                                            box-shadow: inherit;
                                        }
                                    }
                                }



                                
                                
                                

                            }
                        }
                    }
                }
                .main-nav-btns{
                    display: flex;
                    .pr-btn {
                        font-size: 16px;
                        &:lang(hr),
                        &:lang(hu),
                        &:lang(sk),
                        &:lang(sl) {
                            padding: 12px;
                        }
                        @include media-breakpoint-down(xl) {
                            font-size: 14px;
                            font-weight: 400;
                            width: inherit;
                        }
                    }
                    a+a{
                        margin-left: 20px;
                        @include media-breakpoint-down(lg){
                            margin-left: 10px;
                        }
                    }
                    &:lang(ar) > a+a{
                        margin-right: 20px;
                        margin-left: inherit;
                        @include media-breakpoint-down(lg){
                            margin-right: 10px;
                            margin-left: inherit;
                        }
                    }
                }
                @include media-breakpoint-down(xl) {
                    justify-content: flex-end;
                }
            }
            @include media-breakpoint-down(xl) {
                padding: 8px 0;
                height: 75px;
            }
            @include media-breakpoint-down(md){
                position: fixed;
                top: 0;
                width: 100%;
                box-shadow: 0px 2px 6px 0px #0000001a;
            }
        }
        &.scrolled{
            &.admin-bar-spacing {
                .main-menu{
                    top: 32px;
                    @include media-breakpoint-down(md){
                        top: 46px
                    }
                    @include media-breakpoint-down(sm){
                        top: 0;
                    }
                }
            }
            .main-menu{
                position: fixed;
                top: 0;
                width: 100%;
                box-shadow: 0px 2px 6px 0px #0000001a;
                .site-branding{
                    .logo{
                        width: 35px;
                        transition: width .2s;
                        margin-right: 20px;
                        background-position: left center !important;
                        &:lang(ar) {
                            background-position: right center;
                        }
                        @include media-breakpoint-down(xl) {
                            width: 155px !important;
                            transition: width 0s;
                        }
                    }
                }
                .main-nav{
                    width: calc(100% - 55px);
                }
            }
        }
    }
    .mobile-menu{
        background: $white;
        position: relative;
        position: fixed;
        padding: 2px 12px;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 100000000;
        overflow-y: scroll;
        overflow-x: hidden;
        right: 0;
        max-width: 400px;
        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
        .clearfix{
            display: flex;
            justify-content: space-between;
            padding: 20px 0 15px;
            .lang-switcher{
                width: calc(100% - 60px);
                margin: 0;
                font-size: 16px;
                text-align: left;
                line-height: 40px;
                align-items: center;
            }
            .logo{
                width: calc(100% - 40px);
                height: 32px;
                display: block;
                transition: width .2s;
                background-image: url(images/planradar_logo.svg);
                background-size: 150px 32px;
                background-repeat: no-repeat;
                background-position: left center;
                &:lang(ar) {
                    background-position: right center;
                }
            }

        }
        .mobile-nav{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90vh;
            @include media-breakpoint-down(md) {
                justify-content: flex-start;
            }
            .mobile-main-menu{
                &.slide-left{
                    margin-left: calc(-100% + 20px);
                    width: 100%;
                    @include media-breakpoint-down(md) {
                        margin-left: 0;
                    }
                }
                .menu,#menu-mobile-main-menu, #menu-main-menu-1, #menu-supporting-menu-1{
                    &~ .menu,&+ .menu{
                        li{
                            &:first-child{
                                border-top: none;
                            }
                        }
                    }
                    li{
                        border-top: 1px solid $border-color-light;
                        &:last-child{
                            border-bottom: 1px solid $border-color-light;
                        }
                        &:hover{
                         border-left: none;   
                         border-right: none; 
                         &::after{
                            display: none;
                         }  
                        }
                    }
                    // @include media-breakpoint-down(md) {
                    //     li.product-menu-2 + li {
                    //         border-top: 0;
                    //     }
                    // }
                    >li{
                        padding: 11px 0;
                        display: block;
                        margin: 0;

                        a{
                            font-size: 14px;
                            font-weight: $black-bold;
                            display: block;
                            text-align: start;
                            line-height: normal;
                        }
                        >.sub-menu{
                            display: none;
                            align-items: flex-start;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            border: none;
                            li{
                                padding: 10px 0;
                                width: 100%;
                                margin: 0;
                                &.menu-item-has-children{
                                    padding: 0;
                                    width: 100%;
                                    >a{
                                        color: $blue;
                                        padding: 10px 0;
                                        font-weight: $black-bold !important;
                                    }
                                    a{
                                        font-weight: $regular;
                                    }
                                    .sub-menu{
                                        display: block;
                                    }
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                }
                                &:not(.menu-item-has-children){
                                    a{
                                        font-weight: $regular;
                                    }
                                    .menu-item-description{
                                        font-size: 0;
                                        display: none;
                                    }
                                }
                            }
                            // NEW MOBILE
                            li.product-submenu-1 {
                                
                                li {
                                    border-bottom: 0;
                                    border-top: 0;
                                    a {
                                        background-color: #F5F9FD;
                                        border-radius: 7px;
                                        padding: 30px;
                                        position: relative;
                                        height: 250px;
                                        font-size: 27px;
                                        font-weight: 900;
                                        color: $blue;
                                        .menu-item-description {
                                            display: block;
                                            img {
                                                position: absolute;
                                                right: 0;
                                                bottom: 0;
                                                border-radius: 0 0 7px;
                                            }
                                            p {
                                                margin-top: 24px;
                                                font-size: 18px;
                                                color: #000;
                                                font-weight: 400;
                                            }
                                            span {
                                                font-size: 18px;
                                                font-weight: 900;
                                                position: absolute;
                                                bottom: 30px;
                                                left: 30px;
                                                &::after {
                                                    content: "";
                                                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNyA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIuODQ4MjkgMC42NTk5ODdMNi40NDIzNSAzLjEwMjU5TDIuODQ4MjkgNS41NDUyTDIuODQ4MjkgMC42NTk5ODdaIiBmaWxsPSIjMDA2NEM4Ii8+Cjwvc3ZnPgo=");
                                                    background-repeat: no-repeat;
                                                    position: absolute;
                                                    top: 8px;
                                                    right: -14px;
                                                    height: 12px;
                                                    width: 12px;
                                                    display: block;
                                                    background-size: contain;
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                        &.menu-item-has-children{
                            position: relative;
                            > a{
                                position: relative;
                                &:after{
                                    display:inline-block;
                                    margin-left:5px;
                                    content:"";
                                    background:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=") no-repeat;
                                    background-repeat:no-repeat;
                                    background-size:auto;
                                    width:7px;
                                    height:7px;
                                    background-size:cover;
                                    background-repeat:no-repeat;
                                    border:none;
                                    position:absolute;
                                    // top:calc(50% - 1px);
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    right: 5px;
                                    transform: rotate(90deg);

                                }
                                &:lang(ar) {
                                    &:after{
                                        right: inherit;
                                        left: 5px;
                                    }
                                }
                            }
                            &.expanded{
                                @include media-breakpoint-down(md) {
                                    border: none;
                                    padding: 0;
                                }
                                > a{
                                    color: $light-blue;
                                    @include media-breakpoint-down(md) {
                                        padding: 11px 0;
                                        border-top: 1px solid $black;
                                        border-bottom: 1px solid $black;
                                        color: $black;
                                    }
                                    &:after{
                                        color: $light-blue;
                                        transform: rotate(180deg);
                                        filter: invert(20%) sepia(87%) saturate(4500%) hue-rotate(199deg) brightness(94%) contrast(101%);
                                        @include media-breakpoint-down(md) {
                                            transform: rotate(-90deg);
                                        }
                                    }
                                }
                                >.sub-menu{
                                    display: flex;
                                    position: absolute;
                                    width: calc(100% - 40px);
                                    top: 0;
                                    left: calc(100% + 20px);
                                    z-index: inherit;
                                    @include media-breakpoint-down(md) {
                                        position: inherit;
                                        left: inherit;
                                        width: 100%;
                                        
                                    }
                                }
                            }
                        }
                        &.active{
                            >a{
                                color: $light-blue;
                            }
                        }
                    }
                }
                #menu-supporting-menu-1{
                    li{
                        &:first-child{
                            border-top: none;
                        }
                    }
                }
            }
            .btns{
                &.slide-left{
                    margin-left: calc(-100% + 20px);
                    width: 100%;
                    @include media-breakpoint-down(md) {
                        margin-left: 0;
                    }
                }
                @include media-breakpoint-down(md) {
                    margin-top: 20px;
                }
                .pr-btn {
                    margin-bottom: 12px;
                    font-size: 14px !important;
                    font-weight: 400;
                    max-width: inherit;
                    span {
                        display: inline-block;
                    }
                    .telnum_icon{
                        height: 16px;
                    }
                    .chat_icon{
                        height: 16px;
                    }
                }
                // .signup-btn{
                //     font-size: 14px;
                //     font-weight: $medium;
                //     line-height: 40px;
                //     color: $white;
                //     border-radius: 6px;
                //     padding: 0 5px;
                //     min-width: 150px;
                //     text-align: center;
                //     margin: 10px 5px;
                //     text-transform: capitalize;
                //     @include media-breakpoint-down(md) {
                //         margin: 5px 0px 10px;
                //     }
                // }
                // .bordered-btn{
                //     margin: 10px 5px;
                //     line-height: 38px;
                //     font-size: 14px;
                //     @include media-breakpoint-down(md) {
                //         margin: 5px 0px 10px;
                //     }
                //     span{
                //         display: inline-block;
                //     }
                //     .telnum_icon{
                //         height: 32px;
                //     }
                //     .chat_icon{
                //         height: 32px;
                //     }
                // }
                .lang-switcher{
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin: 10px 0px;
                        height: 40px;
                        line-height: 40px;
                    }
                    span{
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

