.m05 {
    margin: 50px 0 100px;

    video {
        max-width: 100%;
        height: auto;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    h3 {
        font-size: 17px;
        font-weight: 700;
        color: #1d62ad;
        padding: 0 15px 0 20px;
        margin-bottom: 8px;
        letter-spacing: 1px;
        &:lang(ar) {
            letter-spacing: 0;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 0;
            padding: 0;
        }
    }

    p {
        color: #3d4548;
        margin: 0;
        padding: 0 15px 0 20px;
        margin-bottom: 50px;
        font-size: 16px;
        &:lang(ar) {
            line-height: 1.5;
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }

    .slider__item {
        padding: 10px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
        margin: 5px 5px 25px;
        border: 1px solid rgba(29, 98, 173, 0.2);
    }
}

