$website-max-width: 1280px;
$page-break-max: 991px;
$page-break-min: 992px;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);
$lato: 'Lato', sans-serif;
$kufi: 'Noto Kufi Arabic', sans-serif;

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$black-bold: 900;

.btn{
  font-family: $lato !important;
}
.light {
  font-weight: $light;
}

.regular {
  font-weight: $regular;
}

.bold {
  font-weight: $bold;
}

$white: #ffffff;
$dark: #12130F;
$gray: #727272;
$gray-light: #bfbfbf;
$dark-lighter: #12130F;
$dark: #0a0908;
$dark-medium: #1e1f1b;
$dark-light: #888;
$blue: #0064C8;
$light-blue: #1d62ad;
$dark-blue: #003264;
$black: #000;
$light-green: #8cc04b;
$hover-green: #78a63f;
$green: #28965a;
$dark-green: #1e7a48;

$border-color-light: #DCDCDC;
$border-color: #C8C8C8;
.white {
  color: $white !important;
}

.gray {
  color: $gray !important;
}
.blue{
  color: $blue !important;
}
.black{
  color: $black !important;
}

.bg-gray{
  background-color: #F7F7F7;
}
.bg-lightblue{
  background-color: #F2F7FC;
}
.bg-white{
  background-color: $white;
}
.bg-blue{
  background-color: $blue;
}
.bg-gradient{
  background: linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
  color: $white !important;
}
body {
  font-family: $lato;
  font-size: 16px;
  font-weight: $regular;
  line-height: 22px;
  margin: 0;
  padding: 0;
  font-variant-ligatures: none;
  -webkit-font-smoothing: antialiased;
  
  
  &.de,&.at,&.ch,&.ru{
    @include media-breakpoint-down(md) {
      overflow-wrap: anywhere;
      // word-break: break-word;
      // word-wrap: break-word;
      // hyphens: none;
    }
  
  

    /*-webkit-hyphenate-limit-before: 3; 
    -webkit-hyphenate-limit-after: 4;  
    -ms-hyphenate-limit-chars: 15 3 4;
    hyphenate-limit-chars: 15 3 4; */
  
   }
  
  &:lang(ar) {
    font-family: $kufi;
    h1,h2,h3,h4,select,.btn,span,*{
      font-family: $kufi !important;
    }
  }
  @media (max-width: $page-break-max) {
    font-size: 12px;
    line-height: 18px;
  }
  @include media-breakpoint-down(md){
    padding-top: 75px;
  }
  &.page_without_header {
    @include media-breakpoint-down(md){
      padding-top: inherit;
    }
  }
}

h1 {
  font-family: $lato;
  font-size: 40px;
  line-height: 1.2;
  font-weight: $regular;
  @media (max-width: $page-break-max) {
    font-size: 20px;
  }
}

h2 {
  font-family: $lato;
  font-size: 40px;
  line-height: 1.2;
  font-weight: $black-bold;
  letter-spacing: .85px;
  @include media-breakpoint-down(lg) {
    font-size: 35px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 30px;
  }
}

h3 {
  font-family: $lato;
  font-size: 24px;
  line-height: 1.2;
  font-weight: $regular;
  @media (max-width: $page-break-max) {
    font-size: 18px;
  }
}

h4 {
  font-family: $lato;
  font-size: 18px;
  line-height: 1.2;
  font-weight: $regular;
  @media (max-width: $page-break-max) {
    font-size: 14px;
  }
}

p {
  font-size: 18px;
  font-weight: $regular;
  line-height: 1.2;
  // max-width: 940px;
  // @media (max-width: $page-break-max) {
  //   font-size: 12px;
  // }
}

select{
  font-family: $lato;
  font-size: 16px;
  font-weight: $regular;
  line-height: 1.2;
}

span{
  font-family: $lato;
}

/*
body {
  :focus {
    outline: none !important;
  }

  @media (min-width: 1000px) {
    .container {
      max-width: 939px;
    }
  }
}
*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield; 
}


a {
  &.text {
    margin: 20px 13px 0!important;
    padding: 0!important;
    border-radius: 0!important;
    font-size: 22px!important;
    font-weight: 900!important;
    font-stretch: initial!important;
    font-style: initial!important;
    line-height: initial!important;
    letter-spacing: initial!important;
    text-align: initial!important;
    display: block!important;
    width: initial!important;
    color: #000!important;
    background-color: initial!important;
    text-decoration: underline!important;
  }
  &.button-green {
    display: inline-block;
    font-size: 22px;
    font-weight: 900;
    min-width: 280px;
    color: #ffffff!important;
    padding: 8px 15px;
    border-radius: 8px;
    line-height: normal;
    background-color: $green;
    border-color: $green;
    text-align: center;
    margin: 15px 0;
    &:hover {
      background-color: $dark-green;
      border-color: $dark-green;
      text-decoration: none!important;
    }
  }
  &.button-blue {
    @extend .btn;
    @extend .blue-btn;
    display: inline-block;
    font-size: 22px;
    font-weight: 900;
    min-width: 280px;
    color: #ffffff!important;
    // padding: 8px 15px;
    border-radius: 8px;
    // line-height: normal;
    // background-color: $blue;
    // border-color: $blue;
    // text-align: center;
    margin: 15px 0;
    // &:hover {
    //   filter: brightness(90%);
    //   text-decoration: none!important;
    // }
  }
  &.button-outline-green {
    display: inline-block;
    font-size: 22px;
    font-weight: 900;
    min-width: 280px;
    color: $green !important;
    padding: 8px 15px;
    border-radius: 8px;
    line-height: normal;
    background-color: #FFF;
    border-color: $green;
    text-align: center;
    margin: 15px 0;
    &:hover {
      background-color: rgba(0, 0 ,0, .1);
    }
  }
}
.zsiq_theme1.zsiq_floatmain {
  right: -1000px;
}
//new website component libarary
// .staging{
//   //Blue
//   $blue: #0064C8;
//   $hover-blue: #0055AA;
//   $active-blue: #004488;
//   $light-blue: #1d62ad;
//   $blue80: #3383D3;
//   $blue60: #66A2DE;
//   $blue40: #99C1E9;
//   $blue20: #CCE0F4;
//   $blue10: #E5EFF9;
//   $blue5: #F2F7FC;
//   //Dark blue
//   $dark-blue: #003264;
//   $hover-dark-blue: #002142;
//   $active-dark-blue: #001020;
//   //Green
//   $green: #28965a;
//   $hover-green: #217B4A;
//   $active-green: #1A603A;
//   //White
//   $white: #ffffff;
//   $hover-white: #F2F2F2;
//   $active-white: #E1E1E1;
//   //Yellow
//   $yellow: #FFE346;
//   //Grey
//   $grey: #3C4650;
//   $medium-grey: #B1B5B9;
//   $light-grey: #F8F8F8;
//   //Black 
//   $black: #000000;

//   //Typography
//   h1 {
//       font-family: $lato;
//       font-size: 50px;
//       font-weight: $black-bold;
//       line-height: 1.15;
//       margin: 32px 0;
//       // color: $blue;
//       @include media-breakpoint-down(md) {
//           font-size: 40px;
//       }
//   }

//   h2 {
//       font-family: $lato;
//       font-size: 40px;
//       font-weight: $black-bold;
//       line-height: 1.15;
//       margin: 32px 0;
//       // color: $black;
//       @include media-breakpoint-down(md) {
//           font-size: 36px;
//           line-height: 1.25;
//       }
//   }

//   h3 {
//       font-family: $lato;
//       font-size: 27px;
//       line-height: 1.25;
//       font-weight: $black-bold;
//       // color: $black;
//       margin: 24px 0;
//   }

//   h4 {
//       font-family: $lato;
//       font-size: 22px;
//       line-height: 1.5;
//       font-weight: $black-bold;
//       // color: $black;
//       margin: 20px 0;
//   }
//   h5{
//       font-family: $lato;
//       font-size: 18px;
//       line-height: 1.5;
//       font-weight: $black-bold;
//       // color: $black;
//       margin: 20px 0;
//   }
//   p{
//     font-family: $lato;
//     font-size: 22px;
//     font-weight: $regular;
//     line-height: 1.5;
//     // margin: 20px 0;
//     @include media-breakpoint-down(md) {
//         font-size: 18px;
//         line-height: 1.25;
//     }
//   }

//   //Buttons
//   .btn,.button{
//     font-family: $lato;
//     font-size: 22px;
//     text-align: center;
//     font-weight: $black-bold;
//     line-height: 1.1;
//     padding: 12px 24px;
//     border-radius: 8px;
//     text-transform: lowercase;
//     &::first-letter {
//       text-transform: uppercase;
//     }
//   }
//   .blue-btn,.button-blue {
//       background-color: $blue;
//       color: $white;
//       &:hover {
//           background-color: $hover-blue;
//       }
//       &:active,&:focus{
//           background-color: $active-blue;
//       }
//   }

//   .green-btn,.button-green {
//       background-color: $green;
//       color: $white;
//       // font-size: 22px;
//       // text-align: center;
//       // font-weight: $black-bold;
//       // line-height: 1.1;
//       // padding: 12px 24px;
//       // border-radius: 8px;
//       &:hover {
//         background-color: $hover-green;
//       }
//       &:active,&:focus{
//           background-color: $active-green;
//       }
//   }
//   .dark-blue-btn{
//       font-family: $lato;
//       background-color: $dark-blue;
//       color: $white;
//       // font-size: 22px;
//       // text-align: center;
//       // font-weight: $black-bold;
//       // line-height: 1.1;
//       // padding: 12px 24px;
//       // border-radius: 8px;
//       &:hover {
//           background-color: $hover-dark-blue;
//       }
//       &:active,&:focus{
//           background-color: $active-dark-blue;
//       }
//   }
//   .white-btn{
//       font-family: $lato;
//       background-color: $white;
//       color: $blue;
//       // font-size: 22px;
//       // text-align: center;
//       // font-weight: $black-bold;
//       // line-height: 1.1;
//       // padding: 12px 24px;
//       // border-radius: 8px;
//       &:hover {
//           background-color: $hover-white;
//       }
//       &:active,&:focus{
//           background-color: $active-white;
//       }
//   }
//   .outline-btn {
//     font-family: $lato;
//     background-color: $white;
//     color: $black;
//     // font-size: 22px;
//     // text-align: center;
//     // font-weight: $black-bold;
//     // line-height: 1.1;
//     // padding: 12px 24px;
//     // border-radius: 8px;
//     border-color: $medium-grey;
//     &:hover {
//         background-color: $hover-white;
//     }
//     &:active,&:focus{
//         background-color: $active-white;
//     }
//   }




//   .black-btn {
//       background-color: $black;
//   }

//   .bordered-btn {
//       line-height: 1.1;
//       border: 1px solid $border-color;
//       // margin: 0 5px;
//       // min-width: 110px;
//       // // text-align: center;
//       // padding: 12px 24px;
//       // border-radius: 8px;
//       &:hover {
//           border: 1px solid $blue;
//       }
//   }

  // section{
  //   padding: 64px 0;
  //   @include media-breakpoint-down(md) {
  //     padding: 32px 0;
  //   }
  // }

  // input:not([type='checkbox']){
  //   color: $medium-grey;
  //   padding: 12px 14px;
  //   border-radius: 8px;
  //   border: 1px solid $border-color-light;
  //   background: $white;
  //   font-size: 18px;
  //   font-weight: $regular;//$bold;
  //   line-height: 1.25;
  // }
  // select{
  //   color: $medium-grey;
  //   width: 100%;
  //   padding: 12px 14px;
  //   border-radius: 8px;
  //   border: 1px solid $border-color-light;
  //   background-color: $white;
  //   background-image: url(images/icons/select-arrow.svg);
  //   background-repeat: no-repeat;
  //   background-position: 97% center;
  //   background-size: 10px;
  //   appearance: none;
  //   font-size: 18px;
  //   font-weight: $regular;//$bold;
  //   line-height: 1.25;
  // }

// }
