.m94{
    padding: 52px 0;
    margin-bottom: 32px;
    // background-color: #F8F8F8;
    h2{
        font-weight: $black-bold;
        font-size: 40px;
        line-height: 1.2;
        margin: 0 auto 24px;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
    p{
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
    img{
        max-width: 100%;
    }
    .steps{
        @include media-breakpoint-down(md) {
            padding-top: 70px;
        }
        .step{
            padding-inline-start: 160px;
            position: relative;
            min-height: 210px;
            cursor: pointer;
            opacity: 0.5;
            @include media-breakpoint-down(md) {
                padding-inline-start: 55px;
            }
            span{
                font-size: 0;
                font-weight: $black-bold;
                line-height: 32px;
                color: $blue;
                position: absolute;
                top: 0;
                left: 0;
                @include media-breakpoint-down(md) {
                    top: -30px;
                    left: 55px;
                }
            }
            &:not(:last-child){
                &:before{
                    content: '';
                    position: absolute;
                    top: 8px;
                    bottom: 0;
                    left: 120px;
                    width: 2px;
                    height: 100%;
                    background-color: #3C4650;
                    @include media-breakpoint-down(md) {
                        left: 22px;
                    }
                }
            }
            &:after{
                content: '';
                position: absolute;
                top: 5px;
                bottom: 0;
                left: 112px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: #3C4650;
                @include media-breakpoint-down(md) {
                    left: 13px;
                }
            }
            &:hover{
                opacity: 1;
                &:after{
                    background-color: $blue;
                }
                span{
                    font-size: 27px;
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                }
                img{
                    filter: invert(24%) sepia(40%) saturate(4944%) hue-rotate(197deg) brightness(80%) contrast(100%);
                }
            }
            h3{
                font-weight: $black-bold;
                font-size: 22px;
                line-height: 26px;
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
            }
            p{
                font-weight: 300;
                font-size: 18px;
                line-height: 22px;
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                    text-align: start;
                }
            }
            img{
                margin-inline-start: 14px;
                width: 26px;
                vertical-align: middle;
            }
        }
    }
    .horizontal{
        position: relative;
        svg{
            position: absolute;
            top: calc(48% + 24px);
            left: 15px;
            width: 100%;
            @include media-breakpoint-down(md) {
                top: calc(48% + 3px);
                max-width: 96%;
            }
        }
        .steps{
            display: flex;
            overflow-x: scroll;
            padding: 48px 0 0;
            @include media-breakpoint-down(md) {
                overflow: hidden;
                margin-bottom: 0;
                padding: 48px 0;
            }
            
            .step{
                min-height: 450px;
                display: flex;
                flex-direction: column;
                padding-inline-start: 0;
                flex-grow: 1;
                min-width: 220px;
                width: calc(100% / 5);
                opacity: 1;
                &:nth-child(odd){
                    justify-content: flex-end;
                }
                &:nth-child(even){
                    justify-content: flex-start;
                    span{
                        top: 55%;
                    }
                }
                span{
                    top: 35%;
                }
                .step-txt {
                    min-height: 45%
                }
                h3{
                    font-weight: $black-bold;
                    font-size: 22px;
                    line-height: 1.25;
                    opacity: 0.5;
                    display: flex;
                    align-items: center;
                    margin: 0 0 24px;
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                }
                p{
                    font-weight: $regular;
                    font-size: 16px;
                    line-height: 1.5;
                    opacity: 0.5;
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        text-align: start;
                    }
                }
                &:before{
                    // content: '';
                    // position: absolute;
                    // top: 50%;
                    // left: 0;
                    // width: 100%;
                    // height: 1px;
                    // border-bottom: 1px dashed #000;
                    // background-color: transparent;
                    display: none;
                }
                &:after{
                    background-color: #3C4650;
                    top: 47%;
                    left: 0;
                }
                &:hover,&.slick-active{
                    h3,p{
                        opacity: 1;
                    }
                    &:after{
                        background-color: $blue;
                    }
                    span{
                        font-size: 27px;
                        @include media-breakpoint-down(md) {
                            left: 0;
                            font-size: 16px;
                        }
                    }
                    img{
                        filter: brightness(0) saturate(100%) invert(29%) sepia(99%) saturate(5559%) hue-rotate(199deg) brightness(93%) contrast(101%);
                        //filter: invert(24%) sepia(40%) saturate(4944%) hue-rotate(197deg) brightness(80%) contrast(100%);
                    }
                }
            }
            .slick-dots{
                bottom: 0;
                li{
                    button{
                        &:before{
                            font-size: 14px;
                        }
                    }
                    &.slick-active{
                        button{
                            &:before{
                                color: $blue;
                                opacity: 1;
                            }
                        }
                    }
                } 
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    &.m94-2 {
        padding: 64px 0;
        margin: inherit;
        @include media-breakpoint-down(md) {
            padding: 48px 0;
        }
        h2 {
            letter-spacing: 0;
            text-align: center;
            @include media-breakpoint-down(md) {
                text-align: left;
                font-size: 36px;
            }
        }
        .m94-steps {
            display: flex;
            flex-wrap: wrap;
            margin: 48px 0;
            @include media-breakpoint-down(md) {
                display: block;
                padding: 32px 0 48px;
                margin: 0;
            }
            .m94-step {
                width: 267px;
                text-align: center;
                justify-content: center;
                @include media-breakpoint-down(md) {
                    width: inherit;
                }
                img {
                    margin-bottom: 32px;
                }
                h3 {
                    margin-bottom: 24px;
                    font-size: 27px;
                    font-weight: 900;
                }
                p {
                    margin-bottom: 0;
                }
            }
            .m94-arrow {
                width: calc((100% - 801px) / 2);
                position: relative;
                @include media-breakpoint-down(md) {
                    width: inherit;
                    margin: 32px 0;
                    height: 32px;                
                }
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 36px;
                    height: 32px;
                    background-image: url('data:image/svg+xml,<svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.4785 17.7578L21.9785 30.2578C21.041 31.2734 19.4004 31.2734 18.4629 30.2578C17.4473 29.3203 17.4473 27.6797 18.4629 26.7422L26.666 18.4609H2.75977C1.35352 18.4609 0.259766 17.3672 0.259766 15.9609C0.259766 14.6328 1.35352 13.4609 2.75977 13.4609H26.666L18.4629 5.25781C17.4473 4.32031 17.4473 2.67969 18.4629 1.74219C19.4004 0.726562 21.041 0.726562 21.9785 1.74219L34.4785 14.2422C35.4941 15.1797 35.4941 16.8203 34.4785 17.7578Z" fill="%23B1B5B9"/></svg>');
                    top: calc(50% - 16px);
                    left: calc(50% - 18px);
                    @include media-breakpoint-down(md) {
                        top: 0;
                        transform: rotate(90deg);
                    }
                }
            }
        }
        .subtitle {
            text-align: center;
            @include media-breakpoint-down(md) {
                text-align: left;
                br {
                    display: none;
                }
            }
        }
        .button {
            justify-content: center;
            padding-bottom: 48px;
            .pr-btn {
                margin: 0;
            }
        }
    }

}