.m53{
    padding: 45px 0;
    color: $black;
    .title{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 1.2;
        margin-bottom: 8px;
        letter-spacing: .69px;
        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 40px;
        }
    }
    .subtitle{
        font-size: 22px;
        color: #000;
        font-weight: $black-bold;
    }
    .awards{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        @include media-breakpoint-down(md){
            flex-direction: column;
            padding: 0 15px;
        }
        .award{
            width: calc(100%/2);
            border-radius: 15px;
            box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
            height: 100%;
            max-width: 375px;
            margin: auto;
            padding: 0;
            margin: 50px 0;
            @include media-breakpoint-down(lg){
                max-width: 330px;
            }
            @include media-breakpoint-down(md){
                max-width: 100%;
                width: 100%;
                margin: 15px 0;
            }
            .block{
                background-color: #FFFFFF;
                height: 100%;
                border-radius: 15px;
                // @include media-breakpoint-down(md) {
                //     border-radius: 0;
                // }
                .image{
                    height: 250px;
                    border-radius: 15px 15px 0 0;
                    &.bg-gray{
                        background-color: #DFDFDF;
                    }
                    .block-image{
                        max-width: 160px;
                        height: auto;
                        display: block;
                        margin: auto;
                    }
                }
                .content{
                    padding: 15px 15px 60px;
                    .block-title{
                        font-size: 18px;
                        font-weight: 900;
                        margin-bottom: 18px;
                        padding-left: 0;
                        margin-top: 0;
                    }
                    .block-desc{
                        font-size: 18px;
                        font-weight: $light;
                        line-height: 22px;
                    }
                    .read-more{
                        display: block;
                        font-weight: $black-bold;
                        text-decoration: underline;
                        color: $blue;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}