.m98{
    padding: 24px 0;
    @include media-breakpoint-down(md){
        padding: 24px 0 0;
    }
    &.lp-template{
        padding: 44px 0 0;
        &+.m62{
            padding: 35px 0 65px;
        }
        &+.content-page{
            margin-top: 30px;
        }
    }
    .breadcrumb{
        margin: 0;
        .breadcrumb-item {
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            color: $black;
            font-weight: $regular;
            a {
                font-weight: $black-bold;
                color: $black;
            }
            &.active {
                color: $black;
            }
        }
    }
}