.m32{
    padding: 60px 0 1020px;
    @include media-breakpoint-down(xl) {
        padding: 60px 0 850px;
    }
    h2{
        color: $blue;
        font-size: 40px;
        letter-spacing: 0.69px;
        font-weight: $black-bold;
        margin: 0 auto 30px;
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }
    p{
        font-size: 18px;
        font-weight: $light;
        letter-spacing: 0.31px;
    }
    .stories{
        margin: 90px auto;
        position: absolute;
        left: 0;
        right: 0;
        padding-bottom: 76px;
        @include media-breakpoint-down(md) {
            margin: 35px auto 110px;
        }
        .story{
            margin: 0 25px;
            width: 500px;
            max-width: 80%;
            opacity: 0.3;
            &.slick-active{
                opacity: 1;
            }
            @include media-breakpoint-down(lg) {
                margin: 0 20px;
            }
            @include media-breakpoint-down(md) {
                margin: 0 5px;
            }
            .head{
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                h3{
                    color: $blue;
                    font-size: 22px;
                    font-weight: $black-bold;
                    letter-spacing: 0.38px;
                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                }
                span{
                    font-size: 18px;
                    font-weight: $light;
                    letter-spacing: 0.31px;
                    text-align: right;
                    min-width: 50px;
                    @include media-breakpoint-down(md) {
                        text-align: left;
                        margin-bottom: 5px;
                    }
                }
            }
            .content{
                img{
                    width: 100%;
                    height: auto;
                    margin-bottom: 20px;
                }
                h4{
                    font-size: 18px;
                    font-weight: $black-bold;
                    letter-spacing: 0.31px;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 10px;
                    }
                }
                p{
                    font-size: 18px;
                    font-weight: $light;
                    letter-spacing: 0.31px;
                    line-height: 1.2;
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        height: 100px;
                        overflow: hidden;
                        transition: height 0.2s ease-out;
                        &.expanded{
                            height: auto;
                            transition: height 0.25s ease-in;
                        }
                    }
                }
                .read-more{
                    font-size: 16px;
                    font-weight: 900;
                    color: $blue;
                    text-decoration: none;
                }
            }
        }
        .slick-dots{
            margin: 0 0 40px;
            li{
                margin: 0;
                &.slick-active{
                    button{
                        &:before{
                            color: $blue;
                            opacity: 1;
                        }
                    }
                }
                button{
                    &:before{
                        font-size: 10px;
                    }
                    &:hover{
                        &:before{
                            color: $blue;
                        }
                    }
                    &:focus {
                        &:before{
                            opacity: 0.25;
                        }
                    }
                }
                &.slick-active {
                    button{
                        &:focus {
                            &:before{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            
        }
    }
}