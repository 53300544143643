.m01d {
    .pr-btn-group {
        justify-content: center;
    }
    .hero-d {
        height: 600px;
        background-image: var(--bg);
        background-size: cover ;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        overflow: hidden;

        @include media-breakpoint-down(md) {
            background-image: var(--bgm);
            height: 700px;
            .container {
                height: 100%;
                position: relative;
                .pr-btn-group {
                    position: absolute;
                    bottom: 30px;
                    width: calc(100% - var(--bs-gutter-x, 1.5rem));
                }
            }
        }
        &.m01d-video {
            height: 1000px;
            .outline-btn {
                @include media-breakpoint-down(md) {
                    margin-bottom: 12px;
                }
            }
            .hero-vimeo-video {
                width: 570px;
                margin: 80px auto 0;
                @include media-breakpoint-down(md) {
                    display: none;
                }
                .plyr--full-ui.plyr--video .plyr__control--overlaid{
                    border: 2px solid #fff !important;
                    background-color: transparent !important;
                }
                .plyr--vimeo{
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        background-color: #00000047;
                        z-index: 1;
                    }
                    &.plyr--playing{
                        &::before{
                            display: none;
                        }
                    }
                }
            }
        }
        h1 {
            font-weight: 900;
            font-size: 65px;
            color: $black;
            margin-bottom: 50px;
            @include media-breakpoint-down(md) {
                margin-top: 10px;
                font-size: 30px;
            }
        }
        .m01d-ply-btn {
            width: 140px;
            height: 140px;
            // background-color: red;
            top: 280px;
            position: relative;
            left: calc(50% - 70px);
            background-image: url('images/Play.svg');
            background-position: center;
            background-size: cover;
            cursor: pointer;
        }
        .m01d-modal-body {
            button.btn-close {
                position: absolute;
                right: 15px;
                top: 15px;
                z-index: 9;
            }
        }
    }
    &.m01d-2 {
        background-color: #F8F8F8;
        position: relative;
        padding-top: 86px;
        @include media-breakpoint-down(lg) {
            background-image: var(--mobilebg);
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: contain;
            padding-bottom: 60vw;
            background-color: $white;
        }
        &::after{
            content: '';
            position: absolute;
            background-image: var(--rbg);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: cover;
            width: calc(100%/3);
            height: 100%;
            right: 0;
            top: 0;
            z-index: 8;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        &::before{
            content: '';
            position: absolute;
            background-image: var(--lbg);
            background-position: left center;
            background-repeat: no-repeat;
            background-size: cover;
            width: calc(100%/3);
            height: 100%;
            left: 0;
            top: 0;
            z-index: 8;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        .container{
            position: inherit;
            z-index: 9;
        }
        h1{
            color: $blue;
            text-align: center;
            font-size: 50px;
            font-weight: 900;
            line-height: 1.15;
            margin: 32px 0 89px;
            @include media-breakpoint-down(xl) {
                font-size: 40px;
            }
            @include media-breakpoint-down(lg) {
                font-size: 36px;
                margin-bottom: 48px;
            }
        }

        .col-lg-6{
            padding: 0;
            @include media-breakpoint-down(lg) {
                padding: 0 12px;
            }
        }
        .col-lg-3{
            display: flex;
        }
        .mockup{
            width: 100%;
            height: auto;

        }
    }
}