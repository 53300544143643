.pr-form-group {
    margin: 0 0 16px;
    &.pr-form-password {
        position: relative;
        i.password-ico {
            &::after {
                content: "";
                position: absolute;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
                
            }
            &.show::after {
                background-image: url('images/icons/show.svg');
                width: 20.23px;
                height: 16px;
                bottom: 16px;
                right: 18px;
            }
            &.hide::after {
                background-image: url('images/icons/hide.svg');
                width: 22.51px;
                height: 18px;
                bottom: 15px;
                right: 17px;
            }
        }
    }
}
.pr-form-control {
    // @extend .form-control;
    display: block;
    width: 100%;
    color: #000;
    border: 1px solid #B1B5B9;
    appearance: none;
    padding: 12px 18px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 1;
    &:lang(ar) {
        font-size: 16px;
        padding: 7px 18px 8px;
    }
    &:focus {
        outline: 0;
        border-color: $blue;
        box-shadow: 0 0 0 2px $blue;
    }
    &.is-invalid {
        border-color: #FF0000;
        color: #FF0000;
        &:focus {
            box-shadow: unset;
            border-color: #FF0000;
        }
    }
    &:disabled {
        background-color: #DDD;
        opacity: 0.75;
    }
}
.pr-form-label {
    display: flex;
    @include media-breakpoint-down(md){
        display: block;
    }
    p {
        margin: 0 0 8px !important;
        font-size: 16px;
        &.pr-form-error {
            // background-color: red;
            color: #FF0000;
            font-size: 16px;
            margin-left: auto !important;
            a {
                font-weight: 900;
                color: #FF0000;
            }
            div::before {
                content: "";
                margin-right: 2px;
                background-image: url('images/error.svg');
                width: 20px;
                height: 13px;
                display: inline-block;
                background-size: contain;
                background-position: center bottom;
                background-repeat: no-repeat;
            }
        }
        &:not(.pr-form-error) {
            font-weight: 900;
        }
    }
}

#tos_error {display: none;}

.pr-form-phone {
    position: relative;
    input {
        padding-left: 140px;
    }
    .pr-form-control:focus ~  .select2 {
        .select2-selection {
            border-right: 1px solid $blue;
        }
    }
    input.is-invalid ~ .select2 {
        .select2-selection {
            border-color: #FF0000;
        }
    }
    .select2 {
        position: absolute;
        top: 0;
        left: 1px;
        &:lang(ar) {
            left: inherit;
            right: 1px;
        }
        .select2-selection {
            background-color: transparent;
            width: 125px;
            display: block;
            border-radius: inherit;
            box-shadow: inherit;
            border: 0;
            border-right: 1px solid #B1B5B9;
            padding: 13px 15px;
            height: 48px;
            &:lang(ar) {
                border-right: none;
                border-left: 1px solid #B1B5B9;
            }
            .country-phone-code {
                display: block;
                margin: 0;
                span {
                    margin-right: 1px;
                }
            }
        }
    }
}
.pr-dropdown-group {
    .select2 {
        display: block;
        width: 100% !important;
        border-radius: 8px;
        .select2-selection {
            box-shadow: none;
            font-size: 18px;
            height: 48px;
            padding: 9px 18px;
            border-radius: 8px;
            border-color: #B1B5B9;
        }
        &.select2-container--focus {
            .select2-selection {
                box-shadow: 0 0 0 2px $blue;
                border-radius: 8px !important;
                border-bottom: 1px solid !important;
                border-color: $blue !important;
            }
        }
    }
}

.pr-form-select.is-invalid + .select2 .select2-selection {
    box-shadow: unset;
    border-color: #FF0000 !important;
}
.select2-dropdown.select2-dropdown--below {
    margin-top: 8px;
    border-radius: 8px !important;
    border-top: 1px solid !important;
    border-color: #B1B5B9 !important;
    li.select2-results__option--disabled {
        display: none;
    }
}
.select2wimg {
    display: flex;
    align-items: center;
    padding: 2px 0;
    img {
        width: 18px;
        height: auto;
        margin-right: 12px;
    }
    p {
        margin: 0;
        width: calc(100% - 30px);
    }
}


// APPLY THIS FOR COUNTRY SELECT
.select2-container {
    .select2-dropdown.select2-dropdown--below {
        min-width: 125px
    }
}

.pr-form-check {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 32px;
    margin: 16px 0;
    &:lang(ar) {
        padding-left: inherit;
        padding-right: 32px;
    }
    .pr-form-check-input {
        float: left;
        margin-left: -32px; 
        margin-top: 2px;
        appearance: none;
        font: inherit;
        width: 20px;
        height: 20px;
        border: 1px solid #B1B5B9;
        border-radius: 4px;
        cursor: pointer;
        &:lang(ar) {
            float: right;
            margin-left: inherit;
            margin-right: -32px;
        }
        &:checked {
            background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6L4.33333 9.33333L12.6667 1" stroke="%230064C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 14px 14px;
        }
        &.is-invalid {
            border-color: #FF0000;
        }
        &:disabled {
            opacity: 0.75;
        }
        &[type=radio] {
            border-radius: 50%;
            &:checked {
                background-image: none;
                // background-color: red;
                position: relative;
                &::after {
                    content: "";
                    background-color: $blue;
                    width: 70%;
                    height: 70%;
                    display: block;
                    border-radius: 50%;
                    position: absolute;
                    left: 15%;
                    top: 15%;
                }
            }
        }
    }
    .pr-form-check-input.is-invalid + .pr-form-check-label {
        color: #FF0000;
        a {
            color: #FF0000;
        }
    }
    .pr-form-check-input:disabled + .pr-form-check-label {
        opacity: 0.75;
    }
    .pr-form-check-label {
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        a {
            font-weight: 900;
            cursor: pointer;
        }
    }
    &.pr-form-radio {
        display: flex;
        align-items: center;
        gap: 8px;
        label {
            width: calc(100% - 28px);
        }
    }
}
.pr-form-loading {
    @extend .form-loading;
}

// HUBSPOT EMEBDED FORM
.hbspt-form {
    .hs-form-field {
        margin: 0 0 24px;
        position: relative;
        label {
            font-weight: 900;
            margin: 0 0 12px;
            font-size: 18px;
            display: block !important;
        }
        input {
            @extend .pr-form-control ;
            width: 100% !important;
            &::placeholder {
                display: none;
                // background-color: red;
                opacity: 0;
            }
        }
        .hs-form-booleancheckbox label {
            position: relative;
            display: block;
            min-height: 1.5rem;
            padding-left: 32px;
            margin: 16px 0;
            input {
                float: left;
                margin-left: -32px; 
                margin-top: 2px;
                appearance: none;
                padding: 0;
                font: inherit;
                width: 20px !important;
                height: 20px;
                border: 1px solid #B1B5B9;
                border-radius: 4px;
                &:checked {
                    background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6L4.33333 9.33333L12.6667 1" stroke="%230064C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 14px 14px;
                }
                &.is-invalid {
                    border-color: #FF0000;
                }
                &:disabled {
                    opacity: 0.75;
                }
            }
            span {
                display: inline-block;
                font-size: 16px;
                font-weight: 400;
                font-size: 16px;
                a {
                    font-weight: 900 !important;
                    cursor: pointer;
                }
                p {
                    display: inline;
                }
            }
        }

        .hs-error-msgs {
            position: absolute;
            top: 0;
            right: 0;
            @include media-breakpoint-down(md) {
                position: inherit;
                top: inherit;
                left: inherit;
            }
            li label {
                font-size: 16px;
                color: #FF0000;
                font-weight: 400;
                &::before {
                    content: "";
                    margin-right: 2px;
                    background-image: url("images/error.svg");
                    width: 20px;
                    height: 13px;
                    display: inline-block;
                    background-size: contain;
                    background-position: center bottom;
                    background-repeat: no-repeat;
                }
            }
        }
        select {
            border-radius: 8px;
            font-size: 16px;
            background-color: #FFF;
            border: 1px solid #B1B5B9;
            padding: 12px 18px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right 0.75rem center;
            background-size: 16px 12px;
            appearance: none;
            width: 100% !important;
        }
    }
    .form-columns-2 {
        .hs-error-msgs {
            position: inherit;
        }
    }
    .hs-fieldtype-booleancheckbox {
        .hs-error-msgs {
             position: inherit;
        }
    }
    .hs_error_rollup {
        margin-bottom: 16px;
        color: #FF0000
    }
    .hs-button {
        @extend .pr-btn;
        @extend .pr-primary-btn;
        width: 100% !important;
    }
    .hs-form-field > .input > .inputs-list {
        list-style: none !important;
    }
}
.bg-gradient a {
    color: #FFF;
}
form {
    .col-md-6,
    .col-lg-6 {
        .pr-form-label {
            display: block;
        }
    }
}
.pr-phone-group {
    .select2 {
        .select2-selection {
            background-color: transparent;
            // width: 125px;
            display: block;
            border-radius: inherit;
            box-shadow: inherit;
            border-color: #B1B5B9;
            padding: 13px 15px;
            height: 48px;
            border-radius: 8px;
            .country-phone-code {
                display: block;
                margin: 0;
                span {
                    margin-right: 1px;
                }
            }
        }
    }
}
.select2-container--bootstrap-5 .select2-selection--single:lang(ar) {
    background-position: left 0.75rem center;
}

.modal-pr {
    max-width: 494px;
    border-radius: 8px;
    .modal-content {
        padding: 32px;
        .modal-header {
            // background-color: red;
            position: relative;
            border: none;
            padding: 18px 0 32px;
            button {
                right: 0;
                top: 0;
                position: absolute;
                background-image: url('data:image/svg+xml,<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.0352 3.33789L11.498 8.875L17.0352 14.4648C17.7207 15.0977 17.7207 16.2051 17.0352 16.8379C16.4023 17.5234 15.2949 17.5234 14.6621 16.8379L9.125 11.3008L3.53516 16.8379C2.90234 17.5234 1.79492 17.5234 1.16211 16.8379C0.476562 16.2051 0.476562 15.0977 1.16211 14.4648L6.69922 8.875L1.16211 3.33789C0.476562 2.70508 0.476562 1.59766 1.16211 0.964844C1.79492 0.279297 2.90234 0.279297 3.53516 0.964844L9.125 6.50195L14.6621 0.964844C15.2949 0.279297 16.4023 0.279297 17.0352 0.964844C17.7207 1.59766 17.7207 2.70508 17.0352 3.33789Z" fill="black"/></svg>') !important;
                background-repeat: no-repeat;
                width: 18px;
                height: 18px;
                opacity: 1 !important;
                span {
                    visibility: hidden;
                }
            }
            h5 {
                font-size: 27px !important;
                font-weight: 900 !important;
            }
        }
        .modal-body {
            padding: 0;
            min-height: 100px;
            button {
                width: 100%;
            }
        }
    }

}
.select2-results ul li p {
    margin: 0;
    padding: 5px 0;
}

.ebook-thanks {
    position: relative;
    padding: 70px 0;
    p {
        margin-bottom: 250px;
        font-size: 34px;
        font-weight: 900;
        @include media-breakpoint-down(md) {
            font-size: 28px;
            text-align: center;
        }
    }
    .action {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        transform: translate(0%,0%);
        max-height: 200px;
        // height: 25vw;
        max-width: 200px;
        // width: 25vw;

        %confetti {
            z-index: -1;
            position: absolute;
            height: 50px;
            width: 10px;
            border-radius: 10px;
            animation-fill-mode: forwards;
            animation-duration: 0.75s;
            animation-iteration-count: 1;
            transform-origin: center middle;
            opacity: 0;
        }
        .confetti {
            @extend %confetti;
            top: 0;
            left: calc(50% - 5px);
            background-color: #28965A;
            animation-name: fly--up;
            animation-delay: 0.35s;
            &.two {
                top: auto;
                bottom: 0;
                animation-name: fly--down;
            }
            &.three,&.four {
                top: calc(50% - 5px);
                left: calc(50% - 25px);
                height: 10px;
                width: 50px;
                animation-name: fly--left;
            }
            &.four {			
                animation-name: fly--right;
            }
            &--purple {
                @extend %confetti;
                background-color: $blue;
                animation-name: fly--up--left;
                transform: rotate(135deg);
                animation-delay: .5s;
                left: 20%;
                top: 20%;
                &.two {
                    animation-name: fly--up--right;
                    left: auto;
                    right: 20%;
                    transform: rotate(45deg);
                }
                &.three {
                    top: auto;
                    bottom: 20%;
                    transform: rotate(45deg);
                    animation-name: fly--down--left;
                }
                &.four {
                    top: auto;
                    bottom: 20%;
                    left: auto;
                    right: 20%;
                    transform: rotate(135deg);
                    animation-name: fly--down--right;
                }
            }
        }
    }  
    .trophy {
        animation: trophy 0.5s 1 forwards;
        background-color: #F8F8F8;
        border-radius: 100%;
    }
    @keyframes trophy {
        0% {
            transform: translateY(500px);
            opacity: 0;
        }
        35% {
            opacity: 0;
        }
        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }
    
    @keyframes fly--up {
        0% {
            transform: translateY(10px);
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        80% {
            transform: translateY(-20vw);
        }
        100% {
            transform: translateY(-15vw);
            opacity: 0;
        }
    }
    
    @keyframes fly--down {
        0% {
            transform: translateY(-10px);
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        80% {
            transform: translateY(20vw);
        }
        100% {
            transform: translateY(15vw);
            opacity: 0;
        }
    }
    
    @keyframes fly--left {
        0% {
            transform: translateX(10px);
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        80% {
            transform: translateX(-35vw);
        }
        100% {
            transform: translateX(-180px);
            opacity: 0;
        }
    }
    
    @keyframes fly--right {
        0% {
            transform: translateX(-10px);
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        80% {
            transform: translateX(35vw);
        }
        100% {
            transform: translateX(180px);
            opacity: 0;
        }
    }
    
    @keyframes fly--up--left {
        0% {
            transform: rotate(135deg) translate(0vw,0vw);
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        100% {
            transform: rotate(135deg) translate(-3vw,20vw);
            opacity: 0;
        }
    }
    
    @keyframes fly--up--right {
        0% {
            transform: rotate(45deg);
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        100% {
            transform: rotate(45deg) translate(-3vw,-20vw);
            opacity: 0;
        }
    }
    
    @keyframes fly--down--left {
        0% {
            transform: rotate(45deg) translate(0vw,0vw);
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        100% {
            transform: rotate(45deg) translate(-3vw,20vw);
            opacity: 0;
        }
    }
    
    @keyframes fly--down--right {
        0% {
            transform: rotate(135deg) translate(0vw,0vw);
            opacity: 0;
        }
        60% {
            opacity: 1;
        }
        100% {
            transform: rotate(135deg) translate(0vw,-20vw);
            opacity: 0;
        }
    }
}
.hbspt-form .submitted-message p {
    font-size: 34px !important;
    font-weight: 900;
    font-family: "Lato", sans-serif !important;
    text-align: left !important;
}