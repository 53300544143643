.m16{
    color: $black;
    padding-top: 50px;
    @include media-breakpoint-down(lg) {
        text-align: center;
    }
    .blocks{
        padding: 45px 0;
        background-image: var(--bg);
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 410px auto;
        &:lang(ar) {
            background-position: bottom left;
        }
        @include media-breakpoint-down(lg) {
            background-image: none;
            text-align: center;
        }
        .block{
            padding-bottom: 140px;
            position: relative;
            height: 100%;
            @include media-breakpoint-down(md) {
                padding-bottom: 130px;
            }
            .block-icon{
                width: auto;
                height: 80px;
            }
            .block-title{
                font-size: 40px;
                font-weight: $black-bold;
                margin-bottom: 18px;
                margin-top: 30px;
                line-height: 48px;
                &:lang(ar) {
                    line-height: 1.5;
                }
            }
            .block-desc{
                font-size: 18px;
                font-weight: $light;
                max-width: 300px;
                line-height: 24px;
                @include media-breakpoint-down(xl) {
                    max-width: 100%;
                }
            }
            // .pr-btn{
            //     margin: 0;
            //     min-width: 180px;
            //     position: absolute;
            //     bottom: 70px;
            //     @include media-breakpoint-down(lg) {
            //         right: 0;
            //         left: 0;
            //         margin: 0 auto;
            //         max-width: 220px;
            //     }
            // }
        }
    }
}