.m30{
    padding: 32px 0;//60px 0;
    h2{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 1.15;
        margin: 0 auto 24px;
        text-align: center;
    }
    p{
        font-size: 18px;
        font-weight: $regular;
        line-height: 1.5;
        margin: 0 auto 48px;
        text-align: center;
        width: 100%;
        max-width: 800px;
    }
    .cards{
        &.gray_cards{
            max-width: 920px;
            margin: auto;
            &.two-cards{
                max-width: 100%;
                margin: auto 12px;
                .card-container{
                    padding: 7.5px 12px;
                    .card{
                        padding: 32px 34px;
                        .card-title{
                            display: flex;
                            align-items: center;
                            margin-bottom: 32px;
                            img{
                                height: 40px;
                                width: auto;
                                margin: 0;
                                margin-inline-end: 12px;
                            }
                            h3{
                                font-size: 27px;
                                font-weight: $black-bold;
                                line-height: 1.25;
                                margin: 0;
                                text-align: start;
                            }
                        }
                        p{
                            text-align: start;
                            font-size: 18px;
                            font-weight: $regular;
                            line-height: 1.5;
                        }
                    }
                }
            }
            .card-container{
                padding: 7.5px 27.5px;
                @include media-breakpoint-down(lg) {
                    padding: 12px;
                }
                .card{
                    background-color: #F8F8F8;
                    box-shadow: none;
                    padding: 52px 28px;
                    
                    h3 {
                        margin-top: 14px;
                        font-size: 22px;
                        font-weight: 900;
                        margin-bottom: 14px;
                    }
                    p {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 0;
                        min-height: auto;
                    }
                    img{
                        width: 50px;
                        height: auto;
                    }
                }
            }
        }
        &.scrollable{
            @include media-breakpoint-down(md) {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                //overflow-x: scroll;
                padding-bottom: 20px;
                .card-container{
                    width: 230px;
                    .card{
                        img{
                            width: 50px;
                            height: auto;
                        }
                    }
                    &:not(.slick-active){
                        opacity: 0.4;
                    }
                }
                            /* width */
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 8px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {        
                    background: #EDEBEB;
                    border-radius: 11px;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #DEDADA; 
                    border-radius: 11px;
                }
                
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #DEDADA; 
                }
                .slick-dots{
                    bottom: 0;
                    li{
                        button{
                            &:before{
                                font-size: 14px;
                            }
                        }
                        &.slick-active{
                            button{
                                &:before{
                                    color: $blue;
                                    opacity: 1;
                                }
                            }
                        }
                    } 
                }
            }
        }
        .card-container{
            padding: 7.5px;
            &:nth-child(3n+1){
                padding-left: 12px;
            }
            &:nth-child(3n){
                padding-right: 12px;
            }
            @include media-breakpoint-down(lg) {
                &:nth-child(even){
                    padding-right: 12px;
                }
                &:nth-child(odd){
                    padding-left: 12px;
                }
            }
            .card{
                height: 100%;
                background-color: $white;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
                border: none;
                border-radius: 10px;
                text-align: center;
                padding: 40px 15px;
                color: $black;
                img{
                    margin: 0 auto;
                }
                h3{
                    margin-top: 25px;
                    font-size: 22px;
                    
                    font-weight: $black-bold;
                    margin-bottom: 0;
                }
                p{
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 30px;
                    min-height: 70px;
                }
                a{
                    font-size: 22px;
                    color: $blue;
                    font-weight: $black-bold;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
