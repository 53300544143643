.m100 {
    background-color: #F2F7FC;
    padding: 64px 0 16px;
    @include media-breakpoint-down(md) {
        margin-left: var(--bs-gutter-x, 0.75rem);
        margin-right: var(--bs-gutter-x, 0.75rem);
        border-radius: 8px;
        padding: 32px 16px;
    }
    h2 {
        font-size: 36px;//40px;
        font-weight: 900;
        text-align: center;
        max-width: 980px;
        margin: 0 auto 48px;
        letter-spacing: inherit;
        @include media-breakpoint-down(md) {
            font-size: 27px;
            text-align: left;
        }
    }
    .m100-icons {
        @include media-breakpoint-down(md) {
            justify-content: inherit;
            overflow: hidden;
            max-height: 250px;
        }
        .m100-icon {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: 12px;
            margin-bottom: 48px;
            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                justify-content: inherit;
                margin-right: inherit;
                margin-bottom: 32px;
                padding: 16px 0;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
            p {
                margin: 0;
                font-size: 22px;
                &:lang(de) {
                    word-break: break-word;
                    hyphens: auto;
                }
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }
            div {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                @include media-breakpoint-down(md) {
                    // margin: 0 24px;
                }
            }
            img {
                max-width: 32px;
                max-height: 32px;
                width: auto;
                height: auto;
            }
        }
    }
    p.less,
    p.more {
        margin-top: 32px;
        text-align: center;
        font-weight: 900;
        font-size: 18px;
        text-decoration: underline;
        display: none;
    }
    @include media-breakpoint-down(md) {
        p.more {
            display: block;
        }
    }
}