.m01s {
    padding: 40px 0 27px;
    @include media-breakpoint-down(md) {
        padding: 32px 0 14px;
    }
    &.bg-grey {
        background-color: #F8F8F8;
    }
    h1 {
        max-width: 553px;
        color: $blue;
        font-weight: 900;
        font-size: 50px;
        line-height: 1.15;
        margin: 0;
        @include media-breakpoint-down(lg) {
            text-align: center;
            max-width: inherit;
            margin-bottom: 32px;
        }
        @include media-breakpoint-down(md) {
            max-width: 540px;
            margin: 0 auto;
            text-align: inherit;
            padding-right: var(--bs-gutter-x, 0.75rem);
            font-size: 40px;
            br {
                display: none;
            }
        }
    }
    img {
        width: 100%;
        height: auto;
        @include media-breakpoint-down(lg) {
            max-width: 480px;
            display: block;
            margin: 0 auto;
        }
        @include media-breakpoint-down(md) {
            margin-right: inherit;
            max-width: 400px;
        }
    }
    @include media-breakpoint-down(md) {
        .container {
            max-width: inherit;
            padding: 0;
            padding-left: var(--bs-gutter-x, 0.75rem);
            .row {
                margin: inherit;
                .col-lg-6 {
                    padding: inherit;
                }
            }
        }
    }
}