.academy-registration-container {
    padding: 50px 0 90px;
    h2 {
        font-size: 40px;
        color: #0064c8;
        padding: 0 0 20px;
        margin: 0;
    }
    .academy-registration-sidebar {
        h2 {
            margin-top: 55px;
        }
        p {
            font-weight: 300;
            font-size: 18px;
            margin: 0 0 1rem;
        }
    }
    .academy-registration {
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right 20px top 20px;
        padding: 50px;
        border-radius: 10px;
        box-shadow: 5px 5px 10px 0 rgba(0,0,0,.3);
        border: solid 1px rgba(49,49,49,.1);
        p {
            margin-bottom: 6px;
            font-weight: 400;
            font-size: 18px;
            &:not(:first-of-type) {
                font-weight: 300;
                font-style: italic;
                margin-bottom: 40px;
            }   
        }
        .academy-registration-form-block {
            position: relative;
            min-height: 400px;
            .account-created {
                position: absolute;
                top: calc(25% - 35px);
                width: 100%;
                p {
                    font-weight: 300;
                    text-align: center;
                }
            }
        }
        .toggle-password {
            position: absolute;
            right: 8px;
            top: calc(50% - 8px);
        }
        // label a {
        //     color: #000;
        //     text-decoration: underline;
        //     cursor: pointer;
        // }
    }
    .modal-body {
        p {
            font-weight: 300;
        }
        h2 {
            font-size: 24px;
            font-weight: 900;
            color: #1d62ad;
        }
    }
}