.m45{
    padding: 40px 0;
    color: $black;
    h2{
        font-size: 22px;
        font-weight: $black-bold;
    }
    h3{
        font-size: 40px;
        font-weight: $black-bold;
        margin-bottom: 30px;
        @include media-breakpoint-down(lg) {
            font-size: 32px;
        }
    }
    .content{
        p{
            font-weight: $light;
            font-size: 18px;
        }
        ul{
            list-style-type: disc;
            padding: 0 0 0 40px;
            li{
                font-size: 18px;
                font-weight: $light;
                line-height: 27px;
            }
        }
        a{
            color: $blue;
            &:hover{
                text-decoration: underline;
            }
        }
        img{
            max-width: 100%;
            height: auto;
        }
    }
}