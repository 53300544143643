*,
::after,
::before {
    box-sizing: border-box;
}

ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: $black;

    &:hover {
        color: $blue;
    }
}

.lang-switcher {
    width: 30px;
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 0 6px;
    line-height: 28px;
    margin: 10px 0;
    text-align: center;
    cursor: pointer;

    &.footer-lang-switcher {
        width: 150px;
        padding: 0 10px;
    }

    img {
        vertical-align: sub;
        margin-right: 5px;
    }
}

.topmenu_countries {
    width: 690px;
    // height: 330px;
    padding: 25px 30px;
    position: absolute;
    background-image: linear-gradient(270deg, #F2F2F2 0%, #FFFFFF 100%);
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    right: 10px;
    top: 5px;
    font-size: 14px;
    transition: all .25s;
    z-index: 999999999;
    -webkit-transform: translate3d(0, 0, 0);
    @include media-breakpoint-down(md) {
        padding: 10px 30px 0;
    }

    &.invisible {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        top: 15px;
        right: 15px;
        max-width: 370px;
        margin: auto;
        height: auto;
    }

    @include media-breakpoint-down(md) {
        top: 0 !important;
        right: 0;
        left: 0;
        max-width: 100%;
        margin: auto;
        position: fixed;
        height: 100%;
        overflow: hidden;
        padding-bottom: 50px;
        
        .row {
            overflow-y: auto;
            height: 100%;
        }
    }

    .fswitch_country {
        display: block;
        padding: 7px 5px 6px 28px;
        color: #000000 !important;
        background-size: 18px !important;
        background-position: 5px center !important;
        margin: 5px 0;
        background: var(--flag);
        background-size: 20px;
        background-repeat: no-repeat;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &.active {
            background-color: #dcedff;
        }
    }
}

// REGION SWITCHER
.regions-container {
    position: absolute;
    display: none;
    width: 350px;
    z-index: 9999;
    padding-bottom: 100px;
    @include media-breakpoint-down(xl) {
        position: fixed;
        z-index: 9999999999;
        top: 0 !important;
        right: 0;
        left: inherit !important;
        width: 100%;
        height: 100%;
        max-width: 400px;
        padding-bottom: inherit;
    }
    @include media-breakpoint-down(md) {
        max-width: inherit;
    }
}
.regions {
    background-color: #FFF;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 0 0 8px 8px;
    @include media-breakpoint-down(xl) {
        border: none;
        border-radius: 0;
        height: 100%;
    }

    > p {
        font-size: 16px;
        font-weight: 900;
        color: #000;
        margin: 17px 14px 11px;
        background-image: url('images/icons/globe.svg');
        background-repeat: no-repeat;
        background-position: center left;
        padding: 1px 0 1px 30px;
        &:lang(ar) {
            padding: 1px 30px 1px 0;
            background-position: center right;
        }
    }
    .search {
        display: flex;
        flex-wrap: wrap;
        padding: 0 14px;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        input {
            background: rgba(118, 118, 128, 0.12);
            border-radius: 10px;
            border: none;
            font-size: 14px;
            padding: 7px 7px 7px 30px;
            width: 100%;
            background-image: url('images/icons/region_search.svg');
            background-position: center left 8px;
            background-repeat: no-repeat;
            transition: width .25s ease;
            &:focus:not(:empty) {
                background-image: inherit;
                padding: 7px;
            }
            &:lang(ar) {
                background-position: center right 8px;
                padding: 7px 30px 7px 7px;
            }
        }
        p {
            margin: 0;
            color: #007AFF;
            font-size: 14px;
            width: 0;
            text-align: center;
            cursor: pointer;
            visibility: hidden;
            transition: width .25s ease;
            position: absolute;
            right: 15px;
            &:lang(ar) {
                right: inherit;
                left: 15px;
            }
        }
        &.active {
            p {
                width: 60px;
                visibility: visible;
                transition: width .25s ease;
            }
        }
    }
    > ul {
        > li {
            font-size: 14px;
            color: #000;
            padding: 0 10px;
            cursor: pointer;
            &.int-country {
                a {
                    width: 100%;
                    padding: 15px;
                    display: block;
                    position: relative;
                    // font-weight: 900;
                    // &::before {
                    //     content: "";
                    //     background-image: url('images/icons/int.svg');
                    //     background-size: contain;
                    //     background-repeat: no-repeat;
                    //     width: 20px;
                    //     height: 20px;
                    //     display: block;
                    //     position: absolute;
                    //     left: 15px;
                    //     top: calc(50% - 10px);
                    // }
                    &:lang(ar) {
                        padding: 15px;
                        // &::before {
                        //     left: inherit;
                        //     right: 15px;
                        // }
                    }
                }
            }
            span {
                border-bottom: 1px solid rgba(118, 118, 128, 0.12);
                padding: 15px 15px;
                position: relative;
                display: block;
                &:hover {
                    color: $blue;
                }
                &:hover::after {
                    filter: invert(24%) sepia(91%) saturate(1803%) hue-rotate(195deg) brightness(96%) contrast(102%);
                }
                &::after {
                    content: "";
                    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=") no-repeat;
                    width: 10px;
                    height: 10px;
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: calc(50% - 5px);
                    background-size: contain;
                    transform: rotate(90deg);
                    transition: all 0.5s ease;
                }
                &:lang(ar)::after {
                    right: inherit;
                    left: 10px;
                }
            }
            ul {
                border-bottom: 1px solid rgba(118, 118, 128, 0.12);
                display: none;
                max-height: 237px;
                overflow-y: auto;
                @include media-breakpoint-down(xl) {
                    max-height: inherit;
                }
            }
            &.opened {
                span {
                    font-weight: 900;
                    color: $blue;
                    &:after {
                        transform: rotate(-90deg);
                        transition: all 0.5s ease;
                        filter: invert(24%) sepia(91%) saturate(1803%) hue-rotate(195deg) brightness(96%) contrast(102%);
                    }
                }
                ul {
                    display: block;
                }
            }
            &:last-child {
                span,
                ul {
                    border-bottom: none;
                }
            }
        }
    }
    .search-countries,
    >ul >li >ul li {
        padding: 1px 0 1px 20px;
        &:lang(ar) {
            padding: 1px 20px 1px 0;
        }
        a {
            background-image: var(--flag);
            background-size: 16px 16px;
            background-repeat: no-repeat;
            background-position: left center;
            padding: 6px 0 6px 28px;
            display: block;
            &:lang(ar) {
                padding: 6px 28px 6px 0;
                background-position: right center;
            }
        }

    }
    .search-countries p {
        text-align: center;
        font-size: 14px;
        font-weight: 900;
        width: calc(100% - 20px);
    }
    .icon-closen {
        margin: 22px 12px 0 0;
    }
}

/* AE */
body:lang(ar) {
    letter-spacing: 0 !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        letter-spacing: 0 !important;
    }

    .topmenu_countries {
        .fswitch_country {
            padding: 7px 28px 6px 5px;
            background-position: right center !important;
        }
    }

    .lang-switcher {
        img {
            margin-right: 0;
        }
    }
}

.close_popup {
    height: 40px;
    cursor: pointer;

    span {
        display: block;
        background-image: url('images/icons/x.svg');
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-size: 18px;
        background-position: center;
        border: 1px solid #C8C8C8;
        border-radius: 8px;
        float: right;
    }
}
.btn:focus {
    box-shadow: none;
}
.blue-btn {
    background-color: $blue;
    color: $white;

    &:hover {
        background: $dark-blue;
        color: $white;
    }
}
.white-btn {
    background-color: $white;
    color: $blue;
    &:hover {
        background-color: darken($white, 10);
        color: $blue;
    }
}
.green-btn {
    background-color: $green;
    border-color: $green;
    color: $white !important;

    &:hover {
        background: $dark-green;
        border-color: $dark-green;
        color: $white;
    }
}

.black-btn {
    background-color: $black;
}

.bordered-btn {
    line-height: 1.5;
    border: 1px solid $border-color;
    border-radius: 4px;
    margin: 0 5px;
    min-width: 110px;
    text-align: center;
    text-align: -webkit-center;

    &:hover {
        border: 1px solid $blue;
    }
}
.outline-btn {
    border-color: #C8C8C8;
    color:#000;
    &:hover {
        background-color: rgba(0, 0, 0, .1);
    }
}

.telnum_icon {
    background-image: url(images/icons/telephone.svg);
    background-size: 13px 14px;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: 100%;
    width: 22px;
    vertical-align: middle;
    filter: invert(50%) sepia(6%) saturate(30%) hue-rotate(333deg) brightness(100%) contrast(93%);
}

.chat_icon {
    background-image: url(images/icons/chat.svg);
    background-size: 13px 14px;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: 100%;
    vertical-align: middle;
    width: 22px;
    filter: invert(50%) sepia(6%) saturate(30%) hue-rotate(333deg) brightness(100%) contrast(93%);
}

.overlay {
    position: fixed;
    top: 75px;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: none;
    opacity: 0;

    &.overlay-mobile {
        top: 0;
        position: absolute;
        width: 100%;
    }

    &.show {
        opacity: 0.7;
        display: block;

        &.overlay-mobile {
            opacity: 0.8;
        }
    }
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.popup_opened {
    overflow: hidden;
}

.unscrollable {
    overflow: hidden;
    position: relative;
}

//hsform
.modal{
    .close {
        background: transparent;
        border: none;
        opacity: 0.5;
        cursor: pointer;
        span {
            font-size: 24px;
            font-weight: 700;
        }
    }
} 
// .hs-form {
//     fieldset {
//         width: 100%;
//         max-width: inherit !important;
//     }

//     select,
//     input[type=text],
//     input[type=name],
//     input[type=email],
//     input[type=tel] {
//         display: block;
//         width: 100% !important;
//         height: calc(1.5em + 0.75rem + 2px);
//         padding: 0.375rem 0.75rem;
//         font-size: 1rem;
//         font-weight: 400;
//         line-height: 1.5;
//         color: #495057;
//         background-color: #fff;
//         background-clip: padding-box;
//         border: 1px solid #ced4da;
//         border-radius: 0.25rem;
//         // margin-top: 25px !important;
//         transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
//     }

//     textarea {
//         width: 100%;
//         padding: 0.375rem 0.75rem;
//         font-size: 1rem;
//         font-weight: 400;
//         line-height: 1.5;
//         color: #495057;
//         background-color: #fff;
//         background-clip: padding-box;
//         border: 1px solid #ced4da;
//         border-radius: 0.25rem;
//         transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
//     }
    
//     ul {
//         list-style: none;
//         padding: 0;

//         li {
//             margin-top: 6px;
//             color: #fff;
//             font-size: 16px;

//             a {
//                 color: #fff;
//                 font-weight: 900;
//                 text-decoration: underline;
//             }
//         }
//     }

//     input[type=submit] {
//         background-color: $green;
//         color: #fff;
//         border-color: $green;
//         font-weight: $black-bold;
//         font-size: 22px;
//         padding: 7px 30px;
//         margin-top: 25px;
//         line-height: 1.5;
//         display: inline-block;
//         text-align: center;
//         vertical-align: middle;
//         user-select: none;
//         border: 1px solid transparent;
//         border-radius: 0.25rem;
//         transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     }
// }
.cookiefirst-root [data-cookiefirst-button="secondary"] {
    padding-left: 0!important;
    padding-right: 0!important;
    font-size: 13px;
}
.cookiefirst-root [data-cookiefirst-button="primary"] {
    padding-left: 0!important;
    padding-right: 0!important;
    font-size: 13px;
}

.cookiefirst-root [data-cookiefirst-widget="box"] [data-cookiefirst-action="reject"] {
    background: #0D56C9!important;
    color: white!important;
}
.cookiefirst-root [data-cookiefirst-widget="box"] [data-cookiefirst-action="reject"]:hover {
    background: white!important;
    color: #0D56C9!important;
}


// .zsiq_theme1.zsiq_floatmain {
//     // z-index: 999 !important;
//     right: -1000px;
//     // visibility: hidden !important;
// }
.fa:lang(ar) {
    font-family: 'FontAwesome' !important;
}
.content-page {
    .wp-block-buttons {
        @extend .container;
        .wp-block-button {
            text-align: center;
            .wp-block-button__link{
                @extend .btn;
                @extend .btn-lg;
                @extend .green-btn;
                font-size: 22px;
                font-weight: 900;
                min-width: 280px;
                display: inline-block;
                margin: 15px 0;
                @include media-breakpoint-up(md) {
                    margin: 15px 0 60px;
                }
            }
        }
    }
    .m18.bg-white + .wp-block-buttons {
        margin-top: -15px;
        @include media-breakpoint-up(md) { 
            margin-top: -40px;
        }
    }
    &.ltr-on-rtl {
        direction: ltr;
        h2,
        p,
        .btn,
        h1 {
            font-family: $lato !important;
        }
    }
}
.etracker-opt-out {
    position: fixed;
    bottom: 50px;
    right: 50px;
}
// COUNTRY SELECT
.contact-select-country,
.select-country,
.selectpicker {
    .dropdown-menu {
        max-height: 430px !important;
        @include media-breakpoint-down(md) {
            max-height: 260px !important;
        }
    }
}
.modal-body.ru-consent {
    p.title {
        font-weight: 900;
        text-align: center;
        font-size: 28px;
    }
    ul {
        list-style: disc;
        padding-left: 15px;
        li {
            font-size: 18px;
        }
    }
    a {
        color: $blue;
        text-decoration: underline;
    }
    
}
.ru.modal-open .modal-backdrop.show {
    opacity: 0.9;
}
.ru > .cookiefirst-root {
    visibility: hidden;
}
.pr-remove-table-border{
    table tr,
    table tr td {
        border-width: 0 !important;
        strong, b {
            font-weight: 900;
            font-size: 24px;
            line-height: 1.2;
            color: $blue;
        }
        em {
            font-style: normal;
            font-weight: 900;
        }
        a {
            color: $blue;
            // font-weight: 900;
            text-decoration: underline;
        }
        vertical-align: top;
        strong em {
            font-size: 16px;
            color: #000;
            font-weight: 900;
        }
    }
    th {
        border-width: 0 !important;
        vertical-align: top;
        strong, b {
            font-size: 32px !important;
        }
    }
}
.modal-body {
    h1 {
        color: $blue !important;
    }
    a[data-bs-toggle="modal"] {
        cursor: pointer;
    }
}
.ltr-on-rtl {
    direction: ltr;
}

// CookieFirst iframe
.yt-oembed {
    margin-bottom: $spacer;
    iframe:not([src]) {
        display: block !important;
    }
    iframe:not([src]) + .cookiefirst-disabled-resource {
        display: flex;
        background-color: #f8f8f8;
        justify-content: center;
        align-items: center;
    }
}

// reCAPTCHA
.grecaptcha-badge { visibility: hidden; }
.recaptcha-notice {
    font-size: 14px;
    margin-top: 16px;
    a {
        font-weight: 900;
    }
    opacity: 0.5;
}

// CookieFirst
body.us .cookiefirst-root {
	visibility: hidden;
}
body.us.show-c1st .cookiefirst-root {
	visibility: visible !important;
}
body.us.show-c1st-btn .cookiefirst-root {
	visibility: visible !important;
}
body.us.show-c1st-btn .cookiefirst-root [data-cookiefirst-action="open-preferences"] {
	visibility: hidden;
}
.cookie-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
}
.cookie-bar .container {
	display: flex;
	align-items: center;
	background-color: #ffffff;
	padding: 16px 26px;
	width: 100%;
	max-width: 100%;
	border-top: 1px solid #000;
}
.cookie-bar p {
	font-size: 18px;
	margin-bottom: 0;
	color: #000;
	padding-right: 20px;
}
.cookie-bar p a {
	color: #0064c8;
	text-decoration: underline;
}