.registration {
  background-color: #e9f0fa;
  position: relative;
    &::before {
      position: absolute;
      content: "";
      display: block;
      width: 50%;
      height: 100%;
      left: 50%;
      background-size: cover;
      @include media-breakpoint-down(md) {
        left: 0;
        bottom: 0;
        top: 700px;
        width: 100%;
        height: calc(100% - 700px);
      }
    }
    .registration-form {
        padding-top: 50px;
        color: #3d4548;
        min-height: 700px;
        position: relative;
        h1 {
            text-align: center;
            font-size: 42px;
            padding-bottom: 25px;
            font-weight: 300;
            letter-spacing: 1px;
            margin: 0;
          }
        .registrationForm {
          margin: 0 8.333333%;
            .form-section {
              display: none;
            }
            .form-section.current {
              display: block;
            }
            select,
            input {
              background-image: none;
            }
            select {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
              background-repeat: no-repeat;
              background-position: right .75rem center;
              background-size: 16px 12px;
            }
            .signup-error {
              background: #ea4e51;
              border-radius: 4px;
              span > div {
                padding: 5px 20px;
                font-weight: 700;
                color: #FFFFFF;
                font-size: 14px;
                margin: 10px 0;
                a {
                  color: #fff;
                  text-decoration: underline;
                }
              }
            }



            
            .signup-phone {
                position: relative;
                input {
                    padding-left: 130px;
                    &:lang(ar) {
                      padding-left: inherit;
                      padding-right: 130px;
                    }
                }
                // select {
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     width: 100px;
                //     border-color: transparent;
                //     border-radius: 0.3rem 0 0 0.3rem;
                //     &:focus {
                //       box-shadow: none;
                //     }
                // }
                .select-country {
                  position: absolute;
                  top: 1px;
                  left: 1px;
                  width: 125px;
                  background-color: transparent;
                  &:lang(ar) {
                    left: inherit;
                    right: 1px;
                  }
                  button {
                    background-color: #FFF;
                    border-radius: 0;
                    margin-left: 0;
                    border: 1px solid transparent;
                    font-size: 18px;
                    padding: 8px 10px 9px;
                    border-radius: 0.3rem 0 0 0.3rem;
                  }
                }
            }
            .signup-password {
                position: relative;
                i {
                    position: absolute;
                    right: 15px;
                    top: calc(50% - 9px);
                    cursor: pointer;
                    font-size: 18px;
                }
            }
            .signup-blue-btn {
              background-color: #1d62ad;
              color: #FFFFFF;
              border-color: #1d62ad;
              font-weight: 700;
              width: 100%;
              font-size: 16px;
              @include media-breakpoint-down(lg) {
                margin-bottom: 1rem;
              }
              &:hover {
                background-color: #3d4548;
                border-color: #3d4548;
              }
            }
            .signup-outline-btn {
              background-color: #FFF;
              width: 100%;
              font-size: 16px;
              font-weight: 700;
              border-color: #1d62ad;
              color: #1d62ad;

            }
        }
    }
    .registration-sidebar {
        color: #FFF;
        padding: 50px 0 25px 50px;
        position: relative;
        z-index: 9;
        @include media-breakpoint-down(md) {
          padding: 50px 15px;
        }
        p {
            font-size: 16px;
            margin: 0 0 16px;
            &:first-child {
                margin-bottom: 5px;
            }
            strong {
            }
        }
        img {
            width: 145px;
            display: block;
            margin: 0 auto;
            margin-bottom: 25px;
        }
    }

}







.form-check {
    position: relative;
}
.pr_checkbox {
    margin: 35px 0;
  }
  
  
  .chekmark-label {
    padding-left: 25px;
    a {
        color: #1D62AD;
        text-decoration: underline;
        cursor: pointer;
    }
  }
  .pr_checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 15px;
    height: 18px;
    width: 18px;
    border: 2px solid #1D62AD;
    border-radius: 2px;
  }
  
  
  
  /* When the checkbox is checked, add a blue background */
  .pr_checkbox input:checked ~ .checkmark {
    background-color: #1D62AD;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .pr_checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .pr_checkbox .checkmark:after {
    left: 4px;
    top: 0;
    width: 6px;
    height: 12px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .custom-control.material-checkbox {
    --color: #26a69a;
  }
  .custom-control.material-checkbox .material-control-input {
    display: none;
  }
  .custom-control.material-checkbox .material-control-input:checked ~ .material-control-indicator {
    border-color: var(--color);
    -webkit-transform: rotateZ(45deg) translate(1px, -5px);
            transform: rotateZ(45deg) translate(1px, -5px);
    width: 10px;
    border-top: 0px solid #fff;
    border-left: 0px solid #fff;
  }
  .custom-control.material-checkbox .material-control-indicator {
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #aaa;
    transition: 0.3s;
  }
  .custom-control.material-checkbox .material-control-description {
    font-size: 16px;
    margin-left: 4px;
  }
  .form-loading {
    height: 0;
    width: 0;
    padding: 15px;
    border: 6px solid rgba(29, 98, 173, .25);
    border-right-color: rgba(29, 98, 173, 1);
    border-radius: 22px;
    -webkit-animation: rotate 1s infinite linear;
    /* left, top and position just for the demo! */
    position: absolute;
    left: calc(50% - 25px);
    top: 30%;
  }
  @-webkit-keyframes rotate {
    /* 100% keyframe for  clockwise.
       use 0% instead for anticlockwise */
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  .bs-searchbox > input {
      padding: 10px !important;
  }