.modal-jobAlert {
    max-width: 900px;
  .jobAlert-content {
    border-radius: 10px;
    box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.5);
    border: 0;
    min-height: 450px;
    .jobAlertHeader {
        border-bottom: 0;
    }
    .jobAlert-modal-body {
        padding: 0;
        .jobAlert-form {
            padding: 0 60px 50px;
            @include media-breakpoint-down(md) {
                padding: 0 25px 25px;
            }
            p.title {
                font-size: 35px;
                color: #0064c8;
                padding: 45px 0 35px; 
                margin-bottom: 0;
                font-weight: 900;
                @include media-breakpoint-down(md) {
                    padding: 0 0 30px;
                    font-size: 24px;
                }
            }
            p.subtitle {
                font-size: 20px;
                font-weight: 900;
                color: #000000;
                margin: 0 0 16px;
            }
            // form {
            //     // input {
            //     //     width: 100%;
            //     //     font-size: 18px;
            //     //     padding: 5px 15px 4px;
            //     //     border: 1px solid #9a9a9a;
            //     //     border-radius: 0;
            //     //     margin-bottom: 20px;    
            //     // }
            //     // label {
            //     //     display: none;
            //     //     color: #000000;
            //     //     &.hs-form-booleancheckbox-display,
            //     //     &.hs-error-msg {
            //     //         display: block;
            //     //     }
            //     //     a {
            //     //         color: #0064c8;
            //     //         text-decoration: underline;
            //     //         cursor: pointer;
            //     //     }
            //     // }
            //     .hs_submit.hs-submit {
            //         text-align: right;
            //         input {
            //             max-width: 215px;
            //             font-size: 22px;
            //             font-weight: 900;
            //             background-color: $green;
            //             color: #FFFFFF;
            //             border-radius: 4px;
            //             border: 1px solid $green;
            //             padding: 8px 0;    
            //         }
            //     }
            //     ul.inputs-list {
            //         padding: 0;
            //         list-style: none;
            //         li input {
            //             width: inherit;
            //         }
            //     }
            // }
        }
        .jobAlert-success {
            text-align: center;
            padding: 0 20px;
            img {
                display: block;
                margin: 75px auto;
            }
            p {
                font-size: 18px;
                font-weight: 300;
                padding-bottom: 100px;
                margin: 0;
                @include media-breakpoint-down(md) {
                    padding-bottom: 75px
                }
                &:first-of-type {
                    color: #0064c8;
                    font-size: 35px;
                    font-weight: 900;
                    padding: 0;
                    line-height: 1.2; 
                    margin-bottom: 16px;
                    @include media-breakpoint-down(md) {
                        font-size: 24px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
  }
}