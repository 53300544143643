.m52 {
    .blog-hero {
        height: 500px;
        position: relative;
        background-size: cover;
        background-position: center;
        h1 {
            margin: 0;
            color: #FFFFFF;
            font-weight: 300;
            font-size: 58px;
            padding: 50px 0 0;
            @include media-breakpoint-down(md) {
                font-size: 34px;
            }
            strong {
                font-weight: 700;
            }
        }
        .blog-scroll-down {
            display: none;
            @include media-breakpoint-down(md) {
                display: block;
                position: absolute;
                width: 60px;
                height: 30px;
                bottom: 20px;
                left: calc(50% - 30px);
                background-image: url('images/blog-arrow.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                animation:arrow_down 1s infinite;
                a span {
                    visibility: hidden
                }
            }
        }
        @keyframes arrow_down {
            0%,
            20%,
            50%,
            80%,
            100% {
             transform:translateY(0)
            }
            40% {
             transform:translateY(-20px)
            }
            60% {
             transform:translateY(-10px)
            }
           }
    }
    .blog-container {
        background-color: #e9f0fa;
        padding: 30px 0 50px;
        article {
            background-color: #FFF;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
            border-radius: 10px;
            margin: 20px 0;
            padding: 20px;
            .no-results {
                text-align: center;
                padding: 40px;
                color: #1d62ad;
                font-size: 22px;
                font-weight: 700;
            }
            @include media-breakpoint-down(md) {
                padding: 0;
            }
            img {
                display: block;
                border-radius: 12px;
                margin-right: 20px;
                max-width: calc(100% - 20px);
                height: auto;
                &:lang(ar) {
                    margin-right: 0;
                    margin-left: 20px;
                }
                @include media-breakpoint-down(md) {
                    max-width: inherit;
                    width: 100%;
                    border-radius: 10px 10px 0 0;
                }
            }
            .post-meta {
                font-size: 12px;
                color: #3d4548;
                @include media-breakpoint-down(md) {
                    padding: 0 15px;
                }
            }
            h2 {
                margin: 15px 0;
                font-size: 18px;
                font-weight: 700;
                @include media-breakpoint-down(md) {
                    padding: 0 15px;
                }
                a {
                    color: #1d62ad;
                    &:hover {
                        color: #3d4548;
                    }
                }
            }
            p {
                margin: 0;
                font-size: 16px;
                color: #3d4548;
                line-height: 1.5;
                @include media-breakpoint-down(md) {
                    padding: 0 15px 15px;
                }
                a {
                    color: #1d62ad;
                    font-weight: 700;
                    text-decoration: underline;
                }
            }
        }

    }
}