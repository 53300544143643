.m76 {
  padding: 50px 0 60px;
  background-color: rgba(0, 100, 200, 0.05);

  h2 {
    color: $blue;
    margin-top: 0;
    margin-bottom: 40px;

    line-height: 1.1;
    font-size: 54px;
    letter-spacing: normal;
    @include media-breakpoint-down(xl){
      font-size: 44px;
      text-align: center;
    }
  }
  nav{
    @include media-breakpoint-down(xl) {
      position: relative;
      width: 100%;
      max-width: 450px;
      height: 60px;
    }
    .nav-tabs-arrow{
      position: absolute;
      transform: rotate(270deg);
      top: 0;
      right: 0;
      z-index: 9;
      width: 58px;
      height: 60px;
      text-align: center;
      padding: 20px;
      cursor: pointer;
      &:lang(ar) {
        right: auto;
        left: 0;
      }
      &.active{
        transform: rotate(90deg);
        z-index: 997;
      }
      img{
        width: 9px;
        height: 17px;
      }
    }
  }
  .nav-tabs {
    background-color: $white;
    flex-wrap: nowrap;
    justify-content: space-between;
    cursor: pointer;
    @include media-breakpoint-down(xl) {
      flex-direction: column;
      position: relative;
      width: 100%;
      max-width: 450px;
    }
    &.active{
      position: absolute;
      z-index: 99;
      .nav-link {
        display: block;
      }
    }
    .nav-link {
      cursor: pointer;
      border: solid 3px transparent;
      border-radius: 8px;
      font-family: $lato;
      font-size: 15px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: center;
      color: $blue;
      max-width: 242px;
      @include media-breakpoint-down(xl) {
        display: none;
        width: 100%;
        max-width: 450px;
        padding-right: 40px;
        text-align: left;
        margin-left: 0;
        &:lang(ar) {
          text-align: right;
          padding-left: 40px;
          padding-right: inherit;
        }
      }
      &:hover {
        border: solid 3px $blue;
      }
      &.active {
        border: solid 3px $blue;
        background-color: rgba(0, 100, 200, 0.1);
        @include media-breakpoint-down(xl) {
          background-color: transparent;
          display: block;
          order: -1;
        }
        img{
          opacity: 1;
        }
      }

      img {
        display: block;
        margin: 0 auto 5px;
        max-height: 40px;
        width: auto;
        @include media-breakpoint-down(xl) {
          opacity: 0.2;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
        }
      }
    }
  }

  .tab-content {
    margin-top: 30px;
    // &.dd-expanded{
    //   margin-top: 100px;
    // }
    .tab-pane {
      .offset-lg-2:lang(ar) {
        margin-right: 16.66667%;
        margin-left: initial;
        @include media-breakpoint-down(lg) {
          margin-right: initial;
        }
      }
      h3 {
        font-size: 27px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: normal;
        color: #0064c8;
        padding: 0 0 15px;
        margin: 0;
        &:lang(ar) {
          line-height: 1.5;
        }

      }

      p {
        padding: 0 0 30px;
        margin: 0;
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #000;
      }

      .feature {
        position: relative;
        p {
          padding: 0 0 20px 41px;
          &:lang(ar) {
            padding: 0 41px 20px 0;
          }
        }
        img {
          position: absolute;
          left: 0;
          &:lang(ar) {
            left: auto;
            right: 0;
          }
        }
      }

      a {
        margin: 38px 0 0 0;
        // @extend .btn;
        // @extend .blue-btn;
        // padding: 9px;
        // border-radius: 8px;
        // font-size: 22px;
        // font-weight: 900;
        // font-stretch: normal;
        // font-style: normal;
        // line-height: normal;
        // letter-spacing: normal;
        // text-align: center;
        // display: block;
        // width: 270px;
        // color: $white;
        // background-color: $blue;

        // &:hover {
        //   filter: brightness(90%);
        // }
      }

      .image-side {
        img {
          max-width: 100%;
          @include media-breakpoint-down(lg) {
            max-width: 450px;
            margin: 0 auto 30px;
            display: block;
          }
          @include media-breakpoint-down(sm) {
            max-width: 100%;
          }
          // @include media-breakpoint-down(md) {
          //   margin-bottom: 30px;
          // }
        }
      }
      @include media-breakpoint-down(lg) {
        .row{
          flex-direction: column-reverse;
        }
      }
    }
  }
  hr {
    background-color: #aec4db;
    height: 1px;
    border: 0;
    margin-top: 22px;
    margin-bottom: 36px;
  }
  .quote {
    img{
      max-width: 100%;
      height: auto;
    }
    blockquote {
      font-family: $lato;
      font-size: 18px;
      font-weight: 900;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.25;
      letter-spacing: normal;
      color: #000;
      padding: 0;
      margin: 0 0 20px;
    }
    p{
      display: inline-block;
      padding: 0!important;
      margin: 0!important;
      b {
        display: inline-block;
      }
    } 
  } 
  .col-3.text-center img {
    max-width: 170px;
    width: 100%;
  }
  .m76-awards {
    margin: 25px 0 0;
    display: flex;
    gap: 20px;
    .award {
      width: 222px;
      max-width: 50%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  &.v2 {
    h2 {
      text-align: center;
      font-size: 52px;
    }
    .nav-tabs {
      border-radius: 8px;
      gap: 16px;
      .nav-link {
        flex-grow: 1;
        flex-basis: 0;
        @include media-breakpoint-down(xl) {
          br {
            display: none;
          }
        }
      }
    }
  }
}
