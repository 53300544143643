.case-study {
    background-size: cover;
    background-position: center;
    .cs-container {
        background-color: #fff;
        padding: 25px;
        margin-bottom: 150px;
        h1 {
            margin: 0;
            font-weight: 300;
            border-bottom: 1px solid #2b78bd;
            padding: 10px 5px;
            letter-spacing: .6px;
            color: #3d4548;
            font-size: 40px;
        }
        .cs-row {
            border-bottom: 1px solid #adadad;
            display: inline-flex;
            align-items: center;
            width: 100%;
            padding: 10px 0;
            color: #3d4548;
            .cs-title {
                width: 40%;
                padding-left: 10px;
                box-sizing: border-box;
                font-weight: 700;
            }
            .cs-detail {
                width: 60%;
                padding-right: 10px;
                box-sizing: border-box;
            }
        }
        .cs-content {
            color: #3d4548;
            margin-top: 25px;
            .cs-nav {
                margin: 25px -15px;
                a {
                    color: #3d4548;
                    border: 1px solid #cdcdcd;
                    padding: 10px 15px;
                    border-radius: 4px;
                    text-transform: uppercase;
                    font-weight: 700;
                    &:hover {
                        color: #FFF;
                        border: 1px solid #1d62ad;
                        text-decoration: none;
                        background: #1d62ad;
                    }
                }
            }
        }
    }
}