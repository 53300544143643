.page-404 {
    background-image: linear-gradient(-44deg, #0064C8 0%, #003164 100%);
    text-align: center;
    color: #FFFFFF;
    padding: 125px 0 100px;
    @include media-breakpoint-down(md) {
        padding: 60px 0 30px;
    }
    h2 {
        margin: 0 0 60px;
        font-weight: 900;
        font-size: 90px;
        @include media-breakpoint-down(md) {
            margin-top: 60px;
            font-size: 70px;
            margin: 0 0 35px;
        }
    }
    p {
        font-size: 18px;
        font-weight: 300;
    }
    .item-404 {
        padding-left: 6px;
        padding-right: 6px;
        @include media-breakpoint-down(md) {
            max-width: 275px;
            margin: 0 auto;
        }
        a > p {
            color: #FFFFFF;
            margin: 0;
        }
        @include media-breakpoint-down(lg) {
            margin-bottom: 30px;
        }
    }
    .footer_404 p {
        margin-top: 110px;
        a {
            color: #FFFFFF;
            font-weight: 900;
            text-decoration: underline;
        }
    }
}