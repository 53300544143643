.currency-switcher {
    display: none;
}

.en .currency-switcher {
    display: block!important;
}

.m03{
    padding: 16px 0 80px;
    @include media-breakpoint-down(md){
        padding: 30px 0 25px;
    }
    .pricing_switcher{
        padding-left: 20%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        @include media-breakpoint-down(xl) {
            padding-left: 15px;
        }
        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }
        .billing-cycle{
            width: calc(100% - 230px);
            background-color: #E0E0E0;
            border-radius: 8px;
            border: 1px solid #E0E0E0;
            display: flex;
            height: 40px;
            position: relative;
            @include media-breakpoint-down(lg){
                width: 100%;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                height: 60px;
            }
            
            .billing {
                position: relative;
                width: 50%;
                z-index: 1;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    margin: 1px;
                    padding: 7px 0;
                    text-align: center;
                    font-size: 18px;
                    line-height: 1.2;
                }
            }
            .selected_cycle {
                background-color: #FFF;
                width: 50%;
                height: 100%;
                position: absolute;
                border-radius: 8px;
                transition: right .25s;
                &.yearlyPlan {
                    right: 0;
                }
                &.monthlyPlan {
                    right: 50%;
                }
            }
        }
        .currency-switcher{
            width: 220px;
            position: relative;
            z-index: 99;
            @include media-breakpoint-down(md) {
                width: 100%;
                height: 60px;
                margin-bottom: 10px;
            }
            button[name="currency-switcher"]{
                width: 100%;
                height: 100%;
                text-align: center;
                background: $white;
                border: 1px solid rgba(0,0,0,0.1);
                border-radius: 8px;
                -webkit-appearance: none;
                -moz-appearance: none;
                cursor: pointer;
                text-align-last: center;
                font-size: 16px;
                line-height: 1.5;
                padding: 6px 12px;
                font-weight: $regular;
                color: $black;
            }
            .currencies{
                display: none;
                position: absolute;
                top: 0;
                background: $white;
                border: 1px solid rgba(0,0,0,0.1);
                width: 100%;
                top: -40px;
                left: 0;
                border-radius: 8px;
                &.show{
                    display: block;
                }
                li{
                    font-size: 16px;
                    font-weight: $regular;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                    &.selected{
                        background-color: #E0E0E0;
                    }
                    &:hover{
                        background-color: #E0E0E0;
                    }
                    &:first-child{
                        border-radius: 8px 8px 0 0;
                    }
                    &:last-child{
                        border-radius: 0 0 8px 8px;
                    }
                }
            }
        }
    }
    .plans{
        .features{
            padding-right: 0;
            max-width: 20%;
            .per-month {
                color: $blue;
                font-weight: 700;
                height: 145px;
                font-size: 18px;
                line-height: 1.2;
            }
            .feature{
                width: 100%;
                min-height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:nth-child(even){
                    background-image: linear-gradient(270deg, rgba(0,0,0,0.05) 0%, rgba(255,255,255,0.07) 100%);
                }
                &:nth-child(odd){
                    background: transparent;
                }
                &.has-info{
                    position: relative;
                    cursor: pointer;
                    &:before{
                        content: "";
                        background-image: url(images/icons/info.svg);
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        right: 10px;
                        top: calc(50% - 10px);
                        background-color: $white;
                        border-radius: 50%;
                        z-index: 99;
                        cursor: pointer;
                    }
                    // &:hover{
                    //     .feature-desc{
                    //         display: inline-table;
                    //         font-size: 12px;
                    //         ul {
                    //             list-style: disc;
                    //         }
                    //     }
                    // }
                    &:lang(ar){
                        &:before{
                            right: auto;
                            left: 10px;
                        }
                    }
                }
                &.medium{
                    min-height: 80px;
                }
                &.large{
                    min-height: 120px;
                }
                &:first-child{
                    border-top: 1px solid $border-color-light;
                    border-bottom: 1px solid $border-color-light;
                    p{
                        font-weight: $black-bold;
                    }
                }
                &:last-child{
                    margin-bottom: 2px;
                }
                p{
                    font-size: 15px;
                    font-weight: $regular;
                    line-height: 23px;
                    padding: 5px 24px 5px 0;
                    margin: 0;
                    color: $black;
                    @include media-breakpoint-down(xl) {
                        font-size: 18px;
                    }
                }
                .feature-desc{
                    display: none;
                    font-size: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 95%;
                    width: 220px;
                    z-index: 9;
                    border-radius: 4px;
                    color: #747474;
                    letter-spacing: 0.31px;
                    padding: 5px 10px 5px 20px;
                    border: 1px solid #E1E1E1;
                    box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.30);
                    background: $white;
                    margin: auto;
                    &:lang(ar) {
                        padding: 5px 20px 5px 10px;
                    }
                }
            }
            ul {
                margin-bottom: -1px
            }
        }
        .plan-container{
            padding-left: 5px;
            padding-right: 5px;
            @include media-breakpoint-down(lg){
                padding-left: 12px;
                padding-right: 12px;
            }
            &:nth-child(2){
                @include media-breakpoint-up(lg) {
                    padding-left: 0;
                }
            }
            &:last-child{
                padding-right: 12px;
            }
            @include media-breakpoint-down(lg) {
                align-self: flex-start !important;
                margin: 0px auto 10px;
            }
            .plan{
                border: 1px solid #0000001a;
                box-shadow: 0 2px 5px 0 #0000001a;
                border-radius: 10px;
                color: $blue;
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include media-breakpoint-down(lg) {
                    box-shadow: none;
                    max-height: 120px;
                    overflow: hidden;
                    cursor: pointer;
                    position: relative;
                    transition: max-height 0.15s ease-out;
                    &:before{
                        content: '';
                        position: absolute;
                        width: 16px;
                        height: 8px;
                        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50'><polygon points='0,0 100,0 50,50'/></svg>");
                        background-repeat: no-repeat;
                        background-size: cover;
                        right: 15px;
                        top: calc(50% - 4px);
                        margin: auto;
                    }
                }
                &.expanded{
                    height: auto;
                    max-height: 1500px;
                    transition: max-height .25s ease-in;
                    &:before{
                        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' transform='scale(1,-1) translate(0,0)' width='100' height='50'><polygon points='0,0 100,0 50,50'/></svg>");
                        right: 15px;
                        top: 50px;
                    }
                }
                &.premium{
                    background-image: linear-gradient(105.56deg, #003264 0%, #0064C8 84.28%);
                    color: $white;
                    &:before{
                        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' width='100' height='50'><polygon points='0,0 100,0 50,50'/></svg>");
                    }
                    &.expanded{
                        &:before{
                            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='white' transform='scale(1,-1) translate(0,0)' width='100' height='50'><polygon points='0,0 100,0 50,50'/></svg>");
                        }
                    }
                    p{
                        color: $white;
                    }
                    .feature{
                        color: $white;
                        .check-mark{
                            filter: invert(99%) sepia(4%) saturate(624%) hue-rotate(276deg) brightness(117%) contrast(100%);
                        }
                    }
                    &.premium-expanded{
                        @include media-breakpoint-down(md) {
                            height: auto;
                            max-height: 1500px;
                        }
                    }
                }
                .plan-head{
                    @include media-breakpoint-down(md) {
                        &:lang(ar) {
                            font-family: 'Lato', sans-serif !important;
                            text-align: center;
                        }
                    }
                    h3{
                        font-size: 38px;
                        font-weight: $black-bold;
                        padding: 15px 15px 0;
                        line-height: 48px;
                        margin: 0 0 40px;
                        @include media-breakpoint-down(lg){
                            margin: 0;
                        }
                        @include media-breakpoint-up(lg) {
                            &:lang(ar) {
                                font-family: 'Lato', sans-serif !important;
                                text-align: center;
                            }
                        }
                        &.has-subtitle{
                            margin: 0;
                        }
                        @include media-breakpoint-down(lg) {
                            font-size: 40px;
                        }
                    }
                    .prices{
                        &:lang(ar) {
                            text-align: center;
                        }
                        .prices-currency{
                            display: none;
                            &.show{
                                display: block;
                            }
                            .price{
                                display: none;
                                font-size: 30px;
                                font-weight: $black-bold;
                                margin: 0 0 40px;
                                @include media-breakpoint-down(xl) {
                                    font-size: 26px;
                                }
                                @include media-breakpoint-down(md) {
                                    font-size: 24px;
                                }
                                &.show{
                                    display: block;
                                }
                                span{
                                    font-size: 14px;
                                    @include media-breakpoint-down(lg) {
                                        font-size: 24px;
                                        line-height: 1.2;
                                    }
                                }
                            }
                        }
                    }
                    .cta-btn{
                        text-align: center;
                        color: $white;
                        margin: 0 auto 55px;
                        display: block;
                        max-width: calc(100% - 30px);
                        font-weight: $black-bold;
                        font-size: 16px;
                        line-height: 1.1;
                        padding: 6px 12px;
                    }
                }
                .plan-features{
                    @include media-breakpoint-down(lg) {
                        margin: 20px 0 20px 30px;
                        padding: 0 15px;
                        &:lang(ar) {
                            margin: 20px 30px 20px 0;
                        }
                    }
                }
                p{  
                    &.price,
                    &.request-quote,
                    &.subtitle {
                        font-size: 16px;
                        font-weight: $regular;
                        padding: 0 15px;
                        display: table;
                        width: 100%;
                        line-height: 1.2;
                    }

                    &.request-quote{
                        font-size: 20px;
                        font-weight: $bold;
                        margin: 0 0 30px;
                        @include media-breakpoint-down(lg){
                            font-size: 24px;
                            font-weight: $black-bold;
                            br{
                                display: none;
                            }
                        }
                    }
                    &.subtitle{
                        font-weight: $bold;
                        line-height: 20px;
                        margin: 0 0 20px;
                        @include media-breakpoint-down(lg){
                            display: none;
                        }
                        @include media-breakpoint-up(lg) {
                            &:lang(ar) {
                                text-align: center;
                            }
                        }
                        &:empty {
                            margin-bottom: 0; // QUICK FIX TO GO LIVE
                        }
                    }
                    
                    &.no-padding{
                        padding: 0;
                    }
                    
                }
                .feature{
                    font-size: 16px;
                    font-weight: $regular;
                    padding: 0 15px;
                    display: table;
                    width: 100%;
                    text-align: center;
                    color: $black;
                    line-height: 23px;
                    min-height: 50px;
                    padding: 15px 5px;
                    margin: 0;
                    min-height: 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @include media-breakpoint-down(lg) {
                        min-height: auto !important;
                        text-align: left;
                        padding: 5px 0;
                        display: list-item;
                        &:lang(ar) {
                            text-align: right;
                        }
                    }
                    &.regular{
                        min-height: 60px;
                    }
                    &.medium{
                        min-height: 80px;
                    }
                    &.large{
                        min-height: 120px;
                    }
                    &:first-child{
                        font-weight: $black-bold;
                        border-top: 1px solid $border-color-light;
                        border-bottom: 1px solid $border-color-light;
                        @include media-breakpoint-down(lg) {
                            border: none;
                        }
                    }
                    &:nth-child(even){
                        background: rgba(0,0,0,0.05);
                        @include media-breakpoint-down(lg) {
                            background: transparent;
                        }
                    }
                    &:nth-child(odd){
                        background: transparent;
                    }
                    &:last-child{
                        border-radius: 0 0 10px 10px;
                    }
                    &.has-info{
                        position: relative;
                        cursor: pointer;
                        flex-direction: row;
                        align-items: center;
                        .info-tip{
                            background-image: url(images/icons/info.svg);
                            width: 20px;
                            height: 20px;
                            background-color: $white;
                            border-radius: 50%;
                            cursor: pointer;
                            margin: 0 6px;
                        }
                        // &:hover{
                        //     .feature-desc{
                        //         display: inline-table;
                        //         font-size: 14px;
                        //     }
                        // }
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 18px;
                    }
                    .check-mark{
                        width: 20px;
                        height: 20px;
                        display: block;
                        margin: auto;
                        background-image: url(images/icons/check.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        filter: invert(22%) sepia(98%) saturate(2233%) hue-rotate(197deg) brightness(93%) contrast(103%);
                    }
                    ul{
                        list-style-type: disc;
                        margin-top: 5px;
                        padding-inline-start: 15px;
                        li{
                            line-height: 1.2;
                            margin-bottom: 8px;
                        }
                    }
                    .feature-desc{
                        display: none;
                        font-size: 0;
                        position: absolute;
                        top: 50px;
                        left: 50%;
                        width: 180px;
                        z-index: 999;
                        border-radius: 4px;
                        color: #747474;
                        letter-spacing: 0.31px;
                        padding: 5px 10px 5px 10px;
                        border: 1px solid #E1E1E1;
                        box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.30);
                        background: $white;
                        margin: auto;
                        text-align: left;
                        &:lang(ar){
                            text-align: right;
                        }
                    }
                }
                .btn-container{
                    display: block;
                    height: 100px;
                    @include media-breakpoint-down(lg) {
                        height: 50px;
                    }
                }
            }
            // RU MOBILE FIX
            @include media-breakpoint-down(lg) {
                &:lang(ru):last-of-type {
                    .plan {
                        max-height: 140px;
                        &.expanded {
                            max-height: 9999px;
                        }
                    }
                    
                }
            }
        }
        .modal{
            color: $black;
            .modal-header{
                .modal-title{
                    font-size: 20px;
                    font-weight: $medium;
                    margin: 0;
                }
                .close{
                    background: transparent;
                    border: none;
                    opacity: 0.5;
                    cursor: pointer;
                    &:hover{
                        opacity: 1;
                    }
                    span{
                        font-size: 24px;
                        font-weight: $bold;
                    }
                }
            }
            // .modal-body{
            //     .form{
            //         .hbspt-form{
            //             .hs-form-field{
            //                 padding: 0 5px;
            //                 margin-bottom: 10px;
            //                 &.hs-fieldtype-text{
            //                     width: 50%;
            //                     display: inline-block;
            //                     @include media-breakpoint-down(md) {
            //                         width: 100%;
            //                         display: block;
            //                     }
            //                 }
            //                 &.hs_contact_tos{
            //                     label{
            //                         display: block;
            //                         a{
            //                             color: $blue;
            //                             &:hover{
            //                                 text-decoration: underline;
            //                             }
            //                         }
            //                     }
            //                     input{
            //                         width: auto;
            //                         height: auto;
            //                     }
            //                 }
                            
            //                 label{
            //                     display: none;
            //                 }
            //                 input{
            //                     width: 100%;
            //                     height: 38px;
            //                     padding: 6px 12px;
            //                     font-size: 16px;
            //                     font-weight: $regular;
            //                     color: #495057;
            //                     background-clip: padding-box;
            //                     border: 1px solid #ced4da;
            //                     border-radius: 4px;
            //                 }
            //                 textarea{
            //                     width: 100%;
            //                     height: 250px;
            //                     resize: none;
            //                     padding: 6px 12px;
            //                     font-size: 16px;
            //                     font-weight: $regular;
            //                     color: #495057;
            //                     background-clip: padding-box;
            //                     border: 1px solid #ced4da;
            //                     border-radius: 4px;
            //                 }
            //             }
            //             input[type="submit"]{
            //                 background-color: $green;
            //                 color: $white;
            //                 font-weight: $black-bold;
            //                 font-size: 22px;
            //                 padding: 10px 30px;
            //                 border: none;
            //                 border-radius: 4px;
            //                 margin: 15px 10px 5px;
            //                 cursor: pointer;
            //             }
            //             .hs-error-msgs{
            //                 color: #f02929;
            //                 padding: 0 10px;
            //             }
            //         }
            //         .contact-select-country {
            //             font-size: 1rem;
            //             background-color: inherit;
            //             font-weight: 400;
            //             color: #212529;
            //             width: 100px;
            //             position: absolute;
            //             left: 0;
            //             top: 0;
            //             button {
            //                 padding: 7px 1rem;
            //                 border: none;
            //                 border-right: 1px solid #ced4da;
            //                 margin: 1px;
            //                 border-radius: 0.25rem 0 0 0.25rem;
            //             }
            //         }
            //         #phone {
            //             padding-left: calc(100px + 0.75rem);
            //         }
            //     }
            // }
            .modal-footer{
                justify-content: center;
                margin: 0 16px;
                a{
                    color: $blue;
                    border-right: 1px solid $black;
                    padding: 0 10px;
                    margin: 10px 0;
                    text-align: center;
                    &:last-child{
                        border-right: none;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .pl-0{
        padding-left: 0 !important;
    }
    .after-plans-txt{
        padding-left: 12px;
        margin: 0;
        font-size: 16px;
        font-weight: $medium;
        line-height: 1.5;
        color: #212529;
    }
    // .form{
    //     input {
    //         height: 40px;
    //         margin: 0 12px 12px 0 !important;
    //         width: 100%;
    //         &[type="checkbox"] {
    //             width: 20px;
    //             height: 20px;
    //             border-radius: 2px;
    //             border: solid 1px #ced4da;
    //         }
    //     }
    // }

    // style update SITEVIEW
    .pricing_switcher {
        .billing-cycle {
            border-color: #aaa;
            @include media-breakpoint-down(md) {
                border-color: #FFF;
                .billing p {
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
            .selected_cycle {
                border: 1px solid #aaa;
                height: 42px;
                top: -2px;
                width: calc(50% + 2px);
                @include media-breakpoint-down(lg) {
                    height: calc(100% + 4px);
                }
                &.yearlyPlan {
                    right: -2px;
                    &:lang(ar) {
                        right: inherit;
                        left: -2px;
                    }
                }
                &.monthlyPlan {
                    right: calc(50% + 2px);
                    &:lang(ar) {
                        right: inherit;
                        left: calc(50% + 2px);
                    }
                }
            }
        }
        .currency-switcher {
            button[name="currency-switcher"] {
                border-color: #AAA;
            }
        }
    }
    .plans {
        .plan-container:last-of-type .plan-head .subtitle {
            visibility: hidden;
        }
        .cta-btn {
            padding: 12px !important;
        }
        .features {
            padding: 0;
            .plan-head {
                .per-month {
                    color: #000;
                }
            }
            .feature {
                padding-left: 12px;
                &:lang(ar) {
                    padding-left: inherit;
                    padding-right: 12px;
                }
                &:first-child {
                    border-color: #AAA;
                }
                &:nth-child(2n) {
                    background-image: inherit;
                    background-color: #F8F8F8;
                    &:lang(ar) {
                        background-image: inherit !important;
                        background-color: #F8F8F8;
                    }
                }
                &.has-info {
                    position: relative;
                    &::before {
                        content: none;
                    }
                    p {
                        padding: 5px 40px 5px 0;
                        &::before {
                            content: "";
                            background-image: url('images/icons/i.svg');
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            right: 14px;
                            top: calc(50% - 10px);
                            // background-color: #ffffff;
                            // border-radius: 50%;
                            z-index: 99;
                            cursor: pointer;
                        }
                        &:lang(ar) {
                            padding: 5px 0 5px 40px;
                            &::before {
                                right: inherit;
                                left: 14px; 
                            }
                        }

                    }
                    p:hover + .feature-desc {
                        display: inline-table;
                        bottom: inherit;
                        top: inherit;
                        font-size: 14px;
                    }
                    // &:hover .feature-desc {
                    //     display: none;
                    // }
                    
                }
                &.has-children {
                    &.opened p::after {
                        background-image: url('images/icons/collapse_close.svg');
                    }
                    > p {
                        // min-height: 60px;
                        display: flex;
                        position: relative;
                        cursor: pointer;
                        padding: 5px 60px 5px 0;
                        align-items: center;
                        &::after {
                            content: "";
                            position: absolute;
                            right: 14px;
                            background-image: url('images/icons/collapse_open.svg');
                            width: 20px;
                            height: 20px;
                            top: calc(50% - 10px);
                        }
                        &:lang(ar) {
                            padding: 5px 0 5px 60px;
                            &::after {
                                right: inherit;
                                left: 14px;
                            }
                        }
                    }
                    &.regular > p {
                        min-height: 60px;
                    }
                    &.medium > p {
                        min-height: 80px;
                    }
                    &.large > p {
                        min-height: 120px;
                    }
                    &.has-info {
                        > p::before {
                            right: 38px;
                        }
                        > p:lang(ar)::before {
                            right: inherit;
                            left: 38px;
                        }
                    }
                    ul {
                        list-style: none;
                        li {
                            // min-height: 60px;
                            display: flex;
                            align-items: center;
                            &.has-info2 {
                                position: relative;
                            }
                            &.regular {
                                min-height: 60px;
                            }
                            &.medium {
                                min-height: 80px;
                            }
                            &.large {
                                min-height: 120px;
                            }
                        }
                    }
                }
                ul {
                    li {
                        p {
                            &::after {
                                content: none !important;
                            }
                        }
                    }
                }
            }
        }
        .plan-container {
            .plan {
                box-shadow: inherit;
                border-color: rgba(0, 0, 0, 0.10) !important;
                border: 1px solid ;
                @include media-breakpoint-down(md) {
                    padding-bottom: 20px !important;
                    cursor: inherit;
                    &::before {
                        top: 56px;
                    }
                    &.premium-expanded {
                        &::before {
                            transform: rotate(180deg);
                        }
                    }
                }
                .plan-head {
                    color: #000;
                    @include media-breakpoint-down(md) {
                        cursor: pointer;
                    }
                }
                &.premium {
                    border-color: $blue;
                    background-color: $blue;
                    background-image: inherit;
                    .plan-head {
                        color: #FFF;
                    }
                    .feature {
                        &:first-child {
                            border-color: #90C7FF;
                        }
                        &:nth-child(2n) {
                            background-color: rgba(0, 0, 0, 0.05);
                            @include media-breakpoint-down(lg) {
                                background-color: inherit;
                            }
                        }
                        @include media-breakpoint-down(md) {
                            &.even-bg {
                                background-color: rgba(0, 0, 0, 0.05);
                            }
                        }
                        &.has_children {
                            @include media-breakpoint-down(md) {
                                p.parent::after {
                                    background-image: url('images/icons/collapse_open_b.svg');
                                    width: 25px;
                                    height: 25px;
                                    top: calc(50% - 12.5px);
                                }
                                &.opened p.parent::after {
                                    background-image: url('images/icons/collapse_close_b.svg');
                                }
                            }
                            
                        }
                    }
                    // &.expanded {
                    //     max-height: 1500px !important;
                    //     padding-bottom: 20px;
                    // }
                }
                .feature {
                    &:first-child {
                        border-color: #AAA;
                    }
                    &:nth-child(2n) {
                        background-color: #F8F8F8;
                        @include media-breakpoint-down(md) {
                            background-color: #FFF;
                        }
                    }
                    @include media-breakpoint-down(md) {
                        &.even-bg {
                            background-color: #F8F8F8;
                        }
                    }
                    &.has_children {
                        min-height: inherit;
                        padding: 0;
                        display: block;
                        p {
                            margin: 0;
                            @include media-breakpoint-up(md) {
                                // min-height: 60px;
                                padding: 15px 5px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            @include media-breakpoint-down(md) {
                                &.parent {
                                    position: relative;
                                    min-height: inherit !important;
                                    &::after {
                                        content: "";
                                        position: absolute;
                                        right: -6px;
                                        background-image: url('images/icons/collapse_open.svg');
                                        width: 25px;
                                        height: 25px;
                                        top: calc(50% - 12.5px);
                                        background-size: cover;
                                    }
                                }
                                &.child {
                                    padding: 13px;
                                    min-height: inherit !important;
                                    &:last-child {
                                        padding-bottom: 0;
                                    }
                                    &:first-child {
                                        padding-top: 26px;
                                    }
                                }
                            }
                        }
                        &.regular p.parent {
                            min-height: 60px;
                        }
                        &.medium p.parent {
                            min-height: 80px;
                        }
                        &.large p.parent {
                            min-height: 120px;
                        }
                        .feature-children {
                            p {
                                &.regular {
                                    min-height: 60px;
                                }
                                &.medium {
                                    min-height: 80px;
                                }
                                &.large {
                                    min-height: 120px;
                                }
                            }
                        }
                        @include media-breakpoint-down(md) {
                            &.opened p.parent::after {
                                background-image: url('images/icons/collapse_close.svg');
                            }
                        }
                    }

                    
                }
                @include media-breakpoint-down(md) {
                    .plan-features {
                        margin: 0;
                        .feature {
                            display: block;
                            padding: 13px;
                        }
                    }
                }

            }

        }
        // FIX FOR LG
        @include media-breakpoint-only(lg) {
            .plan-head {
                h3 {
                    font-size: 32px !important;
                }
            }
            .feature {
                font-size: 16px !important;
                p {
                    font-size: 16px !important;
                }
            }

        }
    }
    
}
.m03b{
    padding: 80px 0 0;
    @include media-breakpoint-down(md){
        padding: 30px 0 0;
    }
    h2{
        font-size: 45px;
        font-weight: $black-bold;
        letter-spacing: 0.78px;
        color: $blue;
        margin-bottom: 10px;
        &:lang(ar) {
            font-size: 36px;
        }
    }
    .calculator{
        .field{
            border-top: 1px solid rgba(0,0,0,0.1);
            padding: 25px 0;
            margin: 0;
            h3{
                font-size: 18px;
                color: $black;
                letter-spacing: 0.31px;
                font-weight: $black-bold;
            }
            p{
                font-size: 18px;
                color: #B1B1B1;
                letter-spacing: 0.31px;
                margin-bottom: 0;
            }
            button[type="submit"]{
                color: $white;
                min-width: 370px;
                font-size: 22px;
                letter-spacing: 0.38px;
                font-weight: $black-bold;
                margin-top: 50px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    min-width: auto;
                }
            }
            .industries{
                position: relative;
                #industry{
                    font-size: 18px;
                    font-weight: $regular;
                    width: 100%;
                    height: 50px;
                    position: relative;
                    text-align: left;
                    color: #999;
                    background: $white;
                    border: 1px solid #E6E6E6;
                    border-radius: 5px;
                    cursor: pointer;
                    text-indent: 15px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    cursor: pointer;
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50'><polygon points='0,0 100,0 50,50'/></svg>");
                    background-repeat: no-repeat;
                    background-position: calc(100% - 15px) center;
                    background-size: 10px 8px;
                    @include media-breakpoint-down(md){
                        font-size: 16px;
                        margin: 0;
                        padding: 6px;
                        text-indent: 0px;
                        height: auto;
                    }
                    &:-ms-expand {
                        display: none;
                    }
                    option{
                        display: none;
                    }
                    &.expanded{
                        background-color: #dae0e5;
                        border-color: #d3d9df;
                    }
                    // &:active{
                    //     border: none;
                    // }
                }
                .industry{
                    display: none;
                    position: absolute;
                    background: $white;
                    border: 1px solid #E6E6E6;
                    width: 100%;
                    top: 100%;
                    left: 0;
                    border-radius: 5px;
                    z-index: 9;
                    &.show{
                        display: block;
                    }
                    li{
                        font-size: 16px;
                        font-weight: $regular;
                        cursor: pointer;
                        padding: 10px 20px;
                        &.selected{
                            background-color: $blue;
                            color: $white;
                        }
                        &:hover{
                            background-color: #E0E0E0;
                            color: $black;
                        }
                        .dropdown-item{
                            &:hover,&:visited,&:focus{
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
            .range-container{
                display: flex;
                justify-content: space-between;
                @include media-breakpoint-down(md) {
                    justify-content: center;
                    flex-direction: column;
                    margin-top: 25px;
                }
                .range_input {
                    padding: 10px 0 11px;
                    font-size: 18px;
                    color: #000;
                    border: 1px solid rgba(0,0,0,0.1);
                    width: 95px;
                    text-align: center;
                    border-radius: 4px;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        height: 50px;
                    }
                }
                .range-input{
                    position: relative;
                    span {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        width: 26px;
                        height: 26px;
                        position: absolute;
                        border-radius: 3px;
                        border: 1px solid rgba(0,0,0,0.1);
                        background-color: #FFFFFF;
                        padding-top: 11px;
                        line-height: 0;
                        font-size: 20px;
                        text-align: center;
                        display: block;
                        color: #C0C0C0;
                        font-weight: 900;
                        cursor: pointer;
                        @include media-breakpoint-down(md) {
                            width: 40px;
                            height: 40px;
                            font-size: 36px;
                            padding-top: 16px;
                        }
                        &.pricing_min {
                            left: -13px;
                            @include media-breakpoint-down(md) {
                                left: 5px;
                            }
                        }
                        &.pricing_plus {
                            right: -13px;
                            @include media-breakpoint-down(md) {
                                right: 5px;
                            }
                        }
                        &.disabled{
                            pointer-events: none;
                        }
                    }
                }
                .range-slider {
                    width: calc(100% - 125px);
                    margin-top: 11px;
                    position: relative;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin-top: 40px;
                    }
                    .rangeslider__fill {
                        background-image: linear-gradient(270deg, #0064C8 0%, #003264 100%);
                        position: absolute;
                        border-radius: 10px;
                        margin-left: 2px;
                    }
                    .rangeslider--horizontal {
                        height: 20px;
                        width: 100%;
                        .rangeslider__fill {
                            top: 0;
                            height: 100%;
                        }
                        .rangeslider__handle {
                            top: -4px;
                            font-size: 18px;
                            touch-action: pan-y;
                            background: white;
                            border: 1px solid #ccc;
                            cursor: pointer;
                            display: inline-block;
                            width: 26px;
                            height: 26px;
                            position: absolute;
                            background-color: #0064C8;
                            border: 2px solid #FFFFFF;
                            border-radius: 50%;
                            &:after {
                                content: "";
                                display: block;
                                width: 18px;
                                height: 18px;
                                margin: auto;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
                                background-size: 100%;
                                border-radius: 50%;
                            }
                            &:active{
                                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                                background-size: 100%;
                                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.12)));
                                background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                                background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                                background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                            }
                        }
                        &.rangeslider--active{
                            .rangeslider__handle {
                                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                                background-size: 100%;
                                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.12)));
                                background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                                background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                                background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                            }
                        }
                    }
                    .rangeslider {
                        background-color: rgba(0,0,0,0.08);
                        border-radius: 10px;
                        position: relative;
                        cursor: pointer;
                    }
                }
                .number_of_users,.plans-numbers,.rate {
                    position: absolute;
                    top: -20px;
                    left: 10px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    p{
                        font-size: 12px;
                        margin-top: 0;
                    }
                }
                .number_of_users{
                    @include media-breakpoint-down(lg) {
                        left: 0px;
                    }
                }
            }
        }
    }
    .results{
        background-image: linear-gradient(149deg, #28965a 0%, #1e7a48 100%);
        padding: 25px 0 0;
        color: $white;
        margin-top: 25px;
        text-align: center;
        display: none;
        &.show{
            display: block;
        }
        .savings{
            font-size: 36px;
            font-weight: $black-bold;
            letter-spacing: 0.62px;
            &:lang(ar) {
                font-size: 34px;
            }
            .total:lang(ar) {
                display: inline-block;
                direction: ltr;
            }
            @include media-breakpoint-down(lg) {
                font-size: 30px;
            }
            @include media-breakpoint-down(md) {
                font-size: 26px;
            }
        }
        .note{
            font-size: 25px;
            font-weight: $black-bold;
            letter-spacing: 0.31px;
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
        }
        .disclaimer{
            font-size: 18px;
            font-weight: $light;
            padding-bottom: 25px;
            letter-spacing: 0.31px;
        }
        .suitable-plans{
            padding: 40px 0 75px;
            color: $black;
            text-align: left;
            @include media-breakpoint-down(md) {
                padding: 40px 0 0;
            }
            h3 {
                font-size: 18px;
                color: $black;
                letter-spacing: 0.31px;
                font-weight: $black-bold;
            }
        }
    }
    .plans{
        .features{
            padding: 0;
            .per-month {
                height: 145px;
            }
            .feature{
                width: 100%;
                min-height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:nth-child(even){
                    background-image: linear-gradient(270deg, rgba(0,0,0,0.05) 0%, rgba(255,255,255,0.07) 100%);
                }
                &:nth-child(odd){
                    background: transparent;
                }
                &.has-info{
                    position: relative;
                    cursor: pointer;
                    &:before{
                        content: "";
                        background-image: url(images/icons/info.svg);
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        right: 10px;
                        top: calc(50% - 10px);
                        background-color: $white;
                        border-radius: 50%;
                        z-index: 99;
                        cursor: pointer;
                    }
                    // &:hover{
                    //     .feature-desc{
                    //         display: inline-table !important;
                    //         font-size: 12px;
                    //     }
                    // }
                }
                &.medium{
                    min-height: 80px;
                }
                &.large{
                    min-height: 120px;
                }
                &:first-child{
                    border-top: 1px solid $border-color-light;
                    border-bottom: 1px solid $border-color-light;
                    p{
                        font-weight: $black-bold;
                    }
                }
                &:last-child{
                    margin-bottom: 20px;
                }
                p{
                    font-size: 15px;
                    font-weight: $regular;
                    line-height: 23px;
                    padding: 5px 24px 5px 0;
                    margin: 0;
                    color: $black;
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                    }
                }
                .feature-desc{
                    display: none;
                    font-size: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 95%;
                    width: 220px;
                    z-index: 9;
                    border-radius: 4px;
                    color: #747474;
                    letter-spacing: 0.31px;
                    padding: 5px 10px 5px 20px;
                    border: 1px solid #E1E1E1;
                    box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.30);
                    background: $white;
                    margin: auto;
                }
            }
        }
        .plan-container{
            @include media-breakpoint-down(lg) {
                align-self: flex-start !important;
                margin: 0px auto 10px;
            }
            @include media-breakpoint-down(md) {
                // padding: 0;
                // margin: 0;
            }
            &:last-child{
                h3{
                    color: $blue;
                }
            }
            .plan{
                border: 1px solid #0000001a;
                box-shadow: 0 2px 5px 0 #0000001a;
                border-radius: 10px;
                color: $black;
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px 30px;
                height: 100%;
                @include media-breakpoint-down(lg) {
                    // border: none;
                    box-shadow: none;
                    border-radius: 5px;
                }
                @include media-breakpoint-down(md) {
                    padding: 20px 15px;
                }
                &.premium{
                    background-image: linear-gradient(-26deg, #0064C8 5%, #003264 100%);
                    color: $white;
                    p{
                        color: $white;
                        &.feature{
                            color: $white;
                            .check-mark{
                                filter: invert(99%) sepia(4%) saturate(624%) hue-rotate(276deg) brightness(117%) contrast(100%);
                            }
                        }
                    }
                    h3{
                        color: $white;
                    }
                    .prices{
                        color: $black;
                        .pricing_switcher{
                            .billing-cycle{
                                background-color: #8EBAE6;
                                border: 1px solid #8EBAE6;
                            }
                        }
                    }
                    .plan-head{
                        border-bottom: 1px solid rgba(255,255,255,0.2);
                    }
                    .plan-body{
                        border-top: 1px solid rgba(255,255,255,0.2);
                    }
                    
                }
                .plan-head{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                    padding: 0 0 15px;
                    &:lang(ar) {
                        display: block;
                        p.subtitle {
                            width: 100%;
                            text-align: center;
                        }
                    }
                    h3{
                        font-size: 45px;
                        font-weight: $black-bold;
                        line-height: 48px;
                        margin: 0;
                        &:lang(ar) {
                            text-align: center;
                        }

                    }
                    p.subtitle {
                        visibility: hidden;
                    }
                }
                .prices{
                    padding: 15px 0 0;
                    text-align: center;
                    .prices-currency{
                        display: none;
                        &.show{
                            display: block;
                        }
                        .price{
                            display: none;
                            font-size: 45px;
                            font-weight: $black-bold;
                            margin: 0;
                            &.show{
                                display: block;
                                &:lang(ar) {
                                    direction: ltr;
                                }
                            }
                            span{
                                font-size: 18px;
                                font-weight: $light;
                                margin: 15px 0;
                                display: block;
                            }
                        }
                    }
                    .pricing_switcher{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 13px;
                        position: relative;
                        @include media-breakpoint-down(md) {
                            flex-direction: column-reverse;
                        }
                        .billing-cycle{
                            width: calc(100% - 100px);
                            background-color: #E0E0E0;
                            border-radius: 8px;
                            border: 1px solid #E0E0E0;
                            display: flex;
                            height: 60px;
                            position: relative;
                            @include media-breakpoint-down(lg) {
                                width: 100%;
                            }
                            @include media-breakpoint-down(md) {
                                width: 100%;
                                height: 60px;
                            }
                            .billing {
                                position: relative;
                                width: 50%;
                                z-index: 1;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                padding: 0 5px;
                                p {
                                    margin: 1px;
                                    padding: 7px 0;
                                    text-align: center;
                                    color: #000;
                                    font-size: 14px;
                                }
                            }
                            .selected_cycle {
                                background-color: #FFF;
                                width: 50%;
                                height: 100%;
                                position: absolute;
                                border-radius: 8px;
                                transition: right .25s;
                                &.yearlyPlan {
                                    right: 0;
                                }
                                &.monthlyPlan {
                                    right: 50%;
                                }
                            }

                            // label{
                            //     width: 50%;
                            //     line-height: 1.2;
                            //     text-align: center;
                            //     cursor: pointer;
                            //     position: relative;
                            //     font-size: 14px;
                            //     display: flex;
                            //     align-items: center;
                            //     justify-content: center;
                            // }
                            // input.calc-cycle{
                            //     visibility: hidden;
                            //     &:checked{
                            //         &:after{
                            //             content: attr(data-text);
                            //             width: 100%;
                            //             height: 100%;
                            //             position: absolute;
                            //             background-color: $white;
                            //             visibility: visible;
                            //             top: 0;
                            //             left: 0;
                            //             border-radius: 23.5px;
                            //             text-align: center;
                            //             line-height: 1.2;
                            //             font-size: 14px;
                            //             font-weight: $regular;
                            //             -webkit-transition: visibility 1.2s ease;
                            //             -moz-transition: visibility 1.2s ease;
                            //             -ms-transition: visibility 1.2s ease;
                            //             -o-transition: visibility 1.2s ease;
                            //             transition:  visibility 1.2s ease;
                            //             display: flex;
                            //             align-items: center;
                            //             justify-content: center;
                            //             padding: 0 10px;
                            //         }
                            //     }
                                
                            // }
                        }
                        .currency-switcher{
                            width: 85px;
                            height: 60px;
                            position: relative;
                            @include media-breakpoint-down(md) {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                            button[name="currency-switcher"]{
                                width: 100%;
                                height: 100%;
                                text-align: center;
                                background: $white;
                                border: 1px solid rgba(0,0,0,0.1);
                                border-radius: 8px;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                text-align-last: center;
                                cursor: pointer;
                                // text-indent: 30%;
                                // @include media-breakpoint-down(md) {
                                //     text-indent: 43%;
                                // }
                                // &:-ms-expand {
                                //     display: none;
                                // }
                                // option{
                                //     display: none;
                                // }
                            }
                            .currencies{
                                display: none;
                                position: absolute;
                                top: 0;
                                background: $white;
                                border: 1px solid rgba(0,0,0,0.1);
                                width: 100%;
                                top: -40px;
                                left: 0;
                                border-radius: 8px;
                                &.show{
                                    display: block;
                                    z-index: 9;
                                }
                                li{
                                    font-size: 16px;
                                    font-weight: $regular;
                                    line-height: 40px;
                                    text-align: center;
                                    cursor: pointer;
                                    &.selected{
                                        background-color: #E0E0E0;
                                    }
                                    &:hover{
                                        background-color: #E0E0E0;
                                    }
                                    &:first-child{
                                        border-radius: 8px 8px 0 0;
                                    }
                                    &:last-child{
                                        border-radius: 0 0 8px 8px;
                                    }
                                }
                            }
                        }
                    }
                }
                .plan-body{
                    border-top: 1px solid rgba(0,0,0,0.1);
                    padding: 15px 0;
                    .plan-features{
                        h4{
                            font-size: 18px;
                            font-weight: $black-bold;
                        }
                    }
                }
                p{
                    font-size: 18px;
                    font-weight: $regular;
                    padding: 0 15px;
                    display: table;
                    width: 100%;
                    &.request-quote{
                        font-size: 35px;
                        font-weight: $black-bold;
                        margin: 55px 0;
                        color: $blue;
                        text-align: center;
                        @include media-breakpoint-down(lg){
                            font-size: 24px;
                            font-weight: $black-bold;
                        }
                    }
                    &.subtitle{
                        font-weight: $black-bold;
                        line-height: 20px;
                        margin: 0;
                        width: auto;
                    }
                    &.feature{
                        color: $black;
                        line-height: 23px;
                        min-height: 50px;
                        margin: 0;
                        flex-direction: column;
                        justify-content: center;
                        min-height: auto !important;
                        text-align: left;
                        padding: 5px 0;
                        display: list-item;
                        margin-left: 20px;
                    }
                }
                .plan-footer{
                    display: flex;
                    justify-content: flex-end;
                    margin: 80px 0 0;
                    &:lang(ar) {
                        display: block;
                        text-align: center;
                    }
                    @include media-breakpoint-down(md) {
                        margin: 30px 0;
                    }
                    .cta-btn{
                        text-align: center;
                        color: $white;
                        display: block;
                        min-width: 260px;
                        max-width: calc(100% - 30px);
                        // height: 49px;
                        font-size: 22px;
                        letter-spacing: 0.38px;
                        font-weight: $black-bold;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                            margin: 0 auto;
                            padding: 10px 0;
                        }
                    }
                }
            }
        }
    }
    // updates for SiteView
    padding: 56px 0 48px;
    @include media-breakpoint-down(md) {
        padding: 17px 0 41px;
    }
    h2 {
        color: #000;
        margin-bottom: 48px;
        font-size: 40px;
        text-align: center;
        letter-spacing: 0;
        @include media-breakpoint-down(md) {
            text-align: left;
            font-size: 36px;
            margin-bottom: 32px;
        }
    }
    .calculator {
        .industries #industry {
            border-color: #AAA;
            color: #333;
        }
        .field .range-container {
            .range-slider .rangeslider__fill {
                background-color: #66A2DE;
                background-image: inherit;
            }
            .range_input {
                border-color: #aaa;
            }
            .range-input {
                span {
                    border-color: #aaa;
                    color: #000;
                    border-radius: 50%;
                }
            }
            .number_of_users {
                left: 0;
                p {
                    width: 15px;
                    text-align: right;
                }
            }
        }
        .field button[type="submit"] {
            min-width: inherit;
            margin: 56px auto 0;
        }
    }
    .results {
        background-color: #F8F8F8;
        background-image: inherit;
        p.note,
        p.savings {
            color: #000;
            font-size: 36px;
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
            span {
                color: $blue;
            }
        }
        p.disclaimer {
            color: #000;
            font-weight: 400;
            b {
                font-weight: 900;
            }
        }
        .suitable-plans.bg-gray {
            background-color: #FFF;
            padding: 64px 0;
            h3 {
                margin-bottom: 48px;
                font-size: 40px;
                text-align: center;
                font-weight: 900;
                letter-spacing: 0;
                @include media-breakpoint-down(md) {
                    font-size: 36px;
                }
            }
            .plan.premium {
                background-color: $blue;
                background-image: none;
                .prices {
                    color: #FFF;
                }
                .pricing_switcher {
                    .billing-cycle {
                        width: 400px;
                        margin: 0 auto;
                        height: 40px;
                        border: none;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                            height: inherit;
                        }
                    }
                    .selected_cycle {
                        height: calc(100% + 4px) !important;
                        top: -2px;
                        border: 1px solid #FFF;
                    }
                }
            }
            .pricing_switcher {
                .billing-cycle {
                    width: 400px;
                    margin: 0 auto;
                    height: 40px;
                    background-color: #E0E0E0;
                    border: none;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        height: inherit;
                    }
                }
                .selected_cycle {
                    height: calc(100% + 4px) !important;
                    top: -2px;
                    border: 1px solid #E0E0E0;
                }
                .currency-switcher {
                    display: none !important;
                }
            }
            .plans {
                .enterprise .plan-head h3 {
                    color: #000;
                }
                .plan-head {
                    h3 {
                        margin: 0;
                        line-height: 1;
                    }
                }
                .plan {
                    .request-quote {
                    color: #000 !important;
                    }  
                }

            }
        }
    }

}
/* AE */ 
body.ae,body.sa{
    .m03{
        .pricing_switcher{
            padding-left: 0;
            padding-right: 20%;
            @include media-breakpoint-down(xl) {
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 15px;
            }
            .billing-cycle{
                .selected_cycle{
                    &.monthlyPlan {
                        left: 50%;
                        right: auto;
                    }
                    &.yearlyPlan{
                        left: 0;
                        right: auto;
                    }
                }
            } 
        }
        .plans{
            .features{
                ul{
                    padding-left: 0; 
                }
                .feature{
                    p{
                        padding: 5px 0 5px 24px;
                    }
                    &.has-info{
                        &:before{
                            right: auto;
                            left: 10px;
                        }
                    }
                    &:nth-child(even){
                        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 0.07) 100%);
                    }
                    .feature-desc{
                        left: auto;
                        right: 95%;
                    }
                }
            }
            .plan-container{
                &:nth-child(2){
                    padding-left: 5px;
                    padding-right: 0;
                }
            }
        }
    }   
    .m03b{
        .calculator{
            .field{
                .industries{
                    #industry{
                        text-align: right;
                        background-position: 15px center;
                    }
                    .industry{
                        text-align: right;
                    }
                }
                .range-container{
                    .range-input{
                        span{
                            &.pricing_min {
                                left: auto;
                                right: -13px;
                            }
                            &.pricing_plus {
                                right: auto;
                                left: -13px;
                            }
                        }
                    }
                    .range-slider{
                        direction: ltr;
                    }
                }  
            }
        }  
        .results{
            .suitable-plans{
                h3 {
                    text-align: right;
                }
            }
        }  
        .plans{
            .plan-container{
                .plan{
                    .plan-body{
                        .plan-features{
                            h4{
                                text-align: right;
                            }
                        }
                    }
                    p{
                        &.feature{
                            text-align: right;
                        }
                    }
                }
            }
        }     
    }
}