.m21{
    padding: 40px 0;
    @include media-breakpoint-down(lg){
        padding: 40px 0;
    }
    &.bg-gradient{
        background-image: linear-gradient(225deg, #0064C8 0%, #003264 100%);
    }
    &.bg-gray{
        background-color: #EBEBEB;
        background-image: none;
        h2{
            color: $blue;
        }
    } 
    &.bg-white {
        h2{
            color: $blue;
        }
    }
    .banner{
        color: $white;
        text-align: center;
        h2{
            font-size: 65px;
            font-weight: 900;
            margin: 0 0 25px;
            
            &:lang(ar) {
                line-height: 1.5;
            }
            @include media-breakpoint-down(lg){
                font-size: 42px;
            }
            @include media-breakpoint-down(md) {
                font-size: 24px;
                &:lang(ar) {
                    line-height: 1.5;
                }
            }
        }
        p{
            font-size: 18px;
            margin-top: 0;
            &:lang(ar) {
                margin-top: 15px;
            }
            &.half{
                display: inline-block;
                width: 48%;
            }
            &.text-left{
                text-align: left;
                &:lang(ar) {
                    text-align: right;
                }
            }
            &.text-right{
                text-align: right;
                &:lang(ar) {
                    text-align: left;
                }
            }
        }
        img{
            width: 100%;
            height: auto;
        }
        .cta{
            font-weight: $black-bold;
            font-size: 22px;
            line-height: 1.2;
            padding: 10px 48px;
            margin: 20px auto;
            display: inline-block;
            @include media-breakpoint-down(md) {
                margin: 10px auto 0;
            }
        }
        .offset-md-2:lang(ar) {
            margin: auto;
        }
    }
    .m21-banner {
        border-radius: 8px;
        background-color: $blue;
        background-image: var(--bg);
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
        display: flex;
        @include media-breakpoint-down(lg) {
            display: block;
        }
        &.clickable{
            cursor: pointer;
            &:hover{
                .btn {
                    text-decoration: underline;
                    &::after {
                        right: -20px;
                    }
                }
            }
        }
        .m21-text {
            padding: 46px;
            width: 550px;
            @include media-breakpoint-down(lg) {
                width: inherit;
                padding: 30px;
            }
            p {
                color: #FFF;
                &.title {
                    font-size: 40px;
                    font-weight: 900;
                    margin: 0 0 26px;
                    @include media-breakpoint-down(lg) {
                        font-size: 36px;
                    }
                }
                &.subtitle {
                    font-size: 27px;
                    font-weight: 900;
                    margin: 0 0 26px;
                    @include media-breakpoint-down(lg) {
                        font-size: 22px;
                    }
                }
            }
            .btn {
                padding: 0;
                margin: 0;
                font-size: 20px;
                font-weight: 900;
                color: #FFF;
                position: relative;
                
                &::after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    margin-left: 4px;
                    background-image: url('images/arrow-fill.svg');
                    background-size: contain;
                    top: calc(50% - 4px);
                    right: -15px;
                    display: block;
                    position: absolute;
                    transform: rotate(90deg);
                    filter: invert(92%) sepia(92%) saturate(1%) hue-rotate(150deg) brightness(107%) contrast(100%);
                }
                &:hover{
                    text-decoration: underline;
                    &::after {
                        right: -20px;
                    }
                }
            }
        }
        .m21-image {
            width: calc(100% - 550px);
            padding: 0;
            align-self: end;
            text-align: end;
            @include media-breakpoint-down(lg) {
                width: 100%;
                text-align: center;
                padding: inherit;
                img {
                    width: 200px;
                    height: auto;
                }
            }
            img {
                width: 100%;
                max-height: 250px;
                object-fit: contain;
            }
        }
    }
    &.pricing {
        background-color: #f8f8f8;
        padding: 64px 0;
        @include media-breakpoint-down(md) {
            padding: 40px 0;
        }
        h2 {
            color: #000;
            font-size: 40px;
            line-height: 1.15;
            margin-bottom: 52px;
            letter-spacing: 0;
            @include media-breakpoint-down(md) {
                font-size: 27px;
            }
        }
        .banner .cta {
            margin: 0;
        }
    }
}