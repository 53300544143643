.events {
    h1 {
        text-align: center;
        padding: 25px 0;
        color: #3d4548;
        font-size: 40px;
    }
    .event-list {
        border-bottom: 1px solid #d9d9d9;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        img {
            flex: 0 0 auto;
            width: 160px;
            height: auto;
            @include media-breakpoint-down(sm) {
                display: block;
                margin: 0 auto 32px;
            }
        }
        .event-details {
            width: calc(100% - 340px);
            margin-left: 30px;
            flex: 0 0 auto;
            @include media-breakpoint-between(sm, lg) {
                width: calc(100% - 190px);
                margin-bottom: 16px;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin: 0 0 15px;
            }
            p {
                font-size: 16px;
                color: #3d4548;
                margin: 0;
                &:first-child {
                    font-weight: 700;
                    font-size: 24px;
                    margin-bottom: 10px;
                }
            }
        }
        .event-appointment {
            flex: 0 0 auto;
            width: 150px;
            a {
                width: 100%;
                font-size: 18px;
            }
            @include media-breakpoint-between(sm, lg) {
                width: calc(100% - 190px);
                margin-bottom: 20px;
                margin-left: 190px;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
            .btn {
                padding: 0 5px;
                font-size: 16px;
                line-height: 42px;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 25px;
                    width: 100%;
                }
            }
            h5 {
                margin: 0;
            }
        }
    }
}