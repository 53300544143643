.m29 {
    background: #f7f7f7;
    padding: 70px 0;
    h2 {
        color: #0064c8;
        margin-bottom: 40px;
    }
    p.m29-subheading {
        margin-bottom: 10px;
        a {
            color: $blue;
        }
    }
    .job_dropdown {
       select {
            width: 100%;
            border: none;
            background-color: #f7f7f7;
            font-size: 22px;
            padding: 13px 0;
            font-weight: 900;
            -webkit-appearance: none;
            background-image: url('images/arrow.svg');
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: center right;
            color: $black;
       } 
    }
    .jobPost-item {
        border-top: 1px solid #d8d8d8;
        a[data-bs-toggle="collapse"]{
            cursor: pointer;
            padding: 10px 0;
            position: relative;
            &:hover {
                color: $black;
            }
            &::after {
                content: '';
                position: absolute;
                right: 5px;
                top: calc(50% - 4px);
                width: 8px;
                height: 8px;
                position: absolute;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                transform: rotate(90deg);
                transition: all 0.25s ease;
            }
        }
        a[aria-expanded="true"] {
            &::after {
                transform: rotate(-90deg);
                transition: all 0.25s ease;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: 300;
            margin: 0;
        }
        p {
            margin: 0;
            font-size: 16px;;
        }
    }
    .jobPost-body {
        border-top: 1px solid #d8d8d8;
        background-color: $white;
        header {
            height: 325px;
            background-position: center;
            background-size: cover;
            position: relative;
            margin-bottom: 25px;
            p {
                position: absolute;
                left: 20px;
                bottom: 20px;
                margin: 0;
                font-weight: 900;
                font-size: 40px;
                color: $white;
                width: calc(100% - 40px);
            }
            .job-pr-logo {
                width: 217px;
                height: 100px;
                background-image: url('images/Logo_White@2x.png');
                background-size: contain;
                display: block;
                span {
                    visibility: hidden;
                }
            }
        }
        .job-post-container {
            padding-left: 25px;
            padding-right: 25px;
            .jobpost-info {
                @include media-breakpoint-down(md) {
                    margin-bottom: 15px;
                }
                img {
                    width: 30px;
                    height: auto;
                    margin-right: 10px;
                }
                p {
                    margin-bottom: 0;
                    font-size: 11px;
                    strong {
                        font-weight: 900;
                    }
                }
            }
            
            .JP_content-p {
                padding: 30px 0 16px;
                border-bottom: 1px solid #d8d8d8;
                p {
                    font-size: 13px;
                    margin-bottom: 16px;
                    a {
                        font-weight: 900;
                        color: $blue;
                        text-decoration: underline;
                    }
                    strong {
                        font-weight: 900;
                    }
                }
                p.JP_content-h {
                    font-weight: 900;
                    font-size: 24px;
                    color: $blue;  
                }
                p.job_post_subheading {
                    margin-bottom: 0;
                    font-weight: 900;
                    font-size: 16px;
                }
                ul {
                    padding-left: 25px;
                    margin-bottom: 16px;
                    li {
                        font-size: 13px;
                        list-style: disc;
                    }
                }
            }
        }
        .jobPost-benefits {
            margin: 30px 0;
            p {
                font-weight: 900;
                margin-bottom: 0;
                font-size: 13px;
                strong {
                    font-size: 24px;
                    margin-bottom: 10px;
                    display: block;
                    color: $blue;
                    font-weight: 900;
                }
            }
            .JP_benefit {
                margin: 15px 0;
                img {
                    margin-right: 10px;
                }
            }

        }
        .jobPost-salary {
            background-image: linear-gradient(135deg, #0064C8 0%, #003264 100%);
            width: calc(100% + 50px);
            color: $white;
            padding: 25px;
            margin: 0 -25px 30px;
            p {
                font-weight: 300;
            }
        }
        .jobPost-apply {
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 30px;
            a.btn {
                margin-top: 15px;
                font-weight: 900;
                color: $white;
                min-width: 150px;
            }
            p {
                margin-bottom: 0;
                color: #000;
                font-weight: 300;
                strong {
                    font-weight: 900;
                    font-size: 24px;
                    color: #0064c8;
                    display: block;
                    margin-bottom: 16px;
                }
            }
        }
        .jobPost-recruiter {
            padding: 30px 0 50px;
            p {
                strong {
                    font-weight: 900;
                    font-size: 24px;
                    color: #0064c8;
                    display: block;
                    margin-bottom: 16px;
                }
            }
            .JP-recruiter {
                img {
                    margin-right: 15px;
                    box-shadow: 0 0 5px rgba(0,0,0,.25);
                }
                p {
                    margin: 0;
                    font-weight: 300;
                    color: #3d4548;
                    strong {
                        color: #3d4548;
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                }
            }
        }
        .job-sidebar {
            padding-left: 15px;
            padding-right: 15px;
            position: sticky;
            top: 75px;
            margin-top: 25px;
            p {
                margin-bottom: 16px;
                color: #3d4548;
            }
            a.btn {
                color: $white;
                font-weight: 700;
                margin-bottom: 25px;
            }
            .jobPost-share {
                img {
                   width: 40px;
                   height: auto;
                   margin-right: 15px; 
                }
            }
        }


    }
    

}