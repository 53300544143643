.m95{
    padding: 52px 0;
    h2{
        font-weight: $black-bold;
        font-size: 40px;
        line-height: 1.2;
        margin-bottom: 24px;
    }
    p{
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
    }
    .cards-container{
        max-width: 840px;
        text-align: center;
        .cards{
            justify-content: space-around;
            margin: 60px 0 0;
            .card{
                width: calc(100% / 2 - 56px);
                max-width: 295px;
                background-color: #f7f7f7;
                border: 1px solid #E8E8E8;
                border-radius: 10px;
                padding: 42px 37px;
                margin-bottom: 40px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                .title{
                    display: flex;
                    align-items: flex-start;
                    border-bottom: 2px solid #000000;
                    padding-bottom: 25px;
                    h3{
                        font-weight: $black-bold;
                        font-size: 27px;
                        line-height: 32px;
                        text-align: left;
                        &:lang(ar) {
                            text-align: right;
                        }
                    }
                    img{
                        margin-left: 14px;
                        &:lang(ar) {
                            margin-right: 14px;
                            margin-left: 0;
                        }
                    }
                }
                .content{
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: left;
                    margin-top: 16px;
                    &:lang(ar) {
                        text-align: right;
                    }
                    li,p{
                        font-size: 18px;
                        line-height: 22px;
                        margin: 9px 0;
                    }
                }
            }
        }
    }
}