@mixin getMobileBg($p) {
    @if (empty($p)) {
        background: $p center no-repeat,linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
    } @else {
        background: linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
    }
}
@function empty($value) {
    @if not $value 
        or $value == "" 
        or $value == 0
        or $value == ()
        or length($value) == 0 {
      @return true;
    }
    @return false;
  }

.m01 {
    @include media-breakpoint-down(md) {
        position: relative;
        background-color: #e9f0fa;
        &.has-cta{
            padding-bottom: 110px;
        }
        &.has-btns{
            padding-bottom: 200px;
        }
        &.innerpage{
            background-color: $white;
            padding-bottom: 80px;
        }
        
    }
    .hero{
        color: $white;
        height: 660px;
        background-size: cover !important;
        display: flex;
        align-items: center;
        overflow: hidden;
        &.bg-style-image{
            background: var(--bg) center no-repeat,linear-gradient(-44deg, #0064C8 0%, #003164 100%);
            @include media-breakpoint-down(md) {
                height: 560px;
                @include getMobileBg(var(--mobilebg));   
                background-size: cover !important;    
                .blue{
                    color: $white !important;
                }         
            }
            .align-self-center{
                @include media-breakpoint-down(md) {
                    align-self: flex-start !important;
                    padding-top: 20px;
                }
            }
            .mockups{
                bottom: 0px !important;
                @include media-breakpoint-down(md) {
                    // bottom: -40px !important;
                }
            }
        }
        &.bg-style-gradient{
            background-image: linear-gradient(-44deg, #0064C8 0%, #003164 100%);
        }
        &.bg-style-gradient_image{
            background: var(--rightbg) right bottom no-repeat,var(--leftbg) left bottom no-repeat,linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
            @include media-breakpoint-down(xl) {
                background: var(--rightbg) 480px no-repeat,linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
            }
            @include media-breakpoint-down(lg) {
                background: var(--rightbg) 480px no-repeat,linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
            }
            @include media-breakpoint-down(md) {
                background: var(--mobilebg) bottom no-repeat,linear-gradient(-44deg, #0064C8 0%, #003164 100%) !important;
                height: 600px !important;
            }
            h1{
                @include media-breakpoint-down(md) {
                    text-align: left !important;
                }
            }
            p{
                @include media-breakpoint-down(md) {
                    text-align: left !important;
                    margin-bottom: 13vh;
                }
            }
        }
        &.innerpage{
            //height: 600px;
            @include media-breakpoint-down(md) {
                // height: 860px;
                height: auto;
                // margin-bottom: 80px;
                &:before{
                    content: ' ';
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 40px;
                    background-color: white;
                    margin: auto;
                    z-index: 8;
                }
            }
            h1{
                font-size: 65px;
                font-weight: $black-bold;
                line-height: 1.2;
                text-transform: none;
                &:lang(hr) {
                    letter-spacing: -0.5px;
                }
                &:lang(ar) {
                    line-height: 1.4;
                    margin-bottom: 20px;
                }
                &:lang(hu) {
                    word-wrap: break-word;
                    hyphens:auto;
                }
                // width: 900px;
                // max-width: 100%;
                margin-top: 0;
                @include media-breakpoint-down(lg) {
                    font-size: 48px;
                    width: 650px;
                }
                @include media-breakpoint-down(md) {
                    width: 100%;
                    text-align: center;
                    font-size: 40px;
                }
            }
            p{
                @include media-breakpoint-down(md) {
                    text-align: center;
                    font-size: 18px;
                }
            }
            .cta_btn{
                position: inherit;
                @include media-breakpoint-down(md) {
                    text-align: center;
                }
                .green-btn{
                    @include media-breakpoint-down(md) {
                        // max-width: 100%;
                        // min-width: 340px;
                        width: 100%;
                        background-color: $green;
                        margin: auto;
                        .live &{
                            height: 62px;
                            line-height: 46px;
                        }
                        i{
                            display: none !important;
                        }
                    }
                }
            }
            .mockups{
                @include media-breakpoint-down(md) {
                    position: relative;
                    z-index: 99;
                    bottom: 0px !important;
                    margin-top: 40px;
                }
                .mockup{
                    max-width: 360px;
                    @include media-breakpoint-down(lg) {
                        max-width: 100%;
                    }
                    @include media-breakpoint-down(md) {
                        height: 450px;
                    }
                    &:only-child{
                        right: 0;
                        max-width: 100%;
                    }
                    img{
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        max-height: 450px;
                        margin: auto;

                    }
                }
            }
            
        }
        &.mini-header{
            height: auto;
            margin-bottom: 20px;
            padding: 46px 0;
            @include media-breakpoint-down(lg) {
                height: 390px;
            }
            @include media-breakpoint-down(md) {
                height: auto !important;
                margin-bottom: 20px;
                padding: 40px 0;
            }

            h1{
                font-size: 65px;
                font-weight: $black-bold;
                line-height: 1.2;
                text-transform: none;
                margin-top: 0;
                @include media-breakpoint-down(lg) {
                    font-size: 45px;
                    text-align: center;
                }
                @include media-breakpoint-down(md) {
                    font-size: 40px;
                    text-align: center;
                    &:lang(ar) {
                        line-height: 1.4;
                    }
                }
            }
            p{
                font-size: 18px;
                letter-spacing: 0.3px;
                font-weight: $black-bold;
                margin-top: 25px;
                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }
        }
        &.only-bg{
            height: 525px;
            @include media-breakpoint-down(xl) {
                height: 615px;      
                @include getMobileBg(var(--mobilebg));
                background-size: cover !important;   
                background-position: top !important;    
            }
            @include media-breakpoint-down(lg) {
                height: 460px;
                          
            }
            @include media-breakpoint-down(md) {
                height: 260px;
                //@include getMobileBg();
                align-items: flex-end;
            }
            h1 {
                font-size: 70px;
                margin-bottom: 23px;
                margin-top: 35px;
                text-align: left;
                display: none;
                @include media-breakpoint-down(xl) {
                    display: block;
                    font-size: 40px;
                    &:lang(ar) {
                        text-align: right;
                    }
                }
            }
            .cta_btn{
                display: none;
                width: 100%;
                @include media-breakpoint-down(md) {
                    display: block;
                }
                a {
                    padding: 8px;
                    font-size: 22px;
                    font-weight: $black-bold;
                    text-transform: none;
                    &.green-btn{
                        height: auto;
                        .live &{
                            line-height: 36px;
                        }
                    }
                }
            }
        }
        &.hide-bg-mob{
            @include media-breakpoint-down(md) {
                background-image: linear-gradient(-44deg, #0064C8 0%, #003164 100%) !important;
                height: inherit;
            }
        }
        &.no-mockup{
            @include media-breakpoint-down(md) {
                height: inherit;
                margin-bottom: 0px;
                &::before {
                    content: none;
                }
                .align-self-center {
                    padding-top: inherit;
                    h1 {
                        margin: 25px 0;
                    }
                }
            }
            .align-self-center{
                @include media-breakpoint-down(md) {
                    align-self: center !important;
                }
            }
        }
        &.bg-style-white{
            h1,p{
                color: $black !important;
            }
            .app-stores{
                opacity: 1;
                .app-store{
                    img{
                        filter: invert(100%);
                    }
                }
            }
        }
        h1{
            /*color: $white;*/
            font-weight: 300;
            letter-spacing: 1px;
            margin-bottom: 8px;
            font-size: 40px;
            line-height: 1.2;
            @include media-breakpoint-down(xl) {
                font-size: 38px;
                line-height: 1.2;;
            }
            @include media-breakpoint-down(lg) {
                font-size: 28px;
                line-height: 1.2;;
            }
        }
        p{
            font-size: 24px;
            font-weight: $bold;
            line-height: 36px;
            @include media-breakpoint-down(md) {
                line-height: 28px;
                margin-top: 15px;
            }
        }
        .cta_btn{
            a{
                display: inline-block;
                min-width: 280px;
                color: $white;
                .live &{
                    font-weight: $bold;
                    font-size: 20px;
                    line-height: 36px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 16px;
                }
                &.green-btn{
                    background-color: $green;
                    &:hover{
                        background-color: $dark-green;
                    }
                }
                &.bordered-btn{
                    border: 2px solid $white;
                    height: 50px;
                    &:hover{
                        background-color: $blue;
                        border-color: $blue;
                    }
                    @include media-breakpoint-down(lg) {
                        margin: 15px 0;
                    }
                    @include media-breakpoint-down(md) {
                        border: 1px solid $green;
                        color: $green;
                        background-color: $white;
                        margin: 15px 0 0;
                        padding: 10px 0;
                        height: 60px;
                    }
                }
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                &+a{
                    @include media-breakpoint-down(xl) {
                        margin: 10px 0;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                position: absolute;
                bottom: 30px;
                display: block;
                width: 92%;
                left: 0;
                right: 0;
                margin: auto;
                //transition: position 9s, opacity 9s;
            }
            &.fixed{
                position: fixed;
                z-index: 99999;
                .bordered-btn{
                    display: none;
                }
            }
        }
        .app-stores{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 70px;
        }
        .app-store{
            &.black-btn{
                margin-top: 20px;
                width: 280px;
                display: block;
                height: 50px;
                img{
                    height: 100%;
                    width: auto;
                }
                .staging &{
                    padding: 0 24px;
                }
                @include media-breakpoint-down(md) {
                    // min-width: 340px;
                    width: 92%;
                    // max-width: 100%;
                    text-align: center;
                    margin: 15px auto;
                    .live &{
                        height: 62px;
                    }
                }
            }
        }
        .mockups{
            position: relative;
            .mockup{
                position: absolute;
                z-index: 9;
                background-image: var(--bg);
                background-position: bottom left;
                background-size: contain;
                background-repeat: no-repeat;
                bottom: 0;
                height: 480px;
                width: 520px;
                &.mockup2 {
                   width: 195px;
                   height: 100%;
                   right: 0 !important;
                   background-position: center right;
                }
                @include media-breakpoint-down(md) {
                    background: none;
                    position: inherit;
                    // background-image: var(--mobilebg);
                    // background-position: bottom center;
                    width: 100%;
                    height: 240px;
                }
                .mockup-slider{
                    width: 150px;
                    height: 294px;
                    margin: 28px 0 0 44px;
                    @include media-breakpoint-down(md) {
                        width: 245px;
                        height: 181px;
                        margin: 22px 0 0 76px;
                    }
                    .slick-track,.slick-list{
                        height: 100%;
                    }
                    .slide{
                        background-image: var(--bg);
                        height: 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                        &:not(:first-child){
                            display: none;
                        }
                    }
                    &.slick-initialized{
                        .slide{
                            display: block;
                        }
                    }
                    &.has-mb-slides{
                        .slide{
                            @include media-breakpoint-down(md) {
                                background-image: var(--slidemobilebg);
                            }
                        }
                    }
                    &.no-mb-slides{
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }
                &:only-child{
                    left: 0;
                    max-width: 100%;
                }
                img{
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 450px;
                    margin: auto;
                }
            }
            .extra-mockup{
                position: absolute;
                z-index: 8;
                background-image: var(--bg);
                // width: 77%;
                width: 100%;
                height: 100%;
                background-position: left bottom;
                background-size: contain;
                background-repeat: no-repeat;
                left: -90px;
                @include media-breakpoint-down(xl) {
                    display: none;
                }
                & + .mockup{
                    // width: 187px;
                    // height: 380px;
                    // bottom: 75px;
                    // right: 0;
                    bottom: 0;
                    right: -58px;
                    @include media-breakpoint-down(md) {
                        height: 500px;
                        width: 100%;
                        bottom: -55px;
                        right: 0;
                    }
                }
            }
        }
    }
    .bg-style-blue.mini-header {
        background-color: $blue;
        padding: 64px 0;
        height: inherit;
        @include media-breakpoint-down(md) {
            padding: 40px 0;
        }
        h1 {
            font-size: 50px;
            line-height: 1.15;
            letter-spacing: normal;
            @include media-breakpoint-down(lg) {
                br {
                    display: none;
                }
            }
            @include media-breakpoint-down(md) {
                font-size: 40px;
                text-align: left;
            }
        }
    }
}

.xs-easy-out {
    transition: opacity 1s ease-out;
    opacity: 0;
    animation: smoothScrollDown 1s forwards;
  }
  .xs-easy-in {
    transition: opacity 1s ease-out;
    opacity: 100%;
    animation: smoothScrollUp 1s forwards;
  
  }
  @keyframes smoothScrollUp {
      0% {
          transform: translateY(25px);
      }
      100% {
          transform: translateY(0px);
      }
  }
  @keyframes smoothScrollDown {
      100% {
          transform: translateY(25px);
      }
      0% {
          transform: translateY(0);
      }
  }