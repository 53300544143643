.m01e {
    position: relative;
    overflow: hidden;
    height: 600px;
    &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 50%;
        right: 0;
        background-image: var(--bg);
        background-position: left center;
        background-size: cover;
        background-repeat: no-repeat;
        
    }
    &:lang(ar) {
        &::before {
            right: auto;
            left: 0;
        }
    }
    @include media-breakpoint-down(md) {
        height: inherit;
        &::before {
            content: none;
        }
    }
    h1 {
        font-size: 70px;
        font-weight: 900;
        @include media-breakpoint-down(xl) {
            font-size: 58px;
        }
        @include media-breakpoint-down(lg) {
            font-size: 42px;
            &:lang(hu) {
                hyphens: inherit;
            }
        }
        @include media-breakpoint-down(md) {
            font-size: 54px;
        }
        &:lang(hu) {
            word-wrap: break-word;
            hyphens:auto;
        }
    }
    p {
        font-weight: 900;
        font-size: 27px;
        margin: 25px 0 50px;
        @include media-breakpoint-down(lg) {
            font-size: 22px;
        }
        @include media-breakpoint-down(lg) {
            font-size: 27px;
        }
    }
    img {
        width: 100%;
        margin-bottom: 25px;
        @include media-breakpoint-down(sm) {
            position: relative;
            width: calc(100% + 30px);
            max-width: inherit;
            right: 0;
        }
    }
}