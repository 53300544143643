.m38 {
    background-color: #F7F7F7;
    padding: 43px 0 72px;
    h2 {
        margin: 0 0 15px;
        color: #0064C8;
    }
    p {
        font-weight: 300;
        font-size: 18px; 
        &.mtt_survey_footer {
            font-size: 50px !important;
            color: #000000;
            text-align: center;
            font-weight: 900 !important;
            margin-top: 30px;
            @include media-breakpoint-down(md) {
              font-size: 30px !important;
            }
        }

    }
}





















.survey_pie {
    position: relative;
    text-align: center;
    margin-top: 38px;
    p {
      position: absolute;
      bottom: 35px;
      width: 100%;
      font-size: 60px;
      font-weight: 900;
      color: #FFFFFF;
      @include media-breakpoint-down(md) {
        font-size: 40px;
        bottom: 15px;
      }
    }
  }
 
  .mtt_pie {
    width: 275px;
    background: transparent;
    border-radius: 50%;
    transform: rotate(-90deg);
    @include media-breakpoint-down(md) {
      width: 200px;
    }
    circle {
      fill: none;
      stroke-width: 32;
      animation: rotate 1.5s ease-in;
      transition: stroke-dashoffset .5s linear;
      &:hover {
      stroke-dashoffset: inherit !important;
      }
    }
  }
  #bluePie {
    stop.start-color {
      stop-color: #003264;
    }
    stop.end-color {
      stop-color: #0064C8;
    }
  }
  #greenPie {
    stop.start-color {
      stop-color: #358100;
    }
    stop.end-color {
      stop-color: $green;
    }
  }
  .blueP, .greenP {
    letter-spacing: 0.31px;
    font-weight: 900 !important;
    max-width: 275px;
    margin: 0 auto;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
    text-align: center;
  }
  .blueP {
    color: #0064C8;
  }
  .greenP {
    color: $green;
  }