.m49{
    padding: 83px 0;
    h2{
        color: $blue;
        font-weight: $black-bold;
        font-size: 40px;
        margin-bottom: 23px;
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }
    h3{
        font-weight: $black-bold;
        margin-bottom: 0;
        font-size: 22px;
    }
    p{
        font-size: 22px;
    }
    .columns{
        text-align: center;
        margin-top: 40px;
        .column{
            max-width: 475px;
            margin: 35px auto 0;
            font-size: 22px;
            img{
                max-height: 215px;
                width: auto;
            }
            h3{
                font-weight: $black-bold;
            }
            p{
                margin: 0;
                font-size: 22px;
                &.subtitle{

                }
                &.pretitle{
                    font-size: 18px;
                    font-weight: $light;
                }
            }
            .desc{
                margin: 20px 0;
                p{
                   font-size: 18px;
                    font-weight: $regular;
                    line-height: 1.5; 
                }
                ul{
                    list-style-type: disc;
                    padding: 0 0 0 40px;
                    li{
                        font-size: 18px;
                        font-weight: $regular;
                        line-height: 1.5;
                    }
                }
            }
        }
    }
    &.new-design{
        padding: 32px 0;
        h2{
            color: $black;
            margin: 0;
        }
        p{
            width: 100%;
            max-width: 800px;
            margin: 24px auto 32px;
            font-size: 18px;
            line-height: 1.5;
            font-weight: 400;
        }
        .columns{
            padding: 32px 0 0;
            margin-top: 0;
            @include media-breakpoint-down(md) {
                padding: 0;
            }
            &.vertical{
                .col-md-12{
                    &:only-child{
                        .column{
                            margin: 0;
                        }
                    }
                    &:nth-child(odd){
                        .column{
                            flex-direction: row-reverse;
                            @include media-breakpoint-down(md) {
                                flex-direction: column;
                            }
                            &.image-left{
                                flex-direction: row;
                                @include media-breakpoint-down(md) {
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                    &:nth-child(even){
                        .column{
                            flex-direction: row;
                            @include media-breakpoint-down(md) {
                                flex-direction: column;
                            }
                            &.image-left{
                                flex-direction: row-reverse;
                                @include media-breakpoint-down(md) {
                                    flex-direction: column;
                                }
                            }
                        }
                    }
                    .column{
                        max-width: 100%;
                        margin: 0 0 32px;
                        padding: 64px;//64px 32px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        @include media-breakpoint-down(md) {
                            flex-direction: column;
                            padding: 27px;
                        }
                        &.image-left{
                            flex-direction: row-reverse;
                            @include media-breakpoint-down(md) {
                                flex-direction: column;
                            }
                        }
                        img {
                            max-width: 50%;
                            max-height: none;
                            width: 50%;
                            height: auto;
                            @include media-breakpoint-down(md) {
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                        div{
                            width: 40%;
                            @include media-breakpoint-down(md) {
                                width: 100%;
                            }
                        }
    
                    }
                }
            }
            &.horizontal{
                display: flex;
                justify-content: space-between;
                padding: 32px 0 64px;
                background-image: url('images/line.svg');
                background-position: center center;
                background-repeat: no-repeat;
                @include media-breakpoint-down(md) {
                    background-image: url('images/mob-line.svg');
                    padding: 0;
                    margin-bottom: 32px;
                    justify-content: center;
                }
                .col-md-6{
                    display: flex;
                    &:nth-child(odd){
                        justify-content: flex-start;
                    }
                    &:nth-child(even){
                        justify-content: flex-end;
                    }
                    @include media-breakpoint-down(md) {
                        justify-content: center !important;
                        &:first-child{
                            .column{
                                margin: 0 0 64px;
                            }
                        }
                    }
                    .column{
                        margin: 0;
                        @include media-breakpoint-down(md) {
                            margin: 0; 
                        }
    
                        h3{
                            margin-top: 32px;
                        }
                        img {
                            width: 100%;
                            height: auto;
                            max-height: none;
                        }
                    }
                }
            }
            .column{
                padding: 32px;
                border-radius: 8px;
                margin: 35px 0 0;
                &.bg-blue{
                    background-color: #F2F7FC;
                }
                &.bg-gray{
                    background-color: #F8F8F8;
                }
                h3{
                    font-size: 40px;
                    line-height: 1.15;
                    font-weight: $black-bold;
                    text-align: start;
                    &.blue{
                        color: $blue;
                    }
                    &.navy{
                        color: #003264;
                    }
                    &.gray{
                        color: #989898;
                    }
                    &.darkgray{
                        color: #3C4650;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 27px;
                        line-height: 1.25;
                    }
                }
                p{
                    font-size: 18px;
                    line-height: 1.5;
                    font-weight: $regular;
                    text-align: start;
                }
                .desc{
                    width: 100% !important;
                    margin: 20px 0;
                    text-align: start;
                    font-size: 18px;
                    font-weight: $regular;
                    line-height: 1.5; 
                    p{
                        font-size: 18px;
                        font-weight: $regular;
                        line-height: 1.5; 
                    }
                    ul{
                        list-style-type: disc;
                        padding: 32px 0 0 32px;
                        li{
                            font-size: 18px;
                            font-weight: $regular;
                            line-height: 1.5;
                        }
                    }
                }
                .note{
                    padding: 12px 14px 12px 34px;
                    border-radius: 8px;
                    background-color: #E1E1E1;
                    background-image: url('images/icons/info.svg');
                    background-repeat: no-repeat;
                    background-position: 12px 16px;
                    background-size: 18px 18px;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 1.5;
                    color: $black;
                    margin-top: 46px;
                    &.blue{
                        color: $blue;
                        background-image: url('images/icons/circle-info.svg');
                    }
                    &.navy{
                        color: #003264;
                        background-image: url('images/icons/darkblue-circle-info.svg');
                    }
                    &.gray{
                        color: $black;
                    }
                    &.darkgray{
                        color: #3C4650;
                    }
                }
            } 
        }
        .pr-btn{
            margin: 32px 12px 0;
            @include media-breakpoint-down(md) {
                margin: 12px 0;
            }
        }
    }
}