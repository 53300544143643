.m18{
    //padding: 80px 0;
    padding: 64px 0;
    @include media-breakpoint-down(md) {
        padding: 32px 0;
    }
    .title{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 60px;
        margin-top: 0;
        margin-bottom: 8px;
        line-height: 1.15;
        @include media-breakpoint-down(md) {
            font-size: 27px;
            margin-bottom: 12px;
        }
    }
    .faqs{
        &.columns{
            column-count: 2;
            column-gap: 80px;
            display: inherit;
            @include media-breakpoint-down(md) {
                column-count: 1;
            }
            .faq{
                margin-bottom: 12px;
                overflow: hidden;
                -webkit-column-break-inside: avoid;
                width: 100%;
                h3{
                    font-size: 22px;
                    color: $black;
                    font-weight: $black-bold;
                    margin: 48px 0 24px;
                    line-height: 26px;
                   }
                   .answer{
                    color: $black;
                    p{
                        font-size: 18px;
                        font-weight: $light;
                        line-height: 27px;
                    }
                    strong{
                        font-weight: bolder;
                    }
                    ul{
                        list-style-type: disc;
                        padding: 0 0 0 40px;
                        li{
                            font-size: 18px;
                            font-weight: $light;
                            line-height: 27px;
                        }
                    }
                    a{
                        color: $blue;
                        &:hover{
                            color: $light-blue;
                            text-decoration: underline;
                        }
                    }
                   }
            }
        }
        &.accordion{
            @include media-breakpoint-down(md) {
                padding: 0 15px;
            }
            .question{
                font-size: 18px;
                letter-spacing: 0.31px;
                font-weight: $black-bold;
                border-top: 1px solid #979797;
                padding: 15px 25px;
                cursor: pointer;
                margin-bottom: 0;
                position: relative;
                @include media-breakpoint-down(md) {
                    padding: 15px 25px 15px 0;
                }
                &:lang(ar) {
                    &::after {
                        right: auto;
                        left: 10px; 
                    }
                }
                &:after {
                    position: absolute;
                    right: 10px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    display: block;
                    content: "";
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=);
                    width: 7px;
                    height: 7px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    transform: rotate(90deg);
                    transition: all .2s;
                }
                &[aria-expanded="true"]{
                    &:after{
                        transform: rotate(-90deg);
                    }
                }
            }
            .answer{
                color: $black;
                background-color: #F7F7F7;
                padding: 0 50px;
                @include media-breakpoint-down(md) {
                    padding: 7px 25px;
                }
                p{
                    font-weight: $regular;
                    font-size: 18px;
                    line-height: 27px;
                    max-width: 100%;
                }
                strong{
                    font-weight: bolder;
                }
                ul{
                    list-style-type: disc;
                    padding: 0 0 0 40px;
                    li{
                        font-size: 18px;
                        font-weight: $light;
                        line-height: 27px;
                    }
                }
                a{
                    color: $blue;
                    &:hover{
                        color: $light-blue;
                        text-decoration: underline;
                    }
                }
                &.collapsing{
                    padding: 0 50px;
                    @include media-breakpoint-down(md) {
                        padding: 7px 25px;
                    }
                }
            }
        }
    }
    // NEW GREY FAQ
    &.bg-grayw {
        margin: 32px 0 0 ;
        padding: 64px 0;
        background-color: #F8F8F8;
        @include media-breakpoint-down(md) {
           padding: 32px 0;
           margin-top: -32px;
        }
        .question {
            background-color: $white;
            border-top: none !important;
            margin-bottom: 0 !important;
            border-radius: 8px;
            padding: 20px 40px 20px 20px !important;
            margin-top: 12px !important;
            @include media-breakpoint-down(md) {
                padding: 16px 40px 16px 16px!important;
                margin-top: 12px;
            }
            &:last-of-type {
                margin-bottom: 0 !important;
            }
            &:not(.collapsed) {
                border-radius: 8px 8px 0 0;
            }
            &::after {
                right: 20px !important;
                transform: rotate(0deg) !important;
                width: 12px !important;
                height: 12px !important;
                @include media-breakpoint-down(md) {
                    right: 16px !important;
                }
            }
            &:not(.collapsed)::after {
                transform: rotate(90deg) !important;
            }
        }
        .answer {
            background-color: $white !important;
            margin-bottom: 0;
            border-radius: 0 0 8px 8px;
            padding: 0 20px 20px !important;
            @include media-breakpoint-down(md) {
                padding: 0 16px 20px !important;
            }
            &:last-of-type {
                margin-top: inherit;
            }
            p:last-child {
                margin: 0;
            }
        }
    }
}

