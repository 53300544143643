.m83{
    padding: 80px 0;
    h2{
        font-size: 36px;
        color: $blue;
        letter-spacing: 1.15px;
        text-align: center;
        line-height: 32px;
        font-weight: $black-bold;
        margin-bottom: 37px;
    }
    .offices{
        .office{
            padding: 30px 0;
            img{
                max-width: 100%;
                height: 160px;
                margin-bottom: 17px;
                @include media-breakpoint-down(md) {
                    height: auto;
                }
            }
            .info{
                
                h3{
                    font-size: 18px;
                    color: $blue;
                    letter-spacing: 1.12px;
                    line-height: 32px;
                    font-weight: $black-bold;
                    padding-left: 20px;
                    &:lang(ar) {
                        padding-left: 0;
                        padding-right: 15px;
                    }
                }
                p{
                    font-size: 18px;
                    color: #000000;
                    letter-spacing: 0;
                    line-height: 24px;
                    font-weight: $regular;
                    padding-left: 20px;
                    margin-bottom: 10px;
                    &.rtl {
                        padding-right: 20px;
                        padding-left: inherit;
                    }
                    &.country{
                        font-weight: 300;
                        background-image: url(images/icons/pin-blue.png);
                        background-size: 13px auto;
                        background-position: left center;
                        background-repeat: no-repeat;
                        &:lang(ar) {
                            background-position: right center;
                            padding-right: 20px;
                            padding-left: 0;
                        }
                        strong{
                            font-weight: $black-bold;
                        }
                    }
                    &.address{
                        margin-bottom: 15px;
                        direction: ltr !important;
                        &.rtl {
                            direction: rtl !important;
                        }
                    }
                    &.phone{
                        background-image: url(images/icons/phone-blue.png);
                        background-size: 13px auto;
                        background-position: left center;
                        background-repeat: no-repeat;
                        margin-left: 20px;
                        direction: ltr !important;
                        &.rtl {
                            direction: rtl !important;
                            padding-left: 15px;
                            margin-left: 0;
                            margin-right: 20px;
                            text-align: right;
                            background-position: right center;
                        }
                    }

                }
            }
        }
        // >div:nth-child(17):lang(ar),
        // >div:first-child:lang(ar) {
        //     .office {
        //         .info {
        //             .address {
        //                 direction: rtl;
        //             }
        //             .phone {
        //                 text-align: right;
        //                 background-position: right center;
        //             }
        //         }
        //     }
            
        // }
    }
}
