.m07 {
    padding: 100px 0;

    .col-12,
    .col-md-4 {
        text-align: center;
    }

    h2 {
        font-weight: 300;
        letter-spacing: 1px;
        margin-bottom: 50px;
        font-size: 36px;
        color: #3d4548;
    }

    h3 {
        color: #1d62ad;
        font-weight: 700;
        margin: 10px 0 25px;
        font-size: 17px;
        letter-spacing: 1px;
    }

    p {
        margin-bottom: 25px;
        color: #3d4548;
        font-size: 16px;
        line-height: 1.5;
    }

    img {
        width: 25px;
        height: auto;
    }
    .pr-btn {
        display: block;
        margin: 0 auto;
        max-width: 380px;
        &:first-of-type {
            margin-bottom: 24px;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: inherit;
        }
    }
}