.m43{
    padding: 70px 0;
    h2{
        font-size: 32px;
        font-weight: $light;
        color: #3d4548;
        margin: 0;
    }
    p{
        font-size: 16px;
        color: #3d4548;
        margin-bottom: 16px;
        line-height: 1.5;
    }
    .video-slider{
        margin-top: 30px;
        @include media-breakpoint-down(lg) {
            flex-direction: column-reverse;
        }
        .videos{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
            >div {
                width: 100%;
            }
            .video{
                position: relative;
                text-align: center;
                @include media-breakpoint-down(md) {
                    padding-top: 80px;
                }
                video,.plyr--video{
                    width: calc(100% - 60px);
                    height: auto;
                    border-radius: 10px;
                    margin: auto;
                }
            }
            .video-title{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: calc(100% - 60px);
                background: rgba(29,98,173,.75);
                border-radius: 10px 10px 0 0;
                padding: 10px 0;
                p{
                    color: $white;
                    margin: 0;
                    font-weight: $bold;
                    line-height: 1.5;
                    &+p{
                        text-transform: none;
                        font-weight: $regular;
                    }
                }
            }
        }
        .slick-arrow {
            visibility: visible;
            font-size: 0;
            background-color: transparent;
            border: none;
            height: 100%;
            width: 52px;
            display: inline-block;
            &:focus , &:focus-visible  {
                border: none;
                outline: none;
            }
            @include media-breakpoint-down(md) {
                display: none !important;
            }
            &.slick-next, &.slick-prev{
                &:before {
                    content: "";
                    width: 40px;
                    height: 22px;
                    display: block;
                    background-image: url('images/icons/careers_arrow.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                    margin: 0 auto;
                    cursor: pointer;
                    opacity: 0.5;
                    transform: rotate(90deg);
                }
            }
            &.slick-next{
                &:before{
                    transform: rotate(270deg);
                }
            }
        }
        .slick-dots {
            li {
                width: 15px;
                button {
                    &:before {
                        font-size: 12px;
                    }
                    &:hover{
                        opacity: 0.25;
                    }
                }
                &.slick-active {
                    button{
                        &:before {
                            color: $blue;
                        }
                        &:hover{
                            opacity: 1;
                        }
                    }
                }
                &:only-child{
                    display: none;
                }
            }
        }
        .sidebar{
            h3{
                font-size: 17px;
                font-weight: 700;
                color: #1d62ad;
            }
            p{
                margin-bottom: 20px;
                line-height: 24px;
            }
        }
    }
}