.m70{
    margin: 40px 0 80px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: var(--bg);
    .head{
        background: rgba(0,57,107,.5);
        padding: 40px 0;
        h2{
            color: $white;
            font-size: 36px;
            font-weight: 300;
            letter-spacing: -.86px;
            text-align: center;
            margin: 0;
        }
    }
    .content{
        padding: 25px 0;
        h4{
            font-size: 24px;
            font-weight: $bold;
            letter-spacing: 1px;
            line-height: 1.3;
            color: $white;
            margin: 0;
        }
        p{    
            margin: 15px 0 50px;
            font-size: 16px;
            font-weight: $medium;
            color: $white;
        }
        .feature{
            display: flex;
            background-color: $white;
            margin-bottom: 30px;
            border-radius: 10px;
            padding: 15px 15px 0 0;
            @include media-breakpoint-down(xl) {
                flex-direction: column;
                padding: 15px;
            }
            .image{
                width: 160px;
                height: 160px;
                @include media-breakpoint-down(xl) {
                    width: 100%;
                    height: auto;
                }
                img{
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                }
            }
            .text{
                width: calc(100% - 180px);
                padding-bottom: 15px;
                @include media-breakpoint-down(xl) {
                    width: 100%;
                }
                h3{
                    font-size: 16px;
                    font-weight: 700;
                    color: $blue;
                    letter-spacing: 1.2px;
                    margin-top: 20px;
                    padding-right: 15px;
                }
                p{
                    font-size: 16px;
                    color: #3D4548;
                    margin: 0 0 16px;
                }
                a{
                    color: $blue;
                    font-size: 16px;
                    &:hover{
                        color: #3D4548;
                    }
                }
            }
        }
    }
}