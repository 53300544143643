.m14{
    .title{
        font-size: 43px;
        margin-top: 50px;
        @include media-breakpoint-down(md) {
            font-size: 32px;
            letter-spacing: -1px;
            margin: 20px 0 50px;
        }
    }
    &.boxed {
        max-width: 1140px;
        margin: 0 auto 76px;
        border-radius: 50px;
        .container{
            @include media-breakpoint-up(md) {
                padding: 0;
            }
        }
        .text_image_block{
            padding: 0 12px;
            @include media-breakpoint-down(xl) {
                padding-top: 0px;
                padding-bottom: 50px;
            }
            &.left{
                .text{
                    padding: 60px 0 38px;
                    @include media-breakpoint-down(md) {
                        padding: 60px 12px 38px;
                    }
                }
                .image{
                    img{
                        margin-left: 0;
                    }
                }
            }
            &.right{
                .text{
                    padding: 60px 0 38px 60px;
                    @include media-breakpoint-down(md) {
                        padding: 60px 12px 38px;
                    }

                }
                .text:lang(ar) {
                    padding: 60px 60px 30px 0;
                }
                .image{
                    img{
                        margin-right: 0;
                    }
                }
            }
            .text{
                padding: 60px 0 38px;
                @include media-breakpoint-down(md) {
                    padding: 60px 12px 38px;
                }
                .content{
                    p{
                        max-width: 90%;
                    }
                }
            }
        }
    }
    .text_image_block{
        display: flex;
        justify-content: space-between;
        padding-top: 80px;
        padding-bottom: 80px;
        border-bottom: 1px solid $border-color;
        align-items: center;
        &.no-lines {
            border-bottom: none;
        }
        @include media-breakpoint-down(xl) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
        &:last-child{
            border-bottom: none;
            @include media-breakpoint-down(md) {
                padding-bottom: 40px;
            }
        }
        @include media-breakpoint-down(md) {
            flex-direction: column !important;
        }
        &.right{
            &:nth-child(even){
                flex-direction: row;
            }
            &:nth-child(odd){
                flex-direction: row-reverse;
            }
        }
        &.left{
            &:nth-child(even){
                flex-direction: row-reverse;
            }
            &:nth-child(odd){
                flex-direction: row;
            }
        }
        .image{
            width: 50%;
            @include media-breakpoint-down(md) {
                width: 100%;
            }
            img{
                width: auto;
                margin: auto;
                display: block;
            }
            .plyr--video,.plyr--vimeo{
                position: relative;
                max-height: 480px;
                &::before{
                    content: '';
                    position: absolute;
                    height: 2px;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    background-color: #fff;
                    z-index: 99;
                }
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #000;
                    opacity: 0.4;
                }
                .plyr__control--overlaid {
                    background: transparent;
                }
                .plyr__controls{
                    display: none;
                }
                &.plyr--playing{
                    &::after{
                        display: none;
                    }
                    .plyr__controls{
                        display: flex;
                    }
                }
            }
        }
        .text{
            width: 40%;
            @include media-breakpoint-down(xl) {
                width: 47%;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
            }
            h2{
                font-size: 40px;
                color: $blue;
                font-weight: $black-bold;
                margin-bottom: 30px;
                line-height: 1.2;
                &:lang(ar) {
                    line-height: 1.5;
                }
                @include media-breakpoint-down(xl) {
                    font-size: 35px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 30px;
                }
            }
            .content{
                color: $black;
                
                p{
                    font-size: 18px;
                    font-weight: $regular;
                    line-height: 27px;
                    img{
                        width: calc(100%/3);
                    }
                }
                strong{
                    font-weight: bolder;
                }
                ul{
                    list-style-type: disc;
                    padding: 0 0 0 40px;
                    li{
                        font-size: 18px;
                        font-weight: $regular;
                        line-height: 27px;
                    }
                }
                a{
                    &:not(.pr-btn) {
                        color: $blue;
                        &:hover{
                            text-decoration: underline;
                        }
                    }

                    &.button{
                        &:hover{
                            text-decoration: none;
                        }
                    }
                    &.bordered-btn{
                        min-width: 280px;
                        padding: 8px 15px;
                    }
                    &.black{
                        color: $black !important;
                        font-weight: $black-bold;
                    }
                }
                .form{
                    h2{
                        font-size: 26px;
                    }
                    .hs-form-field{
                        margin: 15px 0;
                        input{
                            margin: 0 !important;
                        }
                    }
                    label{
                        display: none;
                    }
                    ul{
                        &.hs-error-msgs{
                            li{
                                label{
                                    font-size: 14px;
                                    color: #cf2319;
                                    font-weight: 400;
                                }
                            }
                        }
                        padding: 0;
                        li{
                            margin-top: 0;
                            label{
                                display: block;
                                &.hs-error-msg{
                                    font-size: 14px;
                                    color: #cf2319;
                                    font-weight: 400;
                                }
                                span{
                                    color: #000;
                                    margin-inline-start: 10px;
                                    font-weight: 500;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    video {
        max-width: 100%;
    }
    .button {
        margin: 50px 0 0;
        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .cta{
        // font-weight: $black-bold;
        // font-size: 22px;
        // max-width: 380px;
        margin: 15px auto;
        // display: inline-block;
        // color: #fff !important;
        // text-decoration: none !important;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    &.m14-ebook {
        padding: 30px 0;
        p {
            margin: 0;
            &.ebook-title {
                background-color: $blue;
                display: inline-block;
                color: #ffffff;
                font-weight: 900;
                font-size: 14px;
                padding: 4px 12px;
                border-radius: 4px;
            }
            &.ebook-description {
                font-size: 54px;
                font-weight: 900;
                margin: 25px 0 50px;
                @include media-breakpoint-down(md) {
                    font-size: 27px;
                    margin-bottom: 15px;
                }
            }
        }
        a.ebook-btn {
            min-width: 275px;
            font-size: 22px;
            font-weight: 900;
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-top: 35px;
            }
        }
        img {
            width: 450px;
            margin: 0 auto;
            display: block;
        }
    }

    // new design
    &.bg-gray.space {
        background-color: #fff;
        margin: 32px 0;
        .text_image_block {
            border-radius: 8px;
            background: #F8F8F8; 
            margin-bottom: 32px;
            padding: 64px;
            &:last-of-type {
                margin-bottom: 0;
            }
            @include media-breakpoint-down(md) {
                margin-left: 12px;
                margin-right: 12px;
                width: calc(100% - 24px);
                padding: 16px;
                margin-bottom: 24px;
            }
            img {
                border-radius: 8px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 32px;
                }
            }
            h2 {
                margin: 0 0 32px;
                @include media-breakpoint-down(md) {
                    margin: 0 0 24px;
                    font-size: 27px;
                }
            }
            p {
                font-weight: 400;
            }

        }
    }

}