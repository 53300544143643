.m01b {
    .hero {
        background-position: center right;
        &.has-overlay{
            position: relative;
            @include media-breakpoint-down(md) {
                height: 600px;
                padding: 40px 0;
                .col{
                    height: 100%;
                }
            }
            &:before{
                content: " ";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: .3;
                @include media-breakpoint-down(md) {
                    opacity: 0;
                }
            }
            .container{
                z-index: 99;
            }
        }
        .m01b-content {
            &.centered{
                text-align: center !important;
                h1{
                    width: 100%;
                    margin: auto;
                    margin: 25px auto 45px;
                    @include media-breakpoint-down(md) {
                        text-align: center !important;
                    }
                }
                .app-stores{
                    justify-content: center;
                }
                @include media-breakpoint-down(md) {
                    .app-store{
                        margin: 20px auto;
                    }
                }
            }
        }
        @include media-breakpoint-down(md) {
            .container {
                padding-left: 0;
                padding-right: 0;
                .col {
                    position: relative;
                    .m01b-content {
                        top: 0;
                        position: absolute;
                    }
                }
            }
        }
        .m01b-subheading {
            display: inline-flex;
            @include media-breakpoint-down(md) {
                display: block;
                text-align: center;
            }
            img {
               width: 85px;
               display: block;
               @include media-breakpoint-down(md) {
                   margin: 0 auto;
               }
            }
            p {
                font-size: 28px;
                color: #fff;
                font-weight: 900;
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }
        }
        h1 {
            margin:35px 0 95px;
            max-width: 770px;
            font-size: 60px !important;
            @include media-breakpoint-down(md) {
                font-size: 35px !important;
                text-align: left !important;
                padding-left: 15px;
                padding-right: 15px;
                margin: 35px 0 50px;
            }
        }
        .pr-btn-group {
            position: relative !important;
            @include media-breakpoint-down(md) {
                bottom: inherit;
                width: calc(100% - 30px);
                margin-left: 15px;
                margin-right: 15px;
            }
            img{
                position: absolute;
                bottom: -60px;
                width: 170px;
                margin: 0 auto;
                right: 0;
                left: 0;
                @media (max-width: 991.9px) {
                  bottom: -20px;
                  width: 150px;
                  left: 100px;
                }
                @media (max-width: 767.9px) {
                  bottom: -150px;
                  width: 105px;
                  left: 190px;
                }
              }
        }
    }
}