.m93{
    padding: 70px 0;
    color: $black;
    @include media-breakpoint-down(md){
        padding: 30px 0;
    }
    &.bg-white{
        .block{
            background-color: #f7f7f7;
        }
    }
    .title{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 20px;
        @include media-breakpoint-down(md) {
            font-size: 32px;
            line-height: 36px;
        }
    }
    .subtitle{
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: $black;
        margin: 0 auto 66px;
        max-width: 880px;
        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }
    }
    .col-lg-6{
        &:nth-child(2){
            .block{
                background-color: #F1F9F5;
            }
        }
        @include media-breakpoint-down(lg) {
            margin-bottom: 25px;
        }
    }
    .block{
        width: calc(100% - 28px);
        background-color: #EDEDED;
        height: 100%;
        border-radius: 15px;
        padding: 80px 0 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: auto;
        align-items: flex-end;
        @include media-breakpoint-down(md) {
            padding: 25px 0 0;
        }
        .block-image{
            width: auto;
            height: auto;
            max-height: 440px;
            object-fit: contain;
            display: block;
            @include media-breakpoint-down(md) {
                max-height: 240px;
            }
        }
        .content{
            padding: 0 60px 0 36px;
            &:lang(ar) {
                padding: 0 36px 0 60px;
            }
            .pretitle{
                font-weight: 300;
                font-size: 14px;
                line-height: 18px;
                color: #717171;
            }
            .block-title{
                font-size: 40px;
                line-height: 1.2;
                font-weight: 900;
                margin-bottom: 18px;
                padding-left: 0;
                margin-top: 0;
                @include media-breakpoint-down(md) {
                    font-size: 30px;
                }
            }
            .block-desc{
                font-size: 18px;
                font-weight: $light;
                line-height: 26px;
                margin-bottom: 29px;
            }
            .cta{
                display: inline-block;
                font-weight: $black-bold;
                color: $black;
                font-size: 22px;
                line-height: 24px;
                position: relative;
                padding-inline-end: 40px;
                &:after{
                    content: '';
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    background-image: url(images/icons/arrow-right-black.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    &:lang(ar) {
                        right: auto;
                        left: 0;
                    }
                }
            }
        }
    }
}