.m11 {
    background-color: $blue;
    padding: 0 0 10px 0;
    @include media-breakpoint-down(md) {
        padding: 30px 0 10px;
    }
    &.light {
        background-color: rgba(0,100,200, 0.05);
        h2 {
            color: $blue;
        }
        .steps .step .txt {
            color: $black;
        }
    }
    h2 {
        color: #FFFFFF;
        font-size: 70px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        max-width: 550px;
        margin: 0 0 50px;
        &:lang(ar) {
            line-height: 1.5;
        }
    }
    .steps {
        .step {
            margin: 50px 0;
            display: flex;
            @include media-breakpoint-down(md) {
                margin: 40px 0;
            }
            .img {
                img {
                    width: 34px;
                    height: auto;
                    margin-right: 15px;
                    display: block;
                    &:lang(ar) {
                        margin-right: 0;
                        margin-left: 15px;
                    }
                }
            }
            .txt {
                margin: 0;
                color: #FFFFFF;
                font-weight: 900;
                width: calc(100% - 49px);
                font-size: 27px;
            }
        }
    }

}
