.m72 {
    background-color: #f7f7f7;
    padding: 25px 60px;
    @include media-breakpoint-down(lg){
        padding: 25px 0px;
    }
    &.bg-white{
        background-color: #FFFFFF;
        padding: 42px 42px;
        @include media-breakpoint-down(md){
            padding: 24px 0 32px;
        }
        h2{
            font-size: 50px;
            line-height: 1.15;
            margin-bottom: 32px;
            letter-spacing: 0;
            @include media-breakpoint-down(lg){
                font-size: 36px;
                line-height: 1.25;
            }
        }
        h3{
            font-size: 27px;
            margin-bottom: 64px;
            @include media-breakpoint-down(lg){
                line-height: 1.25;
                margin-bottom: 32px;
            }
        }
        ul{
            padding: 0;
            max-width: 80%;
            margin-inline-start: 17px;
            @include media-breakpoint-down(md){
                max-width: 100%;
                margin-bottom: 32px;
            }
            li {
                font-weight: $regular;
                font-size: 18px;
                line-height: 1.5;
                margin-bottom: 0px;
            }
        }
        .col-lg-7{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        // .book-demo-form {
        //     background-color: #F8F8F8;
        //     border-radius: 8px;
        //     border: 0.7px solid var(--lines-strokes-medium-grey, #B1B5B9);
        //     box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        //     padding: 24px;
        //     @include media-breakpoint-down(lg){
        //         padding: 20px 21px;
        //     }
        //     .mb-3 {
        //         margin-bottom: 1.5rem !important;
        //         @include media-breakpoint-down(lg){
        //             .mb-md-0{
        //                 margin-bottom: 24px !important;
        //             }
        //         }
        //     }
        //     input:not([type="checkbox"]),select{
        //         padding: 12px 14px;
        //     }
        //     .contact-select-country {
        //         button{
        //             padding: 12px;
        //         }
        //     }
        // }
    }
    h2 {
        margin: 0;
        color: $blue;
        font-size: 45px;
        margin-bottom: 40px;
        @include media-breakpoint-down(md){
            font-size: 35px;
        }
    }
    h3 {
        font-size: 22px;
        color: #000;
        font-weight: 900;
        margin: 0 0 18px;
        @include media-breakpoint-down(md){
            font-size: 20px;
        }
    }
    ul {
        list-style: initial;
        padding: 15px 0 0 50px;
        @include media-breakpoint-down(lg){
            padding: 15px 0 0 17px;
        }
        li {
            font-weight: 300;
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
    .book-demo-form {
        position: relative;
        min-height: 350px;
        max-width: 475px;
        @include media-breakpoint-down(md) {
            padding-top: 25px;
        }
        // p {
        //     font-weight: 900;
        //     font-size: 14px;
        // }
        // a {
        //     cursor: pointer;
        // }
        // .book-a-demo-form {
        //     .contact-select-country {
        //         @extend .tel_country_flags;
        //     }
        //     #phone {
        //         padding-left: 112px;
        //         &:lang(ar) {
        //             padding-left: inherit;
        //             padding-right: 112px;
        //         }
        //     }
        // }
    }
    padding: 32px 0;
    .book-demo-form {
        padding: 32px;
        background-color: #F8F8F8;
        border-radius: 8px;
        button {
            width: 100%;
        }
    } 
}