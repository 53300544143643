.wp-block-table {
    @extend .table-responsive;
    @extend .container;
    &.is-style-stripes {
        table {
            @extend .table-striped;
        }
    }
    &:not(.is-style-stripes) {
        table {
            @extend .table-bordered;
        }
    }
    table {
        @extend .table;
        @extend .align-middle;
        .has-text-align-center {
            text-align: center;
        }
        .has-text-align-right {
            text-align: right;
        }
        .has-text-align-left {
            text-align: left;
        }
        :not(:first-child) {
            border-top: inherit;
        }
    }
}

