.kb{
    .kb_sidebar{
        background-color: rgba(0,0,0,.03);
        border-radius: 10px;
        padding: 20px;
        width: 276px;
        h4{
            font-size: 22px;
            color: #000;
            font-weight: $black-bold;
            margin-bottom: 10px;
            margin-left: 4px;
        }
        .kb_menu{
            .menu-item{
                a{
                    display: block;
                    font-size: 14px;
                    color: #000;
                    padding: 4px 5px 5px;
                    &:hover{
                        background: rgba(0,0,0,.03);
                    }
                }
                &.active{
                    a{
                        color: $blue;
                    }
                }
            }
        }
    }
    .kb_page{
        .kb_breadcrumb{
            .breadcrumb{
                padding: 0;
                margin-bottom: 25px;
                .breadcrumb-item{
                    font-size: 14px;
                    &.active{
                        color: $blue;
                        font-weight: $black-bold;
                    }
                    &:hover{
                        color: 000;
                    }
                    &:not(:first-child){
                        &:before{
                            content: '>';
                        }
                    }
                }
            }
        }
        .kb-sections{
            .kb-title{
                font-size: 22px;
                font-weight: $black-bold;
                color: #000;
                border-bottom: 1px solid #C8C8C8;
                display: block;
                padding: 12px 0;
                position: relative;
                &:after {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    display: block;
                    content: "";
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=);
                    width: 7px;
                    height: 7px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    transform: rotate(90deg);
                    transition: all .2s;
                }
                &[aria-expanded="true"]::after {
                    transform: rotate(-90deg);
                }
            }
            .kb-content{
                h2{
                    font-size: 30px;
                    color: $blue;
                    font-weight: $black-bold;
                    margin-bottom: 30px;
                }
                p,li{
                    font-size: 18px;
                    font-weight: $light;
                    color: #000;
                    line-height: 1.5;
                }
                a{
                    color: $blue;
                }
                img{
                    // display: block;
                    max-width: 100%;
                    height: auto;
                    // margin: 20px 0;
                }
                strong{
                    font-weight: bolder;
                }
                ul{
                    list-style-type: disc;
                    padding-left: 40px;
                }
                .kb_child_accordion {
                    > br {
                        display: none;
                    }
                    > p {
                        margin: 0;
                    }
                    p:empty {
                        display: none;
                    }
                    a.kb-child-title {
                        display: block;
                        border-bottom: 1px solid #C8C8C8;
                        padding: 14px 0 15px 25px;
                        color: #000;
                        position: relative;
                        cursor: pointer;
                        font-size: 18px;
                        font-weight: 300;
                        &::after {
                            position: absolute;
                            right: 0;
                            top: calc(50% - 3.5px);
                            display: block;
                            content: "";
                            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=);
                            width: 7px;
                            height: 7px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            transform: rotate(90deg);
                            transition: all .2s;
                        }
                    }
                    a.kb-child-title[aria-expanded="true"]::after {
                        transform: rotate(-90deg);
                    }
                }
                .kb_like{
                    background-color: rgba(0,0,0,.03);
                    border-radius: 10px;
                    padding: 15px;
                    display: inline-block;
                    margin: 50px 0 45px;
                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                    .feedback_btns{
                        display: flex;
                        @include media-breakpoint-down(md) {
                            justify-content: center;
                            flex-wrap: wrap;
                            text-align: center;
                        }
                        h6{
                            font-size: 18px;
                            color: #000;
                            font-weight: $black-bold;
                            margin: 10px 0 0;
                            @include media-breakpoint-down(md) {
                                font-size: 22px;
                                width: 100%;
                                margin: 10px 0 20px;
                            }
                        }
                        button{
                            border: 0;
                            width: 39px;
                            height: 40px;
                            cursor: pointer;
                            background-color: inherit;
                            @include media-breakpoint-down(md) {
                                width: 45px;
                                height: 46px;
                                margin-bottom: 10px;
                            }
                            &.like{
                                background-image: url(images/icons/like.png);
                                background-size: cover;
                                margin-left: 15px;
                            }
                            &.dislike{
                                background-image: url(images/icons/dislike.png);
                                background-size: cover;
                                margin-left: 7px;
                            }
                        }
                    }
                    .thanks-msg{
                        h6{
                            font-size: 18px;
                            color: #000;
                            font-weight: $black-bold;
                            margin: 10px 0 0;
                        }
                    }
                }
            }
        }
        &.search{
            h4{
                border-bottom: 2px solid #C8C8C8;
                padding-bottom: 10px;
                font-weight: $black-bold;
                color: #000;
                font-size: 22px;
                margin-bottom: 0;
            }
            .kb_search_section{
                border-bottom: 1px solid #C8C8C8;
                padding: 15px;
                a{
                    h5{
                        font-weight: $black-bold;
                        font-size: 16px;
                        margin-bottom: 0;
                        color: #000;
                    }
                    p {
                        margin-bottom: 0;
                        color: #000;
                    }
                    &:hover{
                        h5{
                            color: $blue;
                        }
                        p{
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}
.kb_search{
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    &.kb_float_menu_search {
        margin: 0 auto 35px;
    }
    input[name="s"]{
        font-family: $lato;
        width: calc(100% - 285px);
        border: 1px solid #C8C8C8;
        border-radius: 4px;
        font-size: 18px;
        padding: 8px 0 8px 45px;
        background-image: url(images/icons/kb_search.png);
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: 12px center;
        @include media-breakpoint-down(md) {
            width: calc(100% - 50px);
            min-height: 40px;
            padding: 0 0 0 45px;
        }
        &#mob-search{
            @include media-breakpoint-down(xl) {
                width: calc(100% - 50px);
                min-height: 40px;
                padding: 0 0 0 45px;
            } 
        }
    }
    .btn_kb_search{
        width: 270px;
        font-weight: $black-bold;
        font-size: 18px;
        @include media-breakpoint-down(md) {
            width: 40px;
            font-size: 0;
            background-image: url(images/icons/search.png);
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: center;
        }
        &.mb-btn_kb_search{
            width: 40px;
            font-size: 0;
            background-image: url(images/icons/search.png);
            background-size: 25px 25px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
.kb_float_menu_btn {
    position: fixed;
    width: 100px;
    height: 100px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -20px;
    z-index: 99;
    background-image: url(images/icons/kb_menu.png);
    background-position: center;
    background-size: cover;
    transition: all .2s;
    &.kb_fm_open {
        @include media-breakpoint-down(xl) {
            right: 329px;
            z-index: 999999;
        }
        @include media-breakpoint-down(md){
            right: 102%;
        }
    }
}
.kb_fm_header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
    background: #FFFFFF;
    height: 75px;
    padding: 18px 15px 0;
    right: 0;
    transition: all .2s;
    &.invisible {
        right: -350px;
    }
    .kb-mb-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo{
            width: calc(100% - 50px);
            height: 32px;
            display: block;
            transition: width .2s;
            background-image: url(images/planradar_logo.svg);
            background-size: 150px 32px;
            background-repeat: no-repeat;
            background-position: left center;
        }
        .kb_fm_close{
            width: 40px;
            margin-left: 10px;
        }
    }
    
}
.kb_float_menu {
    width: 350px;
    height: 100%;
    position: fixed;
    background: #FFFFFF;
    right: 0;
    top: 0;
    z-index: 99999;
    padding: 30px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    transition: all .2s;
    &.invisible {
        right: -350px;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        top: 55px;
    }
    .kb_menu{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
            width: calc(50% - 3px);
            border-bottom: 1px solid #C8C8C8;
            padding: 10px 0;
            &:first-child, &:nth-child(2){
                border-top: 1px solid #C8C8C8;
            }
            a{
                font-size: 14px;
            }
        }
    }
}
.kb_nav_opened {
    overflow: hidden;
    &:after {
        position: fixed;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,.1);
        left: 0;
        top: 0;
        content: "";
        z-index: 9999;
    }
}
.kb_float_menu_btn_close {
    @include media-breakpoint-down(xl) {
        position: fixed;
        width: 100px;
        height: 100px;
        top: calc(50% - 50px);
        right: 329px;
        z-index: 9999999;
    }
}