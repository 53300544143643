.m28 {
    background-image: linear-gradient(134deg, #0064C8 0%, #003264 100%);
    padding: 25px 0;
    color: #FFFFFF;
    img {
        width: 170px;
    }
    h2 {
        margin: 0 0 25px;
    }
    h3 {
        margin: 0;
        font-weight: 900;
        font-size: 22px;
        @include media-breakpoint-down(md) {
            text-align: center;
            margin-bottom: 25px;
        }
    }
    @include media-breakpoint-down(md) {
        .container > .row > .col-md-5 > .row > .col-6 {
            text-align: center;
        }
    }
}