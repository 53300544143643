.m25 {
    background-color: #f7f7f7;
    padding: 60px 0 0;
    &.has-btn{
        padding: 60px 0 48px;
    }
    &.hero{
        padding: 0;
        .video {
            .pr_plyr {
                position: relative;
                .pr_plyr_loading {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #f8f8f8;
                    top: 0;
                    bottom: 0;
                    z-index: 2;
                }
            }
        }
    }
    @include media-breakpoint-down(xl) {
        background-color: inherit;
        padding: 53px 0 20px;
        .container {
            // max-width: inherit;
            // padding-left: 0;
            // padding-right: 0;
        }
    }
    h2 {
        font-size: 40px;
        font-weight: 900;
        letter-spacing: .85px;
        color: $blue;
        margin: 0 auto 25px;
        @include media-breakpoint-down(xl) {
            font-size: 35px;
            // padding-left: 15px;
            // padding-right: 15px;
        }
        @include media-breakpoint-down(md) {
            max-width: 720px;
            // padding-left: 15px;
            // padding-right: 15px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 30px;
            max-width: 540px;
            // padding-left: 15px;
            // padding-right: 15px;
        }
    }
    h4{
        font-size: 24px;
        font-weight: $medium;
        margin-top: 40px;
        // @include media-breakpoint-down(xl) {
        //     padding-left: 15px;
        //     padding-right: 15px;
        // }
    }
    p{
        font-size: 18px;
        font-weight: $light;
        line-height: 1.5;
        // @include media-breakpoint-down(xl) {
        //     padding-left: 15px;
        //     padding-right: 15px;
        // }
    }
    .value{
        h4{
            margin-bottom: 0;
            font-size: 22px;
            font-weight: $black-bold;
        }
    }
    .video{
        max-width: 1116px;
        margin: 0 auto;
        .pr-btn{
            // color: #fff;
            // font-weight: 900;
            // font-size: 22px;
            // text-align: center;
            display: block;
            margin: 48px auto 20px;
            max-width: 240px;
        }
        .m25-player{
            visibility: hidden;
        }
        .plyr--video,.plyr--vimeo{
            position: relative;
            &.plyr{
                visibility: visible;
            }
            &:before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                background-image: var(--plyr-video-background) !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                opacity: 1 !important;
                z-index: 1;
            }
            &.plyr--playing, &.plyr--paused:not(.plyr--stopped){
                &:before{
                    opacity: 0 !important;
                }
                .plyr__control--overlaid {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &.plyr--stopped{
                .plyr__controls {
                    opacity: 0;
                    pointer-events: none;
                    transform: translateY(100%);
                }
            }
        }
    }
    .playBTN {
        width: 148px;
        height: 148px;
        z-index: 9;
        position: absolute;
        top: calc(50% - 74px);
        left: calc(50% - 74px);
        background-image: url('images/play.png');
        background-size: cover;
        cursor: pointer;
    }
    .cta{
        background-image: linear-gradient(134deg, #0064C8 0%, #003264 100%);
        padding: 25px 0;
        color: #fff;
        margin-top: 75px;
        .cta-btn{
            text-align: center;
            display: block;
            margin: 0 auto;
            h3{
                font-weight: $black-bold;
                font-size: 22px;
                color: #ffffff;
                text-decoration: underline;
                margin: 0;
            }
        }
    }
    // CSS FOR THE VIDEO BLOCK
    &.video-block {
        background-color: #ffffff;
        padding: 32px 0;
        p {
            margin-left: 40px;
            @include media-breakpoint-down(md) {
                margin-left: inherit;
            }
            &.title {
                padding: 8px 12px;
                display: inline-block;
                background-color: #f8f8f8;
                margin-bottom: 32px;
                font-weight: 900;
                border-radius: 4px;
            }
            &.subtitle {
                font-size: 40px;
                font-weight: 900;
                line-height: 1.15;
                margin-bottom: 64px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                    font-size: 27px;
                    line-height: 1.25;
                }
                span {
                    color: $blue;
                }
            }
        }
        .pr-btn {
            margin-left: 40px;
            @include media-breakpoint-down(md) { 
                margin-left: 0;
            }
        }
        button.btn {
            color: $blue;
            font-weight: 900;
            position: relative;
            @include media-breakpoint-down(md) {
                width: inherit;
            }
            &::after {
                content: '';
                background-image: url('data:image/svg+xml,<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.85554 1.64446L12 6.5L4.85554 11.3555L4.85554 1.64446Z" fill="%230064C8"/></svg> ');
                width: 12px;
                height: 13px;
                display: block;
                position: absolute;
                right: -6px;
                top: calc(50% - 5px);
            }
        }
        .video {
            margin-right: 25px;
            @include media-breakpoint-down(md) {
                margin-right: 0;
                margin: 32px 0;
            }
            #player,
            iframe, video {
                border-radius: 8px;
            }
        }
    }
}

// move to general
.pr_loader {
    width: 48px;
    height: 48px;
    border: 5px solid $blue;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    animation: pr_rotation 1s linear infinite;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
}

@keyframes pr_rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 