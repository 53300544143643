.m35 {
    h1 {
        font-weight: 900;
        font-size: 50px;
        margin: 0 0 30px; 
    }
    p {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: $regular;
        line-height: 1.2;
        a {
            color: $blue;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .btn {
        font-weight: 900;
        font-size: 22px;
        padding: 7px 30px;
        color: #fff;
        @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 25px;
        }
    }

    .mtt-mobile-hero {
        background-image: linear-gradient(134deg, #0064C8 0%, #003264 100%);
        color: $white;
        h1 {
           font-size: 30px; 
           padding: 90px 0 15px;
        }
    }
}