.m22{
    padding: 60px 0;
    h2{
        font-size: 45px;
        font-weight: $black-bold;
        letter-spacing: 0.78px;
        color: $blue;
        margin-bottom: 10px;
        &:lang(ar) {
            font-size: 36px;
        }
    }
    .calculator{
        .field{
            border-top: 1px solid rgba(0,0,0,0.1);
            padding: 25px 0;
            h3{
                font-size: 18px;
                color: $black;
                letter-spacing: 0.31px;
                font-weight: $black-bold;
            }
            p{
                font-size: 18px;
                color: #B1B1B1;
                letter-spacing: 0.31px;
                margin-bottom: 0;
            }
            button[type="submit"]{
                color: $white;
                min-width: 370px;
                font-size: 22px;
                letter-spacing: 0.38px;
                font-weight: $black-bold;
                margin-top: 50px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
            .industries{
                position: relative;
                #industry{
                    font-size: 18px;
                    width: 100%;
                    height: 50px;
                    position: relative;
                    text-align: left;
                    color: #999;
                    background: $white;
                    border: 1px solid #E6E6E6;
                    border-radius: 5px;
                    cursor: pointer;
                    text-indent: 15px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    cursor: pointer;
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='50'><polygon points='0,0 100,0 50,50'/></svg>");
                    background-repeat: no-repeat;
                    background-position: calc(100% - 15px) center;
                    background-size: 10px 8px;
                    &:-ms-expand {
                        display: none;
                    }
                    option{
                        display: none;
                    }
                    &.expanded{
                        background-color: #dae0e5;
                        border-color: #d3d9df;
                    }
                    // &:active{
                    //     border: none;
                    // }
                }
                .industry{
                    display: none;
                    position: absolute;
                    background: $white;
                    border: 1px solid #E6E6E6;
                    width: 100%;
                    top: 100%;
                    left: 0;
                    border-radius: 5px;
                    z-index: 9;
                    &.show{
                        display: block;
                    }
                    li{
                        font-size: 16px;
                        font-weight: $regular;
                        cursor: pointer;
                        padding: 10px 20px;
                        &.selected{
                            background-color: $blue;
                            color: $white;
                        }
                        &:hover{
                            background-color: #E0E0E0;
                            color: $black;
                        }
                        .dropdown-item{
                            &:hover,&:visited,&:focus{
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
            .range-container{
                display: flex;
                justify-content: space-between;
                @include media-breakpoint-down(md) {
                    justify-content: center;
                    flex-direction: column;
                    margin-top: 25px;
                }
                .range_input {
                    padding: 10px 0 11px;
                    font-size: 18px;
                    color: #000;
                    border: 1px solid rgba(0,0,0,0.1);
                    width: 95px;
                    text-align: center;
                    border-radius: 4px;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        height: 50px;
                    }
                }
                .range-input{
                    position: relative;
                    span {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        width: 26px;
                        height: 26px;
                        position: absolute;
                        border-radius: 3px;
                        border: 1px solid rgba(0,0,0,0.1);
                        background-color: #FFFFFF;
                        padding-top: 11px;
                        line-height: 0;
                        font-size: 20px;
                        text-align: center;
                        display: block;
                        color: #C0C0C0;
                        font-weight: 900;
                        cursor: pointer;
                        @include media-breakpoint-down(md) {
                            width: 40px;
                            height: 40px;
                            font-size: 36px;
                            padding-top: 16px;
                        }
                        &.pricing_min {
                            left: -13px;
                            @include media-breakpoint-down(md) {
                                left: 5px;
                            }
                        }
                        &.pricing_plus {
                            right: -13px;
                            @include media-breakpoint-down(md) {
                                right: 5px;
                            }
                        }
                        &.disabled{
                            pointer-events: none;
                        }
                    }
                }
                .range-slider {
                    width: calc(100% - 125px);
                    margin-top: 11px;
                    position: relative;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        margin-top: 40px;
                    }
                    .rangeslider__fill {
                        background-image: linear-gradient(270deg, #0064C8 0%, #003264 100%);
                        position: absolute;
                        border-radius: 10px;
                        margin-left: 2px;
                    }
                    .rangeslider--horizontal {
                        height: 20px;
                        width: 100%;
                        .rangeslider__fill {
                            top: 0;
                            height: 100%;
                        }
                        .rangeslider__handle {
                            top: -4px;
                            font-size: 18px;
                            touch-action: pan-y;
                            background: white;
                            border: 1px solid #ccc;
                            cursor: pointer;
                            display: inline-block;
                            width: 26px;
                            height: 26px;
                            position: absolute;
                            background-color: #0064C8;
                            border: 2px solid #FFFFFF;
                            border-radius: 50%;
                            &:after {
                                content: "";
                                display: block;
                                width: 18px;
                                height: 18px;
                                margin: auto;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
                                background-size: 100%;
                                border-radius: 50%;
                            }
                            &:active{
                                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                                background-size: 100%;
                                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.12)));
                                background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                                background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                                background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                            }
                        }
                        &.rangeslider--active{
                            .rangeslider__handle {
                                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
                                background-size: 100%;
                                background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.12)));
                                background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                                background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                                background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
                            }
                        }
                    }
                    .rangeslider {
                        background-color: rgba(0,0,0,0.08);
                        border-radius: 10px;
                        position: relative;
                        cursor: pointer;
                    }
                }
                .number_of_users,.plans-numbers,.rate {
                    position: absolute;
                    top: -20px;
                    left: 10px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    p{
                        font-size: 12px;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .results{
        background-image: linear-gradient(149deg, #28965a 0%, #1e7a48 100%);
        padding: 25px 0 0;
        color: $white;
        margin-top: 25px;
        text-align: center;
        display: none;
        &.show{
            display: block;
        }
        .savings{
            font-size: 36px;
            font-weight: $black-bold;
            letter-spacing: 0.62px;
            &:lang(ar) {
                font-size: 34px;
            }
            .total:lang(ar) {
                display: inline-block;
                direction: ltr;
            }
            @include media-breakpoint-down(lg) {
                font-size: 30px;
            }
            @include media-breakpoint-down(md) {
                font-size: 26px;
            }
        }
        .note{
            font-size: 25px;
            font-weight: $black-bold;
            letter-spacing: 0.31px;
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
        }
        .disclaimer{
            font-size: 18px;
            font-weight: $light;
            padding-bottom: 25px;
            letter-spacing: 0.31px;
        }
        .suitable-plans{
            padding: 40px 0 75px;
            color: $black;
            text-align: left;
            h3 {
                font-size: 18px;
                color: $black;
                letter-spacing: 0.31px;
                font-weight: $black-bold;
            }
        }
    }
    .plans{
        .features{
            padding: 0;
            .per-month {
                height: 145px;
            }
            .feature{
                width: 100%;
                min-height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:nth-child(even){
                    background-image: linear-gradient(270deg, rgba(0,0,0,0.05) 0%, rgba(255,255,255,0.07) 100%);
                }
                &:nth-child(odd){
                    background: transparent;
                }
                &.has-info{
                    position: relative;
                    cursor: pointer;
                    &:before{
                        content: "";
                        background-image: url(images/icons/info.svg);
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        right: 10px;
                        top: calc(50% - 10px);
                        background-color: $white;
                        border-radius: 50%;
                        z-index: 99;
                        cursor: pointer;
                    }
                    &:hover{
                        .feature-desc{
                            display: inline-table;
                            font-size: 12px;
                        }
                    }
                }
                &.medium{
                    min-height: 80px;
                }
                &.large{
                    min-height: 120px;
                }
                &:first-child{
                    border-top: 1px solid $border-color-light;
                    border-bottom: 1px solid $border-color-light;
                    p{
                        font-weight: $black-bold;
                    }
                }
                &:last-child{
                    margin-bottom: 20px;
                }
                p{
                    font-size: 15px;
                    font-weight: $regular;
                    line-height: 23px;
                    padding: 5px 24px 5px 0;
                    margin: 0;
                    color: $black;
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                    }
                }
                .feature-desc{
                    display: none;
                    font-size: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 95%;
                    width: 220px;
                    z-index: 9;
                    border-radius: 4px;
                    color: #747474;
                    letter-spacing: 0.31px;
                    padding: 5px 10px 5px 20px;
                    border: 1px solid #E1E1E1;
                    box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.30);
                    background: $white;
                    margin: auto;
                }
            }
        }
        .plan-container{
            @include media-breakpoint-down(lg) {
                align-self: flex-start !important;
                margin: 20px auto 35px;
            }
            @include media-breakpoint-down(md) {
                padding: 0;
                margin: 0;
            }
            &:last-child{
                h3{
                    color: $blue;
                }
            }
            .plan{
                border: 1px solid #0000001a;
                box-shadow: 0 2px 5px 0 #0000001a;
                border-radius: 10px;
                color: $black;
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px 30px;
                height: 100%;
                @include media-breakpoint-down(lg) {
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                }
                @include media-breakpoint-down(md) {
                    padding: 20px 15px;
                }
                &.premium{
                    background-image: linear-gradient(-26deg, #0064C8 5%, #003264 100%);
                    color: $white;
                    p{
                        color: $white;
                        &.feature{
                            color: $white;
                            .check-mark{
                                filter: invert(99%) sepia(4%) saturate(624%) hue-rotate(276deg) brightness(117%) contrast(100%);
                            }
                        }
                    }
                    h3{
                        color: $white;
                    }
                    .prices{
                        color: $black;
                    }
                    .plan-head{
                        border-bottom: 1px solid rgba(255,255,255,0.2);
                    }
                    .plan-body{
                        border-top: 1px solid rgba(255,255,255,0.2);
                    }
                }
                .plan-head{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                    padding: 0 0 15px;
                    &:lang(ar) {
                        display: block;
                        p.subtitle {
                            width: 100%;
                            text-align: center;
                        }
                    }
                    h3{
                        font-size: 45px;
                        font-weight: $black-bold;
                        line-height: 48px;
                        margin: 0;
                        &:lang(ar) {
                            text-align: center;
                        }

                    }
                    
                }
                .prices{
                    padding: 15px 0 0;
                    text-align: center;
                    .prices-currency{
                        display: none;
                        &.show{
                            display: block;
                        }
                        .price{
                            display: none;
                            font-size: 45px;
                            font-weight: $black-bold;
                            margin: 0;
                            &.show{
                                display: block;
                                &:lang(ar) {
                                    direction: ltr;
                                }
                            }
                            span{
                                font-size: 18px;
                                font-weight: $light;
                                margin: 15px 0;
                                display: block;
                            }
                        }
                    }
                    .pricing_switcher{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 13px;
                        position: relative;
                        @include media-breakpoint-down(md) {
                            flex-direction: column-reverse;
                        }
                        .billing-cycle{
                            width: calc(100% - 100px);
                            background-color: #E0E0E0;
                            border-radius: 23.5px;
                            border: 1px solid #E0E0E0;
                            display: flex;
                            height: 60px;
                            position: relative;
                            @include media-breakpoint-down(lg) {
                                width: 100%;
                            }
                            @include media-breakpoint-down(md) {
                                width: 100%;
                                height: 60px;
                            }
                            .billing {
                                position: relative;
                                width: 50%;
                                z-index: 1;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                padding: 0 5px;
                                p {
                                    margin: 1px;
                                    padding: 7px 0;
                                    text-align: center;
                                    color: #000;
                                    font-size: 14px;
                                }
                            }
                            .selected_cycle {
                                background-color: #FFF;
                                width: 50%;
                                height: 100%;
                                position: absolute;
                                border-radius: 23.5px;
                                transition: right .25s;
                                &.yearlyPlan {
                                    right: 0;
                                }
                                &.monthlyPlan {
                                    right: 50%;
                                }
                            }

                            // label{
                            //     width: 50%;
                            //     line-height: 1.2;
                            //     text-align: center;
                            //     cursor: pointer;
                            //     position: relative;
                            //     font-size: 14px;
                            //     display: flex;
                            //     align-items: center;
                            //     justify-content: center;
                            // }
                            // input.calc-cycle{
                            //     visibility: hidden;
                            //     &:checked{
                            //         &:after{
                            //             content: attr(data-text);
                            //             width: 100%;
                            //             height: 100%;
                            //             position: absolute;
                            //             background-color: $white;
                            //             visibility: visible;
                            //             top: 0;
                            //             left: 0;
                            //             border-radius: 23.5px;
                            //             text-align: center;
                            //             line-height: 1.2;
                            //             font-size: 14px;
                            //             font-weight: $regular;
                            //             -webkit-transition: visibility 1.2s ease;
                            //             -moz-transition: visibility 1.2s ease;
                            //             -ms-transition: visibility 1.2s ease;
                            //             -o-transition: visibility 1.2s ease;
                            //             transition:  visibility 1.2s ease;
                            //             display: flex;
                            //             align-items: center;
                            //             justify-content: center;
                            //             padding: 0 10px;
                            //         }
                            //     }
                                
                            // }
                        }
                        .currency-switcher{
                            width: 85px;
                            height: 60px;
                            position: relative;
                            @include media-breakpoint-down(md) {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                            button[name="currency-switcher"]{
                                width: 100%;
                                height: 100%;
                                text-align: center;
                                background: $white;
                                border: 1px solid rgba(0,0,0,0.1);
                                border-radius: 23.5px;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                text-align-last: center;
                                cursor: pointer;
                                // text-indent: 30%;
                                // @include media-breakpoint-down(md) {
                                //     text-indent: 43%;
                                // }
                                // &:-ms-expand {
                                //     display: none;
                                // }
                                // option{
                                //     display: none;
                                // }
                            }
                            .currencies{
                                display: none;
                                position: absolute;
                                top: 0;
                                background: $white;
                                border: 1px solid rgba(0,0,0,0.1);
                                width: 100%;
                                top: -40px;
                                left: 0;
                                border-radius: 23.5px;
                                &.show{
                                    display: block;
                                    z-index: 9;
                                }
                                li{
                                    font-size: 16px;
                                    font-weight: $regular;
                                    line-height: 40px;
                                    text-align: center;
                                    cursor: pointer;
                                    &.selected{
                                        background-color: #E0E0E0;
                                    }
                                    &:hover{
                                        background-color: #E0E0E0;
                                    }
                                    &:first-child{
                                        border-radius: 23.5px 23.5px 0 0;
                                    }
                                    &:last-child{
                                        border-radius: 0 0 23.5px 23.5px;
                                    }
                                }
                            }
                        }
                    }
                }
                .plan-body{
                    border-top: 1px solid rgba(0,0,0,0.1);
                    padding: 15px 0;
                    .plan-features{
                        h4{
                            font-size: 18px;
                            font-weight: $black-bold;
                        }
                    }
                }
                p{
                    font-size: 18px;
                    font-weight: $regular;
                    padding: 0 15px;
                    display: table;
                    width: 100%;
                    &.request-quote{
                        font-size: 35px;
                        font-weight: $black-bold;
                        margin: 55px 0;
                        color: $blue;
                        text-align: center;
                        @include media-breakpoint-down(ld){
                            font-size: 26px;
                        }
                    }
                    &.subtitle{
                        font-weight: $black-bold;
                        line-height: 20px;
                        margin: 0;
                        width: auto;
                    }
                    &.feature{
                        color: $black;
                        line-height: 23px;
                        min-height: 50px;
                        margin: 0;
                        flex-direction: column;
                        justify-content: center;
                        min-height: auto !important;
                        text-align: left;
                        padding: 5px 0;
                        display: list-item;
                        margin-left: 20px;
                    }
                }
                .plan-footer{
                    display: flex;
                    justify-content: flex-end;
                    margin: 80px 0 0;
                    &:lang(ar) {
                        display: block;
                        text-align: center;
                    }
                    @include media-breakpoint-down(md) {
                        margin: 30px 0;
                    }
                    .cta-btn{
                        text-align: center;
                        color: $white;
                        display: block;
                        min-width: 260px;
                        max-width: calc(100% - 30px);
                        // height: 49px;
                        font-size: 22px;
                        letter-spacing: 0.38px;
                        font-weight: $black-bold;
                        @include media-breakpoint-down(md) {
                            width: 100%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}

/* AE */ 
body.ae{
    .m22{
        .calculator{
            .field{
                .industries{
                    #industry{
                        text-align: right;
                        background-position: 15px center;
                    }
                    .industry{
                        text-align: right;
                    }
                }
                .range-container{
                    .range-input{
                        span{
                            &.pricing_min {
                                left: auto;
                                right: -13px;
                            }
                            &.pricing_plus {
                                right: auto;
                                left: -13px;
                            }
                        }
                    }
                    .range-slider{
                        direction: ltr;
                    }
                }  
            }
        }  
        .results{
            .suitable-plans{
                h3 {
                    text-align: right;
                }
            }
        }  
        .plans{
            .plan-container{
                .plan{
                    .plan-body{
                        .plan-features{
                            h4{
                                text-align: right;
                            }
                        }
                    }
                    p{
                        &.feature{
                            text-align: right;
                        }
                    }
                }
            }
        }     
    }
}