.m02 {
    padding: 39px 0 20px;
    @include media-breakpoint-down(md) {
        padding: 24px 0;
    }
    &.scroll,
    &.bar{
        box-shadow: 0 5px 5px #0000001a;
        &.top-shadow{
            box-shadow: 0px 4px 4px rgba(2, 2, 2, 0.06), 0px -4px 10px rgba(0, 0, 0, 0.03);
            margin-top: 10px;
        }
        &.remove-shadow{
            box-shadow: none !important;
        }
        @include media-breakpoint-down(md) {
            margin: 25px 15px;
            border: 1px solid #E3E3E3;
            border-radius: 10px;
            box-shadow: 0 5px 20px 0 #0000001a;
            text-align: center;
        }
        .clients-logos{
            p{
                font-size: 22px;
                text-align: center;
                line-height: 1.2;
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }
            .logos{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                @include media-breakpoint-up(xl) {
                    flex-wrap: nowrap;
                }
                @include media-breakpoint-down(md) {
                    justify-content: flex-start;
                }
                .logo{
                    margin: 10px;
                    text-align: center;
                    @include media-breakpoint-down(xl) {
                        width: calc(100% / 5);
                        margin: 0;
                        padding: 15px 10px;
                    }
                    @include media-breakpoint-down(md) {
                        width: calc(100% / 3);
                    }
                    img{
                        height: auto;
                        max-height: 45px;
                        vertical-align: middle;
                        width: 100%;
                        max-width: 100px;
                        object-fit: contain;
                        min-height: 45px;
                    }
                }
            }
        }
    }
    &.section{
        background-color: #F7F7F7;
        padding: 25px 0 60px;
        .clients-logos{
            p{
                font-size: 40px;
                font-weight: $black-bold;
                letter-spacing: 0.85px;
                color: $blue;
            }
            .logos{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: center;
                @include media-breakpoint-up(xl) {
                    flex-wrap: nowrap;
                }
                @include media-breakpoint-down(md) {
                    justify-content: flex-start;
                }
                .logo{
                    background: #FFFFFF;
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    width: calc(100% / 5 - 15px);
                    padding: 35px 25px;
                    margin: 7.5px;
                    @include media-breakpoint-down(md) {
                        width: calc(100% / 2 - 15px);
                    }
                    img{
                        height: auto;
                        max-height: 35px;
                        vertical-align: middle;
                        width: auto;
                        max-width: 100%;
                    }
                }
            }
        }
    }
    &.scroll {
        box-shadow: none;
        padding: 40px 0 20px;
        @include media-breakpoint-down(md) {
            border: none;
            padding: 0;
        }
        .clients-logos {
            p {
                font-size: 22px;
                margin: 0 0 4px;
                line-height: 1;
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
            }
            .logo {
                width: 84px;
                height: 84px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: inherit !important;
                gap: 12px;
                img {
                    max-height: inherit;
                    width: auto;
                }
            }
        }
        .slick-prev,
        .slick-next {
            width: 0;
            height: 0;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            &::before {
                content: inherit;
            }
        }
        .slick-prev {
            border-right: 12px solid black;
            left: -15px;
        }
        .slick-next {
            border-left: 12px solid black;
            right: -15px;  
        }
        .slick-track {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    // .m02-reviews {
    //     display: flex;
    //     flex-wrap: wrap;
    //     justify-content: center;
    //     gap: 32px;
    //     margin: 20px 0 0;
    //     img {
    //         display: block;
    //         width: auto;
    //         height: 40px;
    //     }
    // }
}