.m40 {
    background-color: #F7F7F7;
    padding: 32px 0;
    h2 {
        color: #0064C8;
        margin: 0;
        .staging & {
            color: $black;//#0064C8;
            text-align: center;
        }
    }
    p {
        margin: 0;
        font-size: 18px;
        margin-bottom: 0;
        font-weight: 300;
        color: #000000;
        @include media-breakpoint-down(md) {
            padding: 0 15px;
        }
        strong {
            font-size: 22px;
            font-weight: 900;
            line-height: 27px;
        }
    }
    .m40-video {
        position: relative;
        margin-bottom: 12px;
        box-shadow: -1px 1px 2px rgba(0,0,0,0.15);
        border-radius: 12px;
        cursor: pointer;
        margin-top: 48px;
        .staging & {
            margin: 48px 0 20px;
        }
        @include media-breakpoint-down(md) {
            border-radius: 0;
        }
        &::before {
            content: "";
            position: absolute;
            width: 60px;
            height: 60px;
            background-image: url("images/play.png");
            background-size: contain;
            left: calc(50% - 30px);
            top: calc(50% - 30px);
            z-index: 2;  
        }
        &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            border-radius: 12px;
            background: linear-gradient(rgba(255,255,255,0) 0%, rgba(0,0,0,0.25) 100%);
            transition: opacity .25s linear;
            opacity: 0.25;
        }
        img {
            border-radius: 12px;
            display: block;
            @include media-breakpoint-down(md) {
                border-radius: 0;
            }
        }
    }
    video {
        max-width: 100%;
    }
}