.m13{
    .quote-container{
        position: relative;
        margin: 60px 0;
        border-top: 1px solid #C8C8C8;
        border-bottom: 1px solid #C8C8C8;
        padding: 50px 0 25px;
        text-align: center;
        &:lang(ar) {
            &::before {
                left: auto;
                right: 0;
            }
            &::after {
                right: auto;
                left: 0; 
            }
        }
        @include media-breakpoint-down(md) {
            padding: 50px 0 60px;
        }
        &:before{
            content: "";
            background-color: $white;
            background-image: url(images/icons/quotes_grey.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;
            width: 100px;
            height: 86px;
            position: absolute;
            transform: rotate(180deg);
            top: -43px;
            left: 0;
        }
        &:after{
            content: "";
            background-color: $white;
            background-image: url(images/icons/quotes_grey.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;
            width: 100px;
            height: 86px;
            position: absolute;
            bottom: -43px;
            right: 0;
        }
        .logo{
            position: absolute;
            top: -40px;
            width: 200px;
            height: 80px;
            margin: 0 auto;
            right: 0;
            left: 0;
            padding: 0 30px;
            background-color: #FFFFFF;
            object-fit: contain;
            @include media-breakpoint-down(md) {
                position: unset;
            }
        }
        .quote{
            max-width: 75%;
            margin: 0 auto;
            font-size: 30px;
            font-weight: $black-bold;
            font-style: italic;
            color: #212529;
            line-height: 45px;
            @include media-breakpoint-down(xl) {
                max-width: 90%;
            }
            @include media-breakpoint-down(md) {
                max-width: 100%;
                font-size: 22px;
                line-height: 33px;
            }
            &.small-font {
                font-size: 18px;
                line-height: 22px;
                font-weight: 400;
            }
        }
        .author{
            font-size: 20px;
            font-weight: $bold;
            color: $blue;
            margin: 15px auto 0;
            span{
                font-weight: $light;
                @include media-breakpoint-down(md){
                    display: block;
                }
            }
            .remove_q_comma{
                @include media-breakpoint-down(md){
                    display: none;
                }
            }
        }
    }
    &.bg-gray{
        .quote-container{
            &:after, &:before{
                background-color: #f7f7f7;
            }
            .logo{
                background-color: #f7f7f7;
            }
        }
    }
    &.bg-gradient{
        .quote-container{
            border: none;
            margin: 0;
            padding: 50px 0;
            &:after, &:before{
                background-color: transparent;
            }
            &:before{
                width: 70px;
                height: 70px;
                opacity: .15;
                top: 0;
            }
            &:after{
                width: 200px;
                height: 100px;
                opacity: .15;
                background-size: cover;
                background-position: top;
                bottom: 0;
            }
            .logo{
                background-color: transparent;
            }
            .quote{
                color: $white;
                @include media-breakpoint-down(lg){
                    max-width: 100%;
                    font-size: 22px;
                    line-height: 1.5;
                }
            }
            .author{
                color: $white;
                font-weight: $bold;
                span{
                    font-weight: $light;
                    @include media-breakpoint-down(md){
                        display: inline-block;
                    }
                }
                @include media-breakpoint-down(lg){
                    font-size: 18px;
                }
            }
        }
    }
    &.layout-left{
        .quote-container{
            display: flex;
            align-items: center;
            @include media-breakpoint-down(lg){
                padding: 25px 0;
            }
            @include media-breakpoint-down(md){
                flex-direction: column;
            }
            .logo{
                position: unset;
                width: 180px;
                height: 180px;
                padding: 0;
                margin: 0 40px;
                @include media-breakpoint-down(lg){
                    margin: 0 20px;
                }
            }
            .quote-content{
                width: calc(100% - 220px);
                @include media-breakpoint-down(md){
                    width: 100%;
                }
                .quote{
                    text-align: left;
                    max-width: 100%;
                    @include media-breakpoint-down(lg){
                        font-size: 22px;
                        line-height: 1.5;
                    }
                    @include media-breakpoint-down(md){
                        text-align: center;
                    }
                }
            }
            .author{
                text-align: left;
                font-weight: $black-bold;
                @include media-breakpoint-down(md){
                    text-align: center;
                }
            }
        }
    }
    &.layout-small {
        max-width: 870px;
        margin: 0 auto;
        p.quote {
            font-weight: 300;
            font-style: normal;
        }
    }
    .cta {
        text-align: center;
        margin: 130px 0 110px;
        @include media-breakpoint-down(md){
            margin: 0;
        }
    }
    &.solo {
        background-color: #F2F7FC;
        padding: 64px 0 32px;
        h2 {
            margin-bottom: 24px;
            text-align: center;
            font-weight: 900;
            font-size: 40px;
            @include media-breakpoint-down(lg){
                margin-bottom: 24px;
                line-height: 1.25;
                text-align: left;
            }
        }
        h3 {
            font-size: 18px;
            text-align: center;
            margin: 0 0 64px;
            @include media-breakpoint-down(lg){
                text-align: left;
                margin: 0 0 32px;
                line-height: 1.5;
            }
        }
        .quote-row {
            background-color: #FFFFFF;
            padding: 64px;
            border-radius: 8px;
            display: flex;
            gap: 32px;
            flex-wrap: wrap;
            @include media-breakpoint-down(lg){
                padding: 27px 16px;
            }
            .text {
                width: calc(50% - 16px);
                @include media-breakpoint-down(lg){
                    width: 100%;
                    order: 1;
                }
                p {
                    margin-bottom: 28px;
                    line-height: 1.5;
                }
                .quote {
                    background-color: #E5EFF9;
                    padding: 16px;
                    border-radius: 8px;
                    &::before {
                        content: "";
                        display: block;
                        margin-bottom: 9px;
                        background-image: url('images/icon_quote.svg');
                        width: 32px;
                        height: 32px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                    p {
                        font-weight: 600;
                    }
                    a {
                        font-weight: 900;
                        color: $blue;
                        position: relative;
                        font-size: 18px;
                        line-height: 1;
                        &::after {
                            content: '';
                            display: block;
                            background-image: url('data:image/svg+xml,<svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.43359 7.29098L1.93359 11.791C1.61719 12.1074 1.125 12.2129 0.703125 12.0371C0.28125 11.8613 0 11.4394 0 10.9824V1.98238C0 1.52535 0.28125 1.13863 0.703125 0.962854C1.125 0.787072 1.61719 0.892541 1.93359 1.20895L6.43359 5.70895C6.89062 6.13082 6.89062 6.8691 6.43359 7.29098Z" fill="%230064C8"/></svg>');
                            background-size: contain;
                            background-repeat: no-repeat;
                            position: absolute;
                            right: -11px;
                            top: 0;
                            width: 7px;
                            height: 11px;
                            top: calc(50% - 4px); 
                            transition: all 0.5s ease;
                        }
                        &:hover::after {
                            right: -16px;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
            .image {
                width: calc(50% - 16px);
                @include media-breakpoint-down(lg){
                    width: 100%;
                    order: 0;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    object-position: center;
                    border-radius: 8px;
                }
            }
        }
    }
}
.m13-like-slider {
    background-color: rgba(0, 100, 200, 0.05);
    padding: 64px 0;
    @include media-breakpoint-down(md){
        margin: 24px 21px;
        padding: 32px 0;
    }
    
    img {
        border-radius: 8px;
    }
    .quote {
        padding: 0 0 0 28px;
        height: 100%;
        position: relative;
        @include media-breakpoint-down(md){
            padding: 0;
        }
        .quote_icon {
            width: 56px;
            height: 56px;
            margin-bottom: 40px;
            background-image: url("images/icon_quote.svg");
            @include media-breakpoint-down(md){
                margin: 32px 0 0;
            }
        }
        p {
            font-size: 22px;
            line-height: 1.5;
            @include media-breakpoint-down(md){
                margin: 28px 0;
            }
        }
        .details {
            img {
                max-width: 150px;
                max-height: 100px;
                width: auto;
                height: auto;
                margin: 15px 0;
            }
            p {
                font-size: 18px;
                margin: 0;
                &:first-of-type {
                    font-weight: 900;
                }
            }
            a {
                color: #0064C8;
                font-weight: 900;
                font-size: 18px;
                display: block;
                @include media-breakpoint-down(md){
                    margin-top: 44px;
                }
                &::after {
                    content: '';
                    margin-left: 6px;
                    background-image: url('data:image/svg+xml,<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.96232 2.00188L12.1068 6.85742L4.96232 11.713L4.96232 2.00188Z" fill="%230064C8"/></svg>');
                    width: 12px;
                    height: 12px;
                    display: inline-block;
                }
            }
        }
    }
}