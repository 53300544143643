.m08 {
    background-color: #e9f0fa;
    padding: 75px 0;
    h2 {
        text-align: center;
        font-weight: 300;
        margin: 0 0 25px;
        font-size: 36px;
        letter-spacing: 1px;
        color: #3d4548;;
    }
    h3 {
        font-weight: 700;
        padding: 15px 10px 10px;
        color: #1d62ad;
        font-size: 17px;
        height: 75px;
        overflow: hidden;  
        letter-spacing: 1px;
        margin: 0 0 8px;
        line-height: 20px;
    }
    p {
        color: #3d4548;
        padding: 0 10px 10px;
        margin: 0 0 16px;
    }
    .row {
      margin-left: -4px;
      margin-right: -4px;
      @include media-breakpoint-down(md) {
          margin-left: -15px;
          margin-right: -15px;
      }
      .col-md-4 {
        padding-left: 4px;
        padding-right: 4px;
        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
        }
        .recent-article {
            background: #FFFFFF;
            padding: 5px;
            box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.05);
            height: 425px;
            overflow: hidden;
            margin-top: 15px;
            }
        }
    }
}