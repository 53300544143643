.m23 {
    .m23_hero {
        background-color: #0064c8;
        // height: 525px;
        height: auto;
        overflow: hidden;
        picture {
            width: 1700px;
            margin: 0 auto;
            display: block;
            @include media-breakpoint-down(md) {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }

    .contact_box {
        margin-top: -185px;
        position: relative;
        z-index: 99;
        @include media-breakpoint-down(lg) {
            margin-top: inherit;
            padding-top: 40px;
        }
        .container {
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
            border-radius: 10px; 
            @include media-breakpoint-down(lg) {
                box-shadow: none;
                border-radius: inherit;
            }
            .row {
                background: #00396B;
                border-radius: 25px 10px 10px 25px;
                .col-lg-9 {
                    background: #FFF;
                    padding: 30px 50px;
                    border-radius: 10px 0 0 10px;
                    &:lang(ar) {
                        border-radius: 0 10px 10px 0;
                    }
                    @include media-breakpoint-down(lg) {
                        padding: 0 15px;

                    }
                    h1 {
                        margin: 0 0 8px;
                        font-size: 36px;
                        color: #1d62ad;
                        font-weight: 300;
                    }
                    p {
                        margin: 0 0 16px;
                        color: #3d4548;
                    }
                    .contact-form {
                        .row {
                            background-color: #FFF;
                        }
                        a {
                            color: #1d62ad;
                            text-decoration: underline;
                            font-weight: 700;
                            cursor: pointer;
                        }
                        .btn {
                            width: 100%;
                            font-weight: 700;
                            cursor: pointer;
                        }
                    }

                }
                .col-lg-3 {
                    position: relative;
                    .contact-sidebar {
                        position: absolute;
                        bottom: 55px;
                        left: 20px;
                        &:lang(ar) {
                            left: inherit;
                            right: 20px;
                        }
                        p {
                            color: #FFF;
                            margin:0;
                            &:first-child {
                                font-weight: 700;
                                margin-bottom: 16px;
                            }
                            &:nth-child(2) {
                                margin-bottom: 16px;
                            }
                            a {
                                color: #FFF;
                            }
                        }
                    }
                }
            }
        }
    }
    .contact-info {
        background: #00396B;
        color: #FFF;
        padding: 40px 0;
        margin-top: 20px;
        p {
            font-size: 16px;
            margin: 0;
            @include media-breakpoint-down(md) {
                text-align: center;
            }
            a {
                color: #FFF;
            }
        }
        @include media-breakpoint-down(md) {
            .contact-info--address {
                p {
                    margin-bottom: 15px;
                }
                
            }
        }
        .col-12 > p {
            font-weight: 700;
            margin: 0 0 16px;
            
        }
    }
    .modal-title {
        line-height: 1.2;
        margin: 0;
        font-size: 18px;
        font-weight: 900;
    }
    .modal-body {
        h2 {
            font-size: 24px;
            font-weight: 900;
            color: #1d62ad;
        }
        p {
            font-weight: 300;
            a {
                color: #1d62ad;
            }
        }
    }
}
.contact-form-container {
    min-height: 288px;
    position: relative;
    @include media-breakpoint-down(md) {
        .row.mb-3 {
            margin-bottom: 0 !important;
            & > div {
                margin-bottom: 1rem;
            }
        }
        label {
            font-size: 16px;
        }
        .btn.btn-lg.blue-btn {
            margin-top: 1rem;
        }
    }
}
// THIS CSS USED FOR ALL FORMS
// .academy-registration-form ,
// .hsfrom {
//     .hsform-error {
//         span {
//             div {
//                 background-color: #dc3545;
//                 margin-bottom: 10px;
//                 padding: 5px 10px;
//                 border-radius: 4px;
//                 color: #FFF;
//                 font-weight: 700;
//             }
//         }
//     }
//     label {
//         a {
//             color: $blue;
//             text-decoration: underline;
//             font-weight: 700;
//         }
//     }
// }
// .form-thanks {
//     text-align: center;
// }
// .form-check:lang(ar) {
//     padding-left: inherit;
//     padding-right: 1.5em;
//     .form-check-input {
//         float: right;
//         margin-left: inherit;
//         margin-right: -1.5em;
//     }
// }
// .form-select:lang(ar) {
//     background-position: left 10px center;
// }