.m47{
    padding: 45px 0;
    color: $black;
    text-align: center;
    h2{
        font-size: 22px;
        font-weight: $black-bold;
        margin-bottom: 30px;
        padding: 12px 15px 0;
        letter-spacing: 0;
        @include media-breakpoint-down(md){
            font-size: 18px;
            padding: 12px 0 0;
        }
    }
    .block{
        //text-align: center;
        .block-icon{
            width: auto;
            height: 85px;
        }
        .block-desc{
            font-size: 22px;
            max-width: 100%;
            padding: 12px 15px 0;
            line-height: 26px;
            margin-top: 0;
            @include media-breakpoint-down(md){
                font-size: 18px;
                padding: 12px 0 0;
            }
        }
    }
}