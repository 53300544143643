.webop-registration {
    // background-color: red;
    background-image: url('images/pr_registration-bg.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    @include media-breakpoint-down(md) {
        background-image: inherit;
    }
    .webop-registration-container {
        padding: 150px 0 215px;
        @include media-breakpoint-down(md) {
            padding: 32px 0;
        }
        h1 {
            font-weight: 900;
            font-size: 50px;
            color: $blue;
            text-align: center;
            margin: 32px 0;
            @include media-breakpoint-down(md) {
                font-size: 40px;
            }
        }
        .indicator {
            width: 222px;
            margin: 28px auto;
            p {
                text-align: center;
                font-size: 16px;
                margin: 0 0 10px;
                &.step_2 {
                    display: none;
                }
            }
            ul {
                height: 7px;
                // background-color: red;
                display: flex;
                gap: 10px;
                li {
                    width: 106px;
                    background-color: #D9E8F7;
                    border-radius: 18px;
                    &:first-child {
                        background-color: #0064C8;
                    }
                }
            }
        }
        &.step2 {
            padding: 0;
            .indicator {
                p {
                    &.step_1 {
                        display: none;
                    }
                    &.step_2 {
                        display: block;
                    }
                }
            }
            ul li {
                background-color: #0064C8;
                &:first-child {
                    display: none;
                }
                &.prev_section {
                    cursor: pointer;
                }
            }
        }
        .webop-registration-form {
            .form-section {
                display: none;
                &.current {
                    display: block;
                }
            }
            .form-check-label a {
                font-weight: 900;
            }
            .form-nav {
                margin: 28px 0 64px;
            }
        }
    }
    .pr-form-loaded.submited {
        position: relative;
        // input, label, .select2, i, button{
        //     opacity: 0.9 !important;
        // }
        .form-nav {
            position: relative;
            .pr-btn-loader {
                position: absolute;
                bottom: -15px;
                right: 0;
                width: 100%;
                .pr-loading {
                    width: 100%;
                    height: 4.8px;
                    display: inline-block;
                    position: relative;
                    background: rgba(0, 0, 0, 0.25);
                    overflow: hidden;
                  }
                  .pr-loading::after {
                    content: '';  
                    box-sizing: border-box;
                    width: 0;
                    height: 4.8px;
                    background: $blue;
                    position: absolute;
                    top: 0;
                    left: 0;
                    animation: animFw 10s linear infinite;
                  }
            }
        }
        &::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
        }
    }
}




  
  @keyframes animFw {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }