.legal-page {
    h1 {
        font-size: 65px;
        color: #0064C8;
        font-weight: 900;
        margin-bottom: 30px;
        @include media-breakpoint-down(md) {
            font-size: 40px;

        }
    }
    h2 {
        font-size: 40px;
        color: #0064C8;
        font-weight: 900;
        margin-bottom: 30px;
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }
    p {
        font-weight: 300;
        a {
            color: #0064C8;
        }
    }
    a {
        color: #0064C8;
    }
    ol {
        padding-left: 25px;
        li {
            font-weight: 300;
            margin: $spacer 0;
            font-size: 18px;
        }
    }
    ul {
        padding-left: 25px;
        li {
            list-style: initial;
            font-weight: 300;
            margin: $spacer 0;
            font-size: 18px;
        }
    }
    table {
        font-weight: 300;
        td,
        th {
            width: 25%;
            border: 1px solid #EEE;
            text-align: left;
            padding: 15px;
        }
    }
}
.imprint-investors {
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-top: 50px;
    padding: 25px 0;
    h2 {
        text-align: center;
        margin: 0;
    }
    img {
        max-width: 250px;
        display: block;
        margin: 25px auto;
        @include media-breakpoint-down(lg) {
            max-width: 100%;
        }
    }
}