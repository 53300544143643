.m87 {
    .blog-hero {
        background-color: $blue;
        padding: 40px 0;
        h1 {
            color: $white;
            font-weight: 900;
            font-size: 70px;
            margin: 0 0 25px;
            @include media-breakpoint-down(md) {
                font-size: 54px;
            }
        }
        h2 {
            color: $white;
            font-size: 27px;
            font-weight: 900;
            margin: 0;
        }
        form {
            max-width: 563px;
            margin-top: 40px;
            position: relative;
            input {
                font-size: 18px;
                font-weight: 700;
                border: inherit;
                border-radius: 8px;
                padding: 12px 10px 11px 40px;
                &::placeholder {
                    color: #B0B3B5;
                }
                &:focus {
                    box-shadow: inherit;
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            input:not(:placeholder-shown) + span,
            input:focus + span {
                color: #000;
            }
            
            span {
                position: absolute;
                left: 14px;
                top: 15px;
                line-height: 1;
                width: 19px;
                height: 20px;
                color: #B0B3B5;
                &::after {
                    content: '\f002';
                    font-family: 'FontAwesome';
                    font-size: 18px;
                    
                }
            }
        }
        &.blog-child {
            padding: 79px 0;
            @include media-breakpoint-down(md) {
                padding: 43px 0;
            }
            h1 {
                margin: 0;
            }
            h2 {
                margin-top: 25px;
                @include media-breakpoint-down(md) {
                    margin-top: 16px;
                }
            }
        }
    }
}