.m23-new {
    margin-bottom: 40px;
    .map{
        height: 600px;
        .gm-style-mtc, .gm-fullscreen-control, .gm-svpc,.gmnoprint{
            display: none !important;
        }
        .gm-style-iw{
            padding: 0!important;
            background-color: #202837;
            color: $white;
            .gm-style-iw-chr {
                display: none;
            }
            .gm-style-iw-d{
                padding: 0 !important;
                background-color: #202837;
                overflow: visible !important;
            }
            .info_content{
                display: flex;
                align-items: center;
                p{
                    padding: 15px 40px;
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                    @include media-breakpoint-down(md){
                        padding: 0 15px;
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }
                img{
                    width: auto;
                    height: 54px;
                    // object-fit: cover;
                    border-radius: 10px 0 0 10px;
                }
            }
        }
        .gm-style {
            .gm-style-iw-tc{
                &:after{
                    background-color: #202837 !important;
                }
            }
        }
        .gm-ui-hover-effect{
            display: none !important;
        }
    }
    .m23_hero {
        height: 600px;
        background: var(--bg) center center no-repeat,linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
        background-size: cover !important;
        background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            background: var(--tabletbg) center center no-repeat,linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
            background-size: cover !important;
        }
        @include media-breakpoint-down(md) {
            background: var(--mobbg) center center no-repeat,linear-gradient(225deg, #0064C8 0%, #003264 100%) !important;
            background-size: cover !important;
        }
        &.has-map{
            position: relative;
            .hero-content{
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 100px;
                @include media-breakpoint-down(lg){
                    h1{
                        text-align: left;
                    }
                }
                @include media-breakpoint-down(md){
                    bottom: auto;
                    left: 0;
                    right: 0;
                    h1{
                        text-align: center;
                        font-size: 50px;
                        line-height: 1.2;
                    }
                }
                @include media-breakpoint-down(xs){
                    h1 {
                        font-size: 42px;
                    }
                }
                
            }
        }
        .hero-content{
            @include media-breakpoint-down(lg){
                height: calc(100% - 110px);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            @include media-breakpoint-down(md){
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;
                text-align: center;
            }
            h1{
                font-size: 90px;
                color: $white;
                // letter-spacing: 3.75px;
                line-height: 108px;
                font-weight: $black-bold;
                margin-bottom: 70px;
                @include media-breakpoint-down(lg){
                    font-size: 60px;
                    line-height: 1 !important;
                    margin-top: 16px;
                    letter-spacing: inherit;
                }
            }
            
        }
    }
    .contact_box{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        background: $white;
        border: 0.5px solid rgba(61,69,72,0.10);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
        border-radius: 10px;
        padding: 50px;
        margin-top: -115px;
        z-index: 9;
        &.staging {
            padding: 32px;
            min-height: 256px;
            align-items: inherit;
        }
        @include media-breakpoint-down(md){
            margin-top: 0;
        }
        @include media-breakpoint-down(sm){
            border-radius: 0;
            padding: 50px 16px;
        }
        .description{
            font-size: 18px;
            color: $black;
            letter-spacing: 0;
            line-height: 1.5;
            font-weight: 600;
            margin-bottom: 30px;
        }
        .m69.form{
            position: relative;
            .hubspot-form{
                position: relative;
                .contact-select-country {
                    @extend .tel_country_flags;                    
                }
                #phone {
                    padding-left: calc(100px + 0.75rem);
                    &:lang(ar) {
                        padding-left: 0.75rem;
                        padding-right: calc(100px + 0.75rem);
                    }
                }
                button[type="submit"]{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    @include media-breakpoint-down(md){
                        position: unset;
                    }
                }
                &:lang(ar) {
                    button[type="submit"] {
                        right: inherit;
                        left: 0;
                    }
                }
            }
        }
        .contact-sidebar{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 60px;
            @include media-breakpoint-down(xl){
                padding-left: 40px;
            }
            &:lang(ar) {
                padding-left: inherit;
                padding-right: 60px;
                @include media-breakpoint-down(xl){
                    padding-left: inherit;
                    padding-right: 40px;
                }
            }
            p{
                font-size: 16px;
                color: $black;
                letter-spacing: 0;
                line-height: 24px;
                font-weight: 400;
                padding-left: 26px;
                &.email{
                    background-image: url(images/icons/email.png);
                    background-size: 18px auto;
                    background-position: left center;
                    background-repeat: no-repeat;
                    &:lang(ar) {
                        direction: ltr;
                        text-align: left;
                        width: 100%;
                    }
                }
                &.phone{
                    background-image: url(images/icons/phone.png);
                    background-size: 18px auto;
                    background-position: left center;
                    background-repeat: no-repeat;
                    &:lang(ar) {
                        direction: ltr;
                        text-align: left;
                        width: 100%;
                    }
                }
            }
        }
        .col-12 {
            .description {
                h2 {
                    font-size: 27px;
                    font-weight: 900;
                    letter-spacing: 0;
                }
            }
            .contact-indactor {
                height: 20px;
                max-width: 222px;
                margin: 32px auto;
                p {
                    text-align: center;
                    margin-bottom: 10px;
                    font-size: 16px;
                    span {
                        font-weight: 900;
                    }
                }
                ul {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    li {
                        width: calc(50% - 5px);
                        height: 7px;
                        background-color: #D9E8F7;
                        border-radius: 18px;
                        &.active {
                            background-color: $blue;
                        }
                    }
                }
            }
            form {
                gap: 0 24px;
                display: flex;
                flex-wrap: wrap;
                .pr-form-group {
                    width: calc(50% - 12px);
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
                .contact-form-footer {
                    width: 100%;
                }
                p.how-help {
                    font-weight: 900;
                    margin-bottom: 18px;
                }
            }
        }
    }
    a.next {
        cursor: pointer;
    }
    .sendto {
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        gap: 0 18px;
        margin-bottom: 32px;
        @include media-breakpoint-down(md) {
            display: block;
        }
        li {
            width: calc(33.33% - 18px);
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-bottom: 18px;
            }
            label {
                display: flex;
                flex-wrap: wrap;
                height: 305px;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid #B1B5B9;
                cursor: pointer;
                @include media-breakpoint-down(md) {
                    height: inherit;
                    padding: 24px;
                }
                div {
                    text-align: center;
                }
                img {
                    height: 104px;
                    width: auto;
                    @include media-breakpoint-down(md) {
                        height: 80px;
                    }
                }
                p {
                    width: 100%;
                    margin: 32px 0 0;
                    font-size: 22px;
                    font-weight: 900;
                    @include media-breakpoint-down(md) {
                        margin: 00;
                        font-size: 12px;
                    }
                }
                &:hover {
                    background-color: #F2F2F2;
                }
            }
            input {
                display: none;
            }
            input:checked + label {
                background-color: #E1E1E1;
            }
        }
    }
    .contact-form-footer > a.next {
        margin-left: auto;
        float: right;
        // display: block;
    }
    .contact-tos-btn {
        display: flex;
        flex-wrap: wrap;
        button {
            margin-left: auto;
            max-height: 48px;
            align-self: center;
        }
    }
}


.tel_country_flags {
    font-size: 1rem;
    background-color: inherit;
    font-weight: 400;
    color: #212529;
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;
    &:lang(ar) {
        right: 0;
        left: inherit;
    }
    button {
        margin-top: 1px;
        margin-left: 1px;
        // border-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0.38rem 0.75rem;
        font-weight: 400;
        border-top: none;
        border-bottom: none;
        border-left-color: transparent;
        &:lang(ar) {
            border-left-color: #ced4da;
            border-right-color: transparent;
            margin-right: 1px;
            margin-left: inherit;
        }
    }
}