.m82{
    padding: 70px 0;
    color: $black;
    @include media-breakpoint-down(md){
        padding: 30px 0;
    }
    &.bg-white{
        .block{
            background-color: #f7f7f7;
        }
    }
    .title{
        font-size: 60px;
        font-weight: $black-bold;
        line-height: 1.2;
        text-align: center;
        margin-bottom: 20px;
        @include media-breakpoint-down(md) {
            font-size: 32px;
            line-height: 36px;
        }
    }
    .subtitle{
        font-weight: 400;
        font-size: 38px;
        line-height: 45px;
        text-align: center;
        color: $black;
        margin-bottom: 125px;
        @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 30px;
        }
    }
    .col-lg-4{
        @include media-breakpoint-down(lg) {
            margin-bottom: 25px;
        }
    }
    .block{
        background-color: #fff;
        height: 100%;
        border-radius: 15px;
        padding: 25px 0 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include media-breakpoint-down(md) {
            padding: 25px 0 0;
        }
        .block-image{
            width: 100%;
            height: auto;
            display: block;
            border-radius: 0 0 15px 15px;
        }
        .content{
            padding: 15px 15px 60px;
            .pretitle{
                font-weight: 300;
                font-size: 14px;
                line-height: 18px;
                color: #717171;
            }
            .block-title{
                font-size: 32px;
                line-height: 38px;
                color: $blue;
                font-weight: 900;
                margin-bottom: 18px;
                padding-left: 0;
                margin-top: 0;
            }
            .block-desc{
                font-size: 22px;
                font-weight: $light;
                line-height: 26px;
            }
            .cta{
                display: block;
                font-weight: $black-bold;
                color: $white;
                font-size: 22px;
            }
        }
    }
}