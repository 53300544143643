.m24 {
    padding: 100px 0 50px;
    @include media-breakpoint-down(lg) {
        padding: 50px 0 25px;
        .container {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            .row {
                --bs-gutter-x: 0;
            }
        }
    }
    h2 {
        text-align: center;
        font-size: 36px;
        letter-spacing: 1.15px;
        font-weight: 300;
        color: #00396B;
        margin-bottom: 75px;
        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
            font-size: 34px;
        }
    }
    h3 {
        border-bottom: 3px solid #E9F0FA;
        padding-bottom: 5px;
        font-size: 16px;
        color: #1D62AD;
        letter-spacing: 1px;
        font-weight: 700;
        @include media-breakpoint-down(lg) {
            padding-left: 40px;
            margin-bottom: 0;
            margin-top: 60px;
            border-bottom: 1px solid #1D62AD;
            &:lang(ar) {
                padding-right: 40px;
                padding-left: inherit;
            }
        }
    }
    .international-contacts_list {
        margin-bottom: 16px;
        color: #3d4548;
        @include media-breakpoint-down(lg) {
            margin-bottom: 0;
        }
        p.international-contacts_country {
            margin: 0 0 4px;
            font-weight: 700;
            @include media-breakpoint-down(lg) {
                border-bottom: 1px solid rgba(0,0,0,.05);
                padding: 15px 40px;
                font-size: 16px;
                margin: 0;
                max-width: inherit;
                position: relative;
                z-index: 99;
                background-color: #FFFFFF;
                &::after {
                    width: 25px;
                    height: 25px;
                    right: 40px;
                    content: "";
                    position: absolute;
                    display: block;
                    top: calc(50% - 12.5px);
                    background-image: url('images/contact_dropdown.png');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: all .25s;
                }
                &:lang(ar)::after {
                    left: 40px;
                    right: inherit;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            p.international-contacts_country.show-country::after {
                transform: rotate(180deg);
            }
        }
        ul {
            list-style: none;
            padding-left: 0;
            @include media-breakpoint-down(lg) {
                visibility: hidden;
                background: rgba(0,0,0,.05);
                max-height: 0;
                padding: 0 40px;
                display: flex;
                flex-wrap: wrap;
                transition: all .2s linear;
                box-shadow: inset 0 8px 8px -10px rgba(0,0,0,.25),inset 0 -8px 8px -10px rgba(0,0,0,.25);

                &.show-cities {
                    visibility: visible;
                    height: auto;
                    max-height: 500px;
                    padding: 30px 40px;
                    transition: max-height .5s linear;
                    position: relative;
                    z-index: 9;
                }
            }
            li {
                position: relative;
                padding-left: 20px;
                @include media-breakpoint-down(lg) {
                    font-size: 16px;
                    display: inline-flex;
                    flex-wrap: wrap;
                    min-width: 150px;
                    order: 1;
                    span {
                        width: 100%;
                    }
                    p {
                        background-color: inherit;
                        font-size: 16px;
                    }
                }
                &:lang(ar) {
                    padding-left: inherit;
                    padding-right: 20px;
                    &::before {
                        left: inherit;
                        right: 0;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: calc(50% - 6.5px);
                    background-image: url(images/email.png);
                    background-size: cover;
                    width: 13px;
                    height: 13px;
                    @include media-breakpoint-down(lg) {
                        top: 2px;
                    }
                }

                a {
                    color: #3d4548;
                    &:hover {
                        color: #3d4548;
                    }
                }
            }
            li.city-contact-info {
                @include media-breakpoint-down(lg) {
                    order: 0;
                }
                span {
                    cursor: pointer;
                }
                
                &::before {
                    background-image: url(images/pin.png);
                    height: 17px;
                    top: calc(50% - 8.5px);
                    @include media-breakpoint-down(lg) {
                        top: 2px
                    }
                    
                }
                div.city-contact-info-box {
                    display: none;
                    position: absolute;
                    bottom: 25px;
                    left: -25%;
                    background-color: #FFF;
                    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
                    border-radius: 10px;
                    padding: 25px 30px;
                    z-index: 9999;
                    border: 1px solid rgba(61,69,72,.05);
                    min-width: 220px;
                    p {
                        font-size: 16px;
                    }
                    @include media-breakpoint-down(lg) {
                        display: block !important;
                        position: inherit;
                        background-color: inherit;
                        box-shadow: inherit;
                        border: inherit;
                        padding: 0;
                        min-width: inherit;
                        left: inherit;
                        bottom: inherit;
                        margin: 0;
                        p {
                            padding: 0;
                            border-bottom: none;
                            margin-bottom: 5px
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 0;
                        margin-left: -.5em;
                        bottom: -16px;
                        left: 50%;
                        box-sizing: border-box;
                        border: 8px solid #000;
                        border-color: transparent transparent #FFF #FFF;
                        transform-origin: 0 0;
                        transform: rotate(-45deg);
                        box-shadow: -3px 2px 4px 0 rgba(0,0,0,.1);
                        @include media-breakpoint-down(lg) {
                            content: inherit;
                        }
                    }
                    p.city-contact-info-box--title {
                        color: #1D62AD;
                        font-weight: 700;
                        margin: 0;
                        @include media-breakpoint-down(lg) {
                            border-bottom: none;
                            margin-top: 25px;
                            color: #3d4548;
                            font-weight: 400;
                        }
                    }
                    p.city-contact-info-box--address {
                        margin-bottom: 15px;
                        font-weight: 400;
                        max-width: 200px;
                    }
                    p.city-contact-info-box--email {
                        font-weight: 400;
                        &::before {
                            content: "@";
                            padding-right: 5px;
                            @include media-breakpoint-down(lg) {
                                content: inherit;
                            }
                        }
                    }
                    p.city-contact-info-box--phone {
                        position: relative;
                        font-weight: 400;
                        padding-left: 15px;
                        @include media-breakpoint-down(lg) {
                            padding-left: 0;
                        }
                        &::before {
                            background-image: url('images/phone.svg');
                            content: "";
                            width: 15px;
                            height: 15px;
                            display: inline-block;
                            background-size: cover;
                            background-position: left center;
                            position: absolute;
                            left: 0;
                            top: 3px;
                            @include media-breakpoint-down(lg) {
                                content: inherit;
                            }
                        }
                    }
                    .close_office_details {
                        position: absolute;
                        top: 0;
                        right: 7px;
                        font-size: 24px;
                        color: #d8dada !important;
                        z-index: 999;
                        cursor: pointer;
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}