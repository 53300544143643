footer{
    padding: 50px 0 0;
    font-size: 14px;
    .footer-menus{
        padding: 30px 0;
        margin-bottom: 20px;
        @include media-breakpoint-down(md) {
            padding: 5px 0 30px;
        }
        .menu-title{
            font-size: 14px;
            color: $blue;
            font-weight: $black-bold;
            @include media-breakpoint-down(lg) {
                margin: 25px 0 5px;
            }
        }
        .menu{
            ul{
                li{
                    a{
                        color: $black;
                        font-weight: $regular;
                        &:hover{
                            color: $light-blue;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
        
        .lang-switcher{
            float: right;
        }
        .footer-logo{
            margin-top: 30px;
            float: right;
            @media (max-width: $page-break-max) {
                float: none;
            }
        }
    }
    .footer-bottom{
        background-color: $blue;
        //background-image: linear-gradient(225deg,$blue 0%,#003264 100%);
        height: 180px;
        vertical-align: middle;
        padding-top: 30px;
        .social-networks{
            a{
                padding: 0 14px;
                @media (max-width: $page-break-max) {
                    padding: 0;
                }
                &:hover{
                    opacity: 0.7;
                }
            }
        }
        .copy-right{
            font-size: 16px;
            color: #ffffff99;
            font-weight: $bold;
            padding-top: 20px;
            line-height: 23px;
        }
    }
}
.staging{
    footer{
        padding: 0;
        .footer-menus{
            padding: 30px 0;
            margin-bottom: 0;
            @include media-breakpoint-down(md) {
                padding: 43px 0 56px;
            }
        }
        .footer-bottom{
            height: auto;
            padding: 30px 0;
        }
    }
}