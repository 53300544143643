.questionnaire {
    .hero {
        background-color: #E5EFF9;
        padding: 64px 0;
        p {
            text-align: center;
            font-size: 16px;
            font-weight: 900;
            margin-bottom: 32px;
            line-height: 1;
            span {
                background-color: #FFF;
                border-radius: 4px;
                padding: 8px 12px;
            }
        }
        h1 {
            color: $blue;
            text-align: center;
            font-weight: 900;
            margin: 0;
            line-height: 1.15;
            font-size: 50px;
            letter-spacing: 0;
            @include media-breakpoint-down(md){
                text-align: left;
                font-size: 40px;
            }
        }
    }

    .questions {
        padding: 64px 0;
        h2 {
            font-size: 27px;
            text-align: center;
            font-weight: 900;
            line-height: 1.25;
            margin-bottom: 64px;
            letter-spacing: 0;
            @include media-breakpoint-down(md){
                text-align: left;
            }
        }
        .questions-list {
            max-width: 752px;
            margin: 64px auto;
            p {
                font-weight: 900;
                margin-bottom: 4px;
                &.error {
                    color: #F00;
                }
            }
            .pr-form-check {
                margin: 16px 0;
                label {
                    font-size: 18px;
                }
            }

        }
        .questions-nav {
            text-align: center;
        }
        .indicator {
            p {
                text-align: center;
                margin: 0 0 10px;
            }
            ul {
                display: flex;
                gap: 10px;
                justify-content: center;
                li {
                    background-color: rgba($color: $blue, $alpha: 0.15);
                    width: 106px;
                    height: 7px;
                    border-radius: 18px;
                    &.done {
                        background-color: $blue;
                    }
                }
            }
        }

    }
    .results {
        padding: 64px 0;
        @include media-breakpoint-down(md){
            padding: 32px 0;
        }
        h2 {
            font-size: 27px;
            text-align: center;
            font-weight: 900;
            line-height: 1.25;
            margin-bottom: 48px;
            letter-spacing: 0;
        }
        .questions-results {
            .answers {
                padding: 20px 0;
                &:last-of-type {
                    margin: 0;
                }
                p {
                    font-size: 18px;
                    line-height: 1.5;
                }
                label {
                    font-size: 18px;
                }
                p.q {
                    font-weight: 900;
                    margin-bottom: 20px;
                }
                .pr-form-check {
                    margin: 0 0 16px;
                }
                p.a {
                    margin: 0;
                }
            }
        }
    }
    .content {
        .sidebar {
            padding: 32px 20px;
            background-color: #F8F8F8;
            text-align: center;
            border-radius: 8px;
            h3 {
                font-size: 27px;
                font-weight: 900;
                line-height: 1.25;
            }
            .item {
                margin: 48px 0;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .blue {
                    margin: 20px 0;
                    font-size: 40px;
                    font-weight: 900;
                    @include media-breakpoint-down(md){
                        margin: 10px 0 0;
                        font-size: 32px;
                    }
                }
                .black {
                    font-size: 27px;
                    font-weight: 900;
                    margin: 0;
                    @include media-breakpoint-down(md){
                        font-size: 22px;
                    }
                }
            }
        }
        .text {
            padding: 0 0 0 70px;
            @include media-breakpoint-down(md){ 
                padding: 32px 0;
            }
            h2 {
                margin-bottom: 64px;
                font-weight: 900;
                font-size: 27px;
                line-height: 1.25;;
            }
            p {
                line-height: 1.5;
            }
        }
    }
    .form {
        margin: 64px 0;
        @include media-breakpoint-down(md){
            margin: 0;
        }
        h2 {
            font-size: 40px;
            font-weight: 900;
            line-height: 1.15;
            margin-bottom: 0;
            color: $blue;
            @include media-breakpoint-down(md){
                font-size: 32px;
            }
        }
        h3 {
            font-size: 40px;
            line-height: 1.25;
            font-weight: 900;
            margin-bottom: 48px;
            @include media-breakpoint-down(md){
                font-size: 32px;
            }
            span {
                color: $blue;
            }
            @include media-breakpoint-down(md){
                text-align: left;
            }
        }
        h4 {
            // text-align: center;
            font-weight: 900;
            font-size: 22px;
            line-height: 1.25;
        }
        .col-lg-6 {
            display: flex;
            flex-wrap: wrap;
            .ebook-img {
                align-self: flex-end;
                background-color: #F2F7FC;
                border-radius: 8px;
                padding: 40px;
                @include media-breakpoint-down(lg){
                    width: 100%;
                    margin-top: 32px;
                    align-self: inherit;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .hs-form {
            background-color: #F8F8F8;
            padding: 32px;
            border-radius: 8px;
            position: relative;
            margin-left: 72px;
            @include media-breakpoint-down(lg){
                margin-left: 0;
                width: 100%;
                margin-top: 64px;
                padding: 16px;
            }
            p.hs-form-title {
                margin: 0 0 32px;
                font-size: 27px;
                font-weight: 900;
            }
            button.btn.btn-lg.green-btn {
                @extend .pr-btn;
                @extend .pr-secondary-btn;
                width: 100%;
            }
        }
    }
}