.m44{
    padding: 40px 0;
    color: $black;
    &.text-center{
        text-align: center;
        .row{
            flex-direction: column;
        }
        h2{
            font-size: 43px;
            margin: 75px 0 30px;
            @include media-breakpoint-down(md) {
                font-size: 32px;
                letter-spacing: -1px;
                margin: 50px 0 30px;
            }
        }
        h3{
            font-size: 24px;
            font-weight: 700; 
            color: #3d4548;
            @include media-breakpoint-down(md) {
                font-size: 18px;
            }
        }
        // .cta{
        //     font-size: 16px;
        // }
    }
    &.blue-background{
        position: relative;
        color: $white !important;
        &::before{
            content: " ";
            position: absolute;
            width: 100%;
            background-image: linear-gradient(225deg, #0064C8 0%, #003264 100%);
            height: 70%;
            top: 0;
            z-index: -1;
            left: 0;
            right: 0;
        }
        h2,h3{
            color: $white !important;
        }
        .container{
            z-index: 99;
        }
    }
    img{
        width: auto;
        height: auto;
        margin-bottom: 20px;
        max-height: 100px;
        max-width: 200px;
    }
    h2{
        font-size: 35px;
        font-weight: $black-bold;
        &.big{
            font-size: 66px;
            @include media-breakpoint-down(md) {
                font-size: 36px;
            }
        }
        &.light{
            font-weight: $light;
        }
    }
    h3{
        font-size: 22px;
        font-weight: $black-bold;
        line-height: 30px;
        margin-bottom: 30px;
    }
    p{
        font-weight: $light;
        font-size: 18px;
        b,strong{
            font-weight: bolder;
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    ul {
        list-style: disc;
        padding-left: 25px;
        margin-bottom: 1rem;
        li {
            font-weight: $light;
            font-size: 18px;
        }
    }
    .cta{
        max-width: 380px;
        margin: 15px auto;
        display: block;
        @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 300px;
        }
    }
    .video{
        width: 100%;
    }
    video,iframe,.plyr--video,.plyr--vimeo{
        display: block;
        margin: 0 auto;
        width: 640px;
        min-height: 300px;
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }
    .plyr--video,.plyr--vimeo{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            z-index: 99;
        }
        &.plyr--stopped{
            .plyr__controls {
                opacity: 0;
                pointer-events: none;
                transform: translateY(100%);
            }
        }
    }
    .image{
        width: 100%;
        img{
            width: 640px;
            height: auto;
            margin: 0 auto;
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
    }
}