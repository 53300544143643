.m36 {
    background-color: #F7F7F7;
    padding: 67px 0 70px;
    h2 {
        color: #0064C8;
        margin-bottom: 18px;  
    }
    select.department-select {
        display: block;
        margin: 0 auto 25px;
        padding: 10px 0 11px 15px;
        appearance: none;
        border: 1px solid #E6E6E6;
    }
    .mtt-row {
        margin-left: -6px;
        margin-right: -6px;
        @include media-breakpoint-down(lg) {
            margin-right: 0;
            margin-left: 0;
        }
        .mtt-col {
            padding-left: 6px;
            padding-right: 6px;
            position: relative;
            z-index: 8;
            margin-bottom: 50px;
            cursor: pointer;
        }
    }
    .mtt-box {
        background-color: #FFFFFF;
        box-shadow: 2px 3px 10px 0 rgba(0,0,0,0.3);
        border-radius: 10px;
        img {
            display: block;
            border-radius: 10px 0 0 10px;
            border-right: 1px solid rgba(0,0,0,0.1);
            max-height: 412px;
            width: 100%;
            object-fit: cover;
            object-position: top;
            @include media-breakpoint-down(xl) {
                max-height: 345px;
            }
        }
    }
    .mtt_quote {
        // font-size: 30px;
        font-size: 24px;
        font-weight: 900;
        font-style: italic;
        color: #000000;
        padding: 30px 20px 0;
        line-height: 38px;
        position: relative;
        z-index: 9;
        margin: 0;
        @include media-breakpoint-down(lg) {
            font-size: 14px;
            line-height: 18px;
        }
        &::before {
            content: "";
            width: 50px;
            height: 50px;
            background-image: url("images/quotes.svg");
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            transform: rotate(180deg);
            position: absolute;
            filter: invert(100%) sepia(92%) saturate(83%) hue-rotate(171deg) brightness(109%) contrast(85%);
            top: 5px;
            left: 5px;
            z-index: -9;
            margin: 0;
        }
    }
    .mtt_name {
        position: absolute;
        bottom: 15px;
        padding: 30px 20px 0;
        p {
            margin: 0;
            font-weight: 300;
            strong {
                font-weight: 700;
            }
            @include media-breakpoint-down(lg) {
                font-size: 16px;
            }
        }
    }
    .mtt-popup {
        padding-left: 6px;
        padding-right: 6px;
        position: absolute;
        z-index: 9;
        @include media-breakpoint-down(lg) {
            position: fixed;
            width: calc(100% - 12px);
            height: calc(100% - 50px);
            top: 50px;
            margin-top: 40px;
            max-width: 694px;
            margin: 50px auto 0;
            width: 100%;
        }
        @include media-breakpoint-down(md) {
            max-width: 516px;

        }
        @include media-breakpoint-down(sm) {
            max-width: calc(100% - 24px);
        }
        .row {
            background-color: #FFFFFF;
            box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.3);
            border-radius: 12px;
            height: 500px;
            overflow: hidden;
            @include media-breakpoint-down(lg) {
                height: inherit;
                overflow-y: auto;
                .col-lg-6.mtt-popup-img {
                    max-height: 350px;
                    overflow: hidden;
                    position: relative;
                    .mtt-popup_close.mobile-close {
                        right: 15px;
                    }
                    img {
                        width: 100%;
                        max-width: 540px;
                        margin: 0 auto;
                    }
                }
            }
            
            .col-lg-6.mtt-popup-col {
                position: relative;
                &::before {
                    content: "";
                    width: 60px;
                    height: 60px;
                    background-image: url("images/quotes.svg");
                    background-size: contain;
                    filter: invert(100%) sepia(92%) saturate(83%) hue-rotate(171deg) brightness(109%) contrast(85%);
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: 5px;
                    transform: rotate(180deg);
                }
                .mtt-popup-txt {
                    padding: 10px 90px 11px 15px;
                    overflow-y: auto;
                    max-height: 360px;
                    margin: 43px 8px 0 0;
                    position: relative;
                    @include media-breakpoint-down(lg) {
                        overflow-y: hidden;
                        max-height: inherit;
                        padding-right: 15px;
                    }
                }
            }
        }
        img {
            border-radius: 12px 0 0 12px;
            display: block;
            border-right: 1px solid rgba(0,0,0,0.1);
        }
        .mtt-popup-txt {
            p {
                margin: 0;
                font-size: 14px;
                font-weight: 300;
                strong {
                    font-weight: 900;
                }
            }
        }
    }
    .department-filter {
        margin-bottom: 25px;
        .btn-depfilter {
            padding: 0 15px;
            font-size: 18px;
            font-weight: 300;
            &.active {
                font-weight: 900;
            }
            &:focus {
                box-shadow: none;
            }
        }
    }
}
.mtt-popup_close,
.mtt-popup_open {
    background-image: url("images/plus.svg");
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    transform: rotate(0deg);
    z-index: 9;
}
.mtt-popup_close {
    transform: rotate(45deg);
}
@include media-breakpoint-down(lg) {
    body.mtt-popup-opened {
        overflow: hidden;
        &::before {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 9;
            content: '';
        }
    }
}