.m65{
    padding: 45px 0;
    h2{
        color: $blue;
        font-size: 40px;
        font-weight: $black-bold;
        margin-top: inherit;
        padding-left: 0;
        line-height: 1.5;
    }
    .list{
        margin-top: 30px;
        .list-item{
            display: flex;
            align-items: center;
            margin: 12px 0;
            @include media-breakpoint-down(md){
                flex-direction: column;
                text-align: center;
            }
            img{

            }
            p{
                width: calc(100% - 75px);
                margin-left: 25px;
                font-weight: $black-bold;
                font-size: 22px;
                margin-bottom: 0;
            }
        }
    }
    .connect-steps{
        padding: 40px 0;
        @include media-breakpoint-down(md){
            padding: 40px 0 0;
        }
        .steps{
            counter-reset: my-sec-counter;
            padding-left: 100px;
            @include media-breakpoint-down(lg){
                padding-left: 25px;
            }
          .step{
                display: flex;
                align-items: center;
                margin: 30px 1px 5px 12px;
                padding: 10px;
                border-radius: 8px;
                box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
                background-color: #fff;
                position: relative;
                &:after{
                counter-increment: my-sec-counter;
                content: counter(my-sec-counter);
                font-size: 22px;
                font-weight: 900;
                line-height: 1.14;
                color: #000;
                position: absolute;
                left: -24px;
                }
                &:not(:first-child){
                margin: 85px 1px 5px 12px;
                &::before{
                    content: '';
                    position: absolute;
                    width: 9px;
                    height: 23px;
                    object-fit: contain;
                    background-image: url('images/icons/down-arrow.svg');
                    background-repeat: no-repeat;
                    background-position: bottom;
                    top: -32px;
                    right: 0;
                    left: 28px;
                }
                .step-title{
                    padding-top: 26px;
                    top: -100px;
                    &::before{
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 20px;
                    object-fit: contain;
                    background-color: #000;
                    // border: solid 1px #000;
                    top: 0;
                    right: 0;
                    left: 0;
                    margin: 5px auto;
                    }
                }
                }
                &:last-child{
                margin: 45px 1px 5px 12px;
                &::before{
                    height: 31px;
                    top: -35px;
                }
                }
                .step-title{
                font-size: 22px;
                font-weight: 900;
                line-height: 1.14;
                color: #000;
                position: relative;
                position: absolute;
                display: inline-block;
                top: -70px;
                left: -5px;
                margin-top: 15px;
                &:not(:first-child){
                    padding-top: 26px;
                    &::before{
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 20px;
                    object-fit: contain;
                    background-color: #000;
                    border: solid 1px #000;
                    top: 0;
                    right: 0;
                    left: 0;
                    margin: 5px auto;
                    }
                }
                }
                .icon{
                img{
                    margin: 0;
                    width: 50px;
                    height: auto;
                }
                }
                p{
                margin: 21px 10px 20px 22px;
                font-size: 18px;
                font-weight: 300;
                line-height: 24px;
                color: #000;
                max-width: 100%;
                text-align: left;
                }
            }
        }
    }
}