.m46{
    padding: 40px 0;
    color: $black;
    h2{
        font-size: 22px;
        font-weight: $black-bold;
        margin-bottom: 50px;
    }
    // .form{
    //     form{
    //         padding: 0;
    //         color: #000;
    //         input{
    //             height: 40px;
    //             margin: 0 12px 12px 0 !important;
    //             width: 100%;
    //             &[name="firstname"],&[name="lastname"]{
    //                 margin: 0 0 12px 0 !important;
    //             }
    //             &[type="checkbox"]{
    //                 width: 20px;
    //                 height: 20px;
    //                 border-radius: 2px;
    //                 border: solid 1px #ced4da;
    //             }
    //             &[type="submit"]{
    //                 width: 273px;
    //                 height: 50px;
    //                 font-size: 22px;
    //                 font-weight: 900;
    //                 line-height: 50px;
    //                 text-align: center;
    //                 color: #fff;
    //                 border-radius: 8px;
    //                 background-color: $green;
    //                 padding: 0;
    //                 margin: 30px 0 0 0;
    //                 &:hover{
    //                     background-color: $dark-green;
    //                 }
    //             }
    //         }
    //         select{
    //             height: 40px;
    //             margin: 0 12px 12px 0;
    //             padding: 6px 9px;
    //             border-radius: 5px;
    //             border: solid 1px #ced4da;
    //             background-color: #fff;
    //             font-size: 18px;
    //             font-weight: 300;
    //             width: 100%;
    //         }
    //         ul{
    //             li{
    //                 label{
    //                     display: block;
    //                 }
    //             }
    //         }
    //         .form-check-label{
    //             font-weight: $medium;
    //         }
    //         .wpcf7-list-item{
    //             margin: 0;
    //         }
    //         a{
    //             color: $blue;
    //             &:hover{
    //                 text-decoration: underline;
    //             }
    //         }
    //         .wpcf7-list-item-label{
    //             font-size: 0;
    //         }
    //         .hs-error-msgs{
    //             padding: 0 0 0 17px;
    //             margin-top: 0;
    //             list-style-type: disc;
    //             position: relative;
    //             margin-bottom: 16px;
    //             color: red;
    //             li{
    //                 padding: 0;
    //                 top: -9px;
    //                 background-color: transparent;
    //                 display: list-item;
    //                 position: absolute;
    //                 width: calc(100% - 8px);
    //                 border-radius: 4px;
    //                 height: 28px;
    //                 margin: 0;
    //                 label{
    //                     font-size: 12px;
    //                     color: #ea4e51;
    //                     margin: 0;
    //                     @include media-breakpoint-down(md){
    //                         font-size: 10px;
    //                     }
    //                 }
    //             }
    //         }
    //         .hs_error_rollup{
    //             margin-top: 40px;
    //         }
    //         .inputs-list:not(.hs-error-msgs){
    //             padding: 0;
    //             margin-bottom: 0;
    //         }
    //     }
    // }
    h3{
        font-size: 22px;
        font-weight: $black-bold;
    }
    p{
        font-size: 18px;
        font-weight: $light;
    }
    .salesman{
        margin: 50px auto 0;
        text-align: center;
        img{
            border-radius: 50%;
            width: 250px;
            height: auto;
        }
        p{
            margin: 0;
        }
    }
}