.w02 {
    width: 100%;
    max-width: 376px;
    margin: 0 auto 30px;
    @include media-breakpoint-down(md) {
        margin: 30px auto;
    }
    img {
        border-radius: 12px 12px 0 0;
        display: block;
        margin: 0 !important;
        width: 100%;
        height: auto;
    }
    .w02-details {
        background-color: $blue;
        border-radius: 0 0 12px 12px;
        padding: 20px;
        h2 {
            margin: 0 0 30px !important;
            color: #fff !important;
            font-size: 22px !important;
            font-weight: 900 !important;
        }
        a {
            width: 100%;
            color: #fff !important;
            font-weight: 900;
            font-size: 18px;
            padding: 8px 0 9px;
        }
    }
}