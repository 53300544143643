.m91 {
    background-color: #F7F7F7;
    padding: 80px 0;
    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }
    p {
        margin: 0;
        &.title {
            font-size: 54px;
            font-weight: 900;
            line-height: 60px;
            margin-bottom: 24px;
            @include media-breakpoint-down(md) {
                font-size: 27px;
                margin-bottom: 12px;
                line-height: 1.2;
            }
        }
        &.subtitle {
            font-size: 18px;
            font-weight: 300px;
        }
    }
    .blog-newsletter {
        position: relative;
        @include media-breakpoint-down(lg) {
            margin-top: 30px;
        }
        form {
            display: flex;
            gap: 12px;
            @include media-breakpoint-down(md) {
                display: block;
            }
            input {
                max-width: 350px;
                width: 100%;
                padding: 10px 14px;
                font-size: 18px;
                font-weight: 700;
                color: #000;
                margin-bottom: 0;
                border-radius: 8px;
                &::placeholder {
                    color: #B0B3B5;
                }
                &:focus {
                    box-shadow: inherit;
                    border-color: #000;
                }
                &:not(:placeholder-shown) {
                    border-color: #000;
                }
                &.is-invalid {
                    background-image: inherit;
                    color: #FF0000;
                    border-color: #FF0000;

                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 12px;
                    max-width: inherit;
                }
            }
            button.pr-btn {
                max-width: 200px;
                width: 100%;
                // font-weight: 900;
                // font-size: 22px;
                // text-align: center;
                // border-radius: 8px;
                // line-height: inherit;
                @include media-breakpoint-down(md) {
                    max-width: inherit;
                    padding: 14px 0;
                }
            }
        }
        p {
            text-align: center;
            &.error {
                text-align: left;
                margin-bottom: 8px;
                color: #FF0000;
                font-size: 14px;
                font-weight: 700;
            }
        }
        .form-thanks {
            display: flex;
            gap: 30px;
            align-items: center;
            @include media-breakpoint-down(lg) {
                display: block;
            }
            i {
                color: #28965A;
                font-size: 64px;
            }
            p {
                text-align: left;
                font-size: 22px;
                font-weight: 900;
                @include media-breakpoint-down(lg) {
                    text-align: center;
                }
                span {
                    display: block;
                    font-size: 27px;
                }
            }
        }
    }

}