.m41 {
    text-align: center;
    background-color: #F7F7F7;
    padding: 71px 0 102px;
    h2 {
        margin: 0;
    }
    a {
        color: $blue;
        &:hover {
            text-decoration: underline;
        }
    }
    img {
        width: 180px;
        height: auto;
        box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.3);
        border-radius: 8px;
        margin: 47px auto 0;
    }
}