.m85 {
    background-color: #F7F7F7;
    padding: 60px 0 30px;
    h2 {
        color: $blue;
        text-align: center;
        margin: 20px;
        @include media-breakpoint-down(md) {
            br {
                display: none;
            }
        }
    }
    .vt-title{
        background-color: #FFF;
        margin: 35px 0;
        padding: 15px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 700;
        width: fit-content;
        @include media-breakpoint-down(md) {
            width: 100%;
            margin: 20px 0;
        }
        &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 5px;
            background-color: transparent;
            border-radius: 8px 0 0 0;
            left: 0;
            top: -5px;
        }
        &.vt-active {
            font-size: 22px;
            font-weight: 900;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
            position: relative;
            transition: all .35s ease;
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: #c7c7c7;
                border-radius: 8px 8px 0 0;
                left: 0;
                top: -5px;
            }
            &::after {
                background-color: #28965A;
                width: 100%;
                // transition: width var(--duration) linear;
                animation-name: greenLine;
                animation-duration: var(--duration);
                animation-play-state: paused;
            }
            &.vt-play::after {
                animation-play-state: running;
            }
            @keyframes greenLine {
                from {width: 0%;}
                to {width: 100%;}
              }
        }
    }
    video {
        max-width: 100%;
    }
    &.m85-new {
        background-color: #FFF;
        h2 {
            color: #000;
        }
        .m85-icons-slider {
            margin-top: 48px;
            width: 100%;
            padding: 0 71px;
            @include media-breakpoint-down(md) {
                padding: 0;
                .slick-list{padding:0 30% 0 10% !important;}
            }
            .slick-track {
                display: flex !important;
                //gap: 35px;
                @include media-breakpoint-up(xl) {
                    transform: inherit !important;
                   // gap: 96px !important;
                }
                @include media-breakpoint-up(md) {
                    transform: inherit !important;
                    //gap: 74px;
                }
                .slick-slide{
                    margin: 0 48px;
                    &:first-child{
                        margin-inline-start: 24px;
                    }
                    &:last-child{
                        margin-inline-end: 24px;
                    }
                    @include media-breakpoint-up(md) {
                        margin: 0 37px;
                    }
                }
                .slick-slide:not(.slick-active) {
                    opacity: 0.5;
                }
            }
            div {
                text-align: center;
                img {
                    width: 25px;
                    height: 25px;
                    margin: 0 auto;
                    object-fit: contain;
                    object-position: center;
                    margin-bottom: 20px;
                }
                p{
                    max-width: 160px;
                }
            }
        }
        .vt-title {
            display: none;
            box-shadow: inherit;
            &.vt-active {
                display: flex;
                align-items: center;
                &::before, &::after {
                    content: none;
                }
                img {
                    width: 37px;
                    height: 37px;
                    display: block;
                    margin-right: 15px;
                    &:lang(ar) {
                        margin-left: 15px;
                        margin-right: 0;
                    }
                }
                p {
                    margin-bottom: 0;
                    font-weight: 900;
                }
            }
        }
    }
}