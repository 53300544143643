.m04 {
    margin-top: 50px;
    h2 {
        font-size: 32px;
        color: #3d4548;
        font-weight: 300;
        letter-spacing: 1px;
        text-align: center;
    }
    h3 {
        font-size: 17px;
        font-weight: 700;
        color: #1d62ad;
        letter-spacing: 1px;
        text-align: center;
      }
    p {
        color: #3d4548;
        text-align: center;
        font-size: 16px;
    }
}