.m50{
    background-image: linear-gradient(225deg, #0064C8 0%, #003264 100%);
    padding: 40px 0;
    text-align: center;
    h2{
        font-size: 35px;
        color: $white;
        font-weight: $black-bold;
    }
    .box-slider{
        .slide{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: $white;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 40px;
            margin: 0 15px;
            h3{
                font-size: 14px;
                font-weight: $bold;
                color: $blue;
                letter-spacing: 0.62px;
                text-align: left;
                min-height: 35px;
            }
            p{
                font-size: 16px;
                color: #3d4548;
                line-height: 48px;
                font-weight: $bold;
            }
            // a{
            //     border-color: $blue;
            //     color: $blue;
            //     font-weight: $bold;
            // }
        }
        .slick-next{
            &:hover{
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 75%);
            }
            &:before {
                content: "→";
                font-family: $lato;
            }
        }
        .slick-prev{
            &:hover{
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 75%);
            }
            &:before {
                content: "←";
                font-family: $lato;
            }
        }
        .slick-dots {
            bottom: -35px;
            li {
                button {
                    &:before {
                        font-size: 16px;
                        color: $white;
                        opacity: 0.75;
                    }
                }
                &.slick-active{
                    button{
                        &:before {
                            color: #0064C8;
                        }
                    }
                }
            }
        }
    }
}