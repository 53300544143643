.m62{
    padding: 50px 0;
    color: $black;
    &.bg-gradient{
        background-image: linear-gradient(-225deg, #0064C8 0%, #003264 100%) !important;
        .hbspt-form{
            background-color: transparent !important;
        }
    }
    // .form{
    //     label{
    //         a{
    //             color: $blue;
    //         }
    //     }
    // }
    &.bg-white {
        .hbspt-form {
            padding: 25px;
            background: #f7f7f7;
            border-radius: 5px;
        }
    }
    h2{
        font-size: 40px;
        font-weight: $black-bold;
        margin-bottom: 10px;
        line-height: 1.2;
        margin-top: 0;
        &.text-center{
            color: $black;
        }
    }
    h3{
        font-size: 22px;
        font-weight: $black-bold;
        margin-bottom: 43px;
        line-height: 1.5;
    }
    h2 + p {
        font-size: 18px;
        font-weight: $regular;
        margin-bottom: 33px;
        margin-top: 18px;
        line-height: 1.5;
    }
    // ul, ol {
    //     padding-left: 16px;
    //     li {
    //         font-size: 18px;
    //         margin-bottom: 16px;
    //         &:last-child {
    //             margin-bottom: 0;
    //         }
    //     }
    // }
    // .form{
    //     label{
    //         a{
    //             color: $white;
    //         }
    //     }
    //     button[type="submit"]{
    //         width: auto;
    //         padding: 7px 30px;
    //         border-radius: 4px;
    //     }
    //     span{
    //         p{
    //             display: inline-block;
    //         }
    //     }
    // }
    .image{
        width: 100%;
        img{
            width: 100%;
            height: auto;
            display: block;
            margin: auto;
        }
    }
    &.m62-hero {
        padding: 32px 0 64px;//64px 0; //105px 0 65px;
        @include media-breakpoint-down(md) {
            padding: 32px 0;//50px 0 20px;
        }
        &.bg-gray{
            margin-top: 32px;
            // .hs_submit {
            //     input.hs-button.primary.large {
            //         @extend .pr-btn;
            //         @extend .pr-primary-btn;
            //         display: block;
            //         width: 100%;
            //         // line-height: inherit;
            //         // height: inherit;
            //         // font-size: 22px;
            //         // font-weight: 900;
            //         // padding: 12px 0;
            //         // border-radius: 8px;
            //     }
            // }
        }
        h1 {
            font-size: 65px;
            color: $blue;
            font-weight: 900;
            @include media-breakpoint-down(md) {
                font-size: 40px;
            }
        }
        h2 {
           font-size: 32px;
           font-weight: 900; 
           &.title_above_content{
            font-size: 40px;
           }
           @include media-breakpoint-down(md) {
            font-size: 26px;
           }
        }
        img {
            max-width: 420px;
            margin: inherit;
            @include media-breakpoint-down(xl) {
                margin: 0 auto 50px;
            }
        }
        .col-xl-6 {
            height: 100%;
        }
        .m69-form-container {
            position: relative;
            z-index: 1;
            // padding: 60px 50px;
            padding: 24px 40px;
            border-radius: 8px;
            border: 1px solid var(--lines-strokes-medium-grey, #B1B5B9);
            @include media-breakpoint-down(md) {
                // padding: 30px 25px;
                padding: 16px 28px;
            }
            .form-error {
                font-size: 18px;
                color: #FF0000;
                a {
                    font-weight: 900;
                    cursor: pointer;
                } 
           }
            // .wpcf7-form{
            //     display: flex;
            //     flex-wrap: wrap;
            //     justify-content: space-between;
            //     &#network-partner-form{
            //         p{
            //             &:nth-child(6),&:nth-child(11),&:nth-child(12){
            //                 width: 100%;
            //             }
            //             &:nth-child(7),&:nth-child(8),&:nth-child(13),&:nth-child(14){
            //                 width: calc( 50% - 10px);
            //             }
            //         }
            //     }
            //     p{
            //         display: inline-block;
            //         width: calc( 50% - 10px);
            //         margin: 12px 0;
            //         @include media-breakpoint-down(md) {
            //             width: 100%;
            //         }
            //         &:nth-child(6),&:nth-child(7),&:nth-child(8),&:nth-child(13),&:nth-child(14){
            //             width: 100%;
            //         }
            //         label{
            //             width: 100%;
            //             font-size: 18px;
            //             font-weight: $black-bold;
            //             &.form-check-label{
            //                 font-size: 14px;
            //                 font-weight: 400;
            //                 a{
            //                     text-decoration: underline;
            //                     font-weight: 600;
            //                 }
            //                 .wpcf7-list-item {
            //                     margin: 0 10px 0 0;
            //                 }
            //                 .wpcf7-list-item-label{
            //                     font-size: 0;
            //                 }
            //             }
            //         }
            //         .wpcf7-submit{
            //             width: 100%;
            //             font-weight: $black-bold;
            //             font-size: 22px;
            //             line-height: 37px;
            //         }
            //     }
            //     [data-class="wpcf7cf_group"]{
            //         width: 100%;
            //         p{
            //             width: 100%;
            //         }
            //     }
            //     &.invalid{
            //         .wpcf7-not-valid-tip{
            //             font-size: 16px;
            //         }
            //     }
            //     &.init{
            //         .wpcf7-not-valid-tip{
            //             font-size: 0;
            //         }
            //     }
            //     .wpcf7-spinner{
            //         height: 0 !important;
            //         display: none !important;
            //     }
            //     form.submitting {
            //         .wpcf7-spinner{
            //             height: 24px !important;
            //             display: inline-block !important;
            //         }
            //     }
            // }
            .thanks-container {
                position: relative;
                padding: 70px 0;
                p {
                    margin-bottom: 350px;
                    font-size: 34px;
                    font-weight: 900;
                    @include media-breakpoint-down(md) {
                        font-size: 28px;
                        text-align: center;
                    }
                }
                // .btn {
                //     min-width: 270px;
                //     margin: auto;
                //     display: block;
                //     font-weight: 900;
                //     font-size: 18px;
                // }
            }
            // &:before {
            //     content: "";
            //     position: absolute;
            //     width: calc(100% + 10px);
            //     height: calc(100% + 5px);
            //     display: block;
            //     background: rgba(0, 0, 0, 0.1);
            //     border-radius: 35px;
            //     filter: blur(45px);
            //     left: -20px;
            //     top: -5px;
            //     z-index: -2;
            // }
            &::after {
                content: "";
                width: 100%;
                height: 100%;
                background-color: #FFF;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                border-radius: 8px;
            }
            h3 {
                // font-size: 24px;
                // padding-bottom: 35px;
                // border-bottom: 1px solid #55565A1F;
                // margin-bottom: 30px;
                font-size: 27px;
                padding: 0;
                margin: 0 0 12px;
                border: none;
            }
            .reloading-form.m69 {
                .hbspt-form {
                    background-color: inherit;
                    padding: 0; 
                }

                .hs-richtext {
                    p {
                        // margin-top: 20px;
                        // margin-bottom: -10px;
                        span {
                            font-family: 'Lato', sans-serif;
                            strong {
                                font-weight: 900;
                                br {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                ul label {
                    font-size: 14px;
                    a {
                        font-weight: 400;
                        color: $black;
                        
                    }
                }
                // .hs_submit {
                //     input.hs-button.primary.large {
                //         @extend .btn;
                //         @extend .green-btn;
                //         display: block;
                //         width: 100%;
                //         line-height: inherit;
                //         height: inherit;
                //         font-size: 18px;
                //         font-weight: 900;
                //         padding: 10px 0;
                //     }
                // }

            }


            
            @keyframes trophy {
                0% {
                    transform: translateY(500px);
                    opacity: 0;
                }
                35% {
                    opacity: 0;
                }
                100% {
                    transform: translateY(0px);
                    opacity: 1;
                }
            }
            
            @keyframes fly--up {
                0% {
                    transform: translateY(10px);
                    opacity: 0;
                }
                60% {
                    opacity: 1;
                }
                80% {
                    transform: translateY(-20vw);
                }
                100% {
                    transform: translateY(-15vw);
                    opacity: 0;
                }
            }
            
            @keyframes fly--down {
                0% {
                    transform: translateY(-10px);
                    opacity: 0;
                }
                60% {
                    opacity: 1;
                }
                80% {
                    transform: translateY(20vw);
                }
                100% {
                    transform: translateY(15vw);
                    opacity: 0;
                }
            }
            
            @keyframes fly--left {
                0% {
                    transform: translateX(10px);
                    opacity: 0;
                }
                60% {
                    opacity: 1;
                }
                80% {
                    transform: translateX(-35vw);
                }
                100% {
                    transform: translateX(-180px);
                    opacity: 0;
                }
            }
            
            @keyframes fly--right {
                0% {
                    transform: translateX(-10px);
                    opacity: 0;
                }
                60% {
                    opacity: 1;
                }
                80% {
                    transform: translateX(35vw);
                }
                100% {
                    transform: translateX(180px);
                    opacity: 0;
                }
            }
            
            @keyframes fly--up--left {
                0% {
                    transform: rotate(135deg) translate(0vw,0vw);
                    opacity: 0;
                }
                60% {
                    opacity: 1;
                }
                100% {
                    transform: rotate(135deg) translate(-3vw,20vw);
                    opacity: 0;
                }
            }
            
            @keyframes fly--up--right {
                0% {
                    transform: rotate(45deg);
                    opacity: 0;
                }
                60% {
                    opacity: 1;
                }
                100% {
                    transform: rotate(45deg) translate(-3vw,-20vw);
                    opacity: 0;
                }
            }
            
            @keyframes fly--down--left {
                0% {
                    transform: rotate(45deg) translate(0vw,0vw);
                    opacity: 0;
                }
                60% {
                    opacity: 1;
                }
                100% {
                    transform: rotate(45deg) translate(-3vw,20vw);
                    opacity: 0;
                }
            }
            
            @keyframes fly--down--right {
                0% {
                    transform: rotate(135deg) translate(0vw,0vw);
                    opacity: 0;
                }
                60% {
                    opacity: 1;
                }
                100% {
                    transform: rotate(135deg) translate(0vw,-20vw);
                    opacity: 0;
                }
            }
            


            
            .trophy {
                animation: trophy 0.5s 1 forwards;
                background-color: #F8F8F8;
                border-radius: 100%;
            }
            
            .action {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: auto;
                transform: translate(0%,0%);
                max-height: 200px;
                // height: 25vw;
                max-width: 200px;
                // width: 25vw;

                %confetti {
                    z-index: -1;
                    position: absolute;
                    height: 50px;
                    width: 10px;
                    border-radius: 10px;
                    animation-fill-mode: forwards;
                    animation-duration: 0.75s;
                    animation-iteration-count: 1;
                    transform-origin: center middle;
                    opacity: 0;
                }
                .confetti {
                    @extend %confetti;
                    top: 0;
                    left: calc(50% - 5px);
                    background-color: #28965A;
                    animation-name: fly--up;
                    animation-delay: 0.35s;
                    &.two {
                        top: auto;
                        bottom: 0;
                        animation-name: fly--down;
                    }
                    &.three,&.four {
                        top: calc(50% - 5px);
                        left: calc(50% - 25px);
                        height: 10px;
                        width: 50px;
                        animation-name: fly--left;
                    }
                    &.four {			
                        animation-name: fly--right;
                    }
                    &--purple {
                        @extend %confetti;
                        background-color: $blue;
                        animation-name: fly--up--left;
                        transform: rotate(135deg);
                        animation-delay: .5s;
                        left: 20%;
                        top: 20%;
                        &.two {
                            animation-name: fly--up--right;
                            left: auto;
                            right: 20%;
                            transform: rotate(45deg);
                        }
                        &.three {
                            top: auto;
                            bottom: 20%;
                            transform: rotate(45deg);
                            animation-name: fly--down--left;
                        }
                        &.four {
                            top: auto;
                            bottom: 20%;
                            left: auto;
                            right: 20%;
                            transform: rotate(135deg);
                            animation-name: fly--down--right;
                        }
                    }
                }
            }            
        }
    }
    &.lp-template{
        padding: 65px 0; 
        h1{
            font-size: 50px;
            padding-bottom: 25px;
        }
        h2{
            font-size: 25px;
        }
    }
    // a {
    //     color: $blue;
    // }
    ol {
        padding: 0 0 0 17px;
        li {
            font-size: 18px;
            margin-bottom: 8px;
        }
    }
    ol.us-list {
        counter-reset: list;
        padding: 0;
        li {
            list-style: none;
            &::before {
                content: counter(list) "). ";
                counter-increment: list;
            }
        }
    }
    ul.arrow-list {
        padding: 0 0 0 30px;
        li {
            margin-bottom: 16px;
            position: relative;
            font-size: 18px;
            &::before {
                content: "";
                background-image: url('data:image/svg+xml,<svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="22" aria-hidden="true"><g id="Check Circle1_layer"><path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></g></svg>');
                filter: invert(47%) sepia(31%) saturate(842%) hue-rotate(95deg) brightness(95%) contrast(94%);
                width: 22px;
                height: 22px;
                left: -30px;
                position: absolute;
                top: 5px;
            }
        }
    }

    .m69-form-container {
        min-height: 256px;
        // form.hubspot-form.hsfrom {
        //     // p {
        //     //     margin: 0 0 5px;
        //     // }
        //     // .form-check-label a {
        //     //     color: $blue;
        //     //     text-decoration: underline;
        //     //     cursor: pointer;
        //     // }
        //     .bootstrap-select.form-control {
        //         .bs-searchbox {
        //             input {
        //                 padding: 5px 5px !important;
        //             }
        //         }
        //         ul {
        //             max-height: 350px;
        //             li {
        //                 margin: 0;
        //                 a {
        //                     color: #000000;
        //                     &.active {
        //                         background-color: #f9f9f9;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     .btn.green-btn {
        //         width: 100%;
        //         font-size: 22px;
        //         // max-width: 300px;
        //         font-weight: 900;
        //         margin: 25px auto 0;
        //     }
        // }
    }
    &.m62-middle {
        padding: 64px 0;
        @include media-breakpoint-down(md) {
            padding: 48px 0;
        }
        h2 {
            text-align: center;
            margin: 0 0 32px;
            letter-spacing: 0;
            @include media-breakpoint-down(md) {
                text-align: left;
                font-size: 32px;
            }
        }
        p.subtitle {
            text-align: center;
            margin: 0 0 48px;
            @include media-breakpoint-down(md) {
                text-align: left;
            }
        }
        .hbspt-form {
            background-color: #FFF;
            box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.20);
            margin-bottom: 64px;
            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
            }
        }
        .footer-txt p {
            margin: 32px 0 0;
            font-size: 16px;
            strong, b {
                font-weight: 900;
            }
            a {
                text-decoration: underline;
            }
            @include media-breakpoint-down(md) {
                margin: 24px 0 0;
            }
        }
    }

    .hs-embeded-form {
        .thanks-container {
            margin: 60px 0;
            p {
                display: none;
            }
            .action {
                top: 0;
            }
        }
    }
}