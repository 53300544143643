.m77 {
    h2 {
        color: $blue;
        font-size: 50px;
        font-weight: 900;
        letter-spacing: .69px;
        margin: 0;
    }
    p {
        margin: 0;
        &.dl_title {
            margin-bottom: 7px;
            font-size: 26px;
            font-weight: 900;
        }
        &.dl_button {
            background-color: $blue;
            margin: 0;
            font-size: 16px;
            font-weight: 900;
            color: #fff;
            padding: 8px 0;
            border-radius: 0 0 6.5px 6.5px;
            text-align: center;
        }
        &.dl-text {
            max-width: 925px;
            margin-bottom: 50px;
        }
    }

    img {
        display: block;
    }
    h2 {
        margin: 70px 0 0;
    }
    .brand {
        h2 {
            margin: 50px 0 70px;
        }
    }
    .downloads {
        padding-top: 35px;
        a {
            width: 50%;
            display: block;
        }
        .col-md-4.col-6 {
            margin-bottom: 80px;
        }
        
    }
    .visuals {
        margin-left: -5px;
        margin-right: -5px;
        .col-md-2.col-4 {
            padding-left: 5px;
            padding-right: 5px;
            a {
                display: block;
                margin-bottom: 10px;
                p {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .visual-items {
        padding-top: 35px;
    }
    .downloads-items {
        padding: 35px  0;
    }

}

