.m88 {
    margin: 60px 0 80px;
    @include media-breakpoint-down(md) {
        margin: 30px 0;
    }
    .featured-post {
        max-width: 563px;
        margin-top: 55px;
        @include media-breakpoint-down(lg) {
            margin: 0 0 20px;
            overflow: hidden;
            border-radius: 8px 8px 0 0;
            max-width: inherit;
        }
        a {
            img {
                border-radius: 8px 8px 0 0;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
                @include media-breakpoint-down(md) {
                    height: 275px;
                    width: auto;
                    max-width: inherit;
                    margin-left: 50%;
                    transform: translateX(-50%);
                }
            }
            .featured-post-content {
                background-color: #F7F7F7;
                border-radius: 0 0 8px 8px;
                padding: 25px 30px;
                    @include media-breakpoint-down(md) {
                        padding: 15px 24px 20px;
                    }
                h3 {
                    font-size: 27px;
                    font-weight: 900;
                }
                p {
                    margin: 0 0 20px;
                    font-size: 14px;
                    font-weight: 900;
                    background-color: $blue;
                    color: $white;
                    padding: 4px 12px;
                    border-radius: 4px;
                    display: inline-block;
                }
            }
            &:hover h3 {
                color: $black;
            }
        }
    }
    .popular-posts {
        h3 {
            font-size: 27px;
            font-weight: 900;
            margin: 0;
        }
        .popular-post {
            border-bottom: 1px solid #DCDCDC;
            padding: 22px 0;
            &:last-child {
                border: none;
            }
            a {
                display: flex;
                align-items: center;
                gap: 20px;
                @include media-breakpoint-down(md) {
                    align-items: start;
                }
                img {
                    width: 106px;
                    height: auto;
                    border-radius: 8px;
                    @include media-breakpoint-down(md) {
                        width: 88px;
                    }
                }
                .popular-post-content {
                    p {
                        font-size: 18px;
                        font-weight: 300;
                        margin: 0;
                        strong {
                            font-size: 22px;
                            font-weight: 900;
                        }
                    }
                }
                &:hover .popular-post-content p {
                    color: $black;
                }
            }
        }
    }
}