.m01c{
    background-color: $blue;
    padding: 40px 0;
    @include media-breakpoint-down(md){
        padding: 32px 0;
    }
    .hero{
        color: $white;
        text-align: center;
        .pre-title{
            margin: 0;
            line-height: inherit;
            span {
                font-weight: 400;
                font-size: 18px;
                line-height: 36px;
                background: rgba(234, 234, 234, 0.2);
                border-radius: 6px;
                padding: 5px 10px;
            }
        }
        .title{
            font-weight: 800;
            font-size: 70px;
            line-height: 90px;
            margin: 42px auto 64px;
            letter-spacing: inherit;
            @include media-breakpoint-down(md){
                font-size: 30px;
                line-height: 33px;
                margin: 18px auto 36px;
                max-width: 90%;
            }
        }
        .subtitle{
            font-size: 24px;
            line-height: 1.2;
        }
        .features{
            display: flex;
            justify-content: center;
            align-items: center;
            @include media-breakpoint-down(md){
                flex-wrap: wrap;
                flex-direction: column;
                max-width: 240px;
                margin: 0 auto;
            }
            .feature{
                padding: 0 34px;
                @include media-breakpoint-down(md){
                    display: flex;
                    align-items: center;
                    padding: 0 !important;
                    justify-content: flex-start;
                    width: 100%;
                    margin-bottom: 20px;
                }
                &:first-child{
                    padding: 0 34px 0 0;
                }
                &:last-child{
                    padding: 0 0 0 34px;
                }
                img{
                    height: 81px;
                    width: auto;
                    margin-bottom: 42px;
                    @include media-breakpoint-down(md){
                        height: 51px;
                        margin-bottom: 0;
                        margin-right: 15px;
                    }
                }
                h3{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 21px;
                    @include media-breakpoint-down(md){
                        text-align: start;
                    }
                }
            }
        }
        .pr-btn{
            margin: 69px auto 44px;
            @include media-breakpoint-down(md){
                margin: 36px auto;
            }
        }
        .app-stores{
            display: flex;
            justify-content: start;
            opacity: 0.8;
            // a{
            //     padding: 0 24px;
            // }
            @include media-breakpoint-down(md){
                opacity: 1;
                a {
                    width: 100%;
                    max-width: 375px;
                }
            }
        }
        &.has-form{
            text-align: unset;
            @include media-breakpoint-down(md){
                text-align: center;
            }
            .pre-title{

            }
            .title{
                font-size: 54px;
                line-height: 64px;
                margin: 40px 0 30px;
                @include media-breakpoint-down(md){
                    font-size: 32px;
                    line-height: 36px;
                    margin: 24px 0 37px;
                    max-width: 100%;
                }
            }
            .features{
                align-items: flex-start;
                justify-content: flex-start;
                @include media-breakpoint-down(md){
                    flex-direction: row;
                    max-width: 100%;
                    gap: 0 32px;
                    justify-content: center;
                }
                .feature{
                    padding: 0 14px;
                    text-align: center;
                    @include media-breakpoint-down(md){
                        width: auto;
                        display: inline-block;
                    }
                    &:first-child{
                        padding: 0 14px 0 0;
                    }
                    h3{
                        font-size: 18px;
                        line-height: 21px;
                        @include media-breakpoint-down(md){
                            text-align: center;
                        }
                    }
                    img{
                        height: 47px;
                        margin-bottom: 24px;
                        @include media-breakpoint-down(md){
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .m69-form-container{
        background-color: #fff;
        padding: 47px 43px 21px;
        border-radius: 14px;
        @include media-breakpoint-down(lg){
            padding: 29px 30px 15px;
            border-radius: 10px;
        }
        .title{
            border-bottom: 0.6px solid rgba(85, 86, 90, 0.1);
            padding-bottom: 13px;
            margin-bottom: 11px;
            @include media-breakpoint-down(lg){
                padding-bottom: 16px;
                margin-bottom: 8px;
            }
            h3{
                font-size: 22px;
                line-height: 33px;
                font-weight: 900;
                @include media-breakpoint-down(lg){
                    text-align: center;
                }
            }
            p{
                font-size: 13px;
                line-height: 22px;
                margin-bottom: 0;
                @include media-breakpoint-down(lg){
                    text-align: center;
                }
            }
        }
        p{
            margin-bottom: 0;
            font-size: 11px;
            font-weight: 600;
        }
        input[type="submit"]{
            display: block;
            width: 100%;
            border-radius: 6px;
        }
        .hs-form-field{
            margin: 12px 0;
            input,select{
                margin: 0 !important;
                font-size: 11px;
                vertical-align: bottom;
            }
        }
        label{
            display: none;
        }
        ul{
            &.hs-error-msgs{
                margin-top: 0;
                li{
                    label{
                        font-size: 10px;
                        color: #cf2319;
                        font-weight: 400;
                    }
                }
            }
            padding: 0;
            li{
                margin-top: 0;
                label{
                    display: block;
                    margin-bottom: 0;
                    &.hs-error-msg{
                        font-size: 10px;
                        color: #cf2319;
                        font-weight: 400;
                    }
                    span{
                        color: #000;
                        margin-inline-start: 10px;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 15px;
                        a{
                            color: #000;
                        }
                    }
                }
            }
        }
        .submitted-message{
            p{
                font-size: 14px;
                color: green;
                text-align: center; 
            }
        }
    }
    &.m72.m72-lp {
        background-color: $blue;
        .title {
            color: $white;
        }
        h3 {
            color: #fff;
        }
        .book-demo-form {
            background-color: #fff;
            padding: 20px 35px;
            border-radius: 8px;
            position: relative;
            z-index: 9;
            @include media-breakpoint-down(lg) {
               margin: 5px auto 0;
               .mb-md-0 {
                margin-bottom: 1rem;
               }
            }
            .contact-select-country {
                height: 100%;
                button {
                    height: 100%;
                    margin: 0 !important;
                    background-color: transparent;
                    .filter-option-inner {
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                }
            }

            .m72-form-title {
                font-size: 23px;
                margin-bottom: 20px;
                line-height: normal;
            }
            $medium-grey: #B1B5B9;
            $light-grey: #F8F8F8;
            $white: #ffffff;

            // input:not([type='checkbox']){
            //     color: #000;
            //     padding: 12px 14px;
            //     border-radius: 8px;
            //     border: 1px solid $border-color-light;
            //     background: $white;
            //     font-size: 18px;
            //     font-weight: $regular;//$bold;
            //     line-height: 1.25;
            // }
            // select{
            //     color: #000;
            //     width: 100%;
            //     padding: 12px 14px;
            //     border-radius: 8px;
            //     border: 1px solid $border-color-light;
            //     background-color: $white;
            //     background-image: url(images/icons/select-arrow.svg);
            //     background-repeat: no-repeat;
            //     background-position: 97% center;
            //     background-size: 10px;
            //     appearance: none;
            //     font-size: 18px;
            //     font-weight: $regular;//$bold;
            //     line-height: 1.25;
            // }
            //   a {
            //     color: $blue;
            //     text-decoration: underline;
            //   }
        }
    }
    
}