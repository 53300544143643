.m31{
    padding: 60px 0;
    h2{
        color: $blue;
        margin: 0;
        font-size: 34px;
        letter-spacing: 0;
    }
    .green-btn{
        font-weight: $black-bold;
        font-size: 22px;
        .live & {
            padding: 7px 30px;
        }
    }
    .team{
        margin: 30px auto 0;
        margin-left: -7.5px;
        margin-right: -7.5px;
        position: relative;
        .col-team-member{
            padding-left: 6px;
            padding-right: 6px;
            margin-bottom: 15px;
            .team-member{
                position: relative;
                box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                height: 100%;
                padding: 10px;
                cursor: pointer;
                text-align: center;
                @include media-breakpoint-down(lg) {
                    box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.1);
                }
                .team-member-img {
                    width: 100%;
                    height: 130px;
                    background-position: center bottom;
                    background-size: contain;
                    background-repeat: no-repeat;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                }
                // img{
                //     height: 130px;
                //     margin: 0 auto;
                //     border-bottom: 1px solid rgba(0,0,0,0.1);
                //     @include media-breakpoint-down(xl) {
                //         width: 100%;
                //         height: auto;
                //     }
                // }
                p{
                    font-size: 18px;
                    color: $black;
                    margin: 0;
                    line-height: 1.4;
                    &.name{
                        font-weight: $light;
                    }
                    &.title{
                        font-weight: $bold;
                        min-height: 51px;
                    }
                }
                .box_open{
                    width: 20px;
                    height: 20px;
                    background-image: url(images/icons/open_close.svg);
                    background-size: cover;
                    display: block;
                    top: 10px;
                    right: 15px;
                    position: absolute;
                }
            }
        }
        .team-member-info{
            position: absolute;
            width: calc(75% - 12px);
            height: 709px;
            background: $white;
            border-radius: 10px;
            z-index: 9;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
            left: calc(25% + 6px);
            &:nth-child(n+17):nth-child(-n+25) {
                top: 240px;
            }
            &:nth-child(n+25):nth-child(-n+33) {
                top: 482px;
            }
            &:nth-child(n+33) {
                top: 723px;
            }
            @include media-breakpoint-down(lg) {
                height: 784px;
                &:nth-child(n+17):nth-child(-n+25) {
                    top: 267px;
                }
                &:nth-child(n+25):nth-child(-n+33) {
                    top: 510px;
                }
                &:nth-child(n+33) {
                    height: 755px;
                    top: 800px;
                }
            }
            @include media-breakpoint-down(md) {
                position: fixed;
                z-index: 9999;
                background-color: rgba(0,0,0,0.6);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0;
                &:nth-child(n+17):nth-child(-n+25) {
                    top: 0;
                }
                &:nth-child(n+25):nth-child(-n+33) {
                    top: 0;
                }
                &:nth-child(n+33) {
                    top: 0;
                }
            }
            .box-container{
                background-color: $white;
                position: relative;
                overflow: hidden;
                @include media-breakpoint-down(md) {
                    height: calc(100% - 160px) !important;
                    max-width: 510px;
                    background: #FFFFFF;
                    margin: 80px auto;
                    color: #000000;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            .info{
                color: $black;
                // padding-left: 0;
                // padding-right: 10px;
                padding: 30px 0px 30px 10px;
                @include media-breakpoint-down(md) {
                    padding-left: 10px;
                }
                h3{
                    font-size: 30px;
                    font-weight: $black-bold;
                }
                img{
                    @include media-breakpoint-down(md) {
                        max-height: 275px;
                    }
                }
                .bio{
                    // height: calc(100% - 220px);
                    height: 500px;
                    overflow: auto;
                    padding-top: 20px;
                    max-width: 95%;
                    @include media-breakpoint-down(md) {
                        // overflow: unset;
                        margin-top: 20px;
                        // height: 320px;
                        height: auto;
                        max-height: 200px;
                        padding-bottom: 50px;
                    }
                    h4{
                        font-size: 18px;
                        font-weight: $black-bold;
                        margin-top: 0;
                    }
                    p{
                        font-size: 16px;
                        margin-top: 0;
                        margin-bottom: 1rem;
                        color: #212529;
                        line-height: 1.5;
                    }
                    a{
                        color: $blue;
                        &:hover{
                            text-decoration: underline;
                            color: $light-blue;
                        }
                        @include media-breakpoint-down(lg) {
                            font-size: 16px;
                        }
                    }
                }
            }
            .box_close{
                width: 20px;
                height: 20px;
                background-image: url(images/icons/open_close.svg);
                background-size: cover;
                display: block;
                top: 10px;
                right: 0px;
                position: absolute;
                transform: rotate(45deg);
                cursor: pointer;
                @include media-breakpoint-down(md) {
                    width: 30px;
                    height: 30px;
                    top: 35px;
                    right: 10px;
                }
            }
        }
    }
}