.m71{
    margin: 75px auto 0;
    @include media-breakpoint-down(lg) {
        display: none;
    }
    .cta{
        border-radius: 12px;
        border: 1px solid rgba(61,69,72,.25);
        box-shadow: 0 5px 10px -5px rgba(61, 69, 72, 0.25);
        padding: 45px;
        margin: 0;
        h2{
            font-size: 28px;
            color: #3D4548;
            font-weight: $bold;
            margin: 0;
            @include media-breakpoint-down(xl) {
                text-align: center;
            }
        }
        .pr-btn{
            width: 100%;
            box-shadow: 0 6px 6px -2px rgba(61, 69, 72, 0.25);
            // font-size: 16px;
            // font-weight: $bold;
            // padding: 10px 15px;
            max-width: 360px;
            margin: 0 auto;
            display: block;
            // &.green-btn{
            //     color: $white !important;
            // }
            @include media-breakpoint-down(xl) {
                text-align: center;
                margin-top: 45px;
            }
        }
    }
}