header.cs-hero {
    padding: 64px 0;
    @include media-breakpoint-down(md) {
        padding: 32px 0;
    }
    .title {
        font-weight: 900; 
        margin-bottom: 24px;
        font-size: 18px;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
        span {
            background-color: #F8F8F8;
            padding: 8px 12px;
            border-radius: 4px;
        }
    }
    .cs-logo {
        background-color: #F2F7FC;
        display: flex;
        width: 156px;
        height: 90px;
        align-items: center;
        justify-content: center;
        border-radius: 3.5px;
        margin-bottom: 24px;
        @include media-breakpoint-down(md) {
            width: 90px;
            height: 51px;
            margin: 0 auto 32px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
        }
    }
    p {
        font-size: 27px;
        font-weight: 900;
        line-height: 1.5;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            line-height: 1.25;
            text-align: center;
        }
        &.quote {
            color: $blue;
            margin-bottom: 32px;
        }
        &.postion {
            font-weight: 300;
            font-size: 22px;
            @include media-breakpoint-down(md) {
                margin-bottom: 32px;
            }
        }
        
    }
    img {
        max-width: 505px;
        max-height: 402px;
        height: auto;
        width: auto;
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: auto;
        }
    }
    .plyr--video.plyr--youtube {
        &::before {
            content: none;
        }
        .plyr__poster {
            background-size: cover;
            background-image: var(--plyr-video-background) !important;
        }
    }
    .plyr--video,.plyr--vimeo{
        position: relative;
        width: 505px;
        height: auto; //402px;
        max-height: 402px;
        border-radius: 8px;
        @include media-breakpoint-down(lg) {
            width: 100%;
            height: auto;
        }
        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            background-image: var(--plyr-video-background) !important;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            opacity: 1 !important;
            z-index: 1;
        }
        &.plyr--playing, &.plyr--paused:not(.plyr--stopped){
            &:before{
                opacity: 0 !important;
            }
            .plyr__control--overlaid {
                opacity: 0;
                visibility: hidden;
            }
        }
        &.plyr--stopped{
            .plyr__controls {
                opacity: 0;
                pointer-events: none;
                transform: translateY(100%);
            }
        }
    }
}
header.cs-hero,
.csVideosCarousel{
    .plyr--full-ui.plyr--video .plyr__control--overlaid {
        background-image: url('images/icon_play.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}
@include media-breakpoint-down(md) {
    .csVideo {
        background-color: #F8F8F8;
    }
}
.csVideosCarousel {
    padding: 64px 0;
    @include media-breakpoint-down(md) {
        padding: 32px 0 64px;
    }
    h2 {
        font-size: 40px;
        font-weight: 900;
        text-align: center;
        margin: 0;
        @include media-breakpoint-down(md) {
            font-size: 36px;
            text-align: left;
        }
    }
    p {
        margin: 32px 0;
        text-align: center;
        font-size: 22px;
        @include media-breakpoint-down(md) {
            font-size: 16px;
            text-align: left;
        }
    }
    .plyr {
        border-radius: 8px;
    }

    .carousel-indicators {
        [data-bs-target] {
            background-color: #E1E1E1;
            border: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            opacity: 1;
        }
        .active {
            background-color: $blue;
        }
    }
    .carousel-control-prev, .carousel-control-next {
        opacity: 1;
        bottom: 64px;
        height: 628px;
        top: inherit;
        @include media-breakpoint-down(lg) {
            height: 50%;
            bottom: 32px;
        }
    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background-image: url('images/arrow-fill.svg');
        opacity: 1;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }
    .carousel-control-next-icon{
        transform: rotate(90deg);
    }
    .carousel-control-prev-icon {
        transform: rotate(-90deg);
    }
    &.playing {
        .carousel-control-prev, .carousel-control-next {
            display: none;
        }
    }
}
.cs-content {
    margin-top: 64px;
    margin-bottom: 64px;
    @include media-breakpoint-down(md) {
        margin-top: 32px;
        margin-bottom: 32px;
    }
    aside {
        .cs-sidebar {
            position: sticky;
            top: 70px;
            @include media-breakpoint-down(md) {
                position: inherit;
                top: inherit;
                margin-bottom: 64px;
            }
        }
        .cs-stats-widget {
            background-color: #F8F8F8;
            padding: 32px 20px;
            border-radius: 8px;
            &:not(:last-of-type) {
                margin-bottom: 48px;
            }
            .title {
                font-size: 27px;
                font-weight: 900;
                margin-bottom: 48px;
                width: 100%;
            }
            .stats img {
                width: 42px;
                height: 36px;
                object-fit: contain;
                object-position: center;
            }
            &.stats_v {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .stats {
                    width: 33.33%;
                    flex: 0 0 auto;
                    p {
                        font-weight: 900;
                        margin: 0;
                    }
                    p.numbers {
                        margin: 14px 0;
                        color: $blue;
                    }
                }
            }
            
            &.stats_1 {
                .stats {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    &:not(:last-of-type) {
                        margin-bottom: 22px;
                    }
                    img {
                        margin-right: 20px;
                    }
                    div {
                        display: flex;
                        p {
                            margin: 0;
                            font-size: 18px;
                            font-weight: 900;
                            &.numbers {
                                color: $blue;
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
            &.stats_2 {
                .stats {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    &:not(:last-of-type) {
                        margin-bottom: 32px;
                    }
                }
                img {
                    margin-right: 20px;
                }
                div {
                    width: calc(100% - 62px);
                    p {
                        margin: 0;
                        font-weight: 900;
                        &.numbers {
                            color: $blue;
                        }
                    }
                }
            }
            .btn {
                display: block;
                margin: 0;
                font-size: 22px;
                font-weight: 900;
                width: 100%;
                border-radius: 8px;
                &:first-of-type {
                    margin-top: 48px;
                    margin-bottom: 24px;
                }
            }
        }
        .cs-quote-widget {
            background-color: #E5EFF9;
            padding: 48px 20px;
            border-radius: 8px;
            margin-bottom: 48px;
            .quote {
                width: 56px;
                height: 56px;
                display: block;
                margin: 0 0 40px;
                background-image: url('images/icon_quote.svg');
            }
            p {
                font-size: 22px;
                strong {
                    font-weight: 900;
                }
                &:first-of-type {
                    margin-bottom: 32px;
                }
            }
        }
    }
    article {
        margin-bottom: 64px;
        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
        }
        .content {
            padding-left: 70px;
            @include media-breakpoint-down(md) {
                padding-left: 0;
            }
            h3 {
                font-size: 27px;
                font-weight: 900;
                margin: 64px 0;
                @include media-breakpoint-down(md) {
                    margin: 32px 0;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
            p {
                line-height: 1.5;
                margin-bottom: 32px;
            }
            img {
                max-width: 100%;
                height: auto;
                border-radius: 8px;
            }
            ul{
                list-style-type: disc;
                padding: 0 0 20px 40px;
                @include media-breakpoint-down(md) {
                    padding: 0 0 20px 20px;
                }
                li{
                    line-height: 27px;
                    font-size: 18px;
                    
                }
            }
            a {
                color: $blue;
                text-decoration: underline;
                &:hover {
                    color: $black;
                }
            }
        }
    }
    .cs-cta {
        display: flex;
        justify-content: center;
        gap: 24px;
        margin: 32px 0;
        @include media-breakpoint-down(md) {
            display: block;
            margin-top: 0;
        }
        .btn {
            font-size: 22px;
            border-radius: 8px;
            font-weight: 900;
            min-width: 267px;
            @include media-breakpoint-down(md) {
                display: block;
                width: 100%;
                margin: 0;
                &:first-child {
                    margin-bottom: 32px;
                }
            }
        }
    }
    .cs-solutions {
        padding: 64px 0;
        @include media-breakpoint-down(md) {
            padding: 32px 0 64px;
        }
        h2 {
            font-size: 40px;
            text-align: center;
            margin-bottom: 12px;
            @include media-breakpoint-down(md) {
                font-size: 34px;
            }
        }
        p {
            font-size: 27px;
            margin-bottom: 48px;
            text-align: center;
        }
        .cs-solutions-slider {
            display: flex;
            @include media-breakpoint-down(md) {
                .slick-list {
                    padding: 0 20% 0 0 !important;
                }
            }
            .slick-track {
                display: flex;
                height: 100%;
            }
            .cs-solutions-slide {
                margin-right: 36px;
                height: 100%;
                &:last-of-type {
                    margin-left: 0;
                }
                @include media-breakpoint-down(md) {
                    margin-right: 12px;
                    &:not(.slick-active) {
                        opacity: 0.2;
                    }
                }
            }
            .slick-dots {
                bottom: -50px;
                li {
                    margin: 0;
                    // background-color: red;
                    width: 24px;
                    height: 24px;
                    button {
                        width: 24px;
                        height: 24px;
                        &::before {
                            width: 24px;
                            height: 24px;
                            font-size: 16px;
                            color: #E1E1E1;
                            opacity: 1;
                        }
                    }
                    &.slick-active {
                        button::before {
                            color: $blue;
                        }
                    }
                }
            }
        }
        .cs-solution {
            background-color: rgba(0, 100, 200, 0.10);
            padding: 35px 35px 150px;
            height: 100%;
            border-radius: 8px;
            position: relative;
            @include media-breakpoint-down(md) {
                padding: 15px 15px 150px;
            }
            img {
                width: 100%;
                height: auto;
                border-radius: 8px;
            }
            p {
                text-align: inherit;
                font-size: 18px;
                line-height: 1.5;
                margin-bottom: 0;
                &:first-of-type {
                    font-size: 22px;
                    font-weight: 900;
                    margin: 20px 0;
                }
            }
            .btn {
                position: absolute;
                width: calc(100% - 70px);
                left: 35px;
                bottom: 35px;
                font-weight: 900;
                font-size: 22px;
                border-radius: 8px;
                @include media-breakpoint-down(md) {
                    bottom: 15px;
                    width: calc(100% - 30px);
                    left: 15px;
                }
            }
        }
        
    }
    .cs-more {
        padding: 64px 0;
        @include media-breakpoint-down(md) {
            padding: 32px 0;
        }
        h2 {
            text-align: center;
            font-size: 40px;
            font-weight: 900;
            margin-bottom: 32px;
            @include media-breakpoint-down(md) {
                font-size: 34px;
            }
        }
        .cs-more-slide {
            background-color: #F8F8F8;
            border-radius: 8px;
            padding: 40px 65px 50px;
            display: flex;
            margin: 25px 0 0;
            .cs-more-img {
                order: 1;
            }
            .cs-more-content {
                order: 0;
            }
            @include media-breakpoint-down(md) {
                display: block;
                padding: 0 0 60px;
            }
        }
        p {
            line-height: 1.5;
            &:first-of-type {
                font-weight: 900;
                margin-bottom: 0;
                font-size: 22px;
                @include media-breakpoint-down(md) {
                    padding: 20px 20px 16px;
                }
            }
            &:last-of-type {
                margin-bottom: 32px;
                @include media-breakpoint-down(md) {
                    padding: 0 20px;
                }
            }
        }
        h3 {
            font-size: 40px;
            font-weight: 900;
            margin-bottom: 32px;
            @include media-breakpoint-down(md) {
                padding: 0 20px;
                margin-bottom: 24px;
            }
        }
        .btn {
            min-width: 275px;
            font-size: 22px;
            font-weight: 900;
            border-radius: 8px;
            @include media-breakpoint-down(md) {
                margin: 0 auto;
                min-width: none;
                max-width: 275px;
                display: block;
            }
        }
        .cs-more-img img {
            margin-top: -65px;
            max-width: 361px;
            max-height: 361px;
            margin-left: 25px;
            // height: auto;
            // width: auto;
            border-radius: 8px;
            object-fit: cover;
            @include media-breakpoint-down(lg) {
                width: 100%;
                height: auto;
                max-width: inherit;
                max-height: inherit;
                margin: 32px 0 0;
            }
        }
        @include media-breakpoint-down(lg) {
            .slick-track {
                display: flex;
                .slick-slide {
                    height: inherit;
                    padding-bottom: 50px;
                    .cs-more-slide {
                        height: 100%;
                    }
                }
            }
        }
        .slick-dots {
            bottom: 15px;
            @include media-breakpoint-down(lg) {
                bottom: 35px;
            }
            li {
                margin: 0;
                // background-color: red;
                width: 24px;
                height: 24px;
                button {
                    width: 24px;
                    height: 24px;
                    &::before {
                        width: 24px;
                        height: 24px;
                        font-size: 16px;
                    }
                }
            }
        }
        .slick-arrow {
            background-image: url('images/arrow-fill.svg');
            width: 13px;
            height: 25px;
            background-size: contain;
            background-repeat: no-repeat;
            transform: rotate(90deg);
            z-index: 9;
            @include media-breakpoint-down(lg) {
                display: none;
            }
            &.slick-prev {
                transform: rotate(-90deg);
                left: 25px;
            }
            &.slick-next {
                right: 25px;
            }
            &::before {
                content: '';
            }
        }
        // slick-prev slick-arrow
        .more-cta {
            text-align: center;
            margin-top: 32px;
            @include media-breakpoint-down(lg) {
                .btn {
                    width: 100%;
                    max-width: inherit;
                }
            }
        }
    }
}