.lp-hero {
    // height: 716px;
    background-color: #FFF;
    position: relative;
    // z-index: 1;
    margin: 0 0 50px;
    @include media-breakpoint-down(md) {
        min-height: 615px;
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        max-width: 1725px;
        height: 716px;
        margin: auto;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        // background-color: $blue;

        // z-index: 1;
        @include media-breakpoint-down(md) {
            height: 100%;
        }
    }
    &::after {
        background-image: url('images/lp_header.svg');
        background-size: 1726px 716px;
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        z-index: 3;
        max-width: 1728px;
        left: calc(50%);
        @include media-breakpoint-down(md) {
            background-image: url('images/lp_header_mobile.svg');
            background-size: contain;
            background-position: bottom center;
            bottom: 0;
            top: inherit;
        }
    }
    &::before {
        background-color: $blue;
        z-index: 1;
    }
    .ipad_cover {
        max-width: 337px;
        @include media-breakpoint-down(md) {
            max-width: 285px;
            margin: 0 auto;
        }
    }
    .cover-img {
        // @extend .container;
        position: absolute;
        z-index: 2;
        height: 716px;
        bottom: 0;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        max-width: 1140px;
        width: 100%;
        padding: 0 1rem;
        @include media-breakpoint-down(md) {
            position: inherit;
            height: inherit;
            top: inherit;
            left: inherit;
            bottom: inherit;
            transform: inherit;
            text-align: center;
        }
        img {
            position: absolute;
            bottom: 0;
            @include media-breakpoint-down(md) {
                position: inherit;
                bottom: inherit;
                width: 310px;
                margin: 0 auto;
                max-width: 100%;
            }
        }
    }
    .container {
        position: relative;
        z-index: 4;
        h1 {
            font-size: 50px;
            font-weight: 900;
            color: #FFF;
            line-height: 1.15;
            margin: 0 0 64px;            
            @include media-breakpoint-down(md) {
                font-size: 36px;
                margin: 0 0 32px;
            }
        }
        h2 {
            font-size: 27px;
            color: #FFF;
            margin: -32px 0 64px;
        }
        .breadcrumb {
            padding: 20px 0 40px;
            margin: 0;
            @include media-breakpoint-down(md) {
                padding: 24px 0;
            }
            li {
                color: #FFF;
                font-weight: 300;
                font-size: 14px;
                a {
                    color: #FFF;
                    font-weight: 700;
                }
            }
            .breadcrumb-item + .breadcrumb-item::before {
                filter: invert(100%) sepia(88%) saturate(488%) hue-rotate(254deg) brightness(115%) contrast(101%);
            }
        }
        p.type {
            margin: 0 0 32px;
            span {
                background-color: #FFF;
                color: #000;
                font-size: 16px;
                font-weight: 900;
                padding: 8px 12px;
                border-radius: 4px;
                display: inline-block;
            }
        }
    }
    .lp-date {
        border-left: 5px solid $white;
        padding-left: 10px;
        p {
            margin: 0;
            color: $white;
            font-size: 22px;
            line-height: 1;
            &:first-of-type {
                font-weight: 900;
                font-size: 27px;
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    font-size: 27px;
                    margin-bottom: 10px;
                }
            }
        }
    }
    &.webinar-event-hero {
        @include media-breakpoint-down(md) {
            padding-bottom: 350px;
        }
    }
    .h_details {
        margin-top: 48px;
        color: #FFF;
        line-height: 1.5;
        @include media-breakpoint-down(md) {
            display: none;
            margin: 48px 0;
        }
    }
}
.webinar-event-form {
    margin-top: -350px;
    z-index: 4;
    position: relative;
}
.hsform-container {
    position: relative;
}
.lp-form {
    background-color: #FFF;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0px 1.36px 41.89px 0px rgba(0, 0, 0, 0.15);
    min-height: 600px;
    @include media-breakpoint-down(md) {
        margin-bottom: 25px;
        min-height: inherit;
    }
    p.title {
        font-weight: 900;
        font-size: 27px;
    }
    &.event-form.sent p.title,
    &.ebooks-form.sent p.title {
        display: none;
    }
}
.lp-content {
    margin: 0 0 50px;
    h2 {
        margin: 0 0 25px;
    }
    ul {
        li {
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 20px;
            position: relative;
            padding-left: 20px;
            &::before {
                content: "";
                background-image: url('images/lp_point.svg');
                background-repeat: no-repeat;
                width: 10px;
                height: 10px;
                position: absolute;
                left: 0;
                top: 8px;
            }
        }
    }
    p {
        strong {
            font-weight: 900;
        }
    }
    .lp-event-list {
        background-color: #F2F7FC;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 1rem;
        @include media-breakpoint-down(md) {
            display: flex;
            .time {
                padding-right: 20px; 
            }
        }
        p {
            margin: 0;
            strong {
                margin-bottom: 16px;
                display: block;
            }
        }
        p.time {
            font-size: 40px;
            font-weight: 900;
            color: $blue;
            margin-bottom: 24px;
        }
    }

    .lp-grey-box {
        background-color: #F8F8F8;
        padding: 20px;
        border-radius: 8px;
        h3 {
            font-size: 18px;
            font-weight: 900;
            margin-bottom: 25px;
        }
    }
}
.lp-99 {
    h2 {
        // text-align: center;
        margin-bottom: 25px;
        @include media-breakpoint-down(md) {
            text-align: inherit;
            margin-bottom: 15px;
        }
    }
    @include media-breakpoint-up(md) {
        .m99-steps {
            &.m99-steps-1,&.m99-steps-2  {
                > div {
                    width: 50% !important;
                    margin-bottom: 0 !important;
                    .m99-step > img:first-of-type {
                        height: 200px !important;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
            &.m99-steps-4 {
                > div {
                    width: 25%;
                    margin-bottom: 0;
                }
            }
            // &.m99-steps-3 {
            //     > div {
            //         width: 33.33%;
            //         margin-bottom: 0;
            //         .m99-step > img:first-of-type {
            //             height: 150px;
            //             object-fit: cover;
            //             object-position: center;
            //         }
            //     }
            // }
            &:not(.m99-steps-4) {
                > div {
                    width: 33.33%;
                    margin-bottom: 24px;
                    .m99-step > img:first-of-type {
                        height: 150px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }
    }

    .m99-step {
        background-color: #F8F8F8 !important;
        padding: 20px 20px 75px !important;
        position: relative;
        img {
            border-radius: 8px;
            width: 100%;
            margin-bottom: 16px !important;
            margin-left: inherit !important;
            &.lp-logo {
                height: 25px;
                width: auto;
                position: absolute;
                bottom: 32px;
                margin: 0 !important;
                border-radius: 0 !important;
            }
        }
        p {
            margin: 0 !important;
            strong {
                margin-bottom: 16px;
                display: block;
            }
        }
    }
}


