.m90 {
    padding: 80px 0;
    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }
    h2 {
        font-size: 54px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 45px;
        @include media-breakpoint-down(md) {
            font-size: 27px;
            text-align: left;
        }
    }
    .selected-cat {
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        
        &.show {
            border-radius: 8px 8px 0 0;
            border-bottom: none;
            background-color: #F7F7F7;
            p::after {
                transform: rotate(0deg);
            }
        }
        p {
            margin: 0;
            font-size: 18px;
            font-weight: 700;
            position: relative;
            padding: 13px 21px;
            &::after {
                content: '';
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=");
                display: block;
                width: 10px;
                height: 10px;
                background-repeat: no-repeat;
                background-size: contain;
                transform: rotate(90deg);
                position: absolute;
                right: 21px;
                top: 20px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .new-blog-nav {
            overflow: hidden;
            width: 100%;
        }
        .new-blog-nav.show {
            background-color: #F7F7F7;
            border: 1px solid #DCDCDC;
            border-top: none;
            border-radius: 0 0 8px 8px;
            position: relative;
            ul.nav-blog-subcategory {
                display: block;
            }
        }
    }





    p.bar-title {
        font-size: 27px;
        font-weight: 900;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    p.bar-count {
        font-size: 18px;
        font-weight: 700;
        text-align: right;
        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }




    .subcategory {
        .posts-count {
            text-align: right;
            &:lang(ar) {
                text-align: left;
            }
        }
        .row {
            margin-left: -6px;
            margin-right: -6px;
            .col-lg-4.col-md-6 {
                padding: 6px;
                a {
                    color: $black;
                    display: block;
                    height: 100%;
                }
            }
        }
    }


    .blog-button {
        text-align: center;
        margin-top: 60px;
        .btn {
            text-align: center;
            font-size: 22px;
            font-weight: 900;
            min-width: 275px;
            border-radius: 8px;
            @include media-breakpoint-down(md) { 
                width: 100%;
            }
        }
    }
    .nav-blog-subcategory > li.nav-item[data-count="0"] {
        display: none;
    }

    &.loading {
        
        .posts-count,
        .bar-title {
            position: relative;
            text-indent: 200%;
            white-space: nowrap;
            overflow: hidden;
            border: 0;
            background-color: inherit;
            &::before {
                content: "";
                background: linear-gradient(to right, #FFFFFF 0%, rgba(220, 220, 220, 0.25) 50%, #FFFFFF 100%);
                animation-duration: 5s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: submenuLoading;
                animation-timing-function: linear;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-width: 250px;
            }

        }
        .posts-count::before {
            left: inherit;
            right: 0;
        }
        .subcategory .row .col-md-6.col-lg-4 a {
            position: relative;
            .new-blog-post {
                visibility: hidden;
            }
            &::before {
                content: "";
                background: linear-gradient(to right, #FFFFFF 0%, rgba(220, 220, 220, 0.25) 50%, #FFFFFF 100%);
                animation-duration: 5s;
                animation-fill-mode: forwards;
                animation-iteration-count: infinite;
                animation-name: submenuLoading;
                animation-timing-function: linear;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }

        @keyframes submenuLoading {
            0% {
                background-position: 0px 0;
              }
              100% {
                background-position: 100em 0;
              }
        }

    }
}




.m90, .m89 {

    .new-blog-post {
        background-color: #F7F7F7;
        padding: 30px 33px 90px;
        border-radius: 8px;
        height: 100%;
        position: relative;
        p {
            margin: 0;
            &.new-post-cat {
                background-color: $blue;
                display: inline-block;
                font-size: 14px;
                color: #FFF;
                font-weight: 900;
                padding: 4px 12px;
                border-radius: 4px;
                line-height: 1.2;
            }
            &.new-post-title {
                font-weight: 900;
                font-size: 22px;
                margin:20px 0 12px;
                line-height: 1.2;
                @include media-breakpoint-up(lg) { 
                    max-height: 54px;
                    overflow: hidden;
                }
            }
            &.new-post-content {
                font-size: 18px;
                font-weight: 300;
                max-height: 90px;
                overflow: hidden;
                line-height: 1.2;
                @include media-breakpoint-down(md) { 
                    display: none;
                    max-height: inherit;
                }
            }
        }
        .blog-img {
            position: relative;
            img {
                width: 297px;
                height: 204px;
                display: block;
                border-radius: 4px;
                margin-bottom: 20px;
                object-fit: cover;
                @include media-breakpoint-down(md) { 
                    width: 100%;
                    height: auto;
                    max-height: 230px;
                }
            }
            .blog-play {
                position: absolute;
                top: calc(50% - 50px);
                left: calc(50% - 50px);
                width: 100px;
                height: 100px;
                background-image: url('images/play.png');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }


        .new-post-data {
            display: flex;
            width: calc(100% - 66px);
            position: absolute;
            bottom: 40px;
            align-items: center;
            p {
                width: 50%;
                &:last-child {
                    text-align: right;
                }
                &:lang(ar):last-child {
                    text-align: left;
                }
                &.estimated {
                    font-size: 14px;
                    font-weight: 300;
                }
                &.read-more {
                    color: $blue;
                    font-size: 18px;
                    font-weight: 900;
                    position: relative;
                    padding-right: 16px;
                    &:lang(ar) {
                        padding-right: inherit;
                        padding-left: 16px;
                    }
                    &::after {
                        content: '';
                        width: 8px; 
                        height: 8px;
                        display: inline-block;
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=");
                        background-repeat: no-repeat;
                        background-size: contain;
                        filter: invert(27%) sepia(100%) saturate(2012%) hue-rotate(196deg) brightness(82%) contrast(102%);
                        position: absolute;
                        right: 4px;
                        top: calc(50% - 2px);
                        transition: right .15s ease;
                    }
                    &:lang(ar)::after {
                        right: inherit;
                        left: 4px;
                        transition: left .15s ease;
                        transform: rotate(180deg);

                    }
                }

            }
        }
        &:hover .new-post-data .read-more::after {
            right: 0;
            transition: right .15s ease;
        }
        &:lang(ar):hover .new-post-data .read-more::after  {
            left: 0;
            transition: left .15s ease;
        }
    }

    ul.nav-blog-category {
        border-bottom: 1px solid #DCDCDC;
        margin-bottom: 25px;
        @include media-breakpoint-down(md) {
            visibility: hidden;
            border-bottom: 0;
            margin-bottom: 0;
            position: absolute;
            width: 100%;
            &.show {
                visibility: visible;
                position: relative;
                left: 0;
                transition: left 0.25s ease;
            }
            &.move {
                position: absolute;
                left: -200%;
                transition: left 0.25s ease;
            }
        }
        li {
            font-weight: 700;
            font-size: 18px;
            @include media-breakpoint-down(md) {
                width: 100%;
                font-weight: 300;
                position: relative;
                &::after {
                    content: '';
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=");
                    display: block;
                    width: 10px;
                    height: 10px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    right: 21px;
                    top: 20px;
                }
            }
            a {
                color: $black;
                cursor: pointer;
                border-radius: 8px 8px 0px 0px;
                @include media-breakpoint-down(md) {
                    border-radius: inherit;
                    text-align: left;
                    padding: 16px 21px;
                }
                &:hover {
                    background-color: rgba(0, 0, 0, .15)
                }
                &.active {
                    background-color: $blue;
                    color: $white;
                    @include media-breakpoint-down(md) {
                        color: $black;
                        background-color: rgba(0, 0, 0, 0.15);
                    }
                }
            }
    
        }
    }
    
    ul.nav-blog-subcategory {
        margin-bottom: 60px;
        @include media-breakpoint-down(md) {
            display: none;
            position: absolute;
            left: 100%;
            margin-bottom: 0;
            &.show {
                visibility: visible;
                position: relative;
                left: 0;
                top: 0;
                width: 100%;
                transition: left 0.25s ease;
            }
        }
        li {
            margin: 5px;
            @include media-breakpoint-down(md) {
                width: 100%;
                margin: 0;
            }
            a {
                border-radius: 8px;
                border: 1px solid #DCDCDC;
                min-width: 150px;
                text-align: center;
                font-weight: 300;
                font-size: 18px;
                color: $black;
                cursor: pointer;
                &:hover {
                    background-color: rgba(0, 0, 0, .15);
                }
                @include media-breakpoint-down(md) {
                    border: none;
                    text-align: left;
                    padding: 16px 21px;
                }
                &.active {
                    background-color: $blue;
                    border: 1px solid $blue;
                    color: $white;
                    font-weight: 700;
                    @include media-breakpoint-down(md) {
                        background-color: inherit;
                        border: inherit;
                        color: $black;
                        font-weight: 300;
                    }
                }
            }
        }
        
    }
}
.m90-blog {
    .category {
        display: inline;
        background-color: $blue;
        color: $white;
        font-size: 14px;
        font-weight: 900;
        padding: 4px 12px;
        border-radius: 4px;
    }
    header {
        padding: 20px 0 40px;
        border-bottom: 1px solid #DCDCDC;
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
            padding: 0 0 30px;
        }
        .breadcrumb {
            margin-bottom: 40px;
            flex-wrap: nowrap;
            @include media-breakpoint-down(md) {
                margin-top: 20px;
            }
            li {
                font-size: 14px;
                position: relative;
                padding-left: 15px;
                white-space: nowrap;
                &::before {
                    position: absolute;
                    left: 5px;
                }
                &:first-child {
                    padding-left: 0;
                }
                a {
                    font-weight: 700;
                    color: $black;
                }
                &.active {
                    font-weight: 300;
                    color: $black;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        h1 {
            font-size: 70px;
            font-weight: 900;
            margin: 30px 0 57px;
            @include media-breakpoint-down(md) {
                font-size: 54px;
                margin: 26px 0;
            }
        }
        p.date-reading-time {
            font-weight: 300;
            font-size: 14px;
            margin-bottom: 0;
            span{
                font-weight: 700;
            }
        }
    }
    .blog-nav{
        display: none;
        flex: 1 0 auto;
        margin-bottom: 20px;
        padding: 25px 20px;
        background: #FFFFFF;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border: 1px solid #BECAD5;
        border-radius: 10px;
        //position: sticky;
        //top: 80px;
        max-height: 90vh;
        overflow-y: auto;
        ul{
            list-style: none;
            padding-left: 0;
            li{
                margin-bottom: 2px;
                ul{
                    margin-left: 20px;
                }
                a{
                    color: #000;
                    padding: 5px 15px;
                    text-decoration: none;
                    display: block;
                    border-radius: 5px;
                    font-size: 16px;
                    font-weight: $regular;
                    line-height: 1.25;
                    &.active{
                        color: #fff;
                        background-color: #0064C8;
                    }
                    &:not(.active){
                        &:hover{
                            background-color: #F2F2F2;
                            color: #000;
                        }
                    }
                }
            }
        } 
    }
    article {
        margin: 0 0 80px;
        p {
            color: $black;
            font-weight: 300 !important;
            font-size: 18px;
            b,strong {
                font-weight: 700;
            }
            span {
                font-weight: 300 !important;
                font-size: 18px !important; 
            }
        }
        h2 {
            font-size: 26px;
            font-weight: 900;
            letter-spacing: 1px;
            margin: 35px 0 25px;
        }
        h3 {
            font-size: 24px;
            font-weight: 700;
            margin: 35px 0 25px;
            letter-spacing: 1px;
        }
        a:not(.btn) {
            color: $blue;
            text-decoration: underline
        }
        .blog-cta {
            margin: 60px 0;
            text-align: center;
        }
        .wp-block-buttons {
            &.is-content-justification-center {
                display: flex;
                align-items: center;
                margin: 50px 0;
            }
            .wp-block-button {
                display: block;
                text-align: center;
                width: 100%;
                &:not(.is-style-outline) {
                    a {
                        @extend .pr-btn;
                        @extend .pr-primary-btn;
                        text-decoration: none;
                        min-width: 280px;
                        width: unset !important;
                    }
                }
                &.is-style-outline {
                    a {
                        @extend .btn;
                        @extend .button-outline-green;
                    }
                }
            }
        }
        ul {
            list-style: initial;
            padding-left: 25px;
            ul {
                list-style: circle;
            }
            li {
                font-size: 18px;
                font-weight: 300;
                span {
                    font-weight: 300 !important;
                }
                b, strong {
                    font-weight: 900;
                }
            }
            &.inputs-list {
                padding: 0 !important;
                margin-bottom: 15px;
                li {
                    list-style: none !important;
                    label {
                        display: block;
                        color: #000;
                        span{
                            font-size: 16px !important;
                            color: #000;
                            font-weight: 300 !important;
                        }
                        a {
                            color: $blue;
                            font-size: 16px;
                            font-weight: 400;
                        }
                        
                        input {
                            @extend .form-check-input;
                            width: 20px;
                            height: 20px;
                            border-radius: 2px;
                            border: solid 1px #ced4da;
                            display: inline-block;
                            padding: 0 10px;
                        }
                    }
                }
    
            }
            &.hs-error-msgs {
                padding: 0 !important;
                li {
                    margin-top: -10px;
                    margin-bottom: 15px;
                    list-style: none !important;
                    label {
                        display: block;
                        color: #dc3545;
                        margin-bottom: 0;
                    }
                }
            }
        }
        ul.post_categories {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-top: 40px;
            list-style: none;
            padding: 0;
            li {
                border: 1px solid #DCDCDC;
                padding: 4px 12px;
                border-radius: 4px;
                &:hover {
                    background-color: #DCDCDC;
                }
                a {
                    font-size: 14px;
                    font-weight: 300;
                    display: block;
                    color: $black;
                    text-decoration: none;
                }
            }
        }
        img {
            max-width: 100%;
            height: auto;
        }
        div {
            max-width: 100%;
        }
    }
    aside {
        .featured-posts {
            color: $black;
            background-color: #F7F7F7;
            border-radius: 8px;
            padding: 33px;
            margin-bottom: 12px;
            p.title {
                font-size: 22px;
                font-weight: 900;
            }
            ul {
                li {
                    border-bottom: 1px solid #DCDCDC;
                    padding: 16px 0;
                    &:last-child {
                        border-bottom: none;
                    }
                    a {
                        font-size: 18px;
                        font-weight: 300;
                        &:hover {
                            color: $black;
                        }
                    }
                }
            }
        }
        .sticky-sidebar {
            position: sticky;
            top: 75px;
            @include media-breakpoint-down(lg) {
                position: inherit;
            }
            .featured-post-w01 {
                width: 100%;
                max-width: 376px;
                margin: 0 auto 30px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                position: relative;
                border-radius: 10px;
                padding: 0 20px 90px;
                img {
                    height: 90px;
                    width: auto;
                    margin: 0 0 0 -25px;
                }
                p {
                    color: #FFF;
                    &.title {
                        font-weight: 900;
                        font-size: 20px;
                        margin-bottom: 16px;
                    }
                    &.subtitle {
                        font-size: 14px;
                        margin-bottom: 50px;
                    }
                }
                a.btn {
                    position: absolute;
                    width: calc(100% - 40px);
                    bottom: 20px;
                    left: 20px;
                    font-size: 18px;
                    font-weight: 900;
                    padding: 10px 0 11px;
                }
            }
            .featured-post {
                a {
                    display: block;
                    &:hover{
                        color: $black;
                    }
                    img {
                        width: 100%;
                        border-radius: 8px 8px 0 0;
                    }
                    div {
                        background-color: #F7F7F7;
                        padding: 24px 33px 33px;
                        border-radius: 0 0 8px 8px;
                        p.title {
                            font-weight: 900;
                            margin: 24px 0 0;
        
                        }
                    }
                }
            }
            .explore-content {
                margin-top: 30px;
                p.title {
                    font-weight: 900;
                    font-size: 22px;
                    margin-bottom: 25px;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                    li {
                        border: 1px solid #DCDCDC;
                        padding: 4px 12px;
                        border-radius: 4px;
                        &:hover {
                            background-color: #DCDCDC;
                        }
                        a {
                            font-size: 14px;
                            font-weight: 300;
                            display: block;
                            color: $black;
                        }
                    }
                }
            }
        }
    }
    .author-card{
        padding: 48px 100px;
        border-radius: 8px;
        background: var(--background-colors-light-grey, #F8F8F8);
        // background: var(--background-colors-plan-radar-blue-5, #F2F7FC);
        margin: 32px 0;
        @include media-breakpoint-down(lg) {
            padding: 48px 32px;
          }
        @include media-breakpoint-down(md) {
          padding: 32px 16px;
        }
        .bio{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          padding: 0 0 24px;
          @include media-breakpoint-down(md) {
            align-items: flex-start;
            flex-direction: column;
          }
          .author{
            display: flex;
            flex-direction: row;
            @include media-breakpoint-down(lg) {
              max-width: 63%;
            }
            @include media-breakpoint-down(md) {
              flex-direction: column;
              max-width: 100%;
            }
            img{
              border-radius: 50%;
              margin-right: 22px;
              &:lang(ar) {
                margin-right: 0;
                margin-left: 22px;
              }
            }
            .pretitle{
              font-size: 16px;
              font-weight: $regular;
              line-height: 1.5;
              margin-bottom: 8px;
            }
            .author-name{
              font-size: 27px;
              font-weight: $black-bold;
              line-height: 1.25;
              margin: 5px 0 8px;
            }
            .author-title{
              font-size: 16px;
              font-weight: $regular;
              line-height: 1.5;
            }
          }
          .btn{
            font-size: 16px;
            line-height: 1.5;
          }
        }
        .description{
          border-top: 1px solid #E1E1E1;
          padding: 24px 0 0;
          p{
            font-size: 16px;
            font-weight: $regular;
            line-height: 1.5;
            margin: 0;
          }
        }
      }
      .m13 {
        .cta {
            margin: 15px 0;
        }
      }
}
.related-newsletter {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .related {
        margin: 0 0 80px;
        width: 100%;
        flex: 0 0 auto;
        @include media-breakpoint-down(lg) {
            order: 1;
        }
        p.title {
            font-weight: 900;
            font-size: 22px;
            margin: 0 0 28px;
        }
        .row {
            margin-left: -6px;
            margin-right: -6px;
            .col-lg-4 {
                padding-left: 6px;
                padding-right: 6px;
                @include media-breakpoint-down(lg) {
                    margin-bottom: 12px;
                }
                .related-post {
                    background-color: #F7F7F7;
                    border-radius: 8px;
                    padding: 30px 33px 66px;
                    height: 100%;
                    position: relative;
                    a {
                        display: block;
                        &:hover {
                            color: $black;
                        }
                        p.category {
                            display: inline;
                            background-color: $blue;
                            color: $white;
                            font-size: 14px;
                            font-weight: 900;
                            padding: 4px 12px;
                            border-radius: 4px;
                        }
                        img {
                            border-radius: 4px;
                            margin-bottom: 22px;
                            height: 200px;
                            object-fit: cover;
                        }
                        p.title {
                            margin-top: 24px;
                        }
                        p.content {
                            font-weight: 300;
                            margin-top: 12px;
                        }
                        .new-post-data {
                            display: flex;
                            position: absolute;
                            width: calc(100% - 66px);
                            align-items: center;
                            bottom: 33px;
                            p {
                                width: 50%;
                                &.estimated {
                                    font-size: 14px;
                                    font-weight: 300;
                                }
                                &.read-more {
                                    text-align: right;
                                    color: $blue;
                                    font-weight: 900;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .m91 {
        width: 100%;
        flex: 0 0 auto;
        @include media-breakpoint-down(lg) {
            order: 0;
            margin: 15px 0;
        }
    }
}

