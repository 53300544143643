.select2-container--bootstrap-5 {
    .select2-selection--single {
        padding: $s2bs5-padding-y $s2bs5-indicator-padding $s2bs5-padding-y $s2bs5-padding-x;
        background-image: escape-svg($s2bs5-indicator);
        background-repeat: no-repeat;
        background-position: $s2bs5-bg-position;
        background-size: $s2bs5-bg-size;

        // Rendered view
        .select2-selection__rendered {
            padding: 0;
            font-weight: $s2bs5-font-weight;
            line-height: $s2bs5-line-height;
            color: $s2bs5-color;

            // Placeholder
            .select2-selection__placeholder {
                font-weight: $s2bs5-font-weight;
                line-height: $s2bs5-line-height;
                color: $s2bs5-placeholder-color;
            }

            // Disable arrow
            .select2-selection__arrow {
                display: none;
            }
        }
    }
}
