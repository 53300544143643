.m75 {
  background-color: $blue;
  padding: 50px 0;
  &.bg-gradient{
    background-color: $blue;
  }
  &.bg-white{
    background-color: $white;
    background-image: none;
    h2,p{
      color: $blue !important;
    }
  }
  &.bg-gray{
    background-color: #F7F7F7;
    background-image: none;
    h2,p{
      color: $black;
    }
  }
  
  .m75-txt-black {
    color: $black !important;
    font-weight: $black-bold;
    @include media-breakpoint-down(md){
      font-weight: $light;
    }
  }

  img {
    margin-right: 50px;
    &:lang(ar) {
      margin-right: unset;
      margin-left: 50px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:lang(ar):last-child {
      margin-left: 0;
    }
  }

  .banner {
    text-align: center;
    color: $white;

    h2 {
      margin: 25px 0;
      font-size: 54px;
      font-weight: $black-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 27px;
      }

      a {
        color: $white;
        text-decoration: underline;
      }
    }

    p {
      font-size: 27px;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      padding: 0;
      margin: 0 auto;
      max-width: 677px;
      &:lang(ar) {
        line-height: 1.5;
      }
      @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 1.25;
      }
      & ~ a{
        margin-top: 52px;
      }
    }
  }
}