.m106 {
    .consultation-check {
        display: none;
    }
    .promo-check {
        display: none;
    }
    .col-lg-7{
        display: unset !important;
        flex-direction: unset !important;
        justify-content: unset !important;
        .content {
            position: sticky;
            top: 125px;
        }
    }
    .registration-form {
        position: relative;
        #phone_number {
            padding-left: calc(100px + 0.75rem);
        }
        input:focus {
            box-shadow: inherit;
            border-color: rgba($color: #ced4da, $alpha: .5);
        }
        .contact-select-country {
            position: absolute;
            top: 0;
            left: 0;
            width: 100px !important;
            font-size: 1rem;
            font-weight: 400;
            background-color: inherit;
            button {
                margin-top: 1px;
                margin-left: 1px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                padding: 0.38rem 0.75rem;
                font-weight: 400;
                border-top: none;
                border-bottom: none;
                border-left-color: transparent;
            }
        }
        .toggle-password {
            position: absolute;
            right: 0.75rem;
            top: calc(50% - 9px);
            z-index: 999;
            cursor: pointer;
            font-size: 18px;
            opacity: 0.5;
        }
        .personal-info {
            font-size: 14px;
            margin-bottom: 0;
            margin-bottom: 12px;
        }
        .promo-code {
            display: none;
        }
        a {
            color: $blue;
            text-decoration: underline;
        }
        .pr-btn {
            width: 100%;
        }
        select {
            position: relative;
            color: rgba($color: #000000, $alpha: .5);
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right .75rem center;
            background-size: 16px 12px;
        }
        @include media-breakpoint-down(md) {
            .col-md-6:first-of-type {
                margin-bottom: 1.5rem !important;
            }
        }
    }
}