.m42{
    padding: 60px 0;
    @include media-breakpoint-down(md) {
        padding: 48px 0;
    }
    h2{
        font-size: 40px;
        color: $blue;
        font-weight: $black-bold;
        margin-bottom: 30px;
        line-height: 1.2;
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }
    .text_image_block{
        display: flex;
        &.right{
            flex-direction: row;
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }
        &.left{
            flex-direction: row-reverse; 
            @include media-breakpoint-down(md) {
                flex-direction: column;
            }
        }
        .image{
            img{
                width: 100%;
                display: block;
            }
        }
        .text{
            h2{
                font-size: 22px;
                color: $black;
                font-weight: $black-bold;
                margin-bottom: 22px;
                padding-left: 25px;
                @include media-breakpoint-down(lg) {
                    padding-left: 0;
                }
            }
            .content{
                color: $black;
                
                p{
                    font-size: 18px;
                    font-weight: $light;
                    line-height: 27px;
                }
                strong{
                    font-weight: $black-bold;
                }
                ul{
                    list-style-type: disc;
                    padding: 0 0 0 40px;
                    li{
                        font-size: 18px;
                        font-weight: $light;
                        line-height: 27px;
                    }
                }
                h2 {
                    font-size: 40px;
                    font-weight: 900; 
                    padding-left: 0;
                    letter-spacing: 0;
                    margin-bottom: 32px;
                }
                .pr-btn {
                    margin-top: 32px;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
            .cta{
                font-weight: $black-bold;
                font-size: 22px;
                max-width: 320px;
                margin: 15px auto;
                width: 100%;
                @include media-breakpoint-down(lg) {
                    display: block;
                    margin: 60px auto 15px;
                }
            }
        }
        .image {
            img {
                width: 100%;
                @include media-breakpoint-down(md) {
                    margin-bottom: 32px;
                }
            }
        }
    }
    &.m58{
        &.no-spaces{
            padding: 0;
            .text_image_block{
                align-items: center;
            }
        }
        .text_image_block{
            align-items: center;
            &.right{
                flex-direction: row;
                @include media-breakpoint-down(lg) {
                    flex-direction: column-reverse;
                }
            }
            &.left{
                flex-direction: row-reverse;
                @include media-breakpoint-down(lg) {
                    flex-direction: column-reverse;
                } 
            } 
            .image{
                text-align: center;
                img{
                    max-width: 100%;
                    width: 350px;
                    margin: 0 auto;
                    height: auto;
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }
            .text{
                .content{
                    p{
                        font-size: 16px;
                        font-weight: $medium;
                        line-height: 1.5;
                        a{
                            color: $blue;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    &.m61{
        .text_image_block{
            .image{
                img{
                    max-width: 235px;
                    height: auto;
                    @include media-breakpoint-down(md) {
                        max-width: 175px;
                        margin: 30px auto 0;
                    }
                }
            } 
        } 
    }
}