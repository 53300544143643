.m17{
    padding: 45px 0;
    color: $black;
    &.bg-white{
        .block{
            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2) !important;
            @include media-breakpoint-down(md) {
                border-radius: 15px;
                margin-top: 16px;
            }
        }
        @include media-breakpoint-down(md) {
            .col-md-4 {
                margin-top: 16px;
            }
        }
    }
    .title{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 1.2;
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
        &:lang(ar) {
            line-height: 1.5;
        }
    }
    .block{
        background-color: #FFFFFF;
        height: 100%;
        border-radius: 15px;
        @include media-breakpoint-down(md) {
            border-radius: 0;
            background-color: transparent;
        }
        .block-image{
            width: 100%;
            border-radius: 15px 15px 0 0;
            height: 234px;
            object-fit: cover;
            // height: auto;
            @include media-breakpoint-down(lg) {
                height: 174px;
            }
            @include media-breakpoint-down(md) {
                height: 234px;
            }
        }
        .content{
            padding: 15px 15px 60px;
            .block-title{
                font-size: 18px;
                font-weight: 900;
                margin-bottom: 18px;
                padding-left: 0;
                margin-top: 0;
            }
            .block-desc{
                font-size: 18px;
                font-weight: $light;
                line-height: 22px;
                &:lang(ar) {
                    line-height: 1.5;
                }
            }
            .read-more{
                display: block;
                font-weight: $black-bold;
                text-decoration: underline;
                color: $blue;
                font-size: 18px;
            }
        }
    }
    .pr-btn-group {
        padding: 60px 0 15px;
        justify-content: center;
        @include media-breakpoint-down(md) {
            padding: 30px 0 0;
        }
    }
    .m17-slider {
        @include media-breakpoint-down(md) {
            padding-left: 25px;
            padding-right: 25px;
            .slick-prev {
                left: 7px;
            }
            .slick-next {
                right: 7px;
            }
        }
        .slick-prev, .slick-next {
            width: 26px;
            height: 26px;
            &::before {
                color: rgba(0, 0, 0, 0.5);
                font-size: 24px;
            }
        }
        .slick-dots {
            bottom: -40px;
            @include media-breakpoint-down(md) {
                width: calc(100% - 50px);
            }
            li {
                button {
                    &::before {
                        font-size: 12px;
                    }
                }
            }
        }
        .col-md-4 {
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
            padding-bottom: 30px;
            .block .content {
                h3.block-title {
                    height: 25px;
                    overflow: hidden;
                }
                p.block-desc {
                    height: 70px;
                    overflow: hidden;
                }
            }
        }
    }
}
.m17.m17-platform {
    h2 {
        text-align: center;
        font-size: 28px;
        margin-bottom: 25px;
    }
    .col-md-4 {
        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .block {
            position: relative;
            @include media-breakpoint-down(md) {
                background-color: #FFF;
                border-radius: 15px;
            }
            .m17-platform-links {
                position: absolute;
                width: calc(100% - 30px);
                bottom: 25px;
                a:hover {
                    color: darken($blue, 10%);
                    
                }
            }
        }
    }
}