.m26 {
    padding: 60px 0 30px;
    @include media-breakpoint-down(xl) {
        padding: 20px 0 30px;
    }
    h2 {
        color: $blue;
        margin-bottom: 40px;
    }
    .benefit-icon{
        width:70px;
        img {
            display: block;
            width: 40px;
            margin-right: 15px;
            margin-left: 15px;
            @include media-breakpoint-down(md) {
                margin-right: 30px;
                margin-left: 0;
            }
        }
    }
    .benefit-title {
        width: calc(100% - 70px);
        h3 {
            font-size: 26px;
            color: #000;
            font-weight: 900;
            padding: 12px 0;
            @include media-breakpoint-down(lg) {
                font-size: 22px;
            }
        }
    }
}