.m97{
    padding: 32px 0;
    h2{
        font-size: 40px;
        font-weight: $black-bold ;
        line-height: 1.15;
        margin-bottom: 24px;
    }
    .posts{
        margin: 32px auto;
        display: flex;
        justify-content: space-between;
        gap: 26px;
        @include media-breakpoint-down(md){
            margin: 12px auto;
        }
        &.slides{
            padding-bottom: 32px;
        }
        .post{
            width: calc(100% / 2);
            padding: 0 13px;
            @include media-breakpoint-down(md){
                width: 100%;
            }
            .post-container{
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                padding: 32px 16px;
                &.lightblue-bg{
                    background-color: #E5EFF9;
                }
                
                .image{
                    width: 100%;
                    margin-bottom: 32px;
                    img{
                        width: 100%;
                        height: auto;
                        max-height: 334px;
                        object-fit: cover;
                    }
                }
                .content{
                    span{
                        display: block;
                        color: $blue;
                        font-size: 16px;
                        font-weight: $black-bold;
                        line-height: 1.5;
                        margin-bottom: 32px;
                    }
                    h3{
                        font-size: 27px;
                        font-weight: $black-bold;
                        line-height: 1.15;
                        margin-bottom: 32px;
                        @include media-breakpoint-down(md){
                            margin-bottom: 24px;
                        }
                        margin-top: 0;
                    }
                    p{
                        font-size: 18px;
                        font-weight: $regular;
                        line-height: 1.5;
                        margin-bottom: 32px;
                    }
                    a{
                        width: 100%;
                        border-radius: 8px;
                        padding: 12px 24px;
                        font-size: 22px;
                        font-weight: $black-bold;
                        line-height: 1.1;
                    }
                }
            }
            &:only-child{
                width: 100%;
                padding: 0;
                .post-container{
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 64px;
                    @include media-breakpoint-down(md){
                        flex-direction: column;
                        padding: 32px 16px;
                    }
                    .image{
                        width: calc(100% / 2 - 12px);
                        margin-bottom: 0;
                        @include media-breakpoint-down(md){
                            width: 100%;
                            margin-bottom: 32px;
                        }
                        img{
                        }
                    }
                    .content{
                        width: calc(100% / 2 - 108px);
                        @include media-breakpoint-down(md){
                            width: 100%;
                        }
                        span{
                            @include media-breakpoint-down(md){
                                font-size: 16px;
                            }
                        }
                        a{
                            width: 360px;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        .slick-dots{
            li{
                margin: 0;
                width: 18px;
                &:only-child{
                    display: none;
                }
                &.slick-active{
                    button{
                        &::before{
                            color: $blue;
                        }
                    }
                }
                button{
                    &::before{
                        font-size: 12px;
                        color: #E1E1E1;
                    }
                }
            }
        }
    }
}