.m68 {
    &.top-video {
        >.container {
            max-width: inherit;
            padding-left: 0;
            padding-right: 0;
            .row {
                margin-left: 0;
                margin-right: 0;
                .col-xl-8 {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .customers_video_hero {
            background-image: linear-gradient(180deg,#00396B 0%,#1D62AD 100%);
            border-top: 1px solid #979797;
            color: #FFF;
            text-align: center;
            padding: 30px 0 50px;
            @include media-breakpoint-down(md) {
                text-align: left;
            }
            h1 {
                font-size: 82px;
                letter-spacing: 1.72px;
                font-weight: 900;
                margin: 0;
                @include media-breakpoint-down(md) {
                    font-size: 48px;
                    letter-spacing: .9px;
                }
            }
            p {
                font-size: 18px;
                line-height: 27px;
                font-weight: 700;
                margin: 0 0 40px;
            }
            .cta_btn a {
                // font-weight: 900;
                // font-size: 22px;
                // padding: 7px 30px;
                width: 100%;
                max-width: 460px;
            }
        }
    }
    &.bottom-video {
        padding: 100px 0;
        h2 {
            font-size: 64px;
            font-weight: 900;
            color: #000;
            margin: 0;
        }
        video {
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
            border-radius: 10px;
        }
    }
    .customers-video-block {
        position: relative;
        .VideoPlayIcon {
            width: 200px;
            height: 200px;
            position: absolute;
            z-index: 9;
            left: calc(50% - 100px);
            top: calc(50% - 100px);
            background-image: url('images/play.png');
            background-size: cover;
            cursor: pointer;
            @include media-breakpoint-down(md) {
                width: 150px;
                height: 150px;
                left: calc(50% - 75px);
                top: calc(50% - 75px);
            }
        }
    }
}
