.author-hero{
    background: var(--background-colors-light-grey, #F8F8F8);
    padding: 44px 0;
    h1{
        font-size: 50px;
        font-weight: $black-bold;
        line-height: 1.15;
        color: $blue;
        margin: 0;
        @include media-breakpoint-down(md) {
            font-size: 40px;
        }
    }
    img{
        border-radius: 50%;
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
        }
    }
}
.author-bio{
    // padding: 64px 0;
    padding: 32px 0;
    @include media-breakpoint-down(md) {
        padding: 32px 0;
    }
    h2{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 1.15;
        margin: 0 0 48px;
        @include media-breakpoint-down(md) {
            font-size: 36px;
        }
    }
    p{
        font-size: 18px;
        font-weight: $regular;
        line-height: 1.5;
    }
}
.author-posts{
    padding: 32px 0;
    h2{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 1.15;
        margin: 0 0 32px;
        @include media-breakpoint-down(md) {
            font-size: 36px;
        }
    }
}
.author-picks{
    padding: 0 0 32px;
    h2{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 1.15;
        margin: 0 0 48px;
        @include media-breakpoint-down(md) {
            font-size: 36px;
        }
    }
    // .slides{
    //     .pick{
    //         width: 100%;
    //         height: 627px;
    //         background-repeat: no-repeat;
    //         background-size: cover;
    //         display: flex;
    //         align-items: baseline;
    //         flex-direction: column;
    //         justify-content: flex-end;
    //         padding: 34px;
    //         border-radius: 4px;
    //         @include media-breakpoint-down(md) {
    //             height: 400px;
    //         }
    //     }
    //     h3{
    //         color: $white;
    //         font-size: 22px;
    //         font-weight: $black-bold;
    //         line-height: 1.5;
    //         margin-bottom: 12px;
    //     }
    //     .estimated{
    //         color: $white;
    //         font-size: 16px;
    //         font-weight: $black-bold;
    //         line-height: 1.5;
    //         margin-bottom: 0;
    //     }
    //     .slick-dots{
    //         bottom: -48px;
    //         li{
    //             margin: 0;
    //             width: 18px;
    //             &:only-child{
    //                 display: none;
    //             }
    //             &.slick-active{
    //                 button{
    //                     &::before{
    //                         color: $blue;
    //                     }
    //                 }
    //             }
    //             button{
    //                 &::before{
    //                     font-size: 12px;
    //                     color: #E1E1E1;
    //                 }
    //             }
    //         }
    //     }
    // }
    .col-lg-4 {
        @include media-breakpoint-down(lg) {
            margin-bottom: 12px;
        }
    }
    .related-post {
        background-color: #F7F7F7;
        border-radius: 8px;
        padding: 30px 33px 66px;
        height: 100%;
        position: relative;
        a {
            display: block;
            &:hover {
                color: $black;
            }
            p.category {
                display: inline;
                background-color: $blue;
                color: $white;
                font-size: 14px;
                font-weight: 900;
                padding: 4px 12px;
                border-radius: 4px;
            }
            img {
                border-radius: 4px;
                margin-bottom: 22px;
                height: 200px;
                object-fit: cover;
                display: block;
            }
            p.title {
                margin: 20px 0 12px;
                font-size: 22px;
                font-weight: 900;
                line-height: 1.1;
            }
            p.content {
                font-size: 18px;
                line-height: 1.25;
                font-weight: 300;
                margin: 0;
            }
            .new-post-data {
                display: flex;
                position: absolute;
                width: calc(100% - 66px);
                align-items: center;
                bottom: 33px;
                p {
                    width: 50%;
                    &.estimated {
                        font-size: 14px;
                        font-weight: 300;
                    }
                    &.read-more {
                        text-align: right;
                        color: $blue;
                        font-size: 18px;
                        line-height: 1.25;
                        font-weight: 900;
                        position: relative;
                        padding-right: 16px;
                        &::after {
                            content: '';
                            width: 8px; 
                            height: 8px;
                            display: inline-block;
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=");
                            background-repeat: no-repeat;
                            background-size: contain;
                            filter: invert(27%) sepia(100%) saturate(2012%) hue-rotate(196deg) brightness(82%) contrast(102%);
                            position: absolute;
                            right: 4px;
                            top: calc(50% - 2px);
                            transition: right .15s ease;
                        }
                    }
                }
            }
            &:hover .new-post-data .read-more::after {
                right: 0;
                transition: right .15s ease;
            }
        }
    }
}