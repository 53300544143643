.m105 {
    margin: 32px 0;
    h2 {
        font-size: 40px;
        margin-bottom: 48px;
        text-align: center;
        @include media-breakpoint-down(md) {
            font-size: 36px;
            margin-bottom: 32px;
            text-align: left;
        }
    }
    img {
        border-radius: 8px;
    }
    .m105-list {
        background-color: #F8F8F8;
        display: flex;
        padding: 20px;
        margin-bottom: 32px;
        border-radius: 8px;
        @include media-breakpoint-down(md) {
            margin-bottom: 0;
            margin-top: 24px;
            display: block;
            padding: 32px 26px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        .icon {
            width: 32px;
            height: auto;
            margin-right: 20px;
        }
        .text {
            width: calc(100% - 52px);
            @include media-breakpoint-down(md) {
                width: 100%;
                margin-top: 20px;
            }
            p {
                margin: 0;
                font-size: 18px;
                list-style: 1.5;
            }
        }

    }
}