.m79{
    background-color: #3C5055;
    padding: 14px 0;
    color: $white;
    position: absolute;
    top: 0;
    width: 100%;
    &.admin-bar-spacing {
        top: 32px;
    }
    @include media-breakpoint-down(md) {
        position: unset;
    }
    .bar{
        display: flex;
        align-items: center;
        align-content: center;
        @include media-breakpoint-up(md) {
            max-height: 24px;
        }
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
        p{
            vertical-align: middle;
            margin-bottom: 0;
            font-size: 18px;
            line-height: 1.2;
            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }
            &.date{
                text-align: start;
                //font-weight: 100;
                // min-width: 200px;
                @include media-breakpoint-down(lg) {
                    font-size: 15px;
                }
                @include media-breakpoint-down(md) {
                    text-align: center;
                    font-size: 18px;
                }
            }
            &.title{
                font-weight: 900;
                font-size: 18px;
                // width: calc(100% - 400px);
                @include media-breakpoint-down(lg) {
                    font-size: 15px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
                a{
                    color: inherit;
                    text-decoration: underline;
                }
            }
        }

        .signup{
            color: $white;
            text-align: end;
            display: block;
            font-weight: 700;
            font-size: 18px;
            position: relative;
            padding-right: 20px;
            // min-width: 200px;
            &::before{
                content: '';
                height: 11px;
                width: 10px;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background-image: url('images/icons/arrow-left-white.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right center;
                transform: rotate(180deg);
            }
            @include media-breakpoint-down(lg) {
                font-size: 15px;
            }
            @include media-breakpoint-down(md) {
                text-align: center;
                font-size: 18px;
            }
        }
    }
}
.m79-v2 {
    padding: 5px 0;
    // padding: 14px 0;
    // color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    &.admin-bar-spacing {
        top: 32px;
    }
    @include media-breakpoint-down(md) {
        // position: unset;
        padding: 16px 0;
    }
    &.blue {
        background-color: #0064C8;
        // p.type {
        //     color: #0064C8;
        // }
    }
    &.dark {
        background-color: #003264;
        // p.type {
        //     color: #003264;
        // }
    }
    &.light {
        background-color: #E5EFF9;
        p {
            color: $black !important;
            // &.type {
            //     color: #FFF !important;
            //     background-color: #0064C8 !important;
            // }
            &.cta {
                a {
                    color: #0064C8 !important;
                    &::after {
                        border-left-color: #0064C8 !important;
                    }
                }
            }
        }
    }
    .notification {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        p {
            color: $white;
            margin: 0;
            font-size: 16px;
            &.text {
                white-space: nowrap;
                overflow: hidden;
            }
            &.cta {
                white-space: nowrap;
                a {
                    font-weight: 900;
                    color: #FFF;
                    padding-right: 16px;
                    position: relative;
                    &::after {
                        content: "";
                        display: block;
                        width: 0px;
                        height: 0px;
                        border:6px solid transparent;
                        border-left-color: #FFF;
                        border-right-width: 0px;
                        position: absolute;
                        top: 5px;
                        right: 4px;
                        transition: 250ms all ease;
                    }
                    &:hover::after {
                        right: 0;
                        transition: 250ms all ease;
                    }
                }
            }
            &.type {
                // background-color: #F8F8F8;
                // border-radius: 4px;
                padding: 6px 0;
                font-weight: 900;
                flex-wrap: 0;
                flex-shrink: 0;
            }
        }
    }
}