.m101 {
    .hbspt-form {
        label {
            display: none
        }
        input, select {
            @extend .form-control;
            margin: 0 12px 12px 0 !important;
            &.invalid {
                @extend .form-control.is-invalid;
            }
            &.hs-button {
                @extend .pr-btn;
                @extend .pr-primary-btn;
            }
        }
        .hs-error-msgs {
            padding: 0 !important;
            li {
                margin-top: -10px;
                margin-bottom: 15px;
                list-style: none !important;
                label {
                    display: block;
                    color: #dc3545;
                    margin-bottom: 0;
                }
            }
        }
        .inputs-list {
            padding: 0 !important;
            margin-bottom: 15px;
            li {
                list-style: none !important;
                label {
                    display: block;
                    color: #000;
                    
                    a {
                        color: $blue;
                    }
                    
                    input {
                        @extend .form-check-input;
                        width: 20px;
                        height: 20px;
                        border-radius: 2px;
                        border: solid 1px #ced4da;
                        display: inline;
                        padding: 0 10px;
                    }
                }
            }

        }
    }
}