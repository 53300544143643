.m73 {
  background-color: rgba(0, 100, 200, 0.05);
  padding: 51px 0 20px;
  @include media-breakpoint-down(lg) {
    background-color: rgba(0, 100, 200, 0.1);
  }

  h2 {
    color: $blue;
    margin-top: 0;
    margin-bottom: 30px;
    line-height: 1.1;
    font-size: 54px;
    letter-spacing: normal;
    @include media-breakpoint-down(lg) {
      font-size: 44px;
    }
  }
  .items-slider{
    position: relative;
  }
  .items {
    .item {
      margin-bottom: 40px;
      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      img {
        max-width: 100%;
        width: 293px;
        height: 310px;
      }
      .image{
        @include media-breakpoint-down(lg) {
          order: 1;
          display: block;
          text-align: center;
          margin: 0 auto;
        }
      }
      h3 {
        font-size: 27px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        font-weight: 900;
        margin: 25px 0 15px 16px;
        min-height: 60px;
      }
      .title{
        @include media-breakpoint-down(lg) {
          order: 0;
        }
      }
      .link {
        width: 275px;
        font-size: 18px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: $blue;
        padding-left: 16px;
        @include media-breakpoint-down(lg) {
          order: 2;
          font-size: 22px;
          color: $white;
          background-color: $blue;
          border-radius: 8px;
          margin: 40px auto;
          display: block;
          padding: 16px;
        }
      }

      &:hover {
        h3 {
          color: $blue;
        }
      }
    }

  }
  .arrow {
    width: 45px;
    height: 80px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    text-align: center;
    &:hover {
      cursor: pointer;
    }

    img {
      margin-bottom: -5px;
    }

    &.arrow-left {
      img {
        margin-left: 5px;
        transition: margin 20ms;
      }

      &:hover {
        img {
          margin-left: 3px;
          transition: margin 20ms;
        }
      }
    }

    &.arrow-right {
      right: 0px !important;
      left: initial !important;

      img {
        transform: rotate(180deg);
        margin-left: 5px;
        transition: margin 20ms;
      }

      &:hover {
        img {
          margin-left: 7px;
          transition: margin 20ms;
        }
      }
    }

    .helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;

    }
  }
}