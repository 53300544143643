.m99 {
    margin: 32px 0;
    @include media-breakpoint-down(md) {
        margin: 24px 0;
        .container {
            padding-right: 0;
        }
    }
    .col-md-3 h2 {
        font-size: 27px;
        font-weight: $black-bold;
        margin: 0;
        padding-right: 16px;
        word-break: break-word;
        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
            padding-right: inherit;
        }
    }
    .col-12 h2 {
        font-size: 40px;
        font-weight: $black-bold;
        margin: 0 0 48px;
        text-align: center;
        letter-spacing: inherit;
        @include media-breakpoint-down(md) {
            font-size: 36px;
            margin-bottom: 32px;
            text-align: left;
            br {
                display: none;
            }
        }
    }
    .m99-steps {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;
        margin-right: -12px;
        @include media-breakpoint-down(lg) {
            display: block;
            margin-left: 0;
            margin-right: 0;
            .slick-list{padding:0 30% 0 0 !important;}
            .slick-slide:not(.slick-current.slick-active) {
                opacity: 0.2;
            }
            .slick-track {
                display: flex !important;
            }
            .slick-slide {
                height: inherit !important;
            }
            .m99-step {
                margin-right: 12px;
            }
        }
        
        .slick-dots {
            li {
                width: 13px;
                height: 13px;
                margin: 0 1px;
                button {
                    width: 13px;
                    height: 13px;
                    padding: inherit;
                    &::before {
                        font-size: 11px;
                        color: #d9d9d9;
                        opacity: 1;
                        width: 13px;
                        height: 13px;
                        line-height: 1;
                    }
                }
                &.slick-active {
                    button::before {
                        color: $blue;
                    }
                }
            }
        }
    }
    .col-md-9 {
        .m99-steps {
             > div {
                width: 33.3333%;
                flex: 0 0 auto;
                padding-left: 12px;
                padding-right: 12px;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
                &:first-of-type {
                    .m99-step {
                        background: #0064C8;
                    }
                }
                &:last-of-type {
                    .m99-step {
                        background: rgba(0, 100, 200, 0.50); 
                    }
                }
                .m99-step {
                    background: rgba(0, 100, 200, 0.75); 
                    height: 100%;
                    padding: 20px 0 187px;
                    color: #fff;
                    border-radius: 8px;
                    position: relative;
                    p {
                      font-size: 18px;
                      margin: 0 0 24px;
                      padding: 0 20px;
                      &.title {
                        margin: 0 0 20px;
                        line-height: 1;
                        font-weight: 900;
                      }
                    }
                    .img {
                        width: 100%;
                        height: 187px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                    img {
                        margin-left: auto;
                        display: block;
                        max-width: calc(100% - 20px);
                        &.has_l_padding {
                            margin: 0 auto;
                        }
                    }
                    @include media-breakpoint-down(lg) {
                        background: #0064C8 !important;
                    }
                }
            }

        }
    }
    .col-12 {
        .m99-steps {
             > div {
                width: 25%;
                flex: 0 0 auto;
                padding-left: 12px;
                padding-right: 12px;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
                .m99-step {
                    background: #E5EFF9; 
                    height: 100%;
                    padding: 20px;
                    color: #000;
                    border-radius: 8px;
                    p {
                      font-size: 18px;
                      margin: 0 0 24px;
                      &.title {
                        margin: 0 0 20px;
                        line-height: 1;
                        font-weight: 900;
                      }
                    }
                    img {
                        margin-left: auto;
                        display: block;
                        margin-bottom: 24px;
                    }
                }
            }

        }
    }

































    .steps-slider3, .steps-slider4 {
        @include media-breakpoint-down(md) {
            .slick-list{padding:0 30% 0 0 !important;}
        }
        .slick-track {
            display: flex !important;
            gap: 12px;

            @include media-breakpoint-down(md) {
                gap: inherit;
                
            }
            .slick-slide {
                background-color: rgba(0, 100, 200, 0.75); 
                height: inherit !important;
                border-radius: 8px;
                color: #fff;
                @include media-breakpoint-down(md) {
                    background-color: $blue;
                    margin-right: 12px;
                    &:not(.slick-active) {
                        opacity: 0.2;
                    }
                }
                &:first-of-type {
                    background-color: $blue;
                }
                &:last-of-type {
                    background-color: rgba(0, 100, 200, 0.50);
                }
                .m99-step {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    height: 100%;
                    &.has-cta{
                        position: relative;
                        padding-bottom: 80px;
                    }
                    p {
                        padding: 20px;
                        margin: 0;
                        font-size: 18px;
                        &.title {
                            padding-bottom: 0;
                            font-size: 22px;
                            font-weight: $black-bold;
                        }
                    }
                    img {
                        align-self: self-end;
                        &.has_l_padding {
                            margin-top: auto;
                            align-self: center;
                        }
                    }
                    // .btn{
                    //     width: calc(100% - 40px);
                    //     margin: 0 20px 32px;
                    //     position: absolute;
                    //     bottom: 0px;
                    //     font-size: 22px;
                    //     text-align: center;
                    //     font-weight: 900;
                    //     line-height: 1.1;
                    //     padding: 12px 24px;
                    //     border-radius: 8px;
                    //     &:lang(hu) {
                    //         font-size: 20px;
                    //         padding: 12px 20px;
                    //     }
                    //     &:lang(tr), & :lang(pl) {
                    //         font-size: 18px;
                    //         padding: 12px 20px;
                    //     }
                    // }
                }
            }
        }
        .slick-dots {
            li {
                width: 13px;
                height: 13px;
                margin: 0 1px;
                button {
                    width: 13px;
                    height: 13px;
                    padding: inherit;
                    &::before {
                        font-size: 11px;
                        color: #d9d9d9;
                        opacity: 1;
                        width: 13px;
                        height: 13px;
                        line-height: 1;
                    }
                }
                &.slick-active {
                    button::before {
                        color: $blue;
                    }
                }
            }
        }
    }
    .steps-slider4 {
        .slick-track {
            gap: inherit;
            .slick-slide {
                margin-right: 24px;
                background-color: #E5EFF9 !important;
                color: #000;
                &:last-child {
                    margin-right: 0;
                }
                .m99-step {
                    display: block;
                    img {
                        height: 150px;
                        width: auto;
                        margin: 0 auto;
                    }
                    p.title {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .m99-cta {
        text-align: center;
        margin-top: 45px;
        // margin-bottom: -96px;
        // @include media-breakpoint-down(md) {
        //     margin-bottom: -64px;
        // }
        @include media-breakpoint-down(md) {
            padding-right: 12px;
        }
        a {
            min-width: 365px;
            font-size: 22px;
            font-weight: $black-bold;
            border-radius: 8px; 
            @include media-breakpoint-down(md) {
                min-width: inherit;
                width: 100%;
            }
        }
    }
}
