.m67 {
    padding: 64px 0;
    @include media-breakpoint-down(lg) {
        padding: 50px 0;
    }
    h2 {
        color: $blue;
    }
    .values {
        .accordion-item {
            border-bottom: 1px solid #979797;
            font-size: 18px;
            color: #000;
            margin: 0;
            &:first-of-type {
                border-top: 1px solid #979797;
            }
            p.value-title {
                margin: 0;
                font-weight: 900;
                padding: 15px 25px;
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    right: 10px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                    display: block;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=);
                    width: 7px;
                    height: 7px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    transition: all .2s;
                    transform: rotate(90deg);
                }
                &[aria-expanded="false"] {
                    &::before {
                        transition: all .2s;
                        transform: rotate(-90deg);  
                    }
                }
            }
            .accordion-body {
                background-color: #f7f7f7;
                margin: 0;
                p {
                    font-weight: 400;
                }
            }
        }
    }
}