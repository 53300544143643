.m01new {
    position: relative;
    overflow: hidden;
    &.has-bg{
        &::before{
            content: '';
            position: absolute;
            height: 100%;
            width: 50%;
            right: 0;
            background-image: var(--bg);
            background-position: left center;
            background-size: cover;
            background-repeat: no-repeat;
            &:lang(ar) {
                right: auto;
                left: 0;
                background-position: right center;
            }
        }
    }
    .const-temp {
        display: none;
    }

    .home-hero {
        background: linear-gradient(to right, #013971 0%, #0164c9 50%, #0164c9 50%, #0164c9 100%);
        padding: 31px 0 34px;
        &:lang(ar) {
            background: linear-gradient(to left, #013971 0%, #0164c9 50%, #0164c9 50%, #0164c9 100%);

            @include media-breakpoint-down(md) {
                padding: 20px 20px 40px;
                background: linear-gradient(to top, #013971 0%, #0164c9 50%, #0164c9 50%, #0164c9 100%);
            }
        }
        

        @include media-breakpoint-down(md) {
            padding: 20px 20px 40px;
            background: linear-gradient(to top, #013971 0%, #0164c9 50%, #0164c9 50%, #0164c9 100%);
        }

        &.mobile-device {
            background: linear-gradient(to right, #013971 0%, #0064c8 50%, #0064c8 50%, #0064c8 100%);
            &:lang(ar) {
                background: linear-gradient(to left, #013971 0%, #0064c8 50%, #0064c8 50%, #0064c8 100%);
    
                @include media-breakpoint-down(md) {
                    background: linear-gradient(to top, #013971 0%, #0064c8 50%, #0064c8 50%, #0064c8 100%);
                } 
            }

            @include media-breakpoint-down(md) {
                background: linear-gradient(to top, #013971 0%, #0064c8 50%, #0064c8 50%, #0064c8 100%);
            }
        }

        &.mac-os {
            background: linear-gradient(to right, #013971 0%, #0070cf 50%, #0070cf 50%, #0070cf 100%);
            &:lang(ar) {
                background: linear-gradient(to left, #013971 0%, #0070cf 50%, #0070cf 50%, #0070cf 100%);
    
                @include media-breakpoint-down(md) {
                    background: linear-gradient(to top, #013971 0%, #0070cf 50%, #0070cf 50%, #0070cf 100%);
                }
            }
            @include media-breakpoint-down(md) {
                background: linear-gradient(to top, #013971 0%, #0070cf 50%, #0070cf 50%, #0070cf 100%);
            }
        }

        &.iphone {
            background: linear-gradient(to top, #013971 0%, #0064c8 50%, #0064c8 50%, #0064c8 100%) !important;
            &:lang(ar) {
                background: linear-gradient(to top, #013971 0%, #0064c8 50%, #0064c8 50%, #0064c8 100%) !important;
            }
        }
        &.home-hero-white{
            background: $white !important;
            padding: 70px 0 70px;
            h1{
                color: $black !important;
                background: #F8F8F8;
                border-radius: 6px;
                display: inline-block;
                padding: 6px 15px;
            }
            .title{
                font-size: 65px;
                color: $black !important;
                margin: 0 auto 30px;
                @include media-breakpoint-down(md) {
                    font-size: 54px;
                }
            }
            h2.bigger{
                color: $black !important;
                margin: 0 auto 46px;
            }
            .mockups{
                &.image-mockup {
                    height: 439px;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin-left: 50px;
                }
            }
            .app-stores{
                opacity: 1;
                .app-store{
                    img{
                        filter: invert(100%);
                    }
                }
            }
        }
        .content {
            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
            }
        }

        .title {
            color: $white;
            font-size: 70px;
            font-weight: $black-bold;
            line-height: 1.1;
            margin: 0 0 25px;
            hyphens: initial;
            &.smaller-title {
                font-size: 62px;
                @include media-breakpoint-down(md) {
                    max-width: inherit;
                    font-size: 28px;
                }
            }
            @include media-breakpoint-down(xl) {
                font-size: 40px;
                margin: 38px auto 8px;
            }

            @include media-breakpoint-down(md) {
                font-size: 40px;
                margin-top: 0;
            }

            &.d-md-none {
                text-align: center;
            }
        }

        .pre-title {
            font-size: 18px;
            color: #fff;
            font-weight: $black-bold;
            margin: 0 0 20px;
            &:lang(ar) {
                line-height: 1.5;
            }
            @include media-breakpoint-down(md) {
                margin: 0 0 10px;
            }
        }

        p {
            color: #fff;
            font-weight: $black-bold;
            margin: 0 0 20px;
            font-size: 18px;

            @include media-breakpoint-down(md) {
                margin: 0 0 10px;
            }

            &.d-md-none {
                max-width: 300px;
                
            }
        }

        .bigger {
            color: #fff;
            font-weight: $black-bold;
            font-size: 27px;
            line-height: 1.25;
            margin: 0 0 60px;
            hyphens: initial;
            &:lang(ar) {
                letter-spacing: 0;
            }
            @include media-breakpoint-down(md) {
                margin: 15px 0 34px 0;
                font-size: 27px;
            }
        }

        .app-stores {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 70px;
            opacity: 0.7;

            @include media-breakpoint-down(md) {
                margin-top: 15px;
                justify-content: center;
            }
        }

        .app-store {
            &.black-btn {
                margin-top: 20px;
                width: 280px;
                height: 50px;
                display: block;

                img {
                    height: 100%;
                    width: auto;
                }

                @include media-breakpoint-down(md) {
                    min-width: 340px;
                    width: 92%;
                    max-width: 100%;
                    height: 62px;
                    text-align: center;
                    margin: 20px auto;
                }
            }
        }

        .mockups {
            margin-left: 120px;
            position: relative;
            height: 596px;
            width: auto;
            overflow-y: clip;
            @media (max-width: 1652px) {
                height: 395px;
            }

            @media (max-width: 1339px) {
                height: 395px;
            }

            @include media-breakpoint-down(xl) {
                margin-left: 32px;
                height: 395px;
            }

            @include media-breakpoint-down(lg) {
                height: 309px;
                margin-left: 0;
            }

            @include media-breakpoint-down(md) {
                max-width: 100%;
                max-height: 294px;
                text-align: center;
            }

            video {
                position: relative;
                position: absolute;
                top: 0;
                bottom: 0;
                height: 600px;
                width: auto;

                @media (max-width: 1652px) {
                    height: 398px;
                }

                @media (max-width: 1339px) {
                    height: 397px;
                }

                @include media-breakpoint-down(xl) {
                    height: 397px;
                }

                @include media-breakpoint-down(lg) {
                    height: 313px;
                }

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                    max-height: 300px;
                }

                @include media-breakpoint-down(md) {
                    position: relative;
                }
            }

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                height: 439px;
                width: auto;

                @media (max-width: 1652px) {
                    height: 398px;
                }

                @media (max-width: 1339px) {
                    height: 397px;
                }

                @include media-breakpoint-down(xl) {
                    height: 397px;
                }

                @include media-breakpoint-down(lg) {
                    height: 313px;
                }

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                    max-height: 300px;
                }

                @include media-breakpoint-down(md) {
                    position: relative;
                }
            }

            &.image-mockup {
                height: 439px;
                margin-top: 98px;
                margin-bottom: 98px;

                @media (max-width: 1652px) {
                    height: 398px;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                @media (max-width: 1555px) {
                    height: 330px;
                }

                @include media-breakpoint-down(xl) {
                    width: 100%;
                }

                @include media-breakpoint-down(lg) {
                    width: 100% !important;
                }

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                    max-height: 300px;
                    height: auto;
                }

                img {
                    @media (max-width: 1652px) {
                        height: 398px;
                    }

                    @media (max-width: 1555px) {
                        height: 330px;
                    }

                    @include media-breakpoint-down(xl) {
                        height: auto;
                        width: 100%;
                    }

                    @include media-breakpoint-down(lg) {
                        height: auto;
                        width: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                        max-height: 300px;
                        width: initial;
                    }
                }
            }
        }
        &.home-hero-blue1 {
            padding: 0;
            p {
                max-width: inherit;
                margin-top: 25px;
            }
            .mockups.image-mockup {
                margin-top: 98px !important;
                margin-bottom: 0;
                @include media-breakpoint-down(md) {
                    margin-top: 0 !important;
                }
            }
            .col-md-6:first-child {
                padding-top: 25px;
            }
            .col-md-6:last-of-type {
                align-self: self-end !important;
            }
            .pr-btn-group {
                margin-bottom: 25px;
            }
        }
    }
}
.m01new2 {
    height: 600px;
    padding: 32px 0 0;
    // padding: 70px 0;
    @include media-breakpoint-down(xl) {
        height: inherit;
    }
    .container, .row {
        height: 100%;
        @include media-breakpoint-up(md) {
            .col-md-6:first-of-type {
                padding-right: 0;
            }
            .col-md-6:last-of-type {
                padding-left: 0;
            }
        }
    }
    h1 {
        display: inline-block;
        font-size: 16px;
        padding: 8px 12px;
        background-color: rgba(248, 248, 248, 1);
        border-radius: 8px;
        font-weight: $black-bold;
        margin: 0;
        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }
    }
    h2 {
        font-size: 50px;
        color: $blue;
        font-weight: $black-bold;
        margin: 32px 0;
        letter-spacing: inherit;
        @include media-breakpoint-down(lg) {
            font-size: 40px;
            margin: 32px 0 28px;
        }
    }
    h3 {
        font-size: 27px;
        font-weight: $black-bold;
        margin-bottom: 0;
        max-width: 90%;
        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
            max-width: 100%;
        }
    }
    .img-fluid{
        width: 100%;
    }
    @include media-breakpoint-down(md) {
        h3 + .btns {
            display: none;
        }
    }
    .app-stores {
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-down(md) {
            display: none;
        }
        .app-store {
            display: inline-block;
            img {
                filter: invert(100%);
                opacity: 0.4;
                width: 132px;
                height: auto;
            }
            
        }
    }
    &.has-bg {
        &:not(.boxed){
            background-color:  #F8F8F8;
            background-image: var(--bg);
            // background-position: right center;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        @include media-breakpoint-down(xl) {
            // background-position: center;
            padding: 30px 0;
        }
        @include media-breakpoint-down(lg) {
            background-image: var(--mbg);
            background-position: center bottom;
            padding-bottom: 100vw;
        }
        @include media-breakpoint-down(md) {
            background-image: var(--mbg);
            background-size: cover;
            padding-bottom: 100vw;
        }
        &.bg-left{
            // background-position: left center;
            // @include media-breakpoint-down(xl) {
            //     background-position: center;
            // }
        }
    }
    &.boxed{
        background-color: $white;
        padding: 0;
        @include media-breakpoint-down(lg) {
            padding-bottom: 100vw;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .container{
            background-image: var(--bg);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: cover;
            padding-top: 70px;
            padding-bottom: 70px;
            @include media-breakpoint-down(lg) {
                background-image: none;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        &.bg-left{
            .container{
                background-position: left center;
            }
        }
    }
    &.m01-grey {
        background-color: #F8F8F8;
        // padding: 111px 0;
        height: auto;
        min-height: 600px;
        @include media-breakpoint-down(md) {
            padding: 32px 0;
        }
        h2 {
            // font-size: 70px;
            @include media-breakpoint-down(md) {
                // font-size: 40px;
            }
        }
        h3 {
            font-size: 27px;
            // color: #003264;
            font-weight: 900;
        }
    }
    &.m01-bg-img {
        height: 700px;
        position: relative;
        @include media-breakpoint-down(lg) {
            padding-bottom: inherit;
        }
        @include media-breakpoint-down(md) {
            height: 820px;
            background-image: var(--mbg) !important;
            br {
                display: none;
            }
            .btns, .app-stores {
                display: block !important;
            }
            .app-store {
                width: 100%;
            }
            .container .row {
                align-items: inherit !important;
            }
            .app-stores.all_apps {
                .app-store {
                    width: inherit;
                }
            }
        }
        &.has-dark-bg::before {
            content: "";
            background-image: linear-gradient(-264deg, #000 -43.01%, rgba(217, 217, 217, 0.00) 90%); 
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        &.has-dark-bg:lang(ar):before {
            transform: scaleX(-1);
        }
        h1, h2, h3, .app-store {
            position: relative;
            z-index: 2;
        }
        h2, h3 {
            color: #fff;
        }
        .col-lg-8 h2, .col-lg-8 h3 {
            max-width: 570px;
        }
        .app-stores .app-store img {
            filter: inherit;
            opacity: 1;
        }
    }
    .flex-row-reverse {
        .full_img {
            width: 100%;
            display: block;
            margin: 0;
        }
    }
    .full_img {
        width: calc(100% - 40px);
        margin: 0 0 0 40px;
        display: block;
        @include media-breakpoint-down(md) {
            width: inherit;
            margin: 0;
        }
    }
    &.light-img {
        background-image: inherit !important;
        // background-position: right center !important;
        // background-size: auto 470px !important;
        background-color: #F2F7FC !important;
        position: relative;
        @include media-breakpoint-down(xl) {
            background-size: 30%  !important;
        }
        @include media-breakpoint-down(lg) {
            background-image: none !important;
            padding: 0 0 32px;
        }
        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: calc(50% - 185px);
            max-height: 400px;
            height: 100%;
            width: calc((100% - 1128px)/2 + 190px);
            background-image:  var(--bg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: left center;
        }
        &:lang(ar)::after {
            right: inherit;
            left: 0;
            background-position: right center;
        }
        @media (min-width: 1980px) {
            &::after {
                background-size: contain;
                // background-position: right center;
            }
        }
        @include media-breakpoint-down(lg) {
            &::after {
                content: inherit;
            }
        }
        .col-lg-3.order-lg-0 img {
            max-height: 400px;
            max-width: 208px;
            height: auto;
            width: auto;
            @include media-breakpoint-down(lg) {
                width: 100%;
                height: auto;
                max-width: 450px;
                max-height: inherit;

            }
        }
    }
    &.m72-lp {
        height: inherit;
        min-height: 524px;
        padding: 38px 0;
        @include media-breakpoint-down(lg) {
            padding: 24px 0;
        }
        &::before {
            content: none;
        }
        .row {
            align-items: inherit !important;
        }
        h1 {
            margin-top: 28px;
            margin-bottom: 32px;
            @include media-breakpoint-down(lg) {
                margin-top: inherit;
                line-height: 1.5;
                max-width: 290px;
            }
        }
        h2 {
            margin-bottom: 32px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10); 
            font-size: 50px;
            line-height: 1.15;
            @include media-breakpoint-down(lg) {
                font-size: 36px;
                line-height: 1.25;
            }
        }
        h3 {
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10); 
            margin-bottom: 64px;
            font-size: 27px;
            @include media-breakpoint-down(lg) {
                margin-bottom: 32px;
            }
        }
        .col-lg-7 > div {
            display: block !important;
        }
        .book-demo-form {
            background-color: #fff;
            padding: 20px 35px;
            border-radius: 8px;
            position: relative;
            z-index: 9;
            @include media-breakpoint-down(lg) {
               margin: 5px auto 0;
               .mb-md-0 {
                margin-bottom: 1rem;
               }
            }
            .contact-select-country {
                height: 100%;
                button {
                    height: 100%;
                    margin: 0 !important;
                    background-color: transparent;
                    .filter-option-inner {
                        display: flex;
                        align-items: center;
                        height: 100%;
                    }
                }
            }

            .m72-form-title {
                font-size: 23px;
                margin-bottom: 20px;
                line-height: normal;
            }
        }
    }
    .m01-awards {
        display: flex;
        gap: 20px;
        position: relative;
        z-index: 3;
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
        .award {
          width: 125px;
          img {
            width: 100%;
            height: auto;
          }
        }
    }
}