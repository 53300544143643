.blog-post {
  background-color: #e9f0fa;
  padding-bottom: 50px;
  .blog-post-hero {
      height: 450px;
      background-position: center;
      background-size: cover;
  }
  article {
      background-color: #FFF;
      padding: 0 25px 25px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
      border-radius: 10px;
      margin-top: -150px;
      header {
          border-bottom: 1px solid #e9f0fa;
          padding: 10px 0 10px 10px;
          margin-bottom: 15px;
          nav {
              ol {
                  padding: 0;
                  margin: 0;
                  li {
                      font-size: 16px;
                      a {
                          color: #1d62ad;
                      }
                  }
                  .breadcrumb-item + .breadcrumb-item::before {
                      content: "";
                      width: 0;
                      height: 0;
                      border-top: 5px solid transparent;
                      border-bottom: 5px solid transparent;
                      border-left: 5px solid #1d62ad;
                      margin-top: 7px;
                  }
                  .breadcrumb-item + .breadcrumb-item:lang(ar)::before {
                    float: right;
                    transform: rotate(180deg);
                  }
                  .breadcrumb-item + .breadcrumb-item:lang(ar) {
                    padding-left: inherit;
                    padding-right: 0.5rem;
                  }

              }
          }
      }
      .post-meta {
          font-size: 12px;
          color: #3d4548;
          margin: 0 0 25px;
          padding: 0 10px;
      }
      h1 {
          font-size: 24px;
          color: #1d62ad;
          font-weight: 700;
          margin: 0 0 8px;
          padding: 0;
      }
      p {
          font-size: 16px;
          color: #3d4548;
          margin: 0 0 16px;
      }
      .post-content {
          >div {
            &:not(.whitepaper_banner_container) {
              max-width: 100% !important;
            }
          }
          h2 {
              font-size: 16px;
              font-weight: 700;
              color: $blue;
              margin-top: 20px;
              letter-spacing: 1px;
          }
          h3{
            font-size: 16px;
            font-weight: 700;
            color: $blue;
            letter-spacing: 1px;
          }
          a {
              color: $blue;
              &.btn.btn-pr-green{
                background-color: $green;
                color: $white;
                border-color: $green;
                font-weight: 700;
                padding: 10px 25px;
              }
          }
          ul,
          ol {
              padding-left: 17px;
              margin-bottom: 20px;
              li {
                  color: #3d4548;
              }
          }
          ul > li {
              list-style: initial;
          }
          h6 {
              color: #3d4548;
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 8px;
          }
          .blog-cta {
              text-align: center;
              a {
                  font-weight: 900;
                  color: #FFF;
              }
          }
          img{
            margin: 20px auto;
          }
          table{
            max-width: 100%;
            overflow-x: auto;
            display: block;
            height: auto !important;
            th,td{
              min-width: 160px;
            }
          }
      }
  }
  .author-card{
    padding: 48px 32px;
    border-radius: 8px;
    background: var(--background-colors-light-grey, #F8F8F8);
    margin: 32px 0;
    @include media-breakpoint-down(md) {
      padding: 32px 16px;
    }
    .bio{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 0 68px 24px;
      @include media-breakpoint-down(lg) {
        padding: 0 0 24px;
      }
      @include media-breakpoint-down(md) {
        align-items: flex-start;
        flex-direction: column;
      }
      .author{
        display: flex;
        flex-direction: row;
        @include media-breakpoint-down(lg) {
          max-width: 63%;
        }
        @include media-breakpoint-down(md) {
          flex-direction: column;
          max-width: 100%;
        }
        img{
          border-radius: 50%;
          margin-right: 22px;
          &:lang(ar) {
            margin-right: 0;
            margin-left: 22px;
          }
        }
        .pretitle{
          font-size: 16px;
          font-weight: $regular;
          line-height: 1.5;
          margin-bottom: 8px;
        }
        .author-name{
          font-size: 27px;
          font-weight: $black-bold;
          line-height: 1.25;
          margin-bottom: 8px;
        }
        .author-title{
          font-size: 16px;
          font-weight: $regular;
          line-height: 1.5;
        }
      }
    }
    .description{
      border-top: 1px solid #E1E1E1;
      padding: 24px 68px 0;
      @include media-breakpoint-down(lg) {
        padding: 24px 0 0;
      }
      p{
        font-size: 16px;
        font-weight: $regular;
        line-height: 1.5;
      }
    }
  }
  .related-posts {
      margin-top: 25px;
      h2 {
          text-align: center;
          font-size: 40px;
          margin: 0;
          color: #3d4548;
          font-weight: 300;
          letter-spacing: 1px;
      }
      .related-post {

          margin: 10px 0;
          padding: 5px 0px 10px;
          a {
              display: block;
              padding: 0 10px;
              p {
                  font-size: 16px;
                  color: #3d4548;
                  margin: 0;
                  font-weight: 400;
                  height: 100px;
                  overflow: hidden;
                  line-height: 1.5;
                  &:first-of-type {
                      color: #1d62ad;
                      font-weight: 900;
                      margin: 10px 0 25px;
                      height: 50px;
                  }
              }
              img{
                max-height: 217px;
              }
          }

      }
  }
}
.sidebar {
  .sidebar-search,
  .sidebar-featured, 
  .sidebar-social {
      background-color: #FFF;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
      border-radius: 10px;
      padding: 15px 25px;
      margin: 20px 0 0;
  }
  p {
      font-size: 16px;
      margin: 0;
      &.sidebar-heading {
          font-weight: 700;
          color: #1d62ad;
          text-align: center;
          margin-bottom: 16px;
      }
  }
  .sidebar-search {
      .pr-btn {
          margin-top: 5px;
          width: 100%;
          font-size: 16px;
      }
  }
  .sidebar-featured {
      ul {
          list-style: none;
          padding: 0;
          li {
              border-bottom: 1px solid #e9f0fa;
              padding: 10px;
              &:first-child {
                  border-top: 1px solid #e9f0fa;
              }
          }
      }
  }
  .sidebar-social {
      p:not(.sidebar-heading) {
          padding: 10px;
          border-top: 1px solid #e9f0fa;
          border-bottom: 1px solid #e9f0fa;
      }
      ul {
          margin-top: 16px;
          li {
              display: inline-block;
              padding-right: 10px;
              &:last-child {
                  padding-right: 0;
              }
          }
      }
  }
  &.v2 {
    .sidebar-social,
    .sidebar-featured {
      padding: 15px 0;
      margin: 0;
      .sidebar-heading {
        padding: 0;
      }
    }
    .sidebar-featured ul li {
      padding: 10px 0;
    }
  }
}

//SHORTCODES
/* QUOTE / AUTHOR SHORTCODE */
.quote_box_white,
.quote_box_blue,
.author_box_white,
.author_box_blue {
margin-left: -35px;
margin-right: -35px;
margin-bottom: -15px;

padding: 25px;
}
.author_box_white,
.author_box_blue {
border-radius: 0 0 10px 10px;
}
.quote_box_white,
.author_box_white {
color: #000000;
}
.quote_box_blue,
.author_box_blue {
background-color: #003264;
background-image: linear-gradient(-90deg, #0064C8 0%, #003264 100%);
p {
  color: #FFFFFF !important;
}
}
.quote_box_white,
.quote_box_blue {
margin-top: 25px;
margin-bottom: 25px;
}
img.author_profile_pic {
width: 175px;
border-radius: 50%;
}
.author_box_white h4,
.author_box_blue h4 {
font-weight: 900;
font-size: 20px;
}
.author_box_white h4 {
color: #0064C8;
}
.author_box_white h5,
.author_box_blue h5 {
font-weight: 900;
font-size: 20px;
color: #FFFFFF;
}
.author_box_white h5 {
color: #000000;
}
.quote_box_blue h5 {
color: #FFFFFF;
}
.quote_box_white h5,
.quote_box_blue h5 {
font-weight: 300;
font-size: 20px;
}
.quote_box_white h5 span,
.quote_box_blue h5 span {
font-weight: 900;
}
.quote_box_font_larger p {
font-size: 30px !important;
}
.author_box_white p,
.author_box_blue p {
font-size: 20px;
font-weight: 300;
}
.quote_box_white p,
.quote_box_blue p {
font-size: 20px;
font-weight: 900;
font-style: italic;
margin-bottom: 25px;
}
.quote_box_white,
.quote_box_blue {
position: relative;
z-index: 9;
}
.quote_box_white::before,
.quote_box_blue::before {
content: "";
background-image: url('images/quotes.svg');
width: 70px;
height: 70px;
display: block;
transform: rotate(180deg);
position: absolute;
top: -5px;
left: 0;
opacity: 0.15;
}
.quote_box_white::after,
.quote_box_blue::after {
content: "";
background-image: url('images/quotes.svg');
background-repeat: no-repeat;
background-size: cover;
width: 200px;
height: 100px;
display: block;
position: absolute;
bottom: -5px;
right: 25px;
opacity: 0.15;
z-index: -9;
}
.quote_box_blue::before,
.quote_box_blue::after {
filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(17deg) brightness(105%) contrast(101%);
}
.quote_box_white::before,
.quote_box_white::after {
filter: invert(32%) sepia(72%) saturate(1049%) hue-rotate(188deg) brightness(97%) contrast(95%);
}

@media (max-width: 767.9px) {
.quote_box_white,
.quote_box_blue,
.author_box_white,
.author_box_blue {
  margin-left: -25px;
  margin-right: -25px;
}
img.author_profile_pic {
  margin-bottom: 25px;
  width: 148px;
}
.author_box_white h4,
.author_box_blue h4 {
  font-size: 18px;
}
.quote_box_white h5,
.quote_box_blue h5,
.author_box_white h5,
.author_box_blue h5,
.quote_box_white p,
.quote_box_blue p,
.author_box_white p,
.author_box_blue p {
  font-size: 15px;
}
.quote_box_white h5 span,
.quote_box_blue h5 span {
  display: block;
}
.quote_box_font_larger p {
  font-size: 22px !important;
}
}
@media (min-width: 767.99px) and (max-width: 1199.98px) {
.quote_box_blue > .row > .col-md-9,
.quote_box_white > .row > .col-md-9,
.author_box_blue > .row > .col-md-9,
.author_box_white > .row > .col-md-9 {
  padding-left: 15px;
}
.author_box_white > .row,
.author_box_blue > .row {
  align-items:inherit !important;
}
}
.post-content {
.wp-block-button {
  text-align: center;
  width: 100%;
  display: block;
  .wp-block-button__link {
    background-color: #28965a;
    font-weight: 700;
    padding: 10px 25px;
    color: #FFF !important;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
    border: 1px solid #28965a;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    display: inline-block;
    &:hover {
      background-color: #1e7a48;
      border-color: #1e7a48;
    }
  }
}
}

// SHORTCODES
/* W H I T E  P A P E R */
.whitepaper_banner_container {
background-position: center;
margin: -36px 0px 35px;
padding: 33px 25px 70px;
background-repeat: no-repeat;
}
.whitepaper_banner_title {
font-weight: 300;
font-size: 28px !important;
color: #FFFFFF !important;
letter-spacing: 0;
padding-top: 80px;
margin-bottom: 0;
}
.whitepaper_banner_subtitle {
font-weight: 900;
font-size: 16px !important;
color: #FFFFFF !important;
letter-spacing: 0;
}
.whitepaper_banner_container_content {
height: 270px;
background-repeat: no-repeat;
/*background-position: bottom -25px left -50px;*/
background-position: bottom left;
background-size: 250px auto;
}
.whitepaper_banner_container_content a {
font-size: 16px;
padding: 10px 30px;
margin-top: 50px;
}
.hs-btn {
color: #FFFFFF !important;
font-weight: 700;
padding: 10px 25px;
font-size: 1.25rem;
}


@media (max-width: 767.98px) {
.whitepaper_banner_title {
  font-size: 26px;
  padding-top: 40px !important;
}
.whitepaper_banner_subtitle{
  margin-bottom: 35px !important;
}
.whitepaper_banner_container {
  width: calc(100% + 24px);
  margin-left: -12px !important;
  background-size: cover;
}
.whitepaper_banner_container_content {
  background-image: none !important;
  padding: 0 12px;
}
.whitepaper_banner_container_content a {
  margin-top: 0;
  margin-left: 0 !important;
}
.blog-post.v2 article .post-content p {
  margin-bottom: 15px !important;
}
}
@media (max-width: 1199.98px) {
.whitepaper_banner_container {
  margin: -3px 0px;
  padding: 20px 0 4px;
}
.whitepaper_banner_container_content {
  height: 260px;
  background-size: 220px auto;
}
.whitepaper_banner_title {
  padding-top: 65px;
}
.whitepaper_banner_container_content a {
margin-top: 0;
margin-left: 100px;
}
}
@media (max-width: 991px) {
.whitepaper_banner_title {
  padding-top: 65px;
  font-size: 24px;
}
}



// PAGINATION
.pr_pagination {
li {
  margin-left: 8px;
  &:first-child {
    margin-left: 0;
  }
  .page-link {
    border: none;
    border-radius: inherit !important;
    border: 1px solid #FFF;
    color: #000;
    &.dots {
      background-color: #e9f0fa;
      border-color: #e9f0fa;
      &:hover {
        border-color: #e9f0fa;
      }
    }
    &:hover {
      border-color: #1D62AD;
    }
    &.current {
      color: #fff;
      background-color: #1D62AD;
      border-color: #1D62AD;
      // padding: 8px 11px 10px;
    }

  }
}
}

.pr-video-container {
position: relative;
padding-bottom: 56.25%;
padding-top: 30px;
height: 0;
overflow: hidden;
iframe, object, embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
}


.blog-post.v2 {
  background-color: #FFF;
  article {
    background-color: #FFF;
    padding: 80px 50px 25px 0;
    box-shadow: unset;
    border-radius: unset;
    margin-top: 0;
    @include media-breakpoint-down(md) {
      padding: 25px 0;
    }
    a.back {
      font-weight: 300;
      font-size: 18px;
      color: #000;
      margin-bottom: 35px;
    }
    header {
        border-bottom: 1px solid #D4D4D4;
        margin: 25px 0 50px;
        padding: 0 0 35px;
        @include media-breakpoint-down(md) {
          padding-bottom: 20px;
        }

        h1 {
          font-size: 60px;
          color: #1d62ad;
          font-weight: 900;
          margin-bottom: 35px;
          @include media-breakpoint-down(md) {
            font-size: 40px;
          }
        }
        p.date {
          text-align: right;
          font-weight: 300;
          font-size: 16px;
          margin: 0;
        }
      }
      .post-content {
        p {
          font-size: 18px;
          font-weight: 300;
          margin-bottom: 25px;
          color: $black;
          strong, b {
            font-weight: 900;
          }
        }
          h2 {
              font-size: 26px;
              font-weight: 900;
              color: $black;
              letter-spacing: 1px;
              margin: 35px 0 25px;
          }
          h3{
            font-size: 24px;
            font-weight: 700;
            color: $black;
            margin: 35px 0 25px;
            letter-spacing: 1px;
          }
          a {
              color: $blue;
              &.btn.btn-pr-green{
                background-color: $green;
                color: $white;
                border-color: $green;
                font-weight: 700;
                padding: 10px 25px;
              }
          }
          ul,
          ol {
            font-size: 18px;
            padding-left: 17px;
            margin-bottom: 20px;
            font-weight: 300 !important;
            li {
                color: #000;
            }
          }
          ul > li {
              list-style: initial;
          }
          h6 {
              color: #3d4548;
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 8px;
          }
          .blog-cta {
              text-align: center;
              a {
                  font-weight: 900;
                  color: #FFF;
              }
          }
          img{
            margin: 20px auto;
            max-width: 100%;
            height: auto;

          }
          table{
            @extend .table;
          }
      }
    }
}
.sidebar.v2 {
  position: relative;
  .sidebar_v2 {
    position: sticky;
    top: 75px;
    >div {
      box-shadow: none;
    }
    p.sidebar-heading {
      text-align: left;
      padding: 0;
      &:lang(ar) {
        text-align: right;
      }
    }
  }
}