.m81 {
    padding: 40px 0;
    position: relative;
    h2{
        color: $blue;
        font-weight: $black-bold;
        font-size: 54px;
        line-height: 65px;
        @include media-breakpoint-down(md){
            font-size: 40px;
            line-height: 48px;
        }
    }
    .map{
        margin: 34px 0;
        width: 100%;
        height: 670px;
        @include media-breakpoint-down(lg){
            height: 440px;
        }
        @include media-breakpoint-down(md){
            height: 320px;
            margin: 34px 12px;
        }
        .gm-style-iw{
            padding: 12px !important;
            @include media-breakpoint-down(md){
                padding: 12px 8px;
            }
        }
        .gm-style-iw-d{
            overflow: auto !important;
        }
        .info_content{
            text-align: center;
            padding: 10px 0;
            @include media-breakpoint-down(md){
                padding: 0;
            }
            .info_location_name{
                font-weight: $black-bold;
                font-size: 14px;
                line-height: 17px;
                @include media-breakpoint-down(md){
                    margin-bottom: 5px;
                }
                span{
                    font-weight: $light;
                }
            }
            .info_location_text{
                img{
                    display: inline-block;
                    width: 151px;
                    height: 122px;
                    margin: 5px 2px;
                    object-fit: cover;
                    @include media-breakpoint-down(md){
                        width: 110px;
                        height: 88px;
                    }
                    &+img{
                        max-width: calc(50% - 4px);
                    }
                }
                @include media-breakpoint-down(md){
                    margin-bottom: 5px;
                }
            }
            // .pr-btn{
            //     font-weight: $black-bold;
            //     font-size: 14px;
            //     &:focus{
            //         box-shadow: none;
            //     }
            //     @include media-breakpoint-down(md){
            //         font-size: 10px;
            //         padding: 3px 12px;
            //     }
            // }
        }
        .gm-ui-hover-effect{
            top: 4px !important;
            right: 4px !important;
        }
        .gm-style-mtc, .gm-fullscreen-control, .gm-svpc{
            display: none !important;
        }
    }
    .offices{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 10px 0px;
        gap: 10px;
        @include media-breakpoint-down(md){
            padding: 0px;
        }
        .office{
            width: calc(100%/5 - 8px);
            padding: 10px 30px;
            border-radius: 10px;
            cursor: pointer;
            &:hover,&.active{
                background: #F2F2F2;
            }
            @include media-breakpoint-down(md){
                width: calc(100%/3 - 8px);
                padding: 10px 8px;
            }
            p{
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 0;
                &.country{
                    font-weight: $black-bold;
                }
            }
        }
        .office-gallery{
            display: none;
            position: absolute;
            background-color: $white;
            width: 100%;
            height: 680px;
            top: 40px;
            filter: drop-shadow(0px 5px 50px rgba(0, 0, 0, 0.15));
            border-radius: 10px;
            padding: 22px 31px;
            flex-direction: column;
            justify-content: space-around;
            &.show{
                display: flex;
            }
            @include media-breakpoint-down(lg){
                height: 450px;
            }
            @include media-breakpoint-down(md){
                height: 330px;
                top: 30px;
                padding: 10px 7px;
                width: calc(100% - 24px);
                margin: auto;
                left: 0;
                right: 0;
            }
            h3{
                font-size: 18px;
                line-height: 22px;
                font-weight: $black-bold;
                margin-bottom: 20px;
                span{
                    font-weight: $light;
                }
            }
            .gallery{
                width: 100%;
                height: calc(100% - 100px);
            }
            .slick-list{
                width: 100%;
                height: 100%;
                .slick-track{
                    width: 100%;
                    height: 100%;
                    .slick-slide{
                        padding: 0 5px;
                        opacity: 0.5;
                        &.slick-active{
                            opacity: 1;
                        }
                    }
                }
            }
            img{
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
            .arrow {
                width: 38px;
                height: 46px;
                position: absolute;
                top: 50%;
                // bottom: 0;
                left: 50px;
                margin: auto;
                cursor: pointer;
                z-index: 99;
                @include media-breakpoint-down(lg){
                    width: 18px;
                    height: 20px;
                    left: 35px;
                    top: calc(50% + 20px) !important;
                }
                @include media-breakpoint-down(md){
                    width: 18px;
                    height: 20px;
                    left: 15px;
                }
                &.arrow-right {
                    right: 50px;
                    left: auto;
                    @include media-breakpoint-down(lg){
                        right: 35px;
                    }
                    @include media-breakpoint-down(md){
                        right: 15px;
                    }
                }
            }
            .close-office-gallery {
                position: absolute;
                top: 35px;
                right: 30px;
                @include media-breakpoint-down(md){
                    top: 25px;
                    right: 10px;
                }
            }
            .quote-container{
                background-color: $blue;
                padding: 160px 80px 80px !important;
                color: $white;
                position: relative;
                height: 100%;
                @include media-breakpoint-down(lg){
                    padding: 60px 40px 40px !important;
                }
                @include media-breakpoint-down(md){
                    padding: 40px 20px 20px !important;
                }
                &::before{
                    content: ' ';
                    background-image: url('images/quotes.svg');
                    position: absolute;
                    top: 70px;
                    width: 70px;
                    height: 70px;
                    background-repeat: no-repeat;
                    opacity: 0.5;
                    @include media-breakpoint-down(lg){
                        top: 20px;
                        width: 40px;
                        height: 40px;
                    }
                    @include media-breakpoint-down(md){
                        top: 10px;
                        width: 30px;
                        height: 30px;
                    }
                }
                .quote{
                    font-size: 30px;
                    font-weight: $black-bold;
                    max-width: 650px;
                    @include media-breakpoint-down(xl){
                        font-size: 28px;
                    }
                    @include media-breakpoint-down(lg){
                        font-size: 24px;
                    }
                    @include media-breakpoint-down(md){
                        font-size: 14px;
                    }
                }
                p{
                    font-weight: $light;
                    font-size: 20px;
                    @include media-breakpoint-down(lg){
                        font-size: 16px;
                    }
                    @include media-breakpoint-down(md){
                        font-size: 12px;
                    }
                    img{
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                        margin-right: 10px;
                        @include media-breakpoint-down(lg){
                            font-size: 16px;
                        }
                        @include media-breakpoint-down(md){
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}