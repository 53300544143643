$pr-green:          #28965a;
$pr-green-dark:     #217b4a;
$pr-green-darker:   #1a603a;

$pr-blue:           #0064c8;
$pr-blue-dark:      #0055AA;
$pr-blue-darker:    #004488;

$pr-white-dark:     #F2F2F2;
$pr-white-darker:   #E1E1E1;

$pr-btns: (
        "primary": (
            bg          : $pr-green,
            bgborder    : $pr-green,
            hover       : $pr-green-dark,
            hborder     : $pr-green-dark,
            focus       : $pr-green-darker,
            fborder     : $pr-green-darker,
            color       : $white,
        ),
        "secondary": (
            bg          : $pr-blue,
            bgborder    : $pr-blue,
            hover       : $pr-blue-dark,
            hborder     : $pr-blue-dark,
            focus       : $pr-blue-darker,
            fborder     : $pr-blue-darker,
            color       : $white,
        ),
        "secondary-outline": (
            bg          : $pr-blue,
            bgborder    : $white,
            hover       : $pr-blue-dark,
            hborder     : $white,
            focus       : $pr-blue-darker,
            fborder     : $white,
            color       : $white,

        ),
        "white": (
            bg          : $white,
            bgborder    : $white,
            hover       : $pr-white-dark,
            hborder     : $pr-white-dark,
            focus       : $pr-white-darker,
            fborder     : $pr-white-darker,
            color       : $black,
        ),
        "white-outline": (
            bg          : $white,
            bgborder    : #B1B5B9,
            hover       : darken($white, 5%),
            hborder     : #B1B5B9,
            focus       : darken($white, 10%),
            fborder     : #B1B5B9,
            color       : $black,
        ),
        "outline": (
            bg          : transparent,
            bgborder    : #B1B5B9,
            hover       : rgba(0, 0, 0, 0.05),
            hborder     : #B1B5B9,
            focus       : rgba(0, 0, 0, 0.1),
            fborder     : #B1B5B9,
            color       : $black,
        )
);
.pr-btn-group {
    position: relative;
    z-index: 9;
    margin: 64px 0 32px;
    display: flex;
    gap: 24px;
    @include media-breakpoint-down(md) {
        display: block;
        gap: unset;
        .pr-btn {
            &:first-of-type {
                margin-bottom: 24px;
            }
        }
    }
}
.pr-btn {
    font-weight: 900;
    font-size: 22px;
    line-height: 1;
    padding: 12px 24px;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    display: inline-block;
    text-align: center;
    // max-width: 320px;
    // white-space: nowrap;
    // overflow: hidden;
    @include media-breakpoint-down(md) {
        width: 100%;
    }
    @each $name, $value in $pr-btns {
        &.pr-#{$name}-btn {
            background-color: map-get($value, bg);
            border-color: map-get($value, bgborder);
            color:  map-get($value, color);
            &:hover {
                background-color: map-get($value, hover);
                border-color: map-get($value, hborder);
            }
            &:focus {
                &:hover {
                    background-color: map-get($value, focus);
                    border-color: map-get($value, fborder);
                }
            }
        }
    }
}

// LOGIN BUTTON <- SUPPORTING MENU
.supporting-menu > ul > li.login-btn  a, // old css
.pr-login-btn a {
    // @extend .pr-btn;
    // @extend .pr-white-outline-btn;
    width: 100px;
    padding: inherit;
    line-height: 30px !important;
    font-size: 11px;
    font-weight: normal;
    &:hover {
        color: #000 !important;
    }
}
// REGISTRATION BUTTON & DEMO BUTTON <- MAIN MENU
// .pr-login-mobile-btn,
// .pr-demo-btn,
// .pr-signup-btn {
//     font-size: 16px;
//     font-weight: 900;
//     line-height: 42px;
//     min-width: 150px;
//     text-align: center;
//     padding: 0 5px;
//     @include media-breakpoint-down(xl) {
//         font-weight: 400;
//         min-width: 110px;
//         line-height: 38px;
//         padding: 0 24px;
//     }
// }
.pr-phone-btn {
    min-width: 40px;
    padding: inherit;
    .telnum_icon {
        margin: auto;
    }
}
// .pr-demo-btn {
//     // min-width: 175px;
//     // max-width: 220px;
//     &:hover {
//         color: #000 !important;
//     }
// }
.lang-switcher {
    border-radius: 8px;
}
.supporting-menu > ul > li.login-btn {
    @extend .pr-btn;
    @extend .pr-white-outline-btn; 
    
        padding: 0 !important;
    
}