.currency-switcher {
    display: none;
}

.en .currency-switcher {
    display: block!important;
}


.m20{
    padding: 0 0 60px;
    .pricing_switcher{
        padding-left: 20%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        @include media-breakpoint-down(xl) {
            padding-left: -webkit-calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
            margin-top: 20px;
        }
        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }
        .billing-cycle{
            width: calc(100% - 230px);
            background-color: #E0E0E0;
            border-radius: 23.5px;
            border: 1px solid #E0E0E0;
            display: flex;
            height: 40px;
            position: relative;
            @include media-breakpoint-down(lg){
                width: 100%;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                height: 60px;
            }
            
            .billing {
                position: relative;
                width: 50%;
                z-index: 1;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                @include media-breakpoint-down(md) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                p {
                    margin: 1px;
                    padding: 7px 0;
                    text-align: center;
                    font-size: 18px;
                    line-height: 1.2;
                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                    }
                }
            }
            .selected_cycle {
                background-color: #FFF;
                width: 50%;
                height: 100%;
                position: absolute;
                border-radius: 23.5px;
                transition: right .25s;
                &.yearlyPlan {
                    right: 0;
                }
                &.monthlyPlan {
                    right: 50%;
                }
            }

            // label{
            //     width: 50%;
            //     line-height: 1.2;
            //     text-align: center;
            //     cursor: pointer;
            //     position: relative;
            //     font-size: 16px;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            // }
            // input[type="radio"]{
            //     visibility: hidden;
            //     &:checked{
            //         &:before{
            //             content: attr(data-text);
            //             width: 100%;
            //             height: 100%;
            //             position: absolute;
            //             background-color: $white;
            //             visibility: visible;
            //             top: 0;
            //             left: 0;
            //             border-radius: 23.5px;
            //             text-align: center;
            //             line-height: 1.2;
            //             font-size: 16px;
            //             font-weight: $regular;
            //             -webkit-transition: visibility 1.2s ease;
            //             -moz-transition: visibility 1.2s ease;
            //             -ms-transition: visibility 1.2s ease;
            //             -o-transition: visibility 1.2s ease;
            //             transition:  visibility 1.2s ease;
            //             display: flex;
            //             align-items: center;
            //             justify-content: center;
            //         }
            //     }
                
            // }
        }
        .currency-switcher{
            width: 220px;
            position: relative;
            z-index: 99;
            @include media-breakpoint-down(md) {
                width: 100%;
                height: 60px;
                margin-bottom: 10px;
            }
            button[name="currency-switcher"]{
                width: 100%;
                height: 100%;
                text-align: center;
                background: $white;
                border: 1px solid rgba(0,0,0,0.1);
                border-radius: 23.5px;
                -webkit-appearance: none;
                -moz-appearance: none;
                cursor: pointer;
                text-align-last: center;
                // text-indent: 42%;
                color: $black;
                // &:-ms-expand {
                //     display: none;
                // }
                // option{
                //     display: none;
                // }
                // &:focus {
                //     outline: none !important;
                // }
            }
            .currencies{
                display: none;
                position: absolute;
                top: 0;
                background: $white;
                border: 1px solid rgba(0,0,0,0.1);
                width: 100%;
                top: -40px;
                left: 0;
                border-radius: 23.5px;
                &.show{
                    display: block;
                }
                li{
                    font-size: 16px;
                    font-weight: $regular;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                    &.selected{
                        background-color: #E0E0E0;
                    }
                    &:hover{
                        background-color: #E0E0E0;
                    }
                    &:first-child{
                        border-radius: 23.5px 23.5px 0 0;
                    }
                    &:last-child{
                        border-radius: 0 0 23.5px 23.5px;
                    }
                }
            }
        }
    }
    .plans{
        .features{
            padding: 0;
            .per-month {
                color: $blue;
                font-weight: 700;
                height: 145px;
            }
            .feature{
                width: 100%;
                min-height: 60px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &:nth-child(even){
                    background-image: linear-gradient(270deg, rgba(0,0,0,0.05) 0%, rgba(255,255,255,0.07) 100%);
                }
                &:nth-child(odd){
                    background: transparent;
                }
                &.has-info{
                    position: relative;
                    cursor: pointer;
                    &:before{
                        content: "";
                        background-image: url(images/icons/info.svg);
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        right: 10px;
                        top: calc(50% - 10px);
                        background-color: $white;
                        border-radius: 50%;
                        z-index: 99;
                        cursor: pointer;
                    }
                    &:hover{
                        .feature-desc{
                            display: inline-table;
                            font-size: 12px;
                        }
                    }
                }
                &.medium{
                    min-height: 80px;
                }
                &.large{
                    min-height: 120px;
                }
                &:first-child{
                    border-top: 1px solid $border-color-light;
                    border-bottom: 1px solid $border-color-light;
                    p{
                        font-weight: $black-bold;
                    }
                }
                &:last-child{
                    margin-bottom: 20px;
                }
                p{
                    font-size: 15px;
                    font-weight: $regular;
                    line-height: 23px;
                    padding: 5px 24px 5px 0;
                    margin: 0;
                    color: $black;
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                    }
                }
                .feature-desc{
                    display: none;
                    font-size: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 95%;
                    width: 220px;
                    z-index: 9;
                    border-radius: 4px;
                    color: #747474;
                    letter-spacing: 0.31px;
                    padding: 5px 10px 5px 20px;
                    border: 1px solid #E1E1E1;
                    box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.30);
                    background: $white;
                    margin: auto;
                }
            }
            ul {
                margin-bottom: -1px
            }
        }
        .plan-container{
            padding-left: 5px;
            padding-right: 5px;
            &:nth-child(2){
                padding-left: 0;
            }
            @include media-breakpoint-down(lg) {
                align-self: flex-start !important;
                margin: 20px auto 35px;
            }
            .plan{
                border: 1px solid #0000001a;
                box-shadow: 0 2px 5px 0 #0000001a;
                border-radius: 10px;
                color: $blue;
                background-color: $white;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                @include media-breakpoint-down(lg) {
                    border: none;
                    box-shadow: none;
                    border-radius: 0;
                }
                &.premium{
                    background-image: linear-gradient(to bottom, #003264 0%, #0064C8 42%);
                    color: $white;
                    p{
                        color: $white;
                    }
                    .feature{
                        color: $white;
                        .check-mark{
                            filter: invert(99%) sepia(4%) saturate(624%) hue-rotate(276deg) brightness(117%) contrast(100%);
                        }
                    }
                }
                .plan-head{
                    @include media-breakpoint-down(md) {
                        &:lang(ar) {
                            font-family: 'Lato', sans-serif !important;
                            text-align: center;
                        }
                    }
                    h3{
                        font-size: 38px;
                        font-weight: $black-bold;
                        padding: 15px 15px 0;
                        line-height: 48px;
                        margin: 0 0 40px;
                        @include media-breakpoint-up(lg) {
                            &:lang(ar) {
                                font-family: 'Lato', sans-serif !important;
                                text-align: center;
                            }
                        }
                        &.has-subtitle{
                            margin: 0;
                        }
                        @include media-breakpoint-down(xl) {
                            font-size: 30px;
                        }
                    }
                    .prices{
                        &:lang(ar) {
                            text-align: center;
                        }
                        .prices-currency{
                            display: none;
                            &.show{
                                display: block;
                            }
                            .price{
                                display: none;
                                font-size: 30px;
                                font-weight: $black-bold;
                                margin: 0 0 40px;
                                @include media-breakpoint-down(xl) {
                                    font-size: 26px;
                                }
                                @include media-breakpoint-down(md) {
                                    font-size: 24px;
                                }
                                &.show{
                                    display: block;
                                }
                                span{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .cta-btn{
                        text-align: center;
                        color: $white;
                        margin: 0 auto 55px;
                        display: block;
                        max-width: calc(100% - 30px);
                    }
                }
                .plan-features{
                    @include media-breakpoint-down(lg) {
                        margin: 20px 0 0 30px;
                        padding: 0 15px;
                        &:lang(ar) {
                            margin: 20px 30px 0 0;
                        }
                    }
                }
                p{
                    font-size: 16px;
                    font-weight: $regular;
                    padding: 0 15px;
                    display: table;
                    width: 100%;
                    &.request-quote{
                        font-size: 20px;
                        font-weight: $bold;
                        margin: 0 0 30px;
                    }
                    &.subtitle{
                        font-weight: $bold;
                        line-height: 20px;
                        margin: 0 0 20px;
                        @include media-breakpoint-up(lg) {
                            &:lang(ar) {
                                text-align: center;
                            }
                        }
                        &:empty {
                            margin-bottom: 0; // QUICK FIX TO GO LIVE
                        }
                    }
                    
                    &.no-padding{
                        padding: 0;
                    }
                }
                .feature{
                    font-size: 16px;
                    font-weight: $regular;
                    padding: 0 15px;
                    display: table;
                    width: 100%;
                    text-align: center;
                    color: $black;
                    line-height: 23px;
                    min-height: 50px;
                    padding: 15px 0;
                    margin: 0;
                    min-height: 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    @include media-breakpoint-down(lg) {
                        min-height: auto !important;
                        text-align: left;
                        padding: 5px 0;
                        display: list-item;
                        &:lang(ar) {
                            text-align: right;
                        }
                    }
                    &.regular{
                        min-height: 60px;
                    }
                    &.medium{
                        min-height: 80px;
                    }
                    &.large{
                        min-height: 120px;
                    }
                    &:first-child{
                        font-weight: $black-bold;
                        border-top: 1px solid $border-color-light;
                        border-bottom: 1px solid $border-color-light;
                        @include media-breakpoint-down(lg) {
                            border: none;
                        }
                    }
                    &:nth-child(even){
                        background: rgba(0,0,0,0.05);
                        @include media-breakpoint-down(lg) {
                            background: transparent;
                        }
                    }
                    &:nth-child(odd){
                        background: transparent;
                    }
                    &:last-child{
                        margin-bottom: 18px;
                    }
                    &.has-info{
                        position: relative;
                        cursor: pointer;
                        flex-direction: row;
                        align-items: center;
                        .info-tip{
                            background-image: url(images/icons/info.svg);
                            width: 20px;
                            height: 20px;
                            background-color: $white;
                            border-radius: 50%;
                            cursor: pointer;
                            margin: 0 6px;
                        }
                        &:hover{
                            .feature-desc{
                                display: inline-table;
                                font-size: 14px;
                            }
                        }
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                    }
                    .check-mark{
                        width: 20px;
                        height: 20px;
                        display: block;
                        margin: auto;
                        background-image: url(images/icons/check.svg);
                        background-repeat: no-repeat;
                        background-size: contain;
                        filter: invert(22%) sepia(98%) saturate(2233%) hue-rotate(197deg) brightness(93%) contrast(103%);
                    }
                    ul{
                        list-style-type: disc;
                        margin-top: 5px;
                        padding-inline-start: 15px;
                        li{
                            line-height: 1.2;
                            margin-bottom: 8px;
                        }
                    }
                    .feature-desc{
                        display: none;
                        font-size: 0;
                        position: absolute;
                        top: 50px;
                        left: 50%;
                        width: 180px;
                        z-index: 999;
                        border-radius: 4px;
                        color: #747474;
                        letter-spacing: 0.31px;
                        padding: 5px 10px 5px 10px;
                        border: 1px solid #E1E1E1;
                        box-shadow: 3px 3px 6px 0 rgba(0,0,0,0.30);
                        background: $white;
                        margin: auto;
                        text-align: left;
                        &:lang(ar){
                            text-align: right;
                        }
                    }
                }
                .btn-container{
                    display: block;
                    height: 130px;
                }
            }
        }
        .modal{
            color: $black;
            .modal-header{
                .modal-title{
                    font-size: 20px;
                    font-weight: $medium;
                    margin: 0;
                }
                .close{
                    background: transparent;
                    border: none;
                    opacity: 0.5;
                    cursor: pointer;
                    &:hover{
                        opacity: 1;
                    }
                    span{
                        font-size: 24px;
                        font-weight: $bold;
                    }
                }
            }
            .modal-body{
                .form{
                    .contact-select-country {
                        @extend .tel_country_flags; 
                    }
                    #phone {
                        padding-left: calc(100px + 0.75rem);
                        &:lang(ar) {
                            padding-left: 0.75rem;
                            padding-right: calc(100px + 0.75rem);
                        }
                    }
                    .hbspt-form{
                        .hs-form-field{
                            padding: 0 5px;
                            margin-bottom: 10px;
                            &.hs-fieldtype-text{
                                width: 50%;
                                display: inline-block;
                                @include media-breakpoint-down(md) {
                                    width: 100%;
                                    display: block;
                                }
                            }
                            &.hs_contact_tos{
                                label{
                                    display: block;
                                    a{
                                        color: $blue;
                                        &:hover{
                                            text-decoration: underline;
                                        }
                                    }
                                }
                                input{
                                    width: auto;
                                    height: auto;
                                }
                            }
                            
                            label{
                                display: none;
                            }
                            input{
                                width: 100%;
                                height: 38px;
                                padding: 6px 12px;
                                font-size: 16px;
                                font-weight: $regular;
                                color: #495057;
                                background-clip: padding-box;
                                border: 1px solid #ced4da;
                                border-radius: 4px;
                            }
                            textarea{
                                width: 100%;
                                height: 250px;
                                resize: none;
                                padding: 6px 12px;
                                font-size: 16px;
                                font-weight: $regular;
                                color: #495057;
                                background-clip: padding-box;
                                border: 1px solid #ced4da;
                                border-radius: 4px;
                            }
                        }
                        input[type="submit"]{
                            background-color: $green;
                            color: $white;
                            font-weight: $black-bold;
                            font-size: 22px;
                            padding: 10px 30px;
                            border: none;
                            border-radius: 4px;
                            margin: 15px 10px 5px;
                            cursor: pointer;
                        }
                        .hs-error-msgs{
                            color: #f02929;
                            padding: 0 10px;
                        }
                    }
                }
            }
            .modal-footer{
                justify-content: center;
                margin: 0 16px;
                a{
                    color: $blue;
                    border-right: 1px solid $black;
                    padding: 0 10px;
                    margin: 10px 0;
                    text-align: center;
                    &:last-child{
                        border-right: none;
                    }
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .pl-0{
        padding-left: 0 !important;
    }
    .after-plans-txt{
        padding-left: 6px;
        margin: 0;
        font-size: 16px;
        font-weight: $medium;
        line-height: 1.5;
        color: #212529;
    }
    .form{
        input {
            height: 40px;
            margin: 0 12px 12px 0 !important;
            width: 100%;
            &[type="checkbox"] {
                width: 20px;
                height: 20px;
                border-radius: 2px;
                border: solid 1px #ced4da;
            }
        }
    }
}

/* AE */ 
body.ae,body.sa{
    .m20{
        .pricing_switcher{
            padding-left: 0;
            padding-right: 20%;
            @include media-breakpoint-down(xl) {
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 15px;
            }
            .billing-cycle{
                .selected_cycle{
                    &.monthlyPlan {
                        left: 50%;
                        right: auto;
                    }
                    &.yearlyPlan{
                        left: 0;
                        right: auto;
                    }
                }
            } 
        }
        .plans{
            .features{
                ul{
                    padding-left: 0; 
                }
                .feature{
                    p{
                        padding: 5px 0 5px 24px;
                    }
                    &.has-info{
                        &:before{
                            right: auto;
                            left: 10px;
                        }
                    }
                    &:nth-child(even){
                        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(255, 255, 255, 0.07) 100%);
                    }
                    .feature-desc{
                        left: auto;
                        right: 95%;
                    }
                }
            }
            .plan-container{
                &:nth-child(2){
                    padding-left: 5px;
                    padding-right: 0;
                }
            }
        }
    }   
}