.section-m57{
    display: flex;
    flex-direction: column;
    &.case-studies-bottom{
        flex-direction: column-reverse;
        .hero_cases {
            .cs-top-filter {
                margin: 32px auto 70px;
                position: unset;
                box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }
    &.rows-6,
    &.rows-8 {
        .container {
            .customers-row {
                overflow: hidden;
                position: relative;
            }
            .view-all-btn {
                position: absolute;
                bottom: 0;
                top: inherit !important;
                z-index: 999;
                height: 105px;
                background-color: #f7f7f7
            }
        }
    }
    &.rows-6 {
        .container {
            .customers-row {
                max-height: 1300px;
            }
        }
    }
    &.rows-8 {
        .container {
            .customers-row {
                max-height: 1700px;
            }
        }
    }
}
.customers-page-block {
    background: rgba(0,0,0,.03);
    padding: 60px 0 40px;
    .filters {
        margin: 0 0 50px;
        button {
            background-color: rgba(255,255,255,.5);
            border: 1px solid rgba(0,0,0,.05);
            text-align: left;
            color: #3D4548;
            margin-bottom: 12px;
            // .live &{
            //     border-radius: 2px;
            //     font-size: 18px;
            //     font-weight: 300; 
            //     padding: 10px 15px;
            // }
            &:focus {
                box-shadow: inherit;
            }
        }
        button.is-checked {
            color: #000;
            font-weight: 900;
            background: #FFF;
        }
        button.btn-filter-all {
            // @extend .btn;
            @extend .pr-secondary-btn;
            // background-color: #1d62ad;
            // color: #FFFFFF;
            // border-color: #1d62ad;
            // font-weight: 900;
            min-width: 275px;
            margin-top: 25px;
            text-align: center;
            // &:hover {
            //     background-color: #3d4548;
            //     border-color: #3d4548;
            // }
        }
    }
    .customer_mobile_filter {
        width: 100%;
        margin-bottom: 50px;
        font-size: 18px;
        letter-spacing: 1.12px;
        font-weight: 700;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 4px;
        padding: 15px;
        background-color: #FFF;
    }
    .customers-row {
        margin-left: -5px;
        margin-right: -5px;
        .customers-col {
            padding-left: 5px;
            padding-right: 5px;
            margin-bottom: 10px;
            height: 190px;
        }
    }
    .customers-container {
        background: #FFF;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,.1);
        border-radius: 10px;
        position: relative;
        padding: 22.5px;
        @include media-breakpoint-down(lg){
            padding: 10px 10px 30px;
        }
        @include media-breakpoint-down(md){
            padding: 22.5px;
        }
        p {
            margin: 0;
            color: #000;
        }
        p.cs-company {
            font-size: 18px;
            font-weight: 300;
        }
        p.cs-title {
            font-size: 22px;
            line-height: 26px;
            font-weight: 900;
            @include media-breakpoint-down(lg){
                font-size: 18px;
            }
        }
        p.cs-more {
            position: absolute;
            right: 25px;
            bottom: 25px;
            color: #1D62AD !important;
            text-decoration: underline;
            font-size: 18px;
            font-weight: 900;
            &:lang(ar) {
                right: inherit;
                left: 25px;
                @include media-breakpoint-down(lg){
                    right: inherit;
                    left: 15px;
                }
            }
            @include media-breakpoint-down(lg){
                font-size: 16px;
                right: 15px;
                bottom: 15px;
            }
        }
        img {
            height: 140px;
            width: auto;
            border-radius: 4px;
            @include media-breakpoint-down(xl){
                max-width: 92%;
                height: auto;
            }
        }
    }
    
    .customers-container-logo {
        padding: inherit;
        background: #FFF;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,.1);
        border-radius: 10px;
        height: 100%;
        width: 100%;
        height: 190px;
        .customers-logo {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 10px;
            img {
                display: block;
                max-width: 100%;
                height: auto;
                width: auto;
                margin: 0 auto;
                max-height: 100%;
            }
        }
        .logo_have_description {
            position: absolute;
            right: 10px;
            top: 10px;
            color: rgba(61,69,72,.5);
            cursor: pointer;
            font-size: 18px;
        }

    }
    .customers-container-logo.with-description {
        cursor: pointer;
    }
    .customers-container-logo.description-opened {
        width: calc(200% + 10px);
        height: calc(200% + 10px);
        position: absolute;
        z-index: 99;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        &::before {
            content: "";
            position: absolute;
            top: -20px;
            left: 20px;
            width: 42px;
            height: 42px;
            background-image: url('images/quotes.svg');
            background-repeat: no-repeat;
            background-size: contain;
            filter: invert(31%) sepia(84%) saturate(647%) hue-rotate(175deg) brightness(95%) contrast(98%);
        }
        .customers-logo {
            width: calc(50% - 5px);
            height: calc(50% - 5px);
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
        .logo_description {
            width: 100%;
            box-sizing: border-box;
            padding: 0 15px;
            p {
                // padding: 0 15px;
                margin: 0;
                font-size: 16px;
                color: #484E52;
                @include media-breakpoint-up(lg) {
                    width: 100%;
                    overflow: hidden;
                    height: auto;
                    max-height: 195px;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 10;
                    -webkit-box-orient: vertical;
                }
            }
            a {
                text-decoration: underline;
                font-weight: 700;
                color: #1d62ad;
            }
        }
        .logo_have_description {
            transform: rotate(45deg);
            color: #1D62AD;
        }
    }
}
.hero_cases {
    &.remove-top-padding{
        .cs-top-filter {
            margin: 32px auto 70px;
            position: unset;
            box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.1);
        }
    }
    .cs-top-filter {
        background: #FFF;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
        border-radius: 10px;
        margin-top: -65px;
        padding: 25px;
        color: #000;
        margin-bottom: 70px;
        width: 100%;
        &:not(:first-child){
            display: none;
        }
        &.ready{
            display: block;
        }
        @include media-breakpoint-down(xl) {
            margin-top: 0;
            border-radius: 0;
            box-shadow: none;
        }
        .col-lg-8 {
            position: relative;
            img {
                position: absolute;
                top: 0;
                right: 0;
                max-width: 150px;
                max-height: 50px;
                width: auto;
                height: auto;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }
    }
    h3 {
        font-weight: 900;
        font-size: 36px;
        letter-spacing: 0;
        line-height: 38px;
        width: 80%;
        margin: 8px 0;
    }
    p {
        margin: 0;
        color: #000;
        font-weight: 300;
        font-size: 18px;
        strong {
            font-weight: 900;
        }
    }
    a.btn {
        display: block;
        width: 180px;
        border-color: $blue;
        margin-top: 30px;
        font-size: 22px;
        font-weight: 900;
        &:hover {
            border-color: #003264;
        }
    }
}