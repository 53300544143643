.m84{
    padding: 70px 0;
    h2{
        padding-bottom: 25px;
    }
    iframe{
        width: 100%;
        height: auto;
        min-height: 400px;
    }
    #player {
        padding: 0 !important;
        background: #fff !important;
    }
    .plyr--full-ui.plyr--video .plyr__control--overlaid{
        border: 2px solid #fff !important;
        background-color: transparent !important;
    }
    .plyr--vimeo{
        position: relative;
        &::before{
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #00000047;
            z-index: 1;
        }
        &.plyr--playing{
            &::before{
                display: none;
            }
        }
    }
}