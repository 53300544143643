.m96 {
    margin: 100px 0 0;
    .accordion {
        .accordion-item {
            border: none;
            border-bottom: 1px solid #DDD;
            .accordion-header {
                a span {
                    display: block;
                    &.time {
                        width: 75px;
                        font-weight: 300;
                        font-size: 22px;
                    }
                    &.title {
                        width: calc(100% - 200px);
                        font-size: 18px;
                        @include media-breakpoint-down(md) {
                            width: calc(100% - 75px);
                            .room {
                                font-size: 16px;
                                display: block;
                            }
                        }
                    }
                }
                .accordion-button{
                    cursor: pointer;
                }
                .accordion-button:not(.collapsed) {
                    background-color: #F0F0F0;
                    border-bottom: none;
                    color: #000;
                    &::after {
                        background-image: url('images/plus.svg');
                        transform: rotate(45deg);
                    }
                }
                .accordion-button.collapsed::after {
                    background-image: url('images/plus.svg');
                }
                a:hover {
                    color: #000;
                    background-color: #F0F0F0;
                }
            }
            

            
            .accordion-header a.nodesc-button {
                display: flex;
                align-items: center;
                padding: 1rem 1.25rem;
                font-size: 1rem;
                background-color: #F8F8F8;
            }
        }
    }
}