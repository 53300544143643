.m51{
    color: #3d4548;
    h2{
        text-align: center;
        padding: 25px 0;
    }
    .categories{
        text-align: center;
        margin-bottom: 25px;
        .cat{
            width: 175px;
            font-size: 13px;
            font-weight: $bold;
            color: #3d4548;
            &.active{
                background-color: $blue;
                border-color: $blue;
                color: $white;
            }
            &:hover{
                background-color: $blue;
                color: $white;
            }
        }
        button:nth-child(2) {
            display: none;
        }
    }
    .mob-categories{
        text-align: center;
        position: relative;
        width: 600px;
        max-width: 90%;
        margin: 0 auto;
        .mob-cat{
            width: 100%;
            display: block;
            background-image: url(images/customers_filter.png);
            background-repeat: no-repeat;
            background-position: 15px center;
            line-height: 1.2;
            &:hover{
                background-color: $blue;
            }
        }
        .mob-cats{
            position: absolute;
            display: none;
            width: 100%;
            background-color: $white;
            z-index: 9;
            padding: 10px 20px;
            &.show{
                display: block;
            }
            li{
                font-size: 16px;
                font-weight: $bold;
                line-height: 35px;
                cursor: pointer;
                &.active{
                    display: none;
                }
                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
    p{
        font-size: 16px;
        @include media-breakpoint-down(lg) {
            padding-top: 25px;
        }
        a{
            color: $blue;
            &:hover{
                text-decoration: underline;
            }
        }
        &.press-desc{
            font-weight: $bold;
            margin: 30px 0;
        }
    }
    .langs{
        padding: 25px 0;
        margin: 5px auto 16px;
        position: relative;
        label{
            font-weight: $bold;
        }
        .bordered-btn{
            border-color: $blue;
            font-size: 16px;
            font-weight: $regular;
            padding: 6px 12px;
        }
    }
    .category{
        display: none;
        padding: 0 20px 15px;
        border-bottom: 1px solid #d9d9d9;
        &.active{
            display: block;
        }
        .article{
            display: flex;
            cursor: pointer;
            padding: 15px 0;
            &:not(:first-child){
                border-top: 1px solid #d9d9d9;
            }
            &.hide{
                display: none;
            }
            &.show{
                display: flex;
            }
            .image{
                img{
                    width: 100%;
                    height: auto;
                }
            }
            .content{
                &.collapsed{
                    color: $blue;
                }
                &:hover{
                    color: #3d4548;
                }
                h3{
                    font-size: 20px;
                    font-weight: $bold;
                }
                span{

                }
            }
        }
        a {
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
            &:last-child {
                margin-left: 15px;
                @include media-breakpoint-down(lg) {
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }
    }
    button#languages {
        font-size: 13px;
        font-weight: 400;
    }
    a.dropdown-item {
        margin-left: 0 !important;
    }
}