.m06 {
    background-color: #e9f0fa;
    border-bottom: 3px solid #1d62ad;
    padding: 50px 0 0;
    h3 {
        color: $light-blue;
        font-weight: $bold;
        font-size: 17px;
        letter-spacing: 1px;
        margin-bottom: 50px;
        margin-top: 15px;
        &:lang(ar) {
            letter-spacing: 0;
        }
    }
    .col-md-3 {
        position: relative;
        &:lang(ar) {
            &:not(:last-child)::after {
                right: auto;
                left: -7px;
                transform: rotate(180deg);
            }
        }
        @include media-breakpoint-up(lg) {
            &::before {
                content: " ";
                position: absolute;
                bottom: -16px;
                left: calc(50% - 20px);
                width: 41px;
                height: 56px;
                background-image: url('images/icons/check_mark.png');   
            }
            &:not(:last-child)::after {
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 15px solid #0074b0;
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                z-index: 1;
                right: -7px;
                bottom: -9px;
            }
        }

    }
}