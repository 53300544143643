.m74 {
  margin-bottom: 32px;//100px;
  @include media-breakpoint-down(lg) {
    padding: 32px 20px 0;
  }
  .container {
    //background-image: linear-gradient(to right, $dark-blue 0%, $blue 100%);
    
    @include media-breakpoint-down(lg) {
      border-radius: 8px;
      padding: 0 20px 20px;
    }
    .row {
      position: relative;
    }
    .m74-slider {
      background-color: $blue;
      padding-left: 0;
      padding-right: 0;
      min-height: 408px;
      overflow: hidden;
      @include media-breakpoint-down(lg) {
        height: auto;
        margin-top: -20px;
        margin-bottom: 10px;
        border-radius: 8px;
      }
      &.slick-initialized {
        overflow: initial;
      }
      &.slick-dotted.slick-slider{
        margin-bottom: 0;
        padding-bottom: 40px;
      }
      &.only-child{
        margin-bottom: 30px;
        .slick-dots{
          display: none;
        }
        & + .arrow{
          img{
            display: none !important;
          }
        }
      }
      .white-line {
        height: 30px;
        background-color: $white;
        padding: 0;
        margin: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
      }

      .slide {
        padding: 30px 64px 0;
        @include media-breakpoint-down(md) {
          padding: 20px;
        }
        .row{
          @include media-breakpoint-down(lg) {
            flex-direction: column-reverse;
            align-items: center;
          }
        }
        img {
          max-width: 408px;
          // margin-top: -30px;
          //height: 408px;
          max-height: 408px;
          margin: -30px auto 0;
          width: auto;
          height: auto;
          @include media-breakpoint-down(xl) {
            max-width: 99%;
          }
          @include media-breakpoint-down(lg) {
            max-width: 215px;
            margin: 0px auto;
            height: auto;
          }
        }

        .title {
          color: $white;
          margin: 0 0 40px 1px;
          padding: 0;
          font-size: 54px;
          font-weight: $black-bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.1;
          letter-spacing: normal;
          &:first-child {
            margin: 74px 0 40px 1px !important;
            @include media-breakpoint-down(lg) {
              margin: 20px 0 40px 1px !important;
            }
          }
          @include media-breakpoint-down(lg) {
            font-size: 27px;
          }
        }

        .pre-title {
          color: $white;
          margin: 45px 0 4px 0; //75px 0 4px 0;
          padding: 0;
          font-size: 27px;
          font-weight: $black-bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.1;
          letter-spacing: normal;
          @include media-breakpoint-down(lg) {
            margin: 20px 0 4px 0;
            font-size: 18px;
          }
        }

        p {
          color: $white;
          margin: 0;
          padding: 0;
          font-size: 18px;
          font-weight: $regular;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        a {
          @extend .pr-btn;
          @extend .pr-white-btn;
          color: $blue !important;
          margin: 64px 0 0;
          // border-radius: 8px;
          // background-color: #fff;
          // font-size: 22px;
          // font-weight: $black-bold;
          // font-stretch: normal;
          // font-style: normal;
          // letter-spacing: normal;
          text-align: center;
          display: block;
          // // width: 290px;
          // // bottom: 0;
          // // position: absolute;

          width: 270px;
          // padding: 9px;
          // line-height: normal;


          @include media-breakpoint-down(lg) {
            position: unset;
            margin: 20px auto 30px;
          }
          // &:hover,
          // &.button:hover {
          //   filter: brightness(90%);
          // }
          &.img-link {
            background-color: inherit !important;
            display: block;
            border: none;
            &:hover {
              filter: brightness(100%);
            }
          }
        }
      }

      .slick-dots {
        bottom: 20px;//-3px;
        li {
          width: 14px;
          height: 14px;
          background-color: transparent !important;
          button {
            width: 14px;
            height: 14px;
            padding: 0;
            margin: 0;

            &:before {
              height: 14px;
              width: 14px;
              font-size: 14px;
              color: $white;
              opacity: 0.4;
              line-height: 14px;
            }
          }

          &.slick-active button {
            &:before {
              color: $white;
              opacity: 1;
              width: 14px;
              height: 14px;
              font-size: 14px;
              line-height: 14px;
            }
          }
        }
      }
    }

    .arrow {
      width: 45px;
      height: calc(100% - 30px);
      background-color: $blue;//$dark-blue;
      position: absolute;
      bottom: 0;
      // left: -45px;
      left: 10px;
      z-index: 99;
      &:hover {
        cursor: pointer;
      }
      &:has(+ .only-child) {
        img{
          display: none !important;
        }
      }
      img {
        margin-bottom: -5px;
      }

      &.arrow-left {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      
        img {
          margin-left: 15px;
          transition: margin 20ms;
        }

        &:hover {
          img {
            margin-left: 13px;
            transition: margin 20ms;
          }
        }
      }

      &.arrow-right {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        //right: -45px !important;
        right: 10px !important;
        left: initial !important;
        background-color: $blue;

        img {
          transform: rotate(180deg);
          margin-left: 15px;
          transition: margin 20ms;
        }

        &:hover {
          img {
            margin-left: 17px;
            transition: margin 20ms;
          }
        }
      }

      .helper {
        display: inline-block;
        height: 100%;
        vertical-align: middle;

      }
    }

    &.light-background {
      .m74-slider {
        background-color: #f3f7fd;//rgba(0, 100, 200, 0.05);
        background-image: initial;
        @include media-breakpoint-down(lg) {
          background-color: #f3f7fd;//rgba(0, 100, 200, 0.1);
        }
        .slide {
          .title {
            color: $black;
            @include media-breakpoint-down(lg) {
              color: $blue;
            }
          }

          .pre-title {
            color: $black;
            @include media-breakpoint-down(lg) {
              color: $blue;
            }
          }

          p {
            color: $black;
          }

          a:not(.img-link) {
            @extend .pr-secondary-btn;
            color: #fff !important;
          }
        }

        .slick-dots {
          li {
            background-color: transparent !important;
            button {
              &:before {
                color: $black;
              }
            }

            &.slick-active button {
              &:before {
                color: $black;
              }
            }
          }
        }
      }

      .arrow {
        background-color: #f3f7fd;//rgba(0, 100, 200, 0.05);

        &.arrow-right {
          background-color: #f3f7fd;//rgba(0, 100, 200, 0.05);
        }

        img {
          filter: grayscale(1) invert(1);
        }
      }
    }
  }
}

.m74-new {
  padding: 64px 0;
  &.dark {
    background-color: #f3f7fd;//rgba(0, 100, 200, 0.05);
    margin: 32px 0;
  }
  @include media-breakpoint-down(md) {
    margin: 24px 21px;
    padding: 32px 0;
  }
  .m74-logo-slider{
    position: relative;
    @include media-breakpoint-down(md) {
      &:lang(ar) {
        direction: ltr;
      }
    }
    .m74-logos {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 22px;
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        margin-bottom: 32px;
        flex-wrap: nowrap;
        justify-content: left;
        gap: 0;
        width: calc(100% - 80px);
        margin-left: 40px;
        overflow: hidden;
      }
      .slide-logo {
        padding: 12px;
        height: 84px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        min-width: 84px;
        cursor: pointer;
        @include media-breakpoint-down(md) {
          flex: 0 0 33.33%;
          width: 33.33%;
          min-width: inherit;
          min-height: inherit;
          box-sizing: border-box;
        }
        &.active {
          background-color: #fff;
          img {
            filter: inherit;
            opacity: 1;
          }
        }
        img {
          max-height: 60px;
          max-width: 120px;
          width: 100%;
          height: auto;
          filter: grayscale(100%);
          opacity: 50%;
          @include media-breakpoint-down(md) {
            max-width: 66px;
            max-height: 48px;
            width: auto;
            height: auto;
            display: block;
            margin: auto;
          }
        }
      }
    }
    .m74-nav {
      display: none;
      @include media-breakpoint-down(md) {
        display: block;
      }
      .next, .prev {
        position: absolute;
        top: calc(50% - 20px);
        background-image: url('data:image/svg+xml,<svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M26.0064 8.71054L9.90835 19.9643L26.0064 31.218L26.0064 8.71054Z" fill="black"/></svg>');
        width: 40px;
        height: 40px;
        cursor: pointer;
        opacity: 0.25;
        &.active {
          filter: invert(17%) sepia(93%) saturate(5650%) hue-rotate(201deg) brightness(97%) contrast(101%);
          opacity: 1;
        }
      }
      .next{
        right: 0;
        transform: rotate(180deg);
      }
      .prev {
        left: 0;
      }
    }
  }
  .m74-content {
    min-height: 250px;
    .slide-content {
      display: none;
      // height: 375px;
      // @include media-breakpoint-down(md) {
      //   height: inherit;
      // }
      &.active {
        display: block !important;
      }
      .img {
        position: relative;
        img {
          width: 100%;
          height: auto;
          border-radius: 8px; 
        }
        .play_button {
          width: 100px;
          height: 100px;
          position: absolute;
          left: calc(50% - 50px);
          top: calc(50% - 50px);
          background-image: url('images/icon_play.svg');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .content-block {
        padding: 0 0 0 28px;
        height: 100%;
        position: relative;
        @include media-breakpoint-down(md) {
          padding: 0;
        }
        .quote_icon {
          width: 56px;
          height: 56px;
          margin-bottom: 40px;
          background-image: url('images/icon_quote.svg');
          @include media-breakpoint-down(md) {
            margin: 32px 0 0;
          }
        }
        .m74-content-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100% - 100px);
          p {
            font-size: 22px;
            line-height: 1.5;
            @include media-breakpoint-down(md) {
              margin: 28px 0;
            }
          }
          img {
            object-fit: contain;
            margin-top: 10px;
          }
          &.xl {
            p:first-of-type {
              font-size: 28px;
              line-height: 36px;
              max-width: 400px;
              span {
                font-weight: $black-bold;
                color: $blue;
              }
            }
          }
          .details {
            // position: absolute;
            // left: 28px;
            // bottom: 0px;
            width: 100%;
            &:lang(ar) {
              .col-lg-5.text-lg-end {
                text-align: left !important;
              }
            }
            @include media-breakpoint-down(md) {
              position: inherit;
            }
            p {
              font-size: 18px !important;
              margin: 0;
              line-height: 1.2 !important;
              max-width: inherit;
              &:first-child {
                font-weight: $black-bold !important;
              }
            }
            a {
              color: $blue;
              font-weight: $black-bold;
              font-size: 18px;
              display: block;
              @include media-breakpoint-down(lg) {
                margin-top: 44px;
                font-size: 18px;
              }
              &:after {
                content: '';
                margin-left: 6px;
                background-image: url('data:image/svg+xml,<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.96232 2.00188L12.1068 6.85742L4.96232 11.713L4.96232 2.00188Z" fill="%230064C8"/></svg>');
                width: 12px;
                height: 12px;
                display: inline-block;
              }
              &:lang(ar)::after {
                transform: rotate(180deg);
                margin-left: inherit;
                margin-right: 6px;
              }
            }
          }
        }


        &.inline {
          display: flex;
          flex-wrap: wrap;
          .quote_icon {
            margin: 0 40px 0 0;
          }
          .m74-content-right {
            width: calc(100% - 96px);
          }
          .details {
            position: inherit;
            margin-top: 64px;
            p {
              display: inline-block;
            }
            img{
              display: block;
              width: 140px;
              height: auto;
              margin-top: 12px;
            }
          }
        }
      }
      &.single {
        .col-md-5 .img img {
          width: 266px;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
  .cta {
    text-align: center;
    margin-top: 32px;
    &.cta-top {
      margin-top: 0;
      margin-bottom: 64px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 32px;
      }
    }
    a {
      font-size: 22px;
      color: #ffffff;
      min-width: 280px;
      max-width: 100%;
      font-weight: 900;
    }
  }
}