.m12 {
    //padding: 64px 0;
    padding: 32px 0;
    color: $black;
    @include media-breakpoint-down(md) {
        text-align: center;
    }
    &.bg-gray{
        .box{
            background: #FFFFFF;
        }
    }
    &.bg-white{
        .box{
            background: #F7F7F7;
        }
    }

    .title {
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 60px;
        margin-bottom: 58px;
        text-align: center;
        &.left {
            text-align: left;
            &:lang(ar) {
                text-align: right;
            }
        }
        &.center {
            text-align: center !important;
            margin: 0 auto 32px;
            line-height: 1.15;
            max-width: 980px;
            @include media-breakpoint-down(md) {
                // text-align: left;
                line-height: 42px;
                font-size: 38px;
                letter-spacing: inherit;
                margin-bottom: 40px;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 40px;
        }
        & + .subtitle {
            // margin-top: -30px;
        }
    }
    .subtitle{
        font-weight: $regular;
        font-size: 18px;
        line-height: 1.5;
        text-align: center;
        margin: 0 auto 60px;
        width: 100%;
        max-width: 900px;
    }
    .desc {
        font-size: 18px;
        font-weight: $bold;
        margin: 0 0 25px;
        min-height: 44px;
    }
    .gbx-row {
        margin-left: -35px;
        margin-right: -35px;
        @include media-breakpoint-down(xl) {
            margin-left: calc(-.5 * var(--bs-gutter-x));
            margin-right: calc(-.5 * var(--bs-gutter-x));
        }
        .col-lg-3 {
            padding-left: 35px;
            padding-right: 35px;
            margin-bottom: 53px;
            @include media-breakpoint-down(xl) {
                padding-right: calc(var(--bs-gutter-x) * .5);
                padding-left: calc(var(--bs-gutter-x) * .5);
                margin-bottom: 30px;
            }
            @include media-breakpoint-down(xl) {
                width: 100%;
                margin-bottom: 12px;
            }
        }
    }
    .vi-row {
        margin-left: -48px;//-77px;
        margin-right: -48px;//-77px;
        @include media-breakpoint-down(lg) {
            margin-left: calc(-.5 * var(--bs-gutter-x));
            margin-right: calc(-.5 * var(--bs-gutter-x));
        }
        .col-md-4 {
            padding-left: 48px;//77px;
            padding-right: 48px;//77px;
            @include media-breakpoint-down(lg) {
                padding-right: calc(var(--bs-gutter-x) * .5);
                padding-left: calc(var(--bs-gutter-x) * .5);
                margin-bottom: 80px;
            }
            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
            }
        }
    }
    &.align-left{
        .title{
            @include media-breakpoint-down(md) {
                text-align: left !important;
                &:lang(ar) {
                    text-align: right;
                }
            }
        }
        .block{
            @include media-breakpoint-down(md) {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                .block-title,.block-desc{
                    text-align: left;
                    &:lang(ar) {
                        text-align: right;
                    }
                }

            }
        }
    }
    .block {
        @include media-breakpoint-down(md) {
            margin-bottom: 25px;
        }
        &.box{
            border: 1.0571px solid rgba(60, 70, 80, 0.1);
            border-radius: 10.571px;
            padding: 28px 0px 28px 21px;
            display: flex;
            align-items: center;
            .block-title{
                font-size: 18px;
                font-weight: 900;
                margin: 0 10px;
            }
        }
        .block-icon {
            width: 68px;
            height: 68px;

            &.big {
                width: 100px;
                height: 100px;
            }
        }

        .block-title {
            font-size: 22px;
            font-weight: $black-bold;
            margin-bottom: 18px;
            margin-top: 30px;
            line-height: 1.5;
            &:lang(ar) {
                line-height: 1.5;
            }
        }

        .block-desc {
            font-size: 18px;
            font-weight: $light;
            max-width: 300px;
            line-height: 22px;
            font-weight: $regular;
            &:lang(ar) {
                text-align: right;
            }

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }

        .block-lesson {
            display: inline-flex;
            align-items: center;

            img {
                display: block;
                width: 50px;
                height: auto;
                &.big{
                    width: 68px;
                }
            }

            p {
                padding-left: 0;
                font-size: 18px;
                margin: 0 0 0 8px;
                font-weight: 900;
                color: #0064c8;
            }
        }

        &.horizontal {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 16px 0;
            text-align: left;

            .block-icon {
                margin-right: 25px;
                &:lang(ar) {
                    margin-right: inherit;
                    margin-left: 25px;
                }
            }

            .block-desc {
                max-width: 100%;
                margin: 0;
                line-height: 27px;

                strong {
                    font-weight: $black-bold;
                }
            }
        }

        &.horizontal2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 16px 0;
            text-align: left;

            .block-icon {
                margin-right: 25px;
            }

            .block-desc {
                margin: 0;
                font-size: 22px;
                font-weight: 300;
                max-width: 300px;

                strong {
                    font-weight: $black-bold;
                }
            }
        }

        &.horizontal4 {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin: 16px 0;
            text-align: left;
            &:lang(ar) {
                text-align: right;
            }

            .block-icon {
                margin-right: 15px;
                &:lang(ar) {
                    margin-right: inherit;
                    margin-left: 15px;
                }
            }

            .block-desc {
                max-width: 100%;
                margin: 0;
                line-height: 1.5;
                font-size: 16px;

                strong {
                    font-weight: $black-bold;
                }
            }
        }

        &.vertical2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 475px;
            margin: 0 auto 35px;

            .block-desc {
                max-width: 100%;
                line-height: 1.5;
                font-size: 18px;
                font-weight: 300;

                strong {
                    font-weight: $black-bold;
                }
            }
        }
        &.greybox {
            background-color: #F8F8F8;
            padding: 23px;
            height: 100%;
            border-radius: 8px;
            @include media-breakpoint-down(lg) {
                text-align: left;
                height: inherit;
                margin-bottom: 0;
            }
            .block-lesson {
                margin-bottom: 24px;
                position: relative;
                width: 100%;
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                    &:before {
                        content: "\f055";
                        font-family: 'FontAwesome';
                        font-size: 24px;
                        line-height: 20.5px;
                        position: absolute;
                        right: 0;
                        top: calc(50% - 12px);
                        color: #E0E5E9;
                        transform: rotate(360deg);
                        transition: all 1s ease;
                    }
                }
                img {
                    max-width: 30px;
                    max-height: 30px;
                    width: auto;
                    height: auto;
                    @include media-breakpoint-down(lg) {
                        max-width: 40px;
                        max-height: 40px;
                    }
                }
                p {
                    color: $black;
                    @include media-breakpoint-down(lg) {
                        font-size: 22px;
                        margin-left: 15px;
                    }
                }
            }
            @include media-breakpoint-down(lg) {
                &.opened {
                    .block-lesson {
                        &::before {
                            transform: rotate(45deg);
                            transition: all 1s ease;
                        }
                    }

                    .block-desc {
                        display: block;
                    }
                }
                .block-desc {
                    display: none;
                    padding-top: 10px;
                    font-size: 22px;
                    margin-bottom: 0;
                }
            }
        }
        &.vertical-icon {
            height: 100%;
            padding-bottom: 70px;
            position: relative;
            @include media-breakpoint-down(md) {
                padding-bottom: 40px;
            }
            .block-lesson {
                width: 100%;
                margin-bottom: 13px;
                height: 60px;
                @include media-breakpoint-down(md) {
                    text-align: left;
                }
                img {
                    order: 1;
                    // max-height: 40px;
                    // max-width: 40px;
                    width: auto;
                    height: 40px;
                    // margin-top: -15px;
                    margin-left: auto;
                }
                p {
                    order: 0;
                    width: calc(100% - 60px);
                    margin: 0;
                    color: $black;
                    // height: 55px;
                    overflow: hidden;
                    font-size: 22px;
                    @include media-breakpoint-down(md) {
                        br {
                            display: none;
                        }
                    }
                }
            }
            @include media-breakpoint-down(md) {
                .block-desc {
                    text-align: left;
                }
            }
            .pr-btn {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                font-weight: 900;
                font-size: 22px;
                text-align: center;
            }
        }
    }
}
