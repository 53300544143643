.m19{
    // background-image: linear-gradient(225deg, #0064C8 0%, #003264 100%);
    background-color: $blue;
    color: $white;
    padding: 64px 0; //50px 0;
    h2{
        font-size: 40px;
        font-weight: $black-bold;
        line-height: 1.15;
        margin-top: 0;
        margin-bottom: 32px;
        //letter-spacing: 0.69px;
        &:lang(ar) {
            line-height: 1.5;
        }
    }
    .features{
        padding-right: 0;
        p{
            font-weight: $black-bold;
            line-height: 23px;
            min-height: 50px;
            padding: 15px 0;
            margin: 0;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.regular{
                min-height: 50px;
            }
            &.medium{
                min-height: 77px;
            }
            &.large{
                min-height: 120px;
            }
            &:first-child{
                border-top: 1px solid $border-color-light;
                border-bottom: 1px solid $border-color-light;
            }
            &:last-child{
                margin-bottom: 20px;
            }
        }
    }
    .plan-container{
        padding-left: 5px;
        padding-right: 5px;
        width: 20%;
        @include media-breakpoint-down(lg) {
            width: 100%;
            margin-top: 15px;
            padding: 0 15px;
        }
        &:nth-child(2){
            padding-left: 0;
            @include media-breakpoint-down(lg) {
                padding: 0 15px;
            }
        }
        &:last-child{
            padding-right: 12px;
        }
        .plan{
            background-color: $white;
            border: 1px solid #0000001a;
            box-shadow: 0 2px 5px 0 #0000001a;
            border-radius: 10px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            
            .plan-head{
    
            }
            h3{
                color: $blue;
                font-size: 27px; //38px;
                font-weight: $black-bold;
                padding: 32px 20px 24px;
                line-height: 1.25;//48px;
                margin: 0;
                &:lang(ar) {
                    font-family: 'Lato', sans-serif !important;
                    text-align: center;
                }
            }
            p{
                color: $black;
                font-size: 18px;
                font-weight: $regular;
                padding: 0 20px;
                line-height: 1.5;
                margin: 0 0 16px;
                &.feature{
                    text-align: center;
                    font-weight: $black-bold;
                    line-height: 23px;
                    min-height: 50px;
                    padding: 15px 0;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include media-breakpoint-down(lg) {
                        display: block;
                    }
                    &.regular{
                        min-height: 50px;
                    }
                    &.medium{
                        min-height: 77px;
                        margin-bottom: 19px;
                    }
                    &.large{
                        min-height: 120px;
                    }
                    &:first-child{
                        border-top: 1px solid $border-color-light;
                        border-bottom: 1px solid $border-color-light;
                    }
                    &:nth-child(even){
                        background: rgba(0,0,0,0.05);
                    }
                    &:nth-child(odd){
                        background: $white;
                    }
                    &:last-child{
                        margin-bottom: 18px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                    }
                    
                }
            }
            .plan-features{
            }
        }
    }
    .pr-btn {
        margin-top: 31px; //50px;
    }
}