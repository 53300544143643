.m78 {
    .clientsLogo {
        margin-bottom: 25px;
        >div {
            background-color: #f9f9f9;
            border: 1px solid #F0F0F0;
            padding: 10px;
            height: 100%;
            .logo-filter-img {
                height: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    display: block;
                    margin-bottom: 15px;
                    max-width: 200px;
                    max-height: 200px;
                    width: auto;
                    height: auto;
                }
            }
            p {
                margin: 0;
                font-size: 16px;
                span {
                    font-weight: 900;
                }
            }
        }
        
    }
}