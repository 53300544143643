// main css is in m90
.m89 {
    .breadcrumb {
        padding: 60px 0 34px;
        li {
            font-size: 14px;
            a {
                font-weight: 700;
                color: $black;
            }
            &.active {
                font-weight: 300;
                color: $black;
            }
        }
    }
    .all-posts {
        a {
            margin-bottom: 12px;
            display: block;
            .new-blog-post {
                display: flex;
                gap: 33px;
                padding: 33px;
                @include media-breakpoint-down(lg) {
                    display: block;
                    padding-bottom: 66px;
                }
                img {
                    max-width: 100%;
                    height: 207px;
                    width: 315px;
                    margin-bottom: 0;
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 22px;
                    }
                }
                .new-blog-post-content {
                    position: relative;
                    width: calc(100% - 315px);
                    @include media-breakpoint-down(lg) {
                        width: 100%;
                    }
                    .new-post-data {
                        width: 100%;
                        bottom: 0;
                        @include media-breakpoint-down(lg) {
                            bottom: -33px;
                        }
                    }
                    p.new-post-content {
                        display: block;
                    }
                }
            }
            &:hover {
                color: inherit;
            }
        }
    }

    .posts-count {
        p {
            text-align: right;
            font-weight: 700;
            font-size: 18px;
            margin: 0 0 33px;
            @include media-breakpoint-down(lg) {
                font-size: 14px;
                margin: 28px 0;
                
            }
        }
    }
    .new-blog-design {
        ul {
            li {
                a {
                    font-size: 14px;
                    font-weight: 700;
                    padding: 2px 8px;
                }
                &.pr-active {
                    span {
                        background-color: $blue;
                        border-radius: 4px !important;
                        padding: 6px 8px;
                        line-height: 14px;
                    }
                }
                .page-link.dots {
                    background-color: inherit;
                    padding: 2px 8px;
                    border-color: #FFF;
                }
                a.next,
                a.prev {
                    background-color: $blue;
                    padding: 7px 9px;
                    color: $white;
                    border-radius: 4px !important;
                    line-height: 14px;
                }
            }
        }
    }
    .new-blog-mobile-nav {
        border-radius: 8px;
        background-color: #F7F7F7;
        border: 1px solid #DCDCDC;
        position: relative;
        p {
            margin-bottom: 0;
            padding: 14px 21px;
            font-size: 18px;
            font-weight: 700;
            position: relative;
            &::after {
                content: "";
                background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=");
                background-repeat: no-repeat;
                display: block;
                background-size: contain;
                width: 10px;
                height: 10px;
                position: absolute;
                right: 21px;
                top: 20px;
            }
            &.opened::after {
                transform: rotate(90deg);
                transition: all 0.25s ease;
            }
        }
        ul {
            width: 100%;
            position: relative;
            left: 0;
            &.blog-children.move {
                left: -100%;
                position: absolute;
                transition: left .25s ease;
                > li {
                    position: static;
                }
            }
            li {
                padding: 14px 21px;
                font-size: 18px;
                font-weight: 300;
                position: relative;
                &::after {
                    content: "";
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEgMTJsLTE4IDEydi0yNHoiLz48L3N2Zz4=");
                    background-repeat: no-repeat;
                    display: block;
                    background-size: contain;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 21px;
                    top: 20px;
                }
                ul {
                    position: absolute;
                    left: 100%;
                    top: 0;
                    li {
                        a {
                            color: $black;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}